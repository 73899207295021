import { Component, OnInit, Inject, ViewChild, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { MapService } from 'src/app/services/map.service';
import { ECar } from 'src/app/services/account.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { timer } from 'rxjs';
import { first } from 'rxjs/operators';
import device from 'current-device';

@Component({
  selector: 'app-car-selector-dialog',
  templateUrl: './car-selector-dialog.component.html',
  styleUrls: ['./car-selector-dialog.component.css']
})
export class CarModelSelectorDialogComponent implements OnInit {

  @ViewChild('carSelectorScrollbar', { static: true }) carSelectorScrollbar: NgScrollbar;
  @HostListener('window:resize') onResize() {
    if (device.mobile() || device.tablet()) {
      this.scrollToSelectedCar();
    }
  }
  ECars: ECar[] = [];
  CarTypes = ["all", "audi", "bmw", "chevrolet", "citroen", "ford", "honda", "hyundai", "jaguar", "kia", "lightyear", "lucidair", "mercedes", "mg", "mitsubishi", "nissan", "opel",
  "peugeot", "renault", "porsche", "seat", "smart", "skoda", "tesla", "volvo", "volkswagen"];
  carType = "all";
  TeslaCarTypes = ["all", "Roadster", "Model S", "Model 3", "Model X", "Model Y", "Cybertruck"];
  teslaCarType = "all";
  carSelected: number = 27;
  unit: any;
  Scrolltimer = timer(500);
  oldIOS: boolean = false;
  /* car selector modal window */
  constructor(
    public dialogRef: MatDialogRef<CarModelSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, private mapService: MapService, private mobileResolutionService: MobileResolutionService) {
    this.CarTypes = this.CarTypes.sort();
    this.carType = data.carType;
    if (data.teslaCarType){
      this.teslaCarType = data.teslaCarType;
    }
  }


  ngOnInit() {

    if (navigator.userAgent.includes('iPhone OS 10') || navigator.userAgent.includes('iPhone OS 9') ||
      navigator.userAgent.includes('iPhone OS 8') || navigator.userAgent.includes('iPhone OS 7')) {
      this.oldIOS = true;
    }

    this.unit = this.mapService.getUnit();
    this.ECars = this.mapService.ECars;
    /*this.ECars = this.ECars.sort((a,b)=>{
      return a.Name>b.Name ? 1 : -1;
    })*/

    if (this.mapService.getSelectedCar() && this.mapService.getSelectedCar().Name) {
      var i = 0;
      while (this.ECars[i].Name != this.mapService.getSelectedCar().Name && i < this.ECars.length) {
        i++;
      }
      this.carSelected = i;
    }
  }

  selectCar(ecar: ECar){
    var i = 0;
    while (this.ECars[i].Name != ecar.Name && i<this.ECars.length){
      i++;
    }
    this.carSelected = i;
  }

  ngAfterViewInit() {
    this.scrollToSelectedCar();
  }

  scrollToSelectedCar() {
    this.Scrolltimer.pipe(first()).subscribe(() => {
      if (document.querySelectorAll(".vehicle-tile.selected").length != 0) {
        var selected: any = document.querySelectorAll(".vehicle-tile.selected")[0];
        var scrolldown = selected.offsetTop;
        this.carSelectorScrollbar.scrollTo({ top: scrolldown });
      }
    });
  }

  setTeslaToAll(){
    this.teslaCarType = "all";
  }

  scrollToTop(){
    this.carSelectorScrollbar.scrollTo({ top: 0 });
  }

  // close window with setting a selected car
  clickOk(): void {
    //this.mapService.setSelectedCar(this.ECars[this.carSelected], true);
    this.dialogRef.close(this.carSelected);
  }

  // close window with no action
  clickCancel(): void {
    this.dialogRef.close();
  }
}