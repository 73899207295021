import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import GeotabApi from 'mg-api-js/dist/api.js';
import L, { LatLng } from 'leaflet';
import device from 'current-device';
import { timer, range } from 'rxjs';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MapService } from 'src/app/services/map.service';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-tracking-module',
  templateUrl: './tracking-module.component.html',
  styleUrls: ['./tracking-module.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrackingModuleComponent implements OnInit {

  GeotabState: boolean = false;
  api: any;
  email: string = "";
  username: string = "";
  database: string = "";
  password: string = "";
  error: boolean = false;
  opened: boolean = false;
  devices = [];
  lastTripDevice = "";
  lastLatLon = [0, 0];
  trackingDevicesTimer = null;
  lastRecordedTripDate = "";
  timer = timer(0, 2500);
  gpsSimulationTimer = timer(0, 2000);
  gpsSimulationTimerElement = null;
  batteryData = null;
  trike: boolean = false;
  trikeLoaded = false;
  tori: boolean = false;
  lastRhaCoords = null;
  activeTracking: number = -1;
  isMobile: boolean;
  Trips: any;
  subscriptions = [];
  navigation: boolean = false;

  /* geotab api implemention for calculating a car rha */
  constructor(private inputParamsService: InputParamsService, private mapService: MapService, private mobileResolutionService: MobileResolutionService,
    private cdr: ChangeDetectorRef, private http: HttpClient, private utilsService: UtilsService) {
  }

  ngOnInit() {
    this.isMobile = device.mobile();
    this.subscriptions.push(this.mapService.ObservableLastTrip.subscribe((resp) => {
      if (resp == "draw" && !this.trike) {
        this.getLastRoute(this.lastTripDevice);
      }
    }));
    this.subscriptions.push(this.inputParamsService.ObservableTrackingTimer.subscribe((resp: any) => {
      if (resp == false) {
        this.unsubscribeTimer();
        this.activeTracking = -1;
        this.lastLatLon = [0, 0];
        this.inputParamsService.setStartCoordsBearingParams(0);
        this.cdr.detectChanges();
      }
    }));
    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((resp: any) => {
      if (resp != undefined) {
        if (resp == "tracking") {
          this.opened = true;
        }
        else {
          this.opened = false;
        }
        this.cdr.detectChanges();
      }
    }));
    this.subscriptions.push(this.mapService.ObservableNavigation.subscribe((resp) => {
      if (resp != undefined) {
        this.navigation = resp;
        this.cdr.detectChanges();
      }
    }));
    if (document.URL.length > 1 && document.URL.includes("trike") || document.URL.includes("tori")) {
      this.trike = true;
      this.cdr.detectChanges();
      /*setTimeout(() => {
        var newlat = 47.49801;
        var newlon = 19.03991;
        this.http.get("https://dev.evnavigation.com/ecar/toritrack/track.php?id=gpst0001&lat=" + newlat + "&lon=" + newlon + "&soc=30").subscribe(() => { });
      }, 5000);*/

      // only for debug porpouses
      /*this.gpsSimulationTimerElement = this.gpsSimulationTimer.subscribe(() => {
        if (this.activeTracking != -1) {
          var newlat = 47.49801;
          var newlon = 19.03991;
          newlat = this.utilsService.getRandomBetweenCoordinates(newlat, newlat - 0.0005);
          newlon = this.utilsService.getRandomBetweenCoordinates(newlon, newlon + 0.0005);
          this.lastLatLon = [newlat, newlon];
          this.http.get("https://dev.evnavigation.com/ecar/toritrack/track.php?id=gpst0001&lat=" + newlat + "&lon=" + newlon + "&soc=30").subscribe(() => { });
        }
      })*/
    }
    if (document.URL.length > 1 && document.URL.includes("tori")) {
      this.tori = true;
      this.cdr.detectChanges();
    }
    if (this.trike && localStorage.getItem("trackingToken") == "qQ2Gv6AYzydwgkjYs4QHA9bLRXfYudCW") {
      if (this.tori) {
        this.loadAllToriDevice();
      }
      else {
        this.loadTrike();
      }
    }

    //this.toriExampleData(); //for the show
  }

  toggleCard() {
    if (!this.opened) {
      this.mobileResolutionService.setMobileVisiblePanel("tracking");
    }
    else {
      this.mobileResolutionService.setMobileVisiblePanel("");
    }
  }

  loadTrike() {
    var allLogUrl, lastLogUrl;
    if (this.tori) {
      allLogUrl = "https://dev.evnavigation.com/ecar/toritrack/getalllog.php";
      lastLogUrl = "https://dev.evnavigation.com/ecar/toritrack/getcurrent.php";
      this.devices = [{ name: "TORI SWIFT", id: "Tori" }];
    }
    else {
      allLogUrl = "https://evnavigation.com/ecar/triketrack/getalllog.php";
      lastLogUrl = "https://evnavigation.com/ecar/triketrack/getcurrent.php";
      this.devices = [{ name: "Trike", id: "Trike" }];
    }

    this.http.get(allLogUrl, { responseType: 'text' }).subscribe((resp) => {
      var lines = resp.split('\n');
      var tracks = [];
      lines.length = lines.length - 1;
      for (let i = 0; i < lines.length; i++) {
        var lineValues = lines[i].split(";");
        this.lastTrikeSignal = lineValues[lineValues.length];
        var properties = {};
        for (let j = 0; j < lineValues.length; j++) {
          var seperatorIdx = lineValues[j].indexOf(":");
          properties[lineValues[j].substring(0, seperatorIdx)] = lineValues[j].substring(seperatorIdx + 1);
        }
        tracks.push(properties);
      }
      var Trips = [];
      var lastTrip = [];
      var lastTripTrackPoints = [];
      for (let i = tracks.length - 1; i >= 1; i--) {
        // check for valid lat lon
        if ((parseFloat(tracks[i].lat) != 0 && parseFloat(tracks[i].lon) != 0) && (parseFloat(tracks[i].lat) >= -90 && parseFloat(tracks[i].lat) <= 90 && parseFloat(tracks[i].lat) >= -180 && parseFloat(tracks[i].lat) <= 180) && tracks[i].servertime) {
          if (this.lastRecordedTripDate == "") {
            this.lastRecordedTripDate = tracks[i].servertime.replace(" ", "T");
          }

          // create a trip
          if (lastTripTrackPoints.length == 0) {
            lastTripTrackPoints.push([parseFloat(tracks[i].lat), parseFloat(tracks[i].lon), parseFloat(tracks[i].soc), tracks[i].servertime]);
          }
          else if (parseFloat(tracks[i].lat) == lastTripTrackPoints[lastTripTrackPoints.length - 1][0] && parseFloat(tracks[i].lon) == lastTripTrackPoints[lastTripTrackPoints.length - 1][1]) {
          }
          else if ((tracks[i].servertime && new Date(lastTripTrackPoints[lastTripTrackPoints.length - 1][3].replace(" ", "T")).getTime() - new Date(tracks[i].servertime.replace(" ", "T")).getTime() < 7200000) && (this.utilsService.distanceBetweenCoordinates([lastTripTrackPoints[lastTripTrackPoints.length - 1][0], lastTripTrackPoints[lastTripTrackPoints.length - 1][1]], [tracks[i].lat, tracks[i].lon]) < 1000)) {
            lastTripTrackPoints.push([parseFloat(tracks[i].lat), parseFloat(tracks[i].lon), parseFloat(tracks[i].soc), tracks[i].servertime]);
          }
          // set a new trip if the data recorded in more than 2 hours
          else {
            if (lastTripTrackPoints.length >= 100) {
              lastTrip.push(lastTripTrackPoints);
              lastTrip.push(Trips.length);
              Trips.push(lastTrip);

            }
            lastTripTrackPoints = [];
            lastTrip = [];
            lastTripTrackPoints.push([parseFloat(tracks[i].lat), parseFloat(tracks[i].lon), parseFloat(tracks[i].soc), tracks[i].servertime]);
          }
        }
      }

      if (Trips.length > 10) {
        Trips.length = 10;
      }
      this.activeTracking = 0;

      var lastTrikeSignal;

      if (Trips.length > 0) {
        this.inputParamsService.setStartCoordsParams(new LatLng(Trips[0][0][0][0], Trips[0][0][0][1]));
        this.Trips = Trips;
        this.lastLatLon = [Trips[0][0][0][0], Trips[0][0][0][1]];

        // set battery
        if (Trips[0][0][0][0] < 255) {
          var rangeInput = this.inputParamsService.getRangeInputParams();
          if (rangeInput.batteryLevel != Trips[0][0][0][0]) {
            rangeInput.batteryLevel = Trips[0][0][0][0];
            this.inputParamsService.setRangeInputParams(rangeInput);
          }
        }
        this.mapService.setLastTrip(Trips);
        lastTrikeSignal = this.lastLatLon;
      }

      this.cdr.detectChanges();
      this.inputParamsService.setTrackingTimer(true);
      this.inputParamsService.setWaypointParams([this.inputParamsService.getWaypointsParams()[0]]);
      this.mobileResolutionService.setMobileVisiblePanel("");

      //this.mapService.setNextTurn({ Location: { Latitude: lastTrip[0][lastTrip[0].length - 1][0], Longitude: lastTrip[0][lastTrip[0].length - 1][1] } });

      this.trackingDevicesTimer = this.timer.subscribe((val) => {
        //lastTrikeSignal = [this.utilsService.getRandomBetweenCoordinates(lastTrikeSignal[0], lastTrikeSignal[0] + 0.0005),
        //this.utilsService.getRandomBetweenCoordinates(lastTrikeSignal[1], lastTrikeSignal[1] + 0.0005)];
        //this.processLastTrikeSignal(lastTrikeSignal);
        this.trackingOldDataCount++;
        this.http.get(lastLogUrl, { responseType: 'text' }).subscribe((resp) => {
          if (this.lastTrikeSignal != resp) {
            this.lastTrikeSignal = resp;

            resp = resp.substring(0, resp.indexOf("\n"));
            var lineValue = resp.split(";");
            var property: any = {};
            for (let j = 0; j < lineValue.length; j++) {
              var seperatorIdx = lineValue[j].indexOf(":");
              property[lineValue[j].substring(0, seperatorIdx)] = lineValue[j].substring(seperatorIdx + 1);
            }
            if (property.lat && property.lon && property.soc) {
              this.processLastTrikeSignal([parseFloat(property.lat), parseFloat(property.lon), parseFloat(property.soc)], val, property.servertime);
            }
          }
          if (this.trackingOldDataCount == 12) {
            this.mapService.ObservableCursorColor.next(false);
          }
        });
      });
    });
  }

  lastTrikeSignal: string;
  trackingActiveCoords: any = [];
  trackingOldDataCount: number = 0;

  processLastTrikeSignal(signal, val, servertime) {
    if ((signal[0] != 0 && signal[1] != 0) && (signal[0] >= -90 && signal[0] <= 90 && signal[1] >= -180 && signal[1] <= 180) /*&& property.servertime*/) {
      var draw = true;
      if (this.trackingActiveCoords.length > 0) {
        var lastLatLon = [this.trackingActiveCoords[this.trackingActiveCoords.length - 1][0], this.trackingActiveCoords[this.trackingActiveCoords.length - 1][1]];
        if (this.utilsService.distanceBetweenCoordinates(lastLatLon, signal) > 1000) {
          draw = false;
          this.trackingActiveCoords = [];
          this.unsubscribeTimer();
          this.loadTrike();
        }
      }
      if (draw) {
        // draw active route
        this.trackingOldDataCount = 0;
        this.mapService.ObservableCursorColor.next(true);
        this.trackingActiveCoords.push(signal);
        this.mapService.setLastActiveTrip(this.trackingActiveCoords);

        // set rha
        if (val == 0 || this.utilsService.distanceBetweenCoordinates([this.inputParamsService.getStartCoordsParams().lat, this.inputParamsService.getStartCoordsParams().lng], signal) > 1000) {
          this.inputParamsService.setStartCoordsParams(new LatLng(signal[0], signal[1]));
        }

        if (val == 0) {
          this.mapService.setMoveTo(new LatLng(signal[0], signal[1]));
        }

        // set battery
        if (signal[2] < 255) {
          var rangeInput = this.inputParamsService.getRangeInputParams();
          if (rangeInput.batteryLevel != signal[2]) {
            rangeInput.batteryLevel = signal[2];
            this.inputParamsService.setRangeInputParams(rangeInput);
            this.inputParamsService.setStartCoordsParams(new LatLng(signal[0], signal[1]));
          }
        }

        if (servertime) {
          this.lastRecordedTripDate = servertime.replace(" ", "T");
        }
      }
    }
  }

  loginTrike() {
    var loginUrl;
    if (this.tori) {
      loginUrl = "https://dev.evnavigation.com/ecar/toritrack/login.php";
    }
    else {
      loginUrl = "https://evnavigation.com/ecar/triketrack/login.php";
    }
    this.error = false;
    this.cdr.detectChanges();
    this.http.post(loginUrl, (this.username + this.password), { responseType: 'text' })
      .subscribe((resp: any) => {
        if (resp == "wrong pwd or username") {
          this.error = true;
          this.cdr.detectChanges();
          setTimeout(() => {
            this.error = false;
            this.cdr.detectChanges();
          }, 1250);
        }
        else {
          this.trikeLoaded = true;
          localStorage.setItem("trackingToken", resp);
          this.loadAllToriDevice();
          this.cdr.detectChanges();
          //this.loadTrike();
        }
      });
  }

  getProfileData() {
    if (this.trike) {
      this.loginTrike();
    }
    else {
      // login
      this.api = GeotabApi((authenticateCallback) => {
        this.error = false;
        this.cdr.detectChanges();
        authenticateCallback('my.geotab.com', this.database, this.email, this.password, (err) => {
          this.error = true;
          this.cdr.detectChanges();
          setTimeout(() => {
            this.error = false;
            this.cdr.detectChanges();
          }, 1250);
        });
      });

      // get device
      this.api.call('Get', {
        typeName: 'Device'
      }, (result) => {
        if (result) {
          if (result.length > 0) {
            for (let i = 0; i < result.length; i++) {
              this.devices.push({ name: result[i].name, id: result[i].id });
            }

            this.cdr.detectChanges();
          }
        }
      }, function (err) {
      });

      this.api.getSession((session) => {
      });
    }
  }

  firstData: boolean = false;

  // start to tracking an electric vehicle
  trackDevice(device, idx) {
    if (this.trike) {
      /*if (this.activeTracking == -1) {
        this.loadTrike();
        // refreshing live trike data timer
        this.trackingDevicesTimer = this.timer.subscribe((val) => {
          //this.getLastTrikeSignal();
        });
      }*/

      //this.lastTripDevice = device.id;
      var rangeInput = this.inputParamsService.getRangeInputParams();
      this.inputParamsService.setStartCoordsBearingParams(device.bearing);
      this.inputParamsService.setStartCoordsParams(new L.LatLng(device.latlon[0], device.latlon[1]));
      this.mapService.ObservableAddMultipleMarkers.next([this.exampledata, idx]);
    }
    else {
      this.lastTripDevice = device.id;
      this.firstData = true;
      // get device data in every 5sec, if position changed reload trips
      this.unsubscribeTimer();
      this.inputParamsService.setTrackingTimer(true);
      this.trackingDevicesTimer = this.timer.subscribe((val) => {
        this.getDeviceStatusInfo(device, idx, val);
      });
    }
  }

  getDeviceStatusInfo(device, idx, tickIdx) {
    this.api.call("Get", {
      typeName: "DeviceStatusInfo",
      search: {
        deviceSearch: {
          id: device.id
        }
      }
    }, (result) => {
      if (result.length > 0 && (this.lastLatLon[0] != result[0].latitude || this.lastLatLon[1] != result[0].longitude)) {
        this.getStatusData(device, result, idx, tickIdx);
      }
    });
  }

  getStatusData(device, deviceStatusInfo, idx, tickIdx) {
    var today = new Date();
    var yesterday = new Date();
    yesterday.setDate(today.getDate() - 30);
    today.setDate(today.getDate() + 1);
    this.lastLatLon = [deviceStatusInfo[0].latitude, deviceStatusInfo[0].longitude];
    if (this.firstData || !this.mapService.getNavigation() && this.inputParamsService.getSelectedMode() == "rha") {
      this.inputParamsService.setStartCoordsBearingParams(deviceStatusInfo[0].bearing);
    }
    this.api.call(
      "Get", {
        typeName: "StatusData",
        search: {
          fromDate: yesterday, toDate: today,
          deviceSearch: {
            id: device.id
          },
          diagnosticSearch: {
            id: "atlKz5GniuEOmiSk76mlTdg"
          }
        }
      },
      (result) => {
        var location;
        var rangeInput = this.inputParamsService.getRangeInputParams();
        location = new L.LatLng(this.lastLatLon[0], this.lastLatLon[1]);

        if (result.length > 0) {
          if (rangeInput.batteryLevel) {
            rangeInput.batteryLevel = result[result.length - 1].data;
          }
        }

        this.batteryData = result;

        // select car
        var nameWords = device.name.split(" ");
        var matchArray = [];
        for (let i = 0; i < this.mapService.ECars.length; i++) {
          matchArray.push(0);
        }

        for (let i = 0; i < this.mapService.ECars.length; i++) {
          for (let j = 0; j < nameWords.length; j++) {
            if (this.mapService.ECars[i].Name.includes(nameWords[j])) {
              matchArray[i]++;
            }
          }
        }

        var maxValId = 0;
        for (let i = 1; i < matchArray.length; i++) {
          if (matchArray[maxValId] < matchArray[i]) {
            maxValId = i;
          }
        }

        if (tickIdx == 0) {
          this.inputParamsService.SetMapToBoundingBox = true;
        }
        else {
          this.inputParamsService.SetMapToBoundingBox = false;
        }
        if (this.firstData || !this.mapService.getNavigation() && this.inputParamsService.getSelectedMode() == "rha") {
          this.mapService.setSelectedCar(this.mapService.ECars[maxValId], true);
          this.inputParamsService.setWaypointParams([this.inputParamsService.getWaypointsParams()[0]]);
          this.inputParamsService.setStartCoordsParams(location);
          this.mobileResolutionService.setMobileVisiblePanel("");
          this.inputParamsService.setRangeInputParams(rangeInput);
        }
        else {
          // change battery if the difference is greater or equal with 3
          if (Math.abs(this.inputParamsService.getRangeInputParams().batteryLevel - rangeInput.batteryLevel) >= 3) {
            this.inputParamsService.setRangeInputParams(rangeInput);
          }
        }
        if (this.firstData) {
          this.firstData = false;
        }
        this.activeTracking = idx;
      });
  }

  lastRoutePointsArray = [];

  getLastRoute(device) {
    var today = new Date();
    var yesterday = new Date();
    yesterday.setDate(today.getDate() - 30);
    today.setDate(today.getDate() + 1);
    this.api.call("Get", {
      typeName: "Trip",
      search: {
        fromDate: yesterday, toDate: today,
        includeOverlappedTrips: true, deviceSearch: { "id": device }
      }
    }, (result) => {
      result.sort((a: any, b: any) => {
        a = new Date(a.start);
        b = new Date(b.start);
        return a > b ? -1 : a < b ? 1 : 0;
      });

      var j;
      if (result.length >= 10) {
        j = 10;
      }
      else {
        j = result.length
      }

      this.lastRecordedTripDate = result[0].stop;
      this.cdr.detectChanges();
      this.lastRoutePointsArray = [];
      for (let i = 0; i < j; i++) {
        /// get 1 to 0.000001 values for opacity
        let opacity = 1 - ((i - 1) / j);
        this.getLastRoutePoints(device, result[i].start, result[i].stop, i, j);
      }
    }, function (error) {
      alert(error);
    });
  }

  getLastRoutePoints(device, fromDate, toDate, idx, j) {
    this.api.call("Get", {
      typeName: "LogRecord",
      search: {
        fromDate: fromDate, toDate: toDate,
        diagnosticSearch: {
          id: "atlKz5GniuEOmiSk76mlTdg"
        },
        deviceSearch: { "id": device }
      }
    }, (result) => {
      var latlonArray = [];
      for (let i = 0; i < result.length; i++) {
        latlonArray.push([result[i].latitude, result[i].longitude, result[i].dateTime]);
      }
      this.lastRoutePointsArray.push([latlonArray, idx]);
      // if this is the last element, send forward to service
      if (this.lastRoutePointsArray.length == j) {
        this.lastRoutePointsArray.sort((a: any, b: any) => {
          return a[1] < b[1] ? -1 : a[1] > b[1] ? 1 : 0;
        });
        //idx for battery data
        var b = 0;

        if (this.batteryData.length > 0) {
          for (let k = 0; k < this.lastRoutePointsArray.length; k++) {
            for (let i = 0; i < this.lastRoutePointsArray[k][0].length; i++) {
              while (new Date(this.lastRoutePointsArray[k][0][i][2]).getTime() > new Date(this.batteryData[b + 1].dateTime).getTime()) {
                b++;
              }
              this.lastRoutePointsArray[k][0][i][2] = this.batteryData[b].data;
            }
          }
        }
        else {
          for (let k = 0; k < this.lastRoutePointsArray.length; k++) {
            for (let i = 0; i < this.lastRoutePointsArray[k][0].length; i++) {
              this.lastRoutePointsArray[k][0][i][2] = null;
            }
          }
        }
        this.mapService.setLastTrip(this.lastRoutePointsArray);
      }

    }, function (error) {
      alert(error);
    });
  }

  exampledata = [
    {
      name: "Tori 1",
      battery: 50,
      latlon: [50.0885840, 9.0081078],
      bearing: 0
    },
    {
      name: "Tori 2",
      battery: 40,
      latlon: [50.0821018, 9.0364983],
      bearing: 20
    },
    {
      name: "Tori 3",
      battery: 34,
      latlon: [50.0761852, 9.0895785],
      bearing: 34
    },
    {
      name: "Tori 4",
      battery: 58,
      latlon: [50.0639341, 9.0404900],
      bearing: 164
    },
    {
      name: "Tori 5",
      battery: 86,
      latlon: [50.0494823, 9.0064121],
      bearing: 67
    },
    {
      name: "Tori 6",
      battery: 36,
      latlon: [50.0191163, 9.0299355],
      bearing: 69
    },
    {
      name: "Tori 7",
      battery: 8,
      latlon: [50.0137962, 9.0064121],
      bearing: 31
    },
    {
      name: "Tori 8",
      battery: 36,
      latlon: [50.0596734, 9.0573126],
      bearing: 54
    },
    {
      name: "Tori 9",
      battery: 10,
      latlon: [50.0705972, 9.0268598],
      bearing: 78
    }
  ];

  // draw to map all of the tori devices
  loadAllToriDevice() {
    this.mapService.ObservableAddMultipleMarkers.next([this.exampledata, 0]);
    this.devices = this.exampledata;
    this.cdr.detectChanges();
  }

  toriExampleData() {
    var txt = "";
    var serverT = new Date();
    serverT.setFullYear(serverT.getFullYear() - 1);
    Math.random()

    this.http.get("assets/tori_example.json").subscribe((resp: any) => {
      var trackIdx = 0;
      while (trackIdx < resp.length) {
        var routepointIdx = 0;
        while (routepointIdx < resp[trackIdx].route.length) {
          var newlat = resp[trackIdx].route[routepointIdx][0];
          var newlon = resp[trackIdx].route[routepointIdx][1];
          /*var date = serverT.getFullYear() + '-' + (serverT.getMonth() + 1) + '-' + serverT.getDate();
          var time = serverT.getHours() + ":" + serverT.getMinutes() + ":" + serverT.getSeconds();
          var servertimeString = date + ' ' + time;*/

          var iso = serverT.toISOString().match(/(\d{4}\-\d{2}\-\d{2})T(\d{2}:\d{2}:\d{2})/);
          var servertimeString = iso[1] + ' ' + iso[2];
          var acc1 = Math.floor(Math.random() * (100 + 1));
          var acc2 = Math.floor(Math.random() * (100 + 1));
          var acc3 = Math.floor(Math.random() * (100 + 1));
          txt += "servertime:" + servertimeString + ";id:tori0001;lat:" + newlat + ";lon:" + newlon + ";soc:30;bat:30;timestamp:;gsmsignalstr:;speed:;acc1:" + acc1 + ";acc2:" + acc2 + ";acc3:" + acc3 + ";can:\n";

          serverT.setMinutes(serverT.getMinutes() + 1);
          routepointIdx++;
        }
        serverT.setHours(serverT.getHours() + 3);
        trackIdx++;
      }
    });
  }

  unsubscribeTimer() {
    if (this.trackingDevicesTimer) {
      this.trackingDevicesTimer.unsubscribe();
      this.trackingOldDataCount = 0;
    }
    if (this.gpsSimulationTimerElement) {
      this.gpsSimulationTimerElement.unsubscribe();
    }
  }

  logout() {
    this.unsubscribeTimer();
    this.activeTracking = -1;
    this.email = "";
    this.database = "";
    this.password = "";
    this.username = "";
    this.inputParamsService.setTrackingTimer(false);
    localStorage.setItem("trackingToken", null);
    if (this.tori) {
      this.mapService.ObservableAddMultipleMarkers.next(null);
    }
    if (this.trike) {
      this.devices = [];
    }
    else {
      this.api.forget();
      this.devices = [];
    }


    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }
}
