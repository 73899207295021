import { Injectable } from '@angular/core';
import L, { LatLng, LatLngBounds } from 'leaflet';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { InputParamsService } from './input-params.service';
import { MobileResolutionService } from './mobile-resolution.service';
import { ECar, UserECar, AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  private MapColor: string;
  public ObservableMapColor: BehaviorSubject<string>;
  private lastCoordinates: LatLng;
  private Navigation: boolean;
  public ObservableNavigation: BehaviorSubject<boolean>;
  private ChargingStations: boolean[];
  public ObservableChargingStations: BehaviorSubject<boolean[]>;
  private SearchedLocation: any;
  public ObservableSearchedLocation: BehaviorSubject<any>;
  private SelectedCoordinate: any;
  public ObservableSelectedCoordinate: BehaviorSubject<any>;
  private SelectedCar: ECar = new ECar(-1, null, null, null, null, null, null, "", null, null, null, null, "", null, null, null, null);
  public ObservableSelectedCar: BehaviorSubject<UserECar>;
  public ECars: ECar[] = [];
  private PlannedRouteParams: any;
  public ObservablePlannedRouteParams: BehaviorSubject<any>;
  private Unit: any = { "speed": "metric", "distance": "metric", "temperature": "metric", "pressure": "metric" };
  public ObservableUnit: BehaviorSubject<string>;
  private LastTrip = [];
  public ObservableLastTrip: BehaviorSubject<any>;
  private LastActiveTrip = [];
  public ObservableLastActiveTrip: BehaviorSubject<any>;
  private MapBounds: LatLngBounds;
  private NextTurn: any;
  public ObservableNextTurn: BehaviorSubject<any>;
  private MoveTo: LatLng;
  public ObservableMoveTo: BehaviorSubject<LatLng>;
  public ObservableCursorColor: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public ObservableAddMultipleMarkers: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public ObservablePositionMapToRoute: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public ObservableECarsLoaded: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private Trike: ECar = {
    Id: -1,
    Name: "Trike",
    Type: "trike",
    Subtype: null,
    Icon: "car_icon_general.png",
    UsedCapacity: 20,
    DesignCapacity: 20,
    ChargerTypes: [5],
    Range: 175,
    FactoryRange: 175,
    FactoryRangeSource: "",
    TopSpeed: 150,
    TotalPower: 10,
    GradeWeights: [
      -50,
      -50,
      -50,
      -50,
      -50,
      -45,
      -40,
      -35,
      -30,
      -25,
      -11,
      -2,
      39,
      89,
      148,
      219,
      256,
      320,
      395,
      469,
      509,
      544,
      601,
      660,
      691,
      688,
      688,
      688,
      688,
      688,
      688,
      688
    ],
    SpeedWeights: [
      0.8336380256,
      0.8336380256,
      0.8336380256,
      0.8336380256,
      0.8336380256,
      0.842883549,
      0.8769230769,
      0.9230769231,
      1,
      1.0883054893,
      1.1906005222,
      1.3065902579,
      1.4339622642,
      1.5778546713,
      1.7404580153,
      1.9240506329,
      2.1308411215,
      2.3682777036,
      2.6057142857,
      2.8972627373,
      3.1888111888
    ],
    FastChargePower: 1,
    ChargePower: 5
  };
  private Tori: ECar = {
    Id: -1,
    Name: "TORI SWIFT",
    Type: "tori",
    Subtype: null,
    Icon: "car_icon_tori.png",
    UsedCapacity: 3.12,
    DesignCapacity: 3.12,
    ChargerTypes: [3],
    ChargePower: 3,
    FastChargePower: 0,
    Range: 140,
    FactoryRange: 140,
    FactoryRangeSource: "",
    TopSpeed: 45,
    TotalPower: 3,
    GradeWeights: [
      -187,
      -187,
      -187,
      -163,
      -135,
      -118,
      -147,
      -151,
      -153,
      -154,
      -120,
      -54,
      12,
      70,
      135,
      197,
      256,
      341,
      452,
      576,
      716,
      837,
      912,
      957,
      1001,
      1085,
      1172,
      1268,
      1268,
      1268,
      1268,
      1268
    ],
    SpeedWeights: [
      0.85,
      0.90,
      0.95,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1,
      1
    ]
  };
  public chargingStationsArray = [];

  constructor(private http: HttpClient, private utilsService: UtilsService, private inputParamsService: InputParamsService,
    private mobileResolutionService: MobileResolutionService, private accountService: AccountService) {
    this.ObservableChargingStations = new BehaviorSubject<any>(this.ChargingStations);
    this.ObservableSearchedLocation = new BehaviorSubject<any>(this.SearchedLocation);
    this.ObservableSelectedCoordinate = new BehaviorSubject<any>(this.SelectedCoordinate);
    this.ObservableSelectedCar = new BehaviorSubject<UserECar>(null);
    this.ObservablePlannedRouteParams = new BehaviorSubject<any>(this.PlannedRouteParams);
    this.ObservableUnit = new BehaviorSubject<string>(this.Unit);
    this.ObservableLastTrip = new BehaviorSubject<any>(this.LastTrip);
    this.ObservableLastActiveTrip = new BehaviorSubject<any>(this.ObservableLastActiveTrip);
    this.ObservableNextTurn = new BehaviorSubject<any>(this.NextTurn);
    this.ObservableNavigation = new BehaviorSubject<boolean>(this.Navigation);
    this.ObservableMoveTo = new BehaviorSubject<LatLng>(this.MoveTo);
    this.ObservableMapColor = new BehaviorSubject<string>(null);
    this.ObservableECarsLoaded = new BehaviorSubject<string>(null);
    var mapsettings = localStorage.getItem("mapsettings");
    if (mapsettings) {
      this.MapColor = JSON.parse(localStorage.getItem("mapsettings")).skin;
      if (this.MapColor == "light") {
        document.body.classList.add("lightSkin");
      }
    }
    else {
      this.MapColor = "dark";
    }

    this.accountService.ObservableAllVehicles.subscribe((resp) => {
      if (!resp || resp === "error") {
        return;
      }

      this.ECars = resp;

      // load cookies
      var selectedCar;
      if (document.URL.length > 1 && document.URL.includes("trike")) {
        this.setSelectedCar(this.Trike, true);
      }
      if (document.URL.length > 1 && document.URL.includes("tori")) {
        this.setSelectedCar(this.Tori, true);
      }
      else if (this.inputParamsService.AcceptCookies) {
        selectedCar = localStorage.getItem('selectedCar');
        if (selectedCar == "Trike" || selectedCar == "Tori") {
          selectedCar = null;
        }
      }

      if (selectedCar) {
        let i = 0;
        while (i < this.ECars.length && this.ECars[i].Name != selectedCar) {
          i++;
        }
        if (i == this.ECars.length) {
          this.setSelectedCar(this.ECars[0], false);
        }
        else {
          this.setSelectedCar(this.ECars[i], false);
        }
      }
      else if (this.getSelectedCar() == undefined || this.getSelectedCar() == null || this.getSelectedCar().Name == null) {
        this.selectDefaultCar();
      }

      var chargingStations = [];
      var chargingStationsStorage;
      if (this.inputParamsService.AcceptCookies) {
        chargingStationsStorage = localStorage.getItem("chargingStations");
      }
      if (chargingStationsStorage) {
        var csArray = chargingStationsStorage.split(",");
        for (let i = 0; i < csArray.length; i++) {
          if (csArray[i] == "true") {
            chargingStations.push(true);
          }
          if (csArray[i] == "false") {
            chargingStations.push(false);
          }
        }
      }
      else {
        chargingStations = [false, false, false, false, false, false];
        for (let i = 0; i < this.SelectedCar.ChargerTypes.length; i++) {
          chargingStations[this.SelectedCar.ChargerTypes[i] - 1] = true;
        }
      }
      this.setChargingStations(chargingStations);
      var ecar: ECar = this.getSelectedCar();
      if (ecar) {
        ecar.ChargerTypes = [];
        for (let i = 0; i < chargingStations.length; i++) {
          if (chargingStations[i]) {
            ecar.ChargerTypes.push(i + 1);
          }
        }
        this.setSelectedCar(ecar, true);
      }

      var unitsettings: any = localStorage.getItem("unit");
      var unit: any;
      try {
        unit = JSON.parse(unitsettings);
      } catch (e) {
        unit = unitsettings;
      }
      if (unit === "metric") {
        this.setUnit({ "speed": "metric", "distance": "metric", "temperature": "metric", "pressure": "metric" });
      }
      else if (unit === "imperial") {
        this.setUnit({ "speed": "imperial", "distance": "imperial", "temperature": "imperial", "pressure": "imperial" });
      }
      else if (typeof (unit) === 'object' && unit != null) {
        this.setUnit(unit);
      }
      else {
        var checkUrl;
        if (document.URL.includes("localhost") || document.URL.includes("staging") || document.URL.includes("192.168.")) {
          checkUrl = "https://dev.evnavigation.com/ecar/check.php";
        }
        else {
          checkUrl = "https://evnavigation.com/ecar/check.php";
        }

        this.http.get(checkUrl, { responseType: 'text' }).subscribe((resp) => {
          if (resp.substring(0, 2) == "GB" || resp.substring(0, 2) == "US") {
            this.setUnit({ "speed": "imperial", "distance": "imperial", "temperature": "imperial", "pressure": "imperial" });
          }
        });
      }

      this.ObservableECarsLoaded.next(this.ECars);
    });

    this.accountService.getAllVehicles();

    window['webViewObj'] = function Class() { };
    window['webViewObj']['goBack'] = () => {
      var p = this.mobileResolutionService.getMobileVisiblePanel();
      if (p == "slider" || p == "chargingstation" || p == "chargingstation" || p == "wind" || p == "search") {
        this.mobileResolutionService.setMobileVisiblePanel("");
        return true;
      }
      else if (document.getElementsByClassName("mat-dialog-container").length > 0) {
        if (document.getElementsByClassName("cdk-overlay-backdrop-showing").length > 0) {
          (document.getElementsByClassName('cdk-overlay-backdrop-showing')[document.getElementsByClassName("cdk-overlay-backdrop-showing").length - 1] as HTMLElement).click();
        }
        return true;
      }
      return false;
    };
    window['webViewObj']['setWebViewLocation'] = (timestamp, latitude, longitude, altitude, accuracy, altitudeAccuracy, heading, speed) => {
      if (latitude == null && longitude == null) {
        this.inputParamsService.lastWebViewLocation = null;
      }
      else {
        this.inputParamsService.lastWebViewLocation = [latitude, longitude, speed];
        this.inputParamsService.ObservableWebViewPosition.next([latitude, longitude, speed]);
      }
    };
    window['webViewObj']['setStartCoordsToYourGeolocation'] = function name() { };
    window['webViewObj']['startNavigation'] = function name() { };
    window['webViewObj']['stopNavigation'] = function name() { };
    window['webViewObj']['openUrl'] = function name() { };
    window['webViewObj']['playAudio'] = function name() { };

    this.inputParamsService.ObservableWebViewPosition.subscribe((resp) => {
      if (resp && localStorage.getItem("cookiesAccepted") != "true" && this.firstWebViewLocation == false) {
        this.inputParamsService.setStartCoordsParams(new LatLng(resp[0], resp[1]));
        this.setMoveTo(new LatLng(resp[0], resp[1]));
        this.firstWebViewLocation = true;
      }
    })

    this.accountService.ObservableUserSettings.subscribe((resp) => {
      if (resp) {
        if (resp.unit != undefined && resp.unit != null) {
          localStorage.setItem('unit', resp.unit);
          this.setUnit(JSON.parse(resp.unit));
        }

        if (resp.mapsettings != undefined && resp.mapsettings != null) {
          var mapsettings = JSON.parse(resp.mapsettings);
          if (mapsettings.skin != undefined && mapsettings.skin != null) {
            localStorage.setItem('mapsettings', resp.mapsettings);
            this.setMapColor(mapsettings['skin']);
          }
        }
      }
    });

    this.accountService.ObservableSelectedUserCar.subscribe((resp) => {
      if (resp) {
        this.ObservableSelectedCar.next(resp);
      }
    });

    this.accountService.ObservableIsAuthorized.subscribe((resp) => {
      if (resp == false) {
        var selectedCar = localStorage.getItem('selectedCar');
        if (selectedCar) {
          let i = 0;
          while (i < this.ECars.length && this.ECars[i].Name != selectedCar) {
            i++;
          }
          if (i == this.ECars.length) {
            this.setSelectedCar(this.ECars[0], false);
          }
          else {
            this.setSelectedCar(this.ECars[i], false);
          }
        }
        else if (this.getSelectedCar() == undefined || this.getSelectedCar() == null || this.getSelectedCar().Name == null) {
          this.selectDefaultCar();
        }
      }
    });
  }

  firstWebViewLocation: boolean = false;

  selectDefaultCar() {
    let defCarId: number = this.utilsService.defaultCarIndex;
    for (var i = 0; i < this.ECars.length; ++i) {
      if (this.ECars[i].Id == defCarId) {
        this.setSelectedCar(this.ECars[i], false);
        break;
      }
    }
  }

  getCarByID(id: number): ECar {
    for (var i = 0; i < this.ECars.length; ++i) {
      if (this.ECars[i].Id == id) {
        return this.ECars[i];
      }
    }
  }

  setMapColor(param: string) {
    if (param == "light") {
      document.body.classList.add("lightSkin");
    }
    if (param == "dark") {
      document.body.classList.remove("lightSkin");
    }
    this.MapColor = param;
    this.ObservableMapColor.next(param);
  }

  getMapColor() {
    return this.MapColor;
  }

  setNavigation(param: boolean) {
    this.Navigation = param;
    this.ObservableNavigation.next(param);
  }

  getNavigation() {
    return this.Navigation;
  }

  setLastCoordinates(lat, lng): void {
    this.lastCoordinates = new LatLng(lat, lng);
  }

  getLastCoordinates(): LatLng {
    return this.lastCoordinates;
  }

  setChargingStations(params: boolean[]): void {
    this.ChargingStations = params;
    this.ObservableChargingStations.next(params);
  }

  getChargingStations(): any {
    return this.ChargingStations;
  }

  setSearchedLocation(searchedEl) {
    this.SearchedLocation = searchedEl;
    this.ObservableSearchedLocation.next(searchedEl);
  }

  getSearchedLocation() {
    return this.SearchedLocation;
  }

  setSelectedCoordinate(latlon) {
    this.SelectedCoordinate = latlon;
    this.ObservableSelectedCoordinate.next(latlon);
  }

  setMoveTo(latlon: LatLng) {
    this.MoveTo = latlon;
    this.ObservableMoveTo.next(latlon);
  }

  getSelectedCoordinate() {
    return this.SelectedCoordinate;
  }

  setSelectedCar(ecar: ECar, setCarChargingStations: boolean) {
    this.SelectedCar = new ECar(ecar.Id, ecar.ChargerTypes, ecar.DesignCapacity, ecar.GradeWeights, ecar.Name, ecar.Range, ecar.FactoryRange, ecar.FactoryRangeSource, ecar.SpeedWeights, ecar.TopSpeed,
      ecar.TotalPower, ecar.UsedCapacity, ecar.Icon, ecar.ChargePower, ecar.FastChargePower, ecar.Type, ecar.Subtype);
    if (!document.URL.includes("tori")) {
      if (ecar.Icon.length > 0) {
        document.getElementById("vehicle-image").setAttribute("src", "/assets/ecars/" + ecar.Icon + ".svg" + "?v3");
        document.getElementsByClassName("mobile-selected-car")[0].setAttribute("src", "/assets/ecars/" + ecar.Icon + ".svg" + "?v3");
      }
      else {
        document.getElementById("vehicle-image").setAttribute("src", "/assets/icon-car.png" + "?v3");
        document.getElementsByClassName("mobile-selected-car")[0].setAttribute("src", "/assets/icon-car.png" + "?v3");
      }
    }

    if (setCarChargingStations) {
      var chargingStations = [false, false, false, false, false, false];
      for (let i = 0; i < this.SelectedCar.ChargerTypes.length; i++) {
        chargingStations[this.SelectedCar.ChargerTypes[i] - 1] = true;
      }
      this.setChargingStations(chargingStations);
    }

    if (this.inputParamsService.AcceptCookies) {
      localStorage.setItem('selectedCar', this.SelectedCar.Name.toString());
    }

    var nullUserEcar = new UserECar();
    nullUserEcar.Ecar = ecar;
    nullUserEcar.name = "";
    nullUserEcar.selected = true;
    nullUserEcar.userVehicleID = -1;
    this.ObservableSelectedCar.next(nullUserEcar);
  }

  getSelectedCar(): ECar {
    if (this.accountService.getIsAuthorized() && this.accountService.user.getSelectedCar()) {
      return this.accountService.user.getSelectedCar();
    }
    return this.SelectedCar;
  }

  getPlannedRouteParams(): any {
    return this.PlannedRouteParams;
  }

  setPlannedRouteParams(params: any) {
    this.PlannedRouteParams = params;
    this.ObservablePlannedRouteParams.next(params);
  }

  setLastTrip(params: any) {
    this.LastTrip = params;
    this.ObservableLastTrip.next(params);
  }

  getLastTrip() {
    return this.LastTrip;
  }

  setLastActiveTrip(params: any) {
    this.LastActiveTrip = params;
    this.ObservableLastActiveTrip.next(params);
  }

  getLastActiveTrip() {
    return this.LastActiveTrip;
  }

  getUnit(): any {
    return this.Unit;
  }

  setUnit(param: any) {
    this.Unit = param;
    this.ObservableUnit.next(param);
  }

  getNextTurn() {
    return this.NextTurn;
  }

  setNextTurn(nextTurn) {
    this.NextTurn = nextTurn;
    this.ObservableNextTurn.next(nextTurn);
  }

  getMapBounds() {
    return this.MapBounds;
  }

  setMapBounds(bounds: LatLngBounds) {
    this.MapBounds = bounds;
  }

  getReverseGeocode(lat, lon) {
    return new Observable((observer: Observer<any>) => {
      this.http.get("https://dev.evnavigation.com/ecar/reversegeocode.php?lat=" + lat + "&lon=" + lon).subscribe((resp: any) => {
        var address = "";
        if (resp.street) {
          address += resp.street + " ";
        }
        if (resp.street && resp.housenumber) {
          address += resp.housenumber + ", ";
        }
        if (resp.city) {
          address += resp.city + ", ";
        }
        if (resp.county) {
          address += resp.county + ", ";
        }
        if (resp.country) {
          address += resp.country;
        }
        if (resp.country == "#country_") {
          address = Math.round(lat * 1000) / 1000 + " " + Math.round(lon * 1000) / 1000;
        }
        observer.next(address);
        observer.complete();
      }, (error) => {
        observer.next(error);
        observer.complete();
      })
    });
  }

  // generating arrow layer for a lod level using element indexes
  generateTrackArrows(latlonArray: any, indexes: any, lodLevelMultiply: number) {
    var markersArray = [];
    var arrowsArray = [];
    // define arrows between points
    for (let i = 1; i < indexes.length; i++) {
      var currElement = latlonArray[indexes[i]];
      var prevElement = latlonArray[indexes[i - 1]];
      if (prevElement[0] != currElement[0] || prevElement[1] != currElement[1]) {

        var arrowArrayLatLon = new L.LatLng((currElement[0] + prevElement[0]) / 2, (currElement[1] + prevElement[1]) / 2);
        var arrowArrayRotation = 180 + this.utilsService.angleFromCoordinate(prevElement[0], prevElement[1], currElement[0], currElement[1]);
        arrowsArray.push({ latlon: arrowArrayLatLon, rotation: arrowArrayRotation });
      }
    }
    // keep arrows, with a defined distance between each other
    var lastPoint = null;
    for (let j = 0; j < arrowsArray.length; j++) {
      var currentPoint = arrowsArray[j].latlon;
      if (j == 0 || (lastPoint.distanceTo(currentPoint) > lodLevelMultiply)) {
        var arrowIcon: L.DivIcon = L.divIcon({
          className: 'arrow-icon',
          html: '<img src="assets/direction_arrow.png" style="width: 12px; height: 12px; transform:rotate(' + arrowsArray[j].rotation + 'deg);" />',
          iconSize: null,
          iconAnchor: [6, 6]
        });
        markersArray.push(L.marker(arrowsArray[j].latlon, { icon: arrowIcon, interactive: false }));
        lastPoint = currentPoint;
      }
    }
    return markersArray;
  }

}
