import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgScrollbar } from 'ngx-scrollbar';
import device from 'current-device';

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.css']
})
export class WelcomeDialogComponent implements OnInit {

  @ViewChild('privacyScrollbar', { static: true }) privacyScrollbar: NgScrollbar;
  private step: number = 0;
  private button: string = "START";
  private ios: boolean = false;
  private contentArr =
    [{
      img: "",
      title: "",
      text: 'Plan your journey and calculate the range you can cover with your electric car at the same time! EVNavigation.com is a smart and intuitive trip planner tool, granting you a trustworthy turn-by-turn navigation with voice guidance while supporting each type of charging station for every EV. It uses the most advanced algorithms to spare energy for your car while shortening charging times by adapting to your driving habits.'
    },
    {
      img: "assets/range_image.jpg",
      title: "Range",
      text: 'The filled area over the map indicates the range you can cover with the currently set battery percentage. It has two adjustable levels: The outer blot refers to the maximum range you can reach with the set battery state, while the inner blot stands for a cautionary distance, granting you the option to reserve a custom battery percentage ("Battery Reserved" slider).'
    },
    {
      img: "assets/search_click_image.jpg",
      title: "Right Click",
      text: 'You can set start and end points after using Right Click on any point of the map. The "Set Start Coordinate" repositions the cursor, the "Set End Coordinate" plans the route between the two spots.'
    },
    {
      img: "assets/navigation_image.jpg",
      title: "Navigation",
      text: 'Placing the end point plans the route, granting you the Itinerary and the Charging Plan through your trip. The "GO" button starts the turn-by-turn navigation along with voice guidance.'
    }
    ];
  private contentLogoArr = ["assets/"]
  private content: any = this.contentArr[0];

  constructor(
    public dialogRef: MatDialogRef<WelcomeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (device.ios() || device.os=="macos"){
      this.ios = true;
    }
    if (window.navigator.userAgent.includes("GPSTWebView")){
      this.step = 1;
      this.button = "Next";
    }
    if (!device.desktop()) {
      this.contentArr[2] = {
        img: "assets/search_tap_image.jpg",
        title: "Long Tap",
        text: 'You can set start and end points after long tapping the map on any point. The "Set Start Coordinate" repositions the cursor, the "Set End Coordinate" plans the route between the two spots.'
      }
    }

  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }

  openUrl(url): void{
    window.open(url, '_blank');
  }

  onNextClick(): void {
    if (this.step == 0) {
      this.button = "Next";
    }
    if (this.step == this.contentArr.length - 1) {
      this.dialogRef.close();
    }
    else {
      this.step++;
    }
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

}

