import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild, ViewChildren, AfterViewInit, QueryList, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { MapService } from 'src/app/services/map.service';
import { ECar } from 'src/app/services/account.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { PrivacyPolicyComponent } from 'src/app/components/privacy-policy/privacy-policy.component';
import { EulaComponent } from 'src/app/components/eula/eula.component';
import { FeedbackComponent } from 'src/app/components/feedback/feedback.component';
import { WelcomeComponent } from 'src/app/components/welcome/welcome.component';
import { TutorialComponent } from 'src/app/components/tutorial/tutorial.component';

@Component({
  selector: 'app-tabbed-info-dialog',
  templateUrl: './tabbed-info-dialog.component.html',
  styleUrls: ['./tabbed-info-dialog.component.css']
})
export class TabbedInfoDialogComponent implements OnInit {

  menuSelected = "welcome";
  subscriptions: any = [];
  @ViewChildren(FeedbackComponent) feedbackComponents: QueryList<FeedbackComponent>;
  @ViewChild('panelScrollBar', { static: true }) panelScrollBar: NgScrollbar;
  @HostListener('window:resize') onResize() {
    setTimeout(()=>{
      this.setDialogSize();
    }, 1000);
  }

  constructor(
    public dialogRef: MatDialogRef<TabbedInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.setDialogSize();
    this.feedbackComponents.changes.subscribe((comps: QueryList<FeedbackComponent>) => {
      this.feedbackComponents.forEach(feedbackTab => {
        this.subscriptions.push(feedbackTab.ObservableTabChangeRequest.subscribe((resp) => {
          if (resp) {
            this.changeSelectedMenu(resp);
          }
        }));
      });
    });

    if (this.data.openMenu != undefined && this.data.openMenu != null) {
      this.changeSelectedMenu(this.data.openMenu);
    }
  }

  setDialogSize() {
    if (window.innerWidth <= 1050 || window.innerHeight <= 700) {
      document.getElementsByClassName("dialog-snap-to-fullscreen")[0].setAttribute("style", "width: " + window.innerWidth + "px; height: " + window.innerHeight + "px;");
      document.querySelectorAll(".tabbed-info-dialog .mat-dialog-content")[0].setAttribute("style", "height: " + (window.innerHeight - 60) + "px");
    }
    else {
      document.getElementsByClassName("dialog-snap-to-fullscreen")[0].setAttribute("style", "");
      document.querySelectorAll(".tabbed-info-dialog .mat-dialog-content")[0].setAttribute("style", "");
    }
  }

  changeSelectedMenu(name: string) {
    this.menuSelected = name;
    this.cdr.detectChanges();
    this.panelScrollBar.scrollToTop();
  }

  clickClose(): void {
    this.dialogRef.close();
  }
}
