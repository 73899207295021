import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgScrollbar } from 'ngx-scrollbar';
import { InputParamsService, RangeParams } from 'src/app/services/input-params.service';
import { MapService } from 'src/app/services/map.service';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { CarModelSelectorDialogComponent } from '../modals/car-selector-dialog/car-selector-dialog.component';
import { UtilsService } from 'src/app/services/utils.service';
import { CarSelectorDialogComponent } from '../modals/car-properties-selector-dialog/car-properties-selector-dialog.component';
import { AccountService, ECar, UserECar } from 'src/app/services/account.service';
import { ManageCarsComponent } from '../manage-cars/manage-cars.component';

@Component({
  selector: 'app-slider-sidebar',
  templateUrl: './slider-sidebar.component.html',
  styleUrls: ['./slider-sidebar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderSidebarComponent implements OnInit {
  @ViewChild('sliderScrollbar', { static: true }) sliderScrollbar: NgScrollbar;
  @ViewChild('manageCarsComponent', { static: false }) manageCarsComponent: ManageCarsComponent;
  mobileResolution = false;
  mobileVisiblePanel = null;
  selectedMode: string = "range";
  batteryLevel: number = 73;
  batteryLevelMin: number = 1;
  weight: number = 2;
  tirePressure: number = 2.3;
  tirePressureString: string = "2.3 Bar";
  userBehavior: number = 50;
  userBehaviorString: string = "normal";
  batterySafetyString: string;
  speedLimiter: number = 100;
  speedLimiterString: string = "100 km/h";
  minBatteryAtDestination: number = 10;
  batterySafetyLimit: number = 50;
  batterySafetyMin: number = 20;
  batterySafetyMax: number = 50;
  weightMin: number = 1;
  weightMax: number = 5;
  speedLimiterMin: number = 80;
  speedLimiterMax: number = 130;
  flatRangeImg: string = "./assets/slider_small_button_battery.svg";
  weightImg: string = "./assets/slider_button_weight.svg";
  tirePressureImg: string = "./assets/slider_small_button_tirepressure.svg";
  userBehaviorImg: string = "./assets/slider_small_button_userbehavior.svg";
  speedLimiterImg: string = "./assets/slider_small_button_speedlimiter.svg";
  batterySafetyLimitImg: string = "./assets/slider_button_safetylimit.svg";
  minBatteryAtDestinationImg: string = "./assets/slider_small_button_batteryatdestination.svg";
  selectedCar: ECar = {
    Id: -1,
    ChargerTypes: null,
    DesignCapacity: null,
    GradeWeights: null,
    Name: "",
    Type: null,
    Subtype: null,
    Range: null,
    FactoryRange: null,
    FactoryRangeSource: "",
    SpeedWeights: null,
    TopSpeed: null,
    TotalPower: null,
    UsedCapacity: null,
    Icon: null,
    FastChargePower: null,
    ChargePower: null
  };
  selectedCarNameString: string = "";
  selectedCarMultilineString: string = "";
  unit: any = {"speed": "metric", "distance": "metric", "temperature": "metric", "pressure": "metric"};
  oldIOS: boolean = false;
  trikeActive: boolean = false;
  subscriptions = [];
  manageCars: boolean = true;
  manageCarsExpanded: boolean = false;
  @HostListener('window:resize') onResize() {
    setTimeout(()=>{
      this.sliderScrollbar.update();
    },500)
    
  }

  // display selected car, car properties sliders
  constructor(private paramsService: InputParamsService, public carDialog: MatDialog, private mapService: MapService, private mobileResolutionService: MobileResolutionService,
    private cdr: ChangeDetectorRef, private utilsService: UtilsService, private accountService: AccountService) {
    this.selectedCar.Name = "";
    this.selectedCarNameString = "";
    this.selectedCarMultilineString = "";
    this.paramsService.setRangeInputParams(this.getRangeSliderParams());
    // observe mobile resolution
    this.subscriptions.push(this.mobileResolutionService.ObservableMobileResolution.subscribe((resp) => {
      if (resp != null) {
        this.mobileResolution = resp;
      }
    }));
    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((resp) => {
      if (resp == "slider") {
        setTimeout(() => {
          this.sliderScrollbar.update();
          this.sliderScrollbar.scrollToTop();
        }, 300);
      }
      if (resp){
        this.mobileVisiblePanel = resp;
        this.cdr.detectChanges();
      }
    }));
    if (navigator.userAgent.includes('iPhone OS 10') || navigator.userAgent.includes('iPhone OS 9') ||
      navigator.userAgent.includes('iPhone OS 8') || navigator.userAgent.includes('iPhone OS 7')) {
      this.oldIOS = true;
    }
  }

  ngOnInit() {
    this.getBatterySafetyLimit();
    this.getTirePressure();
    // observe selected car
    this.subscriptions.push(this.mapService.ObservableSelectedCar.subscribe((resp) => {
      if (resp) {
        this.selectedCar = resp.Ecar;
        this.selectedCarMultilineString = "";
        if (resp.name.length > 0) {
          this.selectedCarNameString = resp.name;
        }
        else {
          this.selectedCarNameString = this.selectedCar.Name;
          if (this.selectedCarNameString && this.selectedCarNameString.toLocaleLowerCase().includes("tesla model")) {
            this.selectedCarMultilineString = this.selectedCarNameString.substr(14);
            this.selectedCarNameString = this.selectedCarNameString.substr(0, 13);
          }
        }
        this.cdr.detectChanges();
      }
    }));
    // observe input params
    this.subscriptions.push(this.paramsService.ObservableRangeInputParams.subscribe((resp: RangeParams) => {
      if (resp) {
        this.batteryLevel = Math.round(resp.RangeInputParams.batteryLevel);
        this.cdr.detectChanges();
      }
    }));

    var firstLoaded = false;
    //observe selected mode
    this.subscriptions.push(this.paramsService.ObservableSelectedMode.subscribe((resp) => {
      this.selectedMode = resp;
      if (resp == "route") {
        this.batterySafetyLimit = 15;
        this.getBatterySafetyLimit();
        this.batterySafetyMin = 10;
        this.batterySafetyMax = 30;
        this.batteryLevelMin = 10;
        if (this.batteryLevel < 10) {
          this.batteryLevel = 10;
          localStorage.setItem('batteryLevel', this.batteryLevel.toString());
        }
      }
      else {
        this.batterySafetyLimit = 50;
        this.getBatterySafetyLimit();
        this.batterySafetyMin = 20;
        this.batterySafetyMax = 50;
        this.batteryLevelMin = 1;

      }
      if (firstLoaded) {
        localStorage.setItem('batterySafetyLimit', this.batterySafetyLimit.toString());
      }
      else {
        firstLoaded = true;
      }
      this.paramsService.setRangeInputParams(this.getRangeSliderParams());
      this.cdr.detectChanges();
    }));
    this.sliderScrollbar.update();

    // observe unit
    this.subscriptions.push(this.mapService.ObservableUnit.subscribe((resp) => {
      if (resp) {
        this.unit = resp;
        this.getSpeedLimiter();
        this.getTirePressure();
        this.cdr.detectChanges();
      }
    }));

    if (document.URL.length > 1 && document.URL.includes("trike") || document.URL.includes("tori")) {
      this.trikeActive = true;
      this.cdr.detectChanges();
    }

    this.loadSettings();

    if (document.URL.length > 1 && document.URL.includes("tori")) {
      this.weightMin = 1;
      this.weightMax = 2;
      if (this.weight > 2) {
        this.weight = 1;
      }
      this.speedLimiterMin = 30;
      this.speedLimiterMax = 45;
      this.speedLimiter = 45;
      this.getSpeedLimiter();
      this.cdr.detectChanges();
    }
    else {
      if (this.speedLimiter <= 45) {
        this.speedLimiter = 100;
        this.getSpeedLimiter();
      }
    }

    this.accountService.ObservableUserSettings.subscribe((resp) => {
      if (resp) {
        this.loadSettings();
      }
    });

    this.accountService.ObservableIsAuthorized.subscribe((resp)=>{
      if (resp){
        this.manageCars = resp;
      }
    })
  }

  sliderInterval: any;

  loadSettings(): void {
    if (this.paramsService.AcceptCookies || this.accountService.getIsAuthorized()) {
      var batteryLevel = localStorage.getItem('batteryLevel');
      if (batteryLevel) {
        this.batteryLevel = parseFloat(batteryLevel);
        this.cdr.detectChanges();
      }
      var batterySafetyLimit = localStorage.getItem('batterySafetyLimit');
      if (batterySafetyLimit) {
        if (this.selectedMode=="route" && parseFloat(batterySafetyLimit)>30){
          batterySafetyLimit = "30";
          localStorage.setItem('batterySafetyLimit', batterySafetyLimit);
        }
        this.batterySafetyLimit = parseFloat(batterySafetyLimit);
        this.getBatterySafetyLimit();
      }
      var minBatteryAtDestination = localStorage.getItem('minBatteryAtDestination');
      if (minBatteryAtDestination) {
        this.minBatteryAtDestination = parseFloat(minBatteryAtDestination);
      }
      var speedLimiter = localStorage.getItem('speedLimiter');
      if (speedLimiter) {
        this.speedLimiter = parseFloat(speedLimiter);
        this.getSpeedLimiter();
      }
      var tirePressure = localStorage.getItem('tirePressure');
      if (tirePressure) {
        this.tirePressure = parseFloat(tirePressure);
        this.getTirePressure();
      }
      var userBehavior = localStorage.getItem('userBehavior');
      if (userBehavior) {
        this.userBehavior = parseFloat(userBehavior);
        this.getUserBehavior();
      }
      var weight = localStorage.getItem('weight');
      if (weight) {
        this.weight = parseFloat(weight);
      }
      this.sliderParamsChanged();
      this.cdr.detectChanges;
    }
  }

  // range slider change
  sliderParamsChanged(batterySlider?: boolean): void {
    clearTimeout(this.sliderInterval);
    this.paramsService.setRangeInputParams(this.getRangeSliderParams());
    if (this.paramsService.AcceptCookies) {
      localStorage.setItem('batteryLevel', this.batteryLevel.toString());
      localStorage.setItem('batterySafetyLimit', this.batterySafetyLimit.toString());
      localStorage.setItem('minBatteryAtDestination', this.minBatteryAtDestination.toString());
      localStorage.setItem('speedLimiter', this.speedLimiter.toString());
      localStorage.setItem('tirePressure', this.tirePressure.toString());
      localStorage.setItem('userBehavior', this.userBehavior.toString());
      localStorage.setItem('weight', this.weight.toString());
    }
  }

  // getting object that contains sliders current values
  getRangeSliderParams(): any {
    return {
      batteryLevel: this.batteryLevel, weight: this.weight, tirePressure: this.tirePressure, userBehavior: this.userBehavior, speedLimiter: this.speedLimiter,
      batterySafetyLimit: this.batterySafetyLimit, minBatteryAtDestination: this.minBatteryAtDestination
    };
  }

  getSpeedLimiter() {
    if (this.unit['speed'] == "metric") {
      this.speedLimiterString = this.speedLimiter + " km/h";
    }
    else if (this.unit['speed'] == "imperial") {
      this.speedLimiterString = Math.round(this.utilsService.kmphToMph(this.speedLimiter)) + " mph";
    }
    this.cdr.detectChanges();
  }

  // user behavior type conversion from the value
  getUserBehavior() {
    if (this.userBehavior < 33) {
      this.userBehaviorString = "eco";
    }
    else if (this.userBehavior < 66) {
      this.userBehaviorString = "normal";
    }
    else {
      this.userBehaviorString = "sport";
    }
    this.cdr.detectChanges();
  }

  // change battery reserved value to return limit at max value 
  getBatterySafetyLimit() {
    if (this.batterySafetyLimit != 50) {
      this.batterySafetyString = this.batterySafetyLimit.toString() + " %";
    }
    else {
      this.batterySafetyString = "Return limit";
    }
    this.cdr.detectChanges();
  }

  getTirePressure() {
    var pre = (this.tirePressure - 2.3 >= 0) ? "+" : "-";
    if (this.unit['pressure'] == "metric") {
      if (this.tirePressure - 2.3 == 0) {
        this.tirePressureString = "Std + 0.0 bar";
      }
      else {
        this.tirePressureString = "Std " + pre + " " + Math.abs(Math.round((this.tirePressure - 2.3) * 10) / 10) + " bar";
      }
    }
    else if (this.unit['pressure'] == "imperial") {
      this.tirePressureString = "Std " + pre + " " + Math.abs(Math.round(this.utilsService.barToPsi(this.tirePressure) - this.utilsService.barToPsi(2.3)) * 10) / 10 + " psi";
    }
    this.cdr.detectChanges();
  }

  // open error dialog modal
  openCarSelectorDialog(): void {
    if (!this.trikeActive) {
      if (!this.accountService.getIsAuthorized()) {
        let dialogRef = this.carDialog.open(CarSelectorDialogComponent, { data: {} });
      }
      else {
        this.manageCarsComponent.toggleView();
        this.manageCarsExpanded = !this.manageCarsExpanded;
        this.cdr.detectChanges();
      }
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }
}
