import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PrivacyPolicyComponent } from 'src/app/components/privacy-policy/privacy-policy.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { InputParamsService } from 'src/app/services/input-params.service';
import { HttpClient } from '@angular/common/http';
import { RecaptchaModule, RecaptchaFormsModule, RecaptchaComponent } from 'ng-recaptcha';
import { timer } from 'rxjs';
import device from 'current-device';
import { UtilsService } from 'src/app/services/utils.service';
import { EulaComponent } from 'src/app/components/eula/eula.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  @ViewChild('captchaRef', { static: false }) reCaptcha: RecaptchaComponent;
  private rating: number = 0;
  private starCount: number = 5;
  private ratingArr = [];
  private reviewText: string = "";
  private allowSensitiveData: boolean = false;
  private email: string = "";
  private captchaResponse: string = null;
  private recaptchaError: boolean = false;
  private msgError: boolean = false;
  private emailError: boolean = false;
  private success: boolean = false;
  private sending: boolean = false;
  private failed: boolean = false;
  private webView: boolean = false;
  private mobile: boolean = false;

  public ObservableTabChangeRequest: BehaviorSubject<string>;
  
  constructor(private inputParamsService: InputParamsService, private http: HttpClient, public matDialog: MatDialog, private utilsSerivce: UtilsService) {
    this.ObservableTabChangeRequest = new BehaviorSubject<string>(null);
    
    if (window.navigator.userAgent.includes("GPSTWebView")) {
      this.webView = true;
    }
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.push(index);
    }
    this.mobile = device.mobile();
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if (this.mobile) {
      setTimeout(() => {
        if (document.getElementsByClassName("g-recaptcha-bubble-arrow").length > 0) {
          document.getElementsByClassName("g-recaptcha-bubble-arrow")[0].parentElement.classList.add("recaptcha-resize");
        }
        else {
          // really slow devices
          setTimeout(() => {
            if (document.getElementsByClassName("g-recaptcha-bubble-arrow").length > 0) {
              document.getElementsByClassName("g-recaptcha-bubble-arrow")[0].parentElement.classList.add("recaptcha-resize");
            }
          }, 3000);
        }
      }, 1000);
    }
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return true;
    } else {
      return false;
    }
  }

  openPrivacyPolicy() {
    this.ObservableTabChangeRequest.next("privacy");
  }

  openEula() {
    this.ObservableTabChangeRequest.next("legal");
  }

  accept() {
    if (!this.failed && !this.sending && !this.success) {
      this.msgError = false;
      this.recaptchaError = false;
      this.emailError = false;
      this.failed = false;
      /*
      var emailMsg = "<!doctype html><head><meta charset='utf-8'></head><body style='font-family: Roboto, Arial, Helvetica, sans-serif; padding: 16px;'>";
      emailMsg += "<h2 style='padding: 13px 0px;margin: 0px;text-align: center;font-weight: 500;font-size: 18px; background: #eaf3f7;border-bottom: 2px solid #51869e;text-transform: uppercase;height: 32px;'>FEEDBACK for EVNavigation.com</h2>";
      if (this.email != "") {
        emailMsg += "<p>Email sent from: " + this.email + " </p>";
      }
      if (this.rating != 0) {
        emailMsg += "<p>Rated with ";
        for (let i = 0; i < this.rating; i++) {
          emailMsg += "<img src='https://dev.evnavigation.com/assets/conti_poidetails_star1.png' style='width: 26.5px; height: 25.5px;'/>";
        }
        emailMsg += "</p>"
      }
      if (this.reviewText != "") {
        emailMsg += "<p><b>The following critique written:</b>  " + this.reviewText + "</p>";
      }
      if (this.allowSensitiveData) {
        emailMsg += "<p><b>Useragent:</b> " + navigator.userAgent; +"</p>";
        emailMsg += "<p><b>Input params:</b></p> <pre> " + JSON.stringify(this.inputParamsService.getRangeParams()) + "</pre>";
      }
      emailMsg += "</body></html>";*/

      if (this.captchaResponse == null) {
        this.recaptchaError = true;
      }
      if (this.email != "" && !this.utilsSerivce.validateEmail(this.email) || this.email == "") {
        this.emailError = true;
      }
      if (this.rating == 0 && this.reviewText == "") {
        this.msgError = true;
      }
      if (!this.msgError && !this.emailError && !this.recaptchaError) {
        if (this.email == "") {
          this.email = null;
        }
        var emailJson = {
          captchaResponse: this.captchaResponse, email: this.email, rating: this.rating, reviewText: this.reviewText, userAgent: null, inputParams: null
        };
        if (this.allowSensitiveData) {
          emailJson.userAgent = navigator.userAgent;
          emailJson.inputParams = JSON.stringify(this.inputParamsService.getRangeParams());
        }
        this.sending = true;
        var emailUrl;
        if (document.URL.includes("localhost") || document.URL.includes("staging") || document.URL.includes("192.168.")) {
          emailUrl = "https://dev.evnavigation.com/mail/mail.php";
        }
        else {
          emailUrl = "https://evnavigation.com/mail/mail.php";
        }
        this.http.post(emailUrl, emailJson).subscribe((resp) => {
          this.sending = false;
          this.success = true;
        },
          () => {
            this.sending = false;
            this.failed = true;
            setTimeout(() => {
              this.failed = false;
            }, 5000);
          });
      }
    }
  }

  resetform() {
    this.sending = false;
    this.success = false;
    this.rating = 0;
    this.email = "";
    this.reviewText = "";
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
  }

  ngOnDestroy() {
  }
}
