import { Component, OnInit, Inject, ChangeDetectorRef, ComponentFactoryResolver } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MapService } from 'src/app/services/map.service';
import { ECar, UserECar, AccountService } from 'src/app/services/account.service';
import { CarModelSelectorDialogComponent } from '../car-selector-dialog/car-selector-dialog.component';
import { UtilsService } from 'src/app/services/utils.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-car-properties-selector-dialog',
  templateUrl: './car-properties-selector-dialog.component.html',
  styleUrls: ['./car-properties-selector-dialog.component.css']
})
export class CarSelectorDialogComponent implements OnInit {

  // cartypes, chargertypes, variables
  CarTypes = ["audi", "bmw", "chevrolet", "citroen", "ford", "fiat", "honda", "hyundai", "jaguar", "kia", "lightyear", "lucidair", "mercedes", "mg", "mini", "mitsubishi", "nissan", "opel",
    "peugeot", "renault", "porsche", "seat", "smart", "skoda", "tesla", "volvo", "volkswagen"];
  TeslaCarTypes = ["Roadster", "Model S", "Model 3", "Model X", "Model Y", "Cybertruck"];
  carType = "tesla";
  teslaCarType = "Model 3";
  unit:any = {"speed": "metric", "distance": "metric", "temperature": "metric", "pressure": "metric"};
  chargerTypes = [
    { name: "CCS", icon: "plug_css.png" }, { name: "CHADEMO", icon: "plug_chademo.png" },
    { name: "SCHUKO", icon: "plug_schuko.png" }, { name: "TESLA", icon: "plug_supercharger.png" },
    { name: "TYPE 1", icon: "plug_type1.png" }, { name: "TYPE 2", icon: "plug_type2.png" }
  ];
  selectedChargerTypes = [false, false, false, false, false, false];
  csError = false;
  ECars: ECar[] = [];
  selectedCar: ECar;
  multipleCarType: boolean = false;
  dialogContentIsLoading: boolean = true;
  customCarNameForm: FormGroup;
  editedUserCar: UserECar = null;
  customNameVisible: boolean = false;

  constructor(public dialogRef: MatDialogRef<CarSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private mapService: MapService, private accountService: AccountService, private matDialog: MatDialog, private utilsService: UtilsService, private cdr: ChangeDetectorRef) {
    if (data && data.editedUserCarId) {
      this.editedUserCar = this.accountService.getUserProfile().getCarById(data.editedUserCarId);
    }
  }

  ngOnInit() {
    this.customNameVisible = this.accountService.getIsAuthorized();
    this.CarTypes.sort();

    this.customCarNameForm = new FormGroup({
       customCarName: new FormControl()
    });

    // load cars, set default car, load unit, chargertypes
    this.mapService.ObservableECarsLoaded.subscribe((resp) => {
      this.ECars = JSON.parse(JSON.stringify(resp));
      if (this.ECars != undefined && this.ECars != null && this.ECars.length != undefined && this.ECars.length > 0) {
        this.selectDefaultCar();
        this.unit = this.mapService.getUnit();

        if (this.editedUserCar != null) {
          this.customCarNameForm.patchValue({'customCarName': this.editedUserCar.name});
          this.selectedCar = this.editedUserCar.Ecar;
          this.carType = this.selectedCar.Type;
          if (this.carType == "tesla") {
            this.teslaCarType = this.selectedCar.Subtype;
          }
        }
        else if (this.mapService.getSelectedCar()) {
          this.selectedCar = this.mapService.getSelectedCar();
          this.carType = this.selectedCar.Type;
          if (this.carType == "tesla") {
            this.teslaCarType = this.selectedCar.Subtype;
          }
        }

        if (!this.accountService.getIsAuthorized()) {
          this.selectedChargerTypes = JSON.parse(JSON.stringify(this.mapService.getChargingStations()));
        }
        else {
          this.setChargerTypes(this.selectedCar.ChargerTypes);
        }

        this.multipleTypeSelector();

        this.dialogContentIsLoading = false;
        this.cdr.detectChanges();
      }
    });
  }

  selectDefaultCar() {
    let defCarId: number = this.utilsService.defaultCarIndex;
    for (let i = 0; i < this.ECars.length; ++i) {
      if (this.ECars[i].Id == defCarId) {
        this.selectedCar = this.ECars[i];
        break;
      }
    }
  }
  
  clickOk() {
    if (!this.csError) {
      // set new vehicle with charging stations data in the application
      var selectedChargersArr = [];
      for (let i = 0; i < this.selectedChargerTypes.length; i++) {
        if (this.selectedChargerTypes[i] == true) {
          selectedChargersArr.push(i + 1);
        }
      }
      if (this.accountService.getIsAuthorized()) {
        var cname:string = this.customCarNameForm.get('customCarName').value;
        if (cname == null || cname == undefined || cname.length <= 0) {
          cname = this.selectedCar.Name;
        }
        if (this.editedUserCar != null) {
          this.accountService.editVehicle(this.editedUserCar.userVehicleID, cname, selectedChargersArr).subscribe(() => { this.dialogRef.close(); });
        }
        else {
          this.accountService.addVehicle(this.selectedCar.Id, cname, selectedChargersArr).subscribe(() => { this.dialogRef.close(); });
        }
      }
      else {
        localStorage.setItem("chargingStations", this.selectedChargerTypes.toString());
      }
      this.selectedCar.ChargerTypes = selectedChargersArr;
      this.mapService.setChargingStations(this.selectedChargerTypes);
      this.mapService.setSelectedCar(this.selectedCar, true);
      this.dialogRef.close();
    }
  }

  carTypeChanged() {
    // change vehicle inside pop up window
    for (let i = 0; i < this.ECars.length; i++) {
      if (this.carType == this.ECars[i].Type) {
        if (this.carType == "tesla" && this.teslaCarType == this.ECars[i].Subtype) {
          this.selectedCar = JSON.parse(JSON.stringify(this.ECars[i]));
          this.setChargerTypes(this.selectedCar.ChargerTypes);
          break;
        }
        else if (this.carType != "tesla") {
          this.selectedCar = JSON.parse(JSON.stringify(this.ECars[i]));
          this.setChargerTypes(this.selectedCar.ChargerTypes);
          break;
        }
      }
    }
  }

  carChanged(ecar) {
    this.selectedCar = JSON.parse(JSON.stringify(ecar));
    this.setChargerTypes(this.selectedCar.ChargerTypes);
  }

  setChargerTypes(chargerTypesArr) {
    // process charger types data
    for (let i = 0; i < this.selectedChargerTypes.length; i++) {
      this.selectedChargerTypes[i] = false;
    }

    for (let i = 0; i < chargerTypesArr.length; i++) {
      this.selectedChargerTypes[chargerTypesArr[i] - 1] = true;
    }

    this.multipleTypeSelector();
  }

  checkChargerTypes() {
    var csTrue = false;
    for (let i = 0; i < this.selectedChargerTypes.length; i++) {
      if (this.selectedChargerTypes[i] == true) {
        csTrue = true;
      }
    }
    if (csTrue) {
      this.csError = false;
    }
    else {
      this.csError = true;
    }
  }

  openSelectModel() {
    // open the car model selector modal
    var dialogRef = this.matDialog.open(CarModelSelectorDialogComponent, { data: { carType: this.carType, teslaCarType: this.teslaCarType } });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        this.selectedCar = this.ECars[resp];
        this.setChargerTypes(this.selectedCar.ChargerTypes);
      }
    });
  }

  multipleTypeSelector() {
    var ecarTypes = this.ECars.filter(it => {
      if (it.Type != "tesla") {
        return it.Type == this.carType;
      }
      else {
        if (this.teslaCarType == "all") {
          return it.Type == this.carType;
        }
        else {
          return (it.Type == this.carType && it.Subtype == this.teslaCarType);
        }
      }
    });
    this.multipleCarType = ecarTypes.length > 1 ? true : false;
  }

  clickCancel() {
    this.dialogRef.close();
    if (this.data && this.data.firstUserCar){
      this.accountService.addDefaultVehicleToUser();
    }
  }
}
