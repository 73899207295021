import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { fromEvent } from 'rxjs';
import { tap, concatMap, takeUntil } from 'rxjs/operators';
import { HostListener } from '@angular/core';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MatDialog } from '@angular/material/dialog';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { ConfirmationDialogComponent } from '../modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-rha-slider',
  templateUrl: './rha-slider.component.html',
  styleUrls: ['./rha-slider.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RhaSliderComponent implements OnInit {

  // input output data
  @Input() altString: string;
  @Input() digit: number;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() imageSource: any;
  @Input() zeroImageSource: any;
  @Input() value: number;
  @Output() valueChange = new EventEmitter();
  @Output() valueChangeEvent = new EventEmitter();

  @ViewChild('sliderOuter', { static: true }) slider: ElementRef;

  // recalculating sliders when size changed
  @HostListener('window:resize') onResize() {
    this.setSliderToCurrValue();
  }

  sliderFill: any;
  sliderImg: any;

  grabImage: any = null;

  subscriptions: any = [];

  constructor(private inputParamsService: InputParamsService, public matDialog: MatDialog, private mobileResolutionService:MobileResolutionService) {
  }

  ngOnChanges(): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.setSliderToCurrValue();
  }

  // subscribe slider move/touch events, setting slider to the default values
  ngOnInit() {
    const sDown = fromEvent(this.slider.nativeElement, 'mousedown');
    const sMove = fromEvent(document, 'mousemove').pipe(tap((mm: MouseEvent) => mm.preventDefault()));
    const sUp = fromEvent(document, 'mouseup').pipe(tap((mu: MouseEvent) => mu.preventDefault()));
    const sDrag = sDown.pipe(concatMap(DragEvent => sMove.pipe(takeUntil(sUp))));
    sDown.subscribe((md: MouseEvent) => (this.moveSliderCondition(md.pageX)));

    sDrag.subscribe((md: MouseEvent) => {
      md.preventDefault();
      if (!isNaN(md.pageX)) {
        this.moveSliderCondition(md.pageX);
      }
    });

    const sTouchStart = fromEvent(this.slider.nativeElement, 'touchstart');
    const sTouchMove = fromEvent(document, 'touchmove').pipe(tap((mm: TouchEvent) => mm.preventDefault()));
    const sTouchEnd = fromEvent(document, 'touchend').pipe(tap((mm: TouchEvent) => mm.preventDefault()));
    const sTouchDrag = sTouchStart.pipe(concatMap(DragEvent => sTouchMove.pipe(takeUntil(sTouchEnd))));

    sTouchDrag.subscribe((td: TouchEvent) => {
      td.preventDefault();
      if (!isNaN(td.changedTouches[0].pageX)) {
        this.moveSliderCondition(td.changedTouches[0].pageX);
      }
    });

    this.sliderFill = this.slider.nativeElement.querySelector('.slider-fill');
    this.sliderImg = this.slider.nativeElement.querySelector('.slider-image');
    this.setSliderToCurrValue();

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((resp) => {
      if (resp == "slider") {
        if (navigator.userAgent.includes("FBIOS")){
          this.setSliderToCurrValue();
        }
      }
    }));
  }

  // setting slider to the current value
  setSliderToCurrValue() {
    var sliderWidth;
    if (window.innerWidth > 1050 && window.innerHeight > 700) {
      sliderWidth = 302;
    }
    else {
      sliderWidth = window.innerWidth * 0.55;
    }
    var sliderMinPos: number = 16;
    var sliderMaxPos: number = sliderWidth - sliderMinPos;
    var valueInPercent = (this.value - this.min) / (this.max - this.min);
    var newPos: number = (valueInPercent * (sliderMaxPos - sliderMinPos)) + sliderMinPos;
    if (this.sliderFill) {
      this.sliderFill.style.width = (newPos + 16) + "px";
    }
    if (this.sliderImg) {
      this.sliderImg.style.left = (newPos - 16) + "px";
    }
    this.grabImage = this.value == 0 ? (this.zeroImageSource != null ? this.zeroImageSource : this.imageSource) : this.imageSource;
  }

  dialogRef;

  // moving slider to the new value from mouse/touch event
  moveSliderCondition(pageX: number) {
    if (this.altString == "battery level" && this.inputParamsService.getTrackingTimer()) {
      if (!this.dialogRef) {

        this.dialogRef = this.matDialog.open(ConfirmationDialogComponent, { data: { message: "Do you really want to stop tracking mode?" } });
        
        this.dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.inputParamsService.setTrackingTimer(false);
            this.moveSlider(pageX);
          }
          else {

          }
          this.dialogRef = null;
        });
      }
    }
    else {
      this.moveSlider(pageX);
    }

  }

  moveSlider(pageX: number) {
    const buttonWidth = 16;
    // coords
    var newPos: number = pageX - this.slider.nativeElement.getBoundingClientRect().left;
    var sliderMinPos: number = buttonWidth;
    var sliderMaxPos: number = this.slider.nativeElement.clientWidth - buttonWidth;

    // overpulled
    if (newPos < sliderMinPos) {
      newPos = sliderMinPos;
    }
    if (newPos > sliderMaxPos) {
      newPos = sliderMaxPos;
    }

    // convert position coord into step groups
    if (this.step && this.step > 0) {
      var sliderStepWidth: number = (sliderMaxPos - sliderMinPos) / (this.step);
      var currStep: number = ((newPos - sliderMinPos) / sliderStepWidth);
      newPos = sliderMinPos + currStep * sliderStepWidth;
    }

    /*this.sliderFill.style.width = (newPos + buttonWidth) + "px";
    this.sliderImg.style.left = (newPos - buttonWidth) + "px";*/
    var currValue: number = (newPos - sliderMinPos) / (sliderMaxPos - sliderMinPos);
    this.changeValue(+this.toFixed(((currValue * (this.max - this.min)) + this.min), this.digit));
  }

  // create number with fixed decimals
  toFixed(num, precision) {
    return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
  }

  // passing slider new value to parent
  changeValue(value: number) {
    this.value = value;
    this.valueChange.emit(parseFloat(value.toFixed(this.digit)));
    this.valueChangeEvent.emit();
  }
}