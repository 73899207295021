import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { MapService } from 'src/app/services/map.service';
import { InputParamsService } from 'src/app/services/input-params.service';
import { HttpClient } from '@angular/common/http';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import LineString from 'ol/geom/LineString';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Point from 'ol/geom/Point';
import Icon from 'ol/style/Icon';
import { fromLonLat, transform } from 'ol/proj';
import { Overlay } from 'ol';
import { NavigationService } from 'src/app/services/navigation.service';
import { UtilsService } from 'src/app/services/utils.service';
import CircleStyle from 'ol/style/Circle';
import { LatLng, LatLngBounds } from 'leaflet';
import { AudioService } from 'src/app/services/audio.service';
import RegularShape from 'ol/style/RegularShape';
import Fill from 'ol/style/Fill';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { createSnapToResolutions } from 'ol/resolutionconstraint';
import { timer, fromEvent } from 'rxjs';
import { first } from 'rxjs/operators';
import Polygon from 'ol/geom/Polygon';
import Text from 'ol/style/Text';
import { defaults as defaultControls, ScaleLine, defaults } from 'ol/control';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  constructor(private mapService: MapService, private inputParamsService: InputParamsService, private http: HttpClient, private navigationService: NavigationService,
    private utilsService: UtilsService, private audioService: AudioService, private cdr: ChangeDetectorRef, private mobileResolutionService: MobileResolutionService,
    private _ngZone: NgZone) {
  }

  map: Map;
  routeLayer: VectorLayer;
  turnInstructionLayer: VectorLayer;
  turnArrowPos: any;
  turnInstructionArrowLayer: VectorLayer;
  tileEVAdminLayer: TileLayer;
  mapTileLayer: TileLayer;
  navigationActive: boolean = false;
  cursorOverlay: Overlay;
  gpsCursorOverlay: Overlay;
  chargingStationOverlay: Overlay;
  watchPositionId: any;
  replayMode: boolean = false;
  planPoints: any;
  turns: any;
  chargingStations: any;
  lastTurnRouteId: number;
  subscriptions: any = [];
  mobileResolution: boolean = false;
  slowTimer = timer(1000);
  fastTimer = timer(5);
  sPressed: boolean = false;
  fakeGpsIterationIndex: number = 0;

  darkMapUrl = 'https://tiles.gpstuner.net/tile_evdark_en_v2/{z}/{x}/{y}.png';
  lightMapUrl = 'https://tiles.gpstuner.net/tile_evlight_en_v2/{z}/{x}/{y}.png';
  darkAdminUrl = 'https://tiles.gpstuner.net/tile_evdark_admin_en_v2/{z}/{x}/{y}.png';
  lightAdminUrl = 'https://tiles.gpstuner.net/tile_evlight_admin_en_v2/{z}/{x}/{y}.png';

  cs0: TileLayer = new TileLayer({
    source: new XYZ({
      url: 'https://dev.evnavigation.com/chargingstations/0/{z}_{x}_{y}.png'
    })
  });

  ngOnInit() {
    if (document.URL.includes("demo")) {
      this.replayMode = true;
    }
    // init map
    this.routeLayer = new VectorLayer({
      source: new VectorSource()
    });

    this.turnInstructionLayer = new VectorLayer({
      source: new VectorSource()
    });

    this.turnInstructionArrowLayer = new VectorLayer({
      source: new VectorSource()
    });

    var maptileUrl = this.mapService.getMapColor() == "light" ? this.lightMapUrl : this.darkMapUrl;
    var maptileLayer = new TileLayer({
      source: new XYZ({
        url: maptileUrl,
        maxZoom: 18,
        minZoom: 3,
      }),
    });

    this.mapTileLayer = maptileLayer;

    var adminUrl = this.mapService.getMapColor() == "light" ? this.lightAdminUrl : this.darkAdminUrl;
    var adminLayer = new TileLayer({
      source: new XYZ({
        url: adminUrl,
        maxZoom: 11,
        minZoom: 3,
      }),
    });
    this.tileEVAdminLayer = adminLayer;

    var mapBounds: LatLngBounds = this.mapService.getMapBounds();
    var Center = mapBounds.getCenter();

    this.map = new Map({
      target: 'map',
      layers: [
        this.mapTileLayer,
        this.tileEVAdminLayer,
        this.routeLayer,
        this.turnInstructionLayer,
        this.turnInstructionArrowLayer
      ],
      view: new View({
        center: fromLonLat([Center.lng, Center.lat]),
        maxZoom: 18,
        zoom: 18,
        minZoom: 3,
      }),
      controls: defaults({
        zoom: false,
        rotate: false
      })
    });
    this.map.addControl(new ScaleLine({ units: 'metric', minWidth: 42 }));
    this.map.addControl(new ScaleLine({ units: 'us', minWidth: 42 }));
    const olMap = document.getElementById('map');
    const touchStart = fromEvent(olMap, 'touchstart');
    const touchMove = fromEvent(olMap, 'touchmove');
    const touchEnd = fromEvent(olMap, 'touchend');

    touchMove.subscribe((event: any) => {
      if (/ OS 13_/.test(navigator.userAgent)) {
        event.stopImmediatePropagation();
        event.preventDefault();
        event.stopPropagation();
      }
    });

    /*this.mapService.ObserableNextTurn.subscribe((resp) => {
      if (resp && resp[0]) {
        var circle = new Style({
          image: new CircleStyle({
            radius: 10,
            fill: null,
            stroke: new Stroke({
              color: 'rgba(255,0,0,0.9)',
              width: 3
            })
          })
        });

        var feature = new Feature(
          new Point(fromLonLat([resp[0].Location.Longitude, resp[0].Location.Latitude]))
        );
        feature.setStyle(circle);

        // draw turn to map
        /*var route = new LineString([[planPoints[i - 1][1], planPoints[i - 1][0]], [planPoints[i][1], planPoints[i][0]]]).transform('EPSG:4326', 'EPSG:3857');
        var routeFeature = new Feature({
          type: 'route',
          geometry: route
        });
        routeFeature.setStyle(new Style({
          stroke: new Stroke({
            width: 20, color: [(planPoints[i][2] * 255), (255 - (planPoints[i][2] * 255)), 0, 1]
          })
        }));

        featureList.push(routeFeature);*/
    /*
    var source = new VectorSource({
      features: [feature]
    });
  }
});*/

    this.map.getView().on('change:rotation', this.changerotationEvt);
    this.map.on('moveend', this.changeZoomEvt);
    this.map.on('pointermove', this.changeZoomEvt);
    //this.map.on("touchmove", this.changeZoomEvt);

    this.subscriptions.push(this.navigationService.ObservableNextTurn.subscribe((resp) => {
      if (resp && this.planPoints && resp.NextTurnInstruction.RoutePointIdx > 0 && resp.NextTurnInstruction.RoutePointIdx != this.lastTurnRouteId) {
        if (resp.NextTurnInstruction.Maneuver.id != "ARRIVE" && resp.NextTurnInstruction.Maneuver.id != "ARRIVE_TO_WAYPOINT") {
          this.lastTurnRouteId = resp.NextTurnInstruction.RoutePointIdx;
          // draw turn to map

          var turnPoints = [];
          var remainingDist = 50;

          var turnStart = this.lastTurnRouteId;
          var turnEnd = resp.NextTurnInstruction.RouteEndPointIdx;

          // roundabout
          if (this.turns[resp.NextTurnInstruction.TurnIdx][0] == 94) {
            turnEnd = this.turns[resp.NextTurnInstruction.TurnIdx + 1][1];
          }

          if (this.turns[resp.NextTurnInstruction.TurnIdx][0] == 95) {
            turnStart = this.turns[resp.NextTurnInstruction.TurnIdx - 1][1];
          }

          // combined turns
          var combinedTurnIdx = resp.NextTurnInstruction.TurnIdx;

          while (combinedTurnIdx - 1 >= 0 && this.turns[combinedTurnIdx - 1][0] >= 12 && this.turns[combinedTurnIdx - 1][0] <= 92) {
            combinedTurnIdx--;
            turnStart = this.turns[combinedTurnIdx][1];
          }

          combinedTurnIdx = resp.NextTurnInstruction.TurnIdx;

          while (combinedTurnIdx + 1 < this.turns.length && this.turns[combinedTurnIdx][0] >= 12 && this.turns[combinedTurnIdx][0] <= 92) {
            combinedTurnIdx++;
            turnEnd = this.turns[combinedTurnIdx][1];
          }

          // add turn center point 
          turnPoints.push([this.planPoints[turnStart][1], this.planPoints[turnStart][0]]);

          // adding points before turn
          var pointIdx = turnStart;

          while (remainingDist > 0) {
            remainingDist -= this.utilsService.distanceBetweenCoordinates(this.planPoints[pointIdx], this.planPoints[pointIdx - 1]);
            pointIdx--;
            turnPoints.unshift([this.planPoints[pointIdx][1], this.planPoints[pointIdx][0]]);
            if (pointIdx <= 0 && remainingDist > 0) {
              break;
            }
          }

          if (remainingDist < 0) {
            var lastRemaingingDist = this.utilsService.distanceBetweenCoordinatesLonLat(turnPoints[0], turnPoints[1]);
            var alpha = (lastRemaingingDist + remainingDist) / lastRemaingingDist;
            var lastLon = this.utilsService.Lerpd(turnPoints[0][0], turnPoints[1][0], 1 - alpha);
            var lastLat = this.utilsService.Lerpd(turnPoints[0][1], turnPoints[1][1], 1 - alpha);
            turnPoints[0] = [lastLon, lastLat];
          }

          // adding 
          for (let i = turnStart + 1; i <= turnEnd; i++) {
            if (i < this.planPoints.length) {
              turnPoints.push([this.planPoints[i][1], this.planPoints[i][0]]);
            }
          }

          pointIdx = turnEnd;
          remainingDist = 50;
          while (remainingDist > 0 && pointIdx < this.planPoints.length - 1) {
            remainingDist -= this.utilsService.distanceBetweenCoordinates(this.planPoints[pointIdx], this.planPoints[pointIdx + 1]);
            pointIdx++;
            turnPoints.push([this.planPoints[pointIdx][1], this.planPoints[pointIdx][0]]);
          }

          if (remainingDist < 0) {
            lastRemaingingDist = this.utilsService.distanceBetweenCoordinatesLonLat(turnPoints[turnPoints.length - 2], turnPoints[turnPoints.length - 1]);
            alpha = (lastRemaingingDist + remainingDist) / lastRemaingingDist;
            lastLon = this.utilsService.Lerpd(turnPoints[turnPoints.length - 2][0], turnPoints[turnPoints.length - 1][0], alpha);
            lastLat = this.utilsService.Lerpd(turnPoints[turnPoints.length - 2][1], turnPoints[turnPoints.length - 1][1], alpha);
            turnPoints[turnPoints.length - 1] = [lastLon, lastLat];
          }

          var route = new LineString(turnPoints).transform('EPSG:4326', 'EPSG:3857');

          var turnFeatureOutline = new Feature({
            type: 'route',
            geometry: route
          });
          turnFeatureOutline.setStyle(new Style({
            stroke: new Stroke({
              width: 17, color: [4, 156, 219, 1]
            })
          }));

          var turnFeature = new Feature({
            type: 'route',
            geometry: route
          });
          turnFeature.setStyle(new Style({
            stroke: new Stroke({
              width: 12, color: [255, 255, 255, 1]
            })
          }));

          var vectorSource = new VectorSource({
            features: [turnFeatureOutline, turnFeature]
          });
          this.turnInstructionLayer.setSource(vectorSource);

          var angle = this.utilsService.angleFromCoordinate(turnPoints[turnPoints.length - 2][1], turnPoints[turnPoints.length - 2][0],
            turnPoints[turnPoints.length - 1][1], turnPoints[turnPoints.length - 1][0]);
          this.turnArrowPos = [turnPoints[turnPoints.length - 1], angle];
          this.setTurnArrow();
        }
        else {
          this.lastTurnRouteId = resp.NextTurnInstruction.RoutePointIdx;
          this.turnInstructionLayer.setSource(new VectorSource());
          this.turnInstructionArrowLayer.setSource(new VectorSource());
          this.turnArrowPos = null;
        }
      }
    }));

    this.subscriptions.push(this.navigationService.ObservableChangeNavigation.subscribe((resp) => {
      if (resp) {
        if (resp == "replan") {
          this.stopNavigation();
          this.startNavigation();
        }
        else if (resp == "stop") {
          this.stopNavigation();
        }
        else if (resp == "arrive") {
          this.stopNavigation();
        }
        else if (resp == "exit") {

          if (this.replayMode) {
            if (this.replayTimer) {
              this.replayTimer.unsubscribe();
            }
          }
        }
      }
    }));

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileResolution.subscribe((resp) => {
      if (resp != null && resp != undefined) {
        this.mobileResolution = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.mapService.ObservableMapColor.subscribe((resp) => {
      if (resp && this.planPoints && this.turns && this.chargingStations) {
        if (resp == "light") {
          var maptileLayer = new TileLayer({
            source: new XYZ({
              url: this.lightMapUrl,
              maxZoom: 18,
              minZoom: 3,
            }),
          });
          this.mapTileLayer.setSource(maptileLayer.getSource());
          var adminLayer = new TileLayer({
            source: new XYZ({
              url: this.lightAdminUrl,
              maxZoom: 11,
              minZoom: 3,
            }),
          });
          this.tileEVAdminLayer.setSource(adminLayer.getSource());
        }
        else {
          var maptileLayer = new TileLayer({
            source: new XYZ({
              url: this.darkMapUrl,
              maxZoom: 18,
              minZoom: 3,
            }),
          });
          this.mapTileLayer.setSource(maptileLayer.getSource());
          var adminLayer = new TileLayer({
            source: new XYZ({
              url: this.darkAdminUrl,
              maxZoom: 11,
              minZoom: 3,
            }),
          });
          this.tileEVAdminLayer.setSource(adminLayer.getSource());
        }

        this.drawRouteToMap(this.planPoints, this.turns, this.chargingStations);
        this.cursorOverlay = null;
        this.DrawCursorToMap(this.lastCoord);
      }
    }));

    document.addEventListener("keydown", (evt: any) => {
      if (evt.key == "s") {
        this.sPressed = true;
      }
    });

    document.addEventListener("keyup", (evt: any) => {
      if (evt.key == "s") {
        this.sPressed = false;
      }
    });

    this.startNavigation();
  }

  setTurnArrow() {
    var step = 0;
    if (this.lastZoom >= 15) {
      step = 0.00004 * Math.pow(2, (18 - this.lastZoom));
    }
    if (step == 0) {
      this.turnInstructionArrowLayer.setSource(new VectorSource());
    }
    else {
      var pos1 = fromLonLat([this.turnArrowPos[0][0] - (2 * step), this.turnArrowPos[0][1] - (1.5 * step)]);
      var pos2 = fromLonLat([this.turnArrowPos[0][0] - (1 * step), this.turnArrowPos[0][1] - (1.5 * step)]);
      var pos3 = fromLonLat([this.turnArrowPos[0][0] - (1 * step), this.turnArrowPos[0][1]]);
      var pos4 = fromLonLat([this.turnArrowPos[0][0], this.turnArrowPos[0][1]]);
      var pos5 = fromLonLat([this.turnArrowPos[0][0] + (1 * step), this.turnArrowPos[0][1]]);
      var pos6 = fromLonLat([this.turnArrowPos[0][0] + (1 * step), this.turnArrowPos[0][1] - (1.5 * step)]);
      var pos7 = fromLonLat([this.turnArrowPos[0][0] + (2 * step), this.turnArrowPos[0][1] - (1.5 * step)]);
      var pos8 = fromLonLat([this.turnArrowPos[0][0], this.turnArrowPos[0][1] + (1.5 * step)]);
      var cord = [pos1, pos2, pos3, pos4, pos5, pos6, pos7, pos8, pos1];

      var polyouter = new Polygon([cord]);
      polyouter.rotate(this.utilsService.toRad(-this.turnArrowPos[1]), fromLonLat(this.turnArrowPos[0]));

      var featureouter = new Feature(polyouter);
      featureouter.setStyle(new Style({
        stroke: new Stroke({ color: [4, 156, 219], width: 6 }),
        zIndex: 1
      }));

      var pos1b = fromLonLat([this.turnArrowPos[0][0] - (2 * step), this.turnArrowPos[0][1] - (1.5 * step)]);
      var pos2b = fromLonLat([this.turnArrowPos[0][0] + (2 * step), this.turnArrowPos[0][1] - (1.5 * step)]);
      var pos3b = fromLonLat([this.turnArrowPos[0][0], this.turnArrowPos[0][1] + (1.5 * step)]);
      var cordb = [pos1b, pos2b, pos3b, pos1b];
      var polyone = new Polygon([cordb]);
      polyone.rotate(this.utilsService.toRad(-this.turnArrowPos[1]), fromLonLat(this.turnArrowPos[0]));

      var featureone = new Feature(polyone);
      featureone.setStyle(new Style({
        fill: new Fill({
          color: 'white'
        }),
        zIndex: 2
      }));

      var vectorSource = new VectorSource({
        features: [featureouter, featureone]
      });
      this.turnInstructionArrowLayer.setSource(vectorSource);
    }
  }

  positionCar() {
    var center = this.map.getView().getCenter();
    var resolution = this.map.getView().getResolution();
    this.map.getView().setCenter([center[0] + 10 * resolution, center[1] + 300 * resolution]);
  }

  private receiveFirstPosition(position) {
    this.navigationService.LastReplanTime = Date.now();
    this.inputParamsService.setStartCoordsParams(new LatLng(position.coords.latitude, position.coords.longitude));
    this.inputParamsService.paramsUpdate();
    //this.map.getView().setCenter(transform([position.coords.longitude, position.coords.latitude], 'EPSG:4326', 'EPSG:3857'));
  }

  private errorReceivingFirstPosition() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.receiveFirstPosition(position);
    }, () => {
      this.errorReceivingFirstPosition();
    }, { enableHighAccuracy: true, timeout: 4000, maximumAge: 0 });
  }

  startNavigation() {
    if (!this.replayMode) {
      if (!window.navigator.userAgent.includes("GPSTWebView") && !window.navigator.userAgent.includes("FB_IAB")) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            this.receiveFirstPosition(position);
          }, () => {
            this.errorReceivingFirstPosition();
          }, { enableHighAccuracy: true, timeout: 4000, maximumAge: 0 });
        }
      }
      else {
        if (this.inputParamsService.lastWebViewLocation) {
          this.navigationService.LastReplanTime = Date.now();
          this.inputParamsService.setStartCoordsParams(new LatLng(this.inputParamsService.lastWebViewLocation[0], this.inputParamsService.lastWebViewLocation[1]));
          this.inputParamsService.paramsUpdate();
        }
        else {
          this.inputParamsService.watchGPSData();
        }
      }

    }
    else {
      this.navigationService.LastReplanTime = Date.now();

      if (this.navigationService.selectedReplayTrack != null && this.navigationService.CurrentPoint) {
        this.inputParamsService.setStartCoordsParams({ "lat": this.navigationService.CurrentPoint.Location.Latitude, "lng": this.navigationService.CurrentPoint.Location.Longitude });
      }
      else {
        this.inputParamsService.setStartCoordsParams(this.inputParamsService.getStartCoordsParams());
      }
      this.inputParamsService.paramsUpdate();
    }

    //for click

    /*this.navigationService.LastReplanTime = Date.now();
    if (this.lastCoord) {
      this.inputParamsService.setStartCoordsParams(new LatLng(this.lastCoord[0], this.lastCoord[1]));
    }
    this.inputParamsService.setStartCoordsParams(this.inputParamsService.getStartCoordsParams());*/

  }

  setPlannedRoute(planPoints, chargingStations, routeSegments, chargePlan, turns) {
    this.planPoints = planPoints;
    this.turns = turns;
    this.chargingStations = chargingStations;
    this.lastTurnRouteId = null;
    this.drawRouteToMap(planPoints, turns, chargingStations);

    this.initNavigation(planPoints, turns);
  }

  drawRouteToMap(planPoints, turns, chargingStations) {
    // remove previous drawing
    this.map.getOverlays().getArray().slice(0).forEach((overlay) => {
      this.map.removeOverlay(overlay);
    })
    var featureList: any = [];

    var locations = [];
    for (let i = 0; i < planPoints.length; i++) {
      locations[i] = [planPoints[i][1], planPoints[i][0]];
    }

    if (this.mapService.getMapColor() == "light") {
      // grey outher
      var route = new LineString(locations).transform('EPSG:4326', 'EPSG:3857');
      var routeFeature = new Feature({
        type: 'route',
        geometry: route
      });

      routeFeature.setStyle(new Style({
        stroke: new Stroke({
          width: 15, color: [204, 204, 204, 1]
        })
      }));

      featureList.push(routeFeature);
    }

    // white line
    var route = new LineString(locations).transform('EPSG:4326', 'EPSG:3857');
    var routeFeature = new Feature({
      type: 'route',
      geometry: route
    });

    routeFeature.setStyle(new Style({
      stroke: new Stroke({
        width: 13, color: [255, 255, 255, 1]
      })
    }));

    featureList.push(routeFeature);

    // colored line
    for (let i = 1; i < planPoints.length; i++) {
      var route = new LineString([[planPoints[i - 1][1], planPoints[i - 1][0]], [planPoints[i][1], planPoints[i][0]]]).transform('EPSG:4326', 'EPSG:3857');
      var routeFeature = new Feature({
        type: 'route',
        geometry: route
      });
      routeFeature.setStyle(new Style({
        stroke: new Stroke({
          width: 7, color: [(planPoints[i][2] * 255), (255 - (planPoints[i][2] * 255)), 0, 1]
        })
      }));

      featureList.push(routeFeature);
    }

    // add waypoints, endmarker
    var waypointIdx = 0;
    for (let i = 0; i < turns.length; i++) {
      if (turns[i][0] == 103) {
        waypointIdx++;
        // waypoint marker icon
        var planidx = turns[i][1];
        this.map.addOverlay(this.createWaypoint(planPoints[planidx], waypointIdx));
      }
    }
    this.map.addOverlay(this.createEndMarker(planPoints[planPoints.length - 1]));


    for (let i = 0; i < chargingStations.length; i++) {
      this.map.addOverlay(this.createChargingStationOnRouteMarker(chargingStations[i], i));
    }

    var source = new VectorSource({
      features: featureList
    });

    this.routeLayer.setSource(source);
  }

  createStartMarker(latLon): Feature {
    var startMarker = new Feature({
      type: 'icon',
      geometry: new Point(fromLonLat([latLon[1], latLon[0]]))
    });

    startMarker.setStyle(new Style({
      image: new Icon({
        src: "../assets/start.png",
        size: [120, 120]
      })
    }));

    return startMarker;
  }

  createEndMarker(latLon): Overlay {
    var divIconHtml = "<img src='assets/route_point_target.svg'/>";
    var div = document.createElement('div');
    div.setAttribute("style", "width: 84px; height: 84px; margin-left: -42px; margin-top: -42px;");
    div.innerHTML = divIconHtml;

    if (this.mapService.getMapColor() == "light") {
      div.children[0].setAttribute("src", "../assets/route_point_target_light.svg")
    }

    return new Overlay({
      position: fromLonLat([latLon[1], latLon[0]]),
      element: div,
      stopEvent: false
    });
  }

  createWaypoint(latLon, waypointIdx): Overlay {
    var divIconHtml = "<div class='waypoint-icon-outer'><div class='waypoint-icon'>";
    if (this.mapService.getMapColor() == "light") {
      divIconHtml += "<img src='assets/route_point_waypoint_light.svg'></div>";
    }
    else {
      divIconHtml += "<img src='assets/route_point_waypoint.svg'></div>";
    }
    divIconHtml += "<div class='waypoint-idx'>" + waypointIdx + "</div></div>";
    var div = document.createElement('div');
    div.setAttribute("style", "width: 84px; height: 84px; margin-left: -42px; margin-top: -42px; position: relative; display: flex; align-items: center; justify-content: center;");
    div.innerHTML = divIconHtml;

    if (this.mapService.getMapColor() == "light") {
      div.children[0].setAttribute("src", "../assets/route_point_waypoint_light.svg")
    }

    return new Overlay({
      position: fromLonLat([latLon[1], latLon[0]]),
      element: div,
      stopEvent: false
    });
  }

  createChargingStationOnRouteMarker(chargingData, i): Overlay {
    var chargingStationDivIconHtml = "<div class='map-charger'>";

    // charge time box
    var chargeTimeMin: any = Math.round(chargingData[3] / 5 + 1) * 5;
    var chargeTimeHour = Math.floor(chargeTimeMin / 60);
    chargeTimeMin -= (60 * chargeTimeHour);
    if (chargeTimeMin.toString().length < 2) {
      chargeTimeMin = "0" + chargeTimeMin.toString();
    }
    if (chargeTimeHour > 0) {
      if (chargeTimeMin == "00") {
        chargingStationDivIconHtml += "<div class='charge-time'><div class='charge-time-inner'> <h4>+" + chargeTimeHour + "</h4> <small>H</small> <h4></div></div>";
      }
      else {
        chargingStationDivIconHtml += "<div class='charge-time'><div class='charge-time-inner'> <h4>+" + chargeTimeHour + "</h4> <small>H</small> <h4>" + chargeTimeMin + "</h4><small>Min</small></div></div>";
      }
    }
    else {
      chargingStationDivIconHtml += "<div class='charge-time'><div class='charge-time-inner'> <h4>+" + chargeTimeMin + "</h4><small>Min</small></div></div>";
    }

    var iconSvg;
    // charge icon
    if (chargingData[2] > 35) {
      iconSvg = "charging_station_3";
    }
    else if (chargingData[2] > 11) {
      iconSvg = "charging_station_1";
    }
    else {
      iconSvg = "charging_station_2";
    }

    if (this.mapService.getMapColor() == "light") {
      iconSvg += "_light";
    }
    else {
      iconSvg += "_dark";
    }
    chargingStationDivIconHtml += "<div class='charge-icon'><img src='assets/" + iconSvg + ".svg  '/></div><div class='charge-index'><img src='assets/chargingstation_icon.png'/>" + (i + 1) + " </div></div>";

    var div = document.createElement('div');
    div.setAttribute("style", "width: 84px; height: 108px; margin-left: -42px; margin-top: -66px; position: relative; display: flex; align-items: center; justify-content: center;");
    div.innerHTML = chargingStationDivIconHtml;

    return new Overlay({
      position: fromLonLat([chargingData[1], chargingData[0]]),
      element: div,
      stopEvent: false
    });
  }

  replayTimer: any;
  lastBearing: number = -9999;
  lastMapRotation: number = null;
  lastBearingPos: any;
  mapDragIn5Seconds: boolean = false;
  DraggingInterval: any;
  pointerdragEvt: any = () => {
    this.mapDragIn5Seconds = true;
    this.cdr.detectChanges();
    clearTimeout(this.DraggingInterval);
    this.DraggingInterval = setTimeout(() => {
      this.lastRotation = this.utilsService.toRad(-this.lastBearing);
      this.mapDragIn5Seconds = false;
      this.cdr.detectChanges();
    }, 5000);
  };
  changerotationEvt: any = (evt) => {
    if (document.getElementById("navi-icon") && this.lastBearing) {
      this.lastMapRotation = this.utilsService.toDeg(evt.target.values_.rotation)
      this.RotateCursor(this.lastMapRotation + this.lastBearing);
    }
  }
  lastZoom: number = 18;
  changeZoomEvt: any = (evt) => {
    var newZoom = this.map.getView().getZoom();
    if (newZoom != this.lastZoom) {
      this.lastZoom = newZoom
      if (this.turnArrowPos) {
        this.setTurnArrow();
      }
    }
    if (newZoom > 11) {
      this.tileEVAdminLayer.setOpacity(0);
    }
    else {
      this.tileEVAdminLayer.setOpacity(1);
    }
  }
  contextmenuEvt: any = (evt) => {
    //pos.coords.longitude, pos.coords.latitude
    var coords = transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
    var pos = { coords: { latitude: coords[1], longitude: coords[0], speed: 50 } };
    this.success(pos);
  }

  // N A V I G A T I O N
  initNavigation(planPoints, turns) {
    this.turnInstructionLayer.setSource(new VectorSource());

    this.DrawCursorToMap(planPoints[0]);

    this.lastBearingPos = [planPoints[0][0], planPoints[0][1]];

    this.map.on('pointerdrag', this.pointerdragEvt);

    // generate turn points array 
    var waypointIdx = 0;
    var NavigationRoute: any = { RoutePoints: [], Turns: [] };

    // get waypoint list reversegeocoded data
    var waypointsRouteList:any = this.inputParamsService.getWaypointsRouteList();
    // remove empty waypoints
    for (let i = 0; i < waypointsRouteList.length; i++) {
      if (waypointsRouteList[i].lat == 0 && waypointsRouteList[i].lng == 0) {
        waypointsRouteList.splice(i, 1);
        i--;
      }
    }

    for (let i = 0; i < turns.length; i++) {
      var currPoint = planPoints[turns[i][1]];
      NavigationRoute.Turns.push({
        Location: { Latitude: currPoint[0], Longitude: currPoint[1] },
        Maneuver: JSON.parse(JSON.stringify(this.utilsService.turnList[turns[i][0]])), RoutePointIdx: turns[i][1], RouteEndPointIdx: turns[i][4], TurnIdx: i
      });
      // enter roundabout
      if (turns[i][0] == 94) {
        NavigationRoute.Turns[i].Maneuver.exitNumber = turns[i][2];
        NavigationRoute.Turns[i].Maneuver.icon = "exit_roundabout" + turns[i][2] + ".png";
        NavigationRoute.Turns[i].Maneuver.name = "AT THE ROUNDABOUT TAKE THE " + this.utilsService.ConvertToEnglishNumbers(turns[i][2]).toUpperCase() + " EXIT";
      }
      // exit roundabout
      if (turns[i][0] == 95) {
        NavigationRoute.Turns[i].Maneuver.name = "TAKE THE " + this.utilsService.ConvertToEnglishNumbers(turns[i][2]).toUpperCase() + " EXIT";
        NavigationRoute.Turns[i].Maneuver.icon = "exit_roundabout" + turns[i][2] + ".png";
        NavigationRoute.Turns[i].Maneuver.exitNumber = turns[i][2];
      }
      // left sided traffic
      if (turns[i][5] == true && (turns[i][0] == 93 || turns[i][0] == 94 || turns[i][0] == 95)) {
        NavigationRoute.Turns[NavigationRoute.Turns.length - 1].Maneuver.icon = NavigationRoute.Turns[NavigationRoute.Turns.length - 1].Maneuver.icon.substr(0, NavigationRoute.Turns[NavigationRoute.Turns.length - 1].Maneuver.icon.length - 4) + "_eng.png";
      }
      // waypoint indices
      if (turns[i][0] == 103) {
        waypointIdx++;
        NavigationRoute.Turns[NavigationRoute.Turns.length - 1].Maneuver.icon = NavigationRoute.Turns[NavigationRoute.Turns.length - 1].Maneuver.icon.substr(0, NavigationRoute.Turns[NavigationRoute.Turns.length - 1].Maneuver.icon.length - 4) + waypointIdx + ".png";
        NavigationRoute.Turns[NavigationRoute.Turns.length - 1].DisplayName = waypointsRouteList[waypointIdx].display_name;
      }
      // road lanes
      NavigationRoute.Turns[i].Roadlanes = [];
      if (turns[i][6].length != 0) {
        for (let j = 0; j < turns[i][6].length; j++) {
          // ordering straight
          /*for (let k = 0; k < turns[i][6][j][1].length; k++){
            if (turns[i][6][j][1][k] == 4 && k!=0){
              turns[i][6][j][1].splice(k, 1);
              turns[i][6][j][1].unshift(4);
            }
          }*/
          var orderedLaneIcons = [];

          for (let k = 0; k < turns[i][6][j][1].length; k++) {
            for (let l = 0; l < this.utilsService.laneTable.length; l++) {
              if (turns[i][6][j][1][k] == this.utilsService.laneTable[l]) {
                orderedLaneIcons.push(l);
                break;
              }
            }
          }

          orderedLaneIcons.sort();

          for (let k = 0; k < orderedLaneIcons.length; k++) {
            orderedLaneIcons[k] = this.utilsService.laneTable[orderedLaneIcons[k]];
          }

          let roadLane = { Highlighted: turns[i][6][j][0], Images: [] };
          /*for (let k = 0; k < turns[i][6][j][1].length; k++) {
            roadLane.Images.push(this.utilsService.laneList[turns[i][6][j][1][k]].toLocaleLowerCase() + "_" + (k + 1) + ".png");
          }*/
          for (let k = 0; k < orderedLaneIcons.length; k++) {
            roadLane.Images.push(this.utilsService.laneList[orderedLaneIcons[k]].toLocaleLowerCase() + "_" + (k + 1) + ".png");
          }

          NavigationRoute.Turns[i].Roadlanes.push(roadLane);
        }
      }
    }
    var featureList = [];

    for (let i = 0; i < planPoints.length; i++) {
      NavigationRoute.RoutePoints.push({
        Location: { Latitude: planPoints[i][0], Longitude: planPoints[i][1] }, RoutePointIdx: i, RouteSpeedLimit: planPoints[i][3], EvSpeedLimit: planPoints[i][4],
        DistanceFromPreviousPoint: planPoints[i][5], TimeFromPreviousPoint: planPoints[i][6], EstimatedBatteryLevel: Math.round((1 - planPoints[i][2]) * 100)
      })

      /*only for debugging      
      var circle = new Style({
        image: new CircleStyle({
          radius: 15,
          fill: new Fill({ color: '#000' }),
          stroke: new Stroke({
            color: 'rgba(0,0,255,0.9)',
            width: 3
          })
        }),
        text: new Text({
          font: '20px Calibri,sans-serif',
          fill: new Fill({ color: '#fff' }),
          stroke: new Stroke({
            color: '#fff', width: 2
          }),
          // get the text from the feature - `this` is ol.Feature
          // and show only under certain resolution
          text: i.toString()
        })
      });
 
      var feature = new Feature(
        new Point(fromLonLat([planPoints[i][1], planPoints[i][0]]))
      );
      feature.setStyle(circle);
      featureList.push(feature);*/
    }

    var source = new VectorSource({
      features: featureList
    });

    this.navigationService.StartNavigation(NavigationRoute);

    if (this.replayMode) {
      this.FakeGpsNavigation(planPoints);
    }
    else {
      this.RealGpsNavigation();
      //this.ClickNavigation();
    }
  }

  private DrawCursorToMap(pos) {
    // cursor
    if (this.cursorOverlay == undefined) {
      var rhaCursorHtml
      if (this.mapService.getMapColor() == "light") {
        rhaCursorHtml = "<img id='navi-icon' src='assets/cursor_light.png' style='width: 50px; height: 50px;' />";
      }
      else {
        rhaCursorHtml = "<img id='navi-icon' src='assets/cursor.png' style='width: 50px; height: 50px;' />";
      }
      var div = document.createElement('div');
      div.setAttribute("style", "width: 50px; height: 50px; margin-left: -25px; margin-top: -25px;");
      div.innerHTML = rhaCursorHtml;


      this.cursorOverlay = new Overlay({
        position: fromLonLat([pos[1], pos[0]]),
        element: div,
        stopEvent: false
      });
    }

    this.map.addOverlay(this.cursorOverlay);

    // draw gps cursor to map
    if (document.URL.includes("localhost") || document.URL.includes("staging") || document.URL.includes("192.168.")) {
      var gpsCursorHtml = document.createElement('div');
      gpsCursorHtml.setAttribute("style", "width: 10px; height: 10px; margin-left: -5px; margin-top: -5px; background: #ff0000; border-radius: 50%; border: 3px solid #fff");

      this.gpsCursorOverlay = new Overlay({
        position: fromLonLat([pos[1], pos[0]]),
        element: gpsCursorHtml,
        stopEvent: false
      });

      this.map.addOverlay(this.gpsCursorOverlay);
    }
  }

  private RotateCursor(deg: number) {
    document.getElementById("navi-icon").setAttribute("style", "transform: rotate(" + deg + "deg); width: 50px; height: 50px;");
  }

  private ClickNavigation() {
    this.map.on('contextmenu', this.contextmenuEvt);
  }

  lastGpsDateTime = new Date();
  webViewLocationSubscription = null;

  private RealGpsNavigation() {
    if (!window.navigator.userAgent.includes("GPSTWebView") && !window.navigator.userAgent.includes("FB_IAB")) {
      this.watchPositionId = navigator.geolocation.watchPosition(
        (position) => {
          if (new Date().getTime() - this.lastGpsDateTime.getTime() > 800) {
            this.lastGpsDateTime = new Date();
            this.success(position);
          }
        },
        (error) => { this.error(error) },
        { enableHighAccuracy: true, timeout: 4000, maximumAge: 0 });
    }
    else {
      if (this.inputParamsService.lastWebViewLocation) {
        this.webViewLocationSubscription = this.inputParamsService.ObservableWebViewPosition.subscribe((resp) => {
          this.success({ coords: { latitude: resp[0], longitude: resp[1], speed: resp[2] } });
        })
      }
      else {
        this.inputParamsService.watchGPSData();
      }
    }
  }

  private FakeGpsNavigation(planPoints) {
    if (this.replayTimer) {
      this.replayTimer.unsubscribe();
    }

    var pointsWithTime = this.navigationService.generateFakeGpsData(planPoints);

    var newspeed;

    this.fakeGpsIteration(pointsWithTime);
  }

  private fakeGpsIteration(pointsWithTime) {
    var i = this.fakeGpsIterationIndex;
    if (i == pointsWithTime.length) {
      this.replayTimer.unsubscribe();
    }
    else {
      this.lastCoord = [pointsWithTime[i].mPosition[0], pointsWithTime[i].mPosition[1]];
      //this.cursorOverlay.setPosition(fromLonLat([pointsWithTime[i].mPosition[1], pointsWithTime[i].mPosition[0]]));
      var snappedCoords = this.navigationService.GetClosestPoint([pointsWithTime[i].mPosition[0], pointsWithTime[i].mPosition[1]])[0];
      this.cursorOverlay.setPosition(fromLonLat([snappedCoords[1], snappedCoords[0]]));
      if (document.URL.includes("localhost") || document.URL.includes("staging") || document.URL.includes("192.168.")) {
        this.gpsCursorOverlay.setPosition(fromLonLat([pointsWithTime[i].mPosition[1], pointsWithTime[i].mPosition[0]]));
      }

      var rad = this.utilsService.toRad(-pointsWithTime[i].mBearing);

      this.lastBearing = pointsWithTime[i].mBearing;

      if (document.getElementById("navi-icon") && this.lastBearing && this.lastMapRotation) {
        this.RotateCursor(this.lastMapRotation + this.lastBearing);
      }

      if (!this.mapDragIn5Seconds) {
        //var newCenter = transform([pointsWithTime[i].mPosition[1], pointsWithTime[i].mPosition[0]], 'EPSG:4326', 'EPSG:3857');
        var newCenter = fromLonLat([snappedCoords[1], snappedCoords[0]]);
        this.map.getView().setCenter(newCenter);

        if (Math.abs(rad - this.map.getView().getRotation()) > this.utilsService.toRad(3)) {
          this.map.getView().setRotation(rad);
        }
      }

      this.navigationService.RefreshTarget({
        Location: {
          Latitude: pointsWithTime[i].mPosition[0],
          Longitude: pointsWithTime[i].mPosition[1],
          Speed: pointsWithTime[i].mSpeed,
          //Speed: newspeed,
          HorizontalAccuracy: 3,
          Course: pointsWithTime[i].mBearing
        }
      })
      if (i == pointsWithTime.length - 1) {
        this.navigationService.ObservableChangeNavigation.next("arrive");
        this.fakeGpsIterationIndex = 0;
        return;
      }

      this.fakeGpsIterationIndex++;
      if (this.sPressed) {
        this.replayTimer = this.fastTimer.pipe(first()).subscribe(() => {
          this.fakeGpsIteration(pointsWithTime);
        });
      }
      else {
        this.replayTimer = this.slowTimer.pipe(first()).subscribe(() => {
          this.fakeGpsIteration(pointsWithTime);
        });
      }
    }
  }

  lastCoord = null;
  lastRotation = null;

  private success(pos) {
    //var snappedCoords = this.navigationService.RoadAndPosMinDistCoords({ Location: { Latitude: pos.coords.latitude, Longitude: pos.coords.longitude } });
    var closestpoint = this.navigationService.GetClosestPoint([pos.coords.latitude, pos.coords.longitude]);
    var snappedCoords = closestpoint[0];

    //this.cursorOverlay.setPosition(fromLonLat([pos.coords.longitude, pos.coords.latitude]));
    this.cursorOverlay.setPosition(fromLonLat([snappedCoords[1], snappedCoords[0]]));
    if (document.URL.includes("localhost") || document.URL.includes("staging") || document.URL.includes("192.168.")) {
      this.gpsCursorOverlay.setPosition(fromLonLat([pos.coords.longitude, pos.coords.latitude]));
    }

    var bearing = 0;
    var actualBearing = 0;

    if (this.lastCoord != null) {
      bearing = this.utilsService.angleFromCoordinate(this.lastCoord[0], this.lastCoord[1], pos.coords.latitude, pos.coords.longitude);
      actualBearing = bearing;
    }

    this.lastCoord = [pos.coords.latitude, pos.coords.longitude];

    var speed = 1;

    if (pos.coords.speed != null) {
      speed = this.utilsService.mpsToKmph(pos.coords.speed);
    }

    if (snappedCoords[0] != pos.coords.latitude && snappedCoords[1] != pos.coords.longitude) {
      bearing = this.navigationService.GetNavigationCursorAngle(closestpoint[1]);
    }

    this.lastBearing = bearing;
    var rad = this.utilsService.toRad(-bearing);

    if (!this.mapDragIn5Seconds) {
      //this.map.getView().setCenter(transform([pos.coords.longitude, pos.coords.latitude], 'EPSG:4326', 'EPSG:3857'));
      this.map.getView().setCenter(transform([snappedCoords[1], snappedCoords[0]], 'EPSG:4326', 'EPSG:3857'));
      if (this.lastRotation) {
        this.map.getView().setRotation(this.lastRotation);
        this.lastRotation = null;
      }
    }

    if (Math.abs(rad - this.map.getView().getRotation()) > this.utilsService.toRad(3) && this.utilsService.distanceBetweenCoordinates(this.lastBearingPos, [pos.coords.latitude, pos.coords.longitude]) > 10
      && speed >= 5) {

      this.lastBearingPos = [pos.coords.latitude, pos.coords.longitude];
      if (!this.mapDragIn5Seconds) {
        this.map.getView().setRotation(rad);
      }

      if (document.getElementById("navi-icon") && this.lastBearing && this.lastMapRotation) {
        this.RotateCursor(this.lastMapRotation + this.lastBearing);
      }
    }

    this.navigationService.RefreshTarget({
      Location: {
        Latitude: pos.coords.latitude,
        Longitude: pos.coords.longitude,
        Speed: speed,
        HorizontalAccuracy: 3,
        Course: actualBearing
      }
    });


    /*for debugging
    var currPoint = [this.navigationService.NavigationRoute.RoutePoints[this.navigationService.TargetIdx].Location.Latitude, this.navigationService.NavigationRoute.RoutePoints[this.navigationService.TargetIdx].Location.Longitude]
    var circle = new Style({
      image: new CircleStyle({
        radius: 10,
        fill: null,
        stroke: new Stroke({
          color: 'rgba(222,0,0,1)',
          width: 3
        })
      })
    });

    var feature = new Feature(
      new Point(fromLonLat([currPoint[1], currPoint[0]]))
    );
    feature.setStyle(circle);
    var source = new VectorSource({
      features: [feature]
    });*/
  }

  private error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  private stopNavigation() {
    navigator.geolocation.clearWatch(this.watchPositionId);
    if (window.navigator.userAgent.includes("GPSTWebView")) {
      if (this.webViewLocationSubscription) {
        this.webViewLocationSubscription.unsubscribe();
      }
      window['webViewObj'].stopNavigation();
    }
  }

  public setToCenter() {
    this.mapDragIn5Seconds = false;
    this.cdr.detectChanges();
    if (this.lastCoord) {
      var newCenter = transform([this.lastCoord[1], this.lastCoord[0]], 'EPSG:4326', 'EPSG:3857');
      this.map.getView().setCenter(newCenter);
      this.map.getView().setRotation(this.utilsService.toRad(-this.lastBearing));
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
    if (this.replayMode) {
      if (this.replayTimer) {
        this.replayTimer.unsubscribe();
      }
    }

    this.map.un('change:rotation', this.changerotationEvt);
    this.map.un('pointerdrag', this.pointerdragEvt);
    this.map.un('contextmenu', this.contextmenuEvt);
    clearTimeout(this.DraggingInterval);
    this.stopNavigation();
  }
}
