import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountDialogComponent } from '../modals/account-dialog/account-dialog.component';
import { AccountService, User } from 'src/app/services/account.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit {

  isAuthorized: boolean = false;
  dropdownVisible: boolean = false;
  username: string = "";

  constructor(public matDialog: MatDialog, private accountService: AccountService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.accountService.ObservableIsAuthorized.subscribe((resp)=>{
      if (resp!=null && resp!=undefined){
        this.isAuthorized = resp;
        this.cdr.detectChanges();
      }
    });
    this.accountService.ObservableUser.subscribe((resp: User)=>{
      if (resp!=null && resp!=undefined && resp.Profile.FirstName && resp.Profile.LastName){
        this.username = resp.Profile.FirstName + " " + resp.Profile.LastName;
        this.cdr.detectChanges();
      }
    });
  }

  profileClick(){
    if (this.isAuthorized){
      this.dropdownVisible = !this.dropdownVisible;
    }
    else{
      this.openAccountDialog();
    }
  }

  openAccountDialog(){
    var process = this.accountService.getIsAuthorized() ? "profileEdit" : "signIn";
    this.matDialog.open(AccountDialogComponent, { 
      data: { process: process },
      panelClass: 'dialog-snap-to-fullscreen'
    });
  }

  logout(){
    this.dropdownVisible = false;
    this.accountService.logout();
  }
}
