import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import device from 'current-device';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.css']
})
export class EulaComponent implements OnInit {

  isIOS: boolean = false;

  constructor() {
    this.isIOS = device.ios()
  }

  ngOnInit() {}
}
