import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { InputParamsService } from './input-params.service';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})

export class AccountService {

  isAuthorized: boolean = false;
  ObservableIsAuthorized: BehaviorSubject<boolean>;
  ObservableAllVehicles: BehaviorSubject<any>;
  ObservableUser: BehaviorSubject<User>;
  private ObservableUserVehiclesInternal: BehaviorSubject<any>;
  ObservableUserVehicles: BehaviorSubject<any>;
  ObservableUserSettings: BehaviorSubject<any>;
  ObservableSelectedUserCar: BehaviorSubject<any>;
  ObservableOpenCarSelectorDialog: BehaviorSubject<any>;
  token: string = null;
  user: User;
  apiUrl: string = "https://staging-api.evnavigation.com/api/";
  ECars: ECar[];

  constructor(private http: HttpClient, private paramsService: InputParamsService,
    private cookieService: CookieService, private matDialog: MatDialog,
    private utilsService: UtilsService) {
    this.user = new User();
    this.user.Profile = new UserProfile();
    this.ObservableIsAuthorized = new BehaviorSubject<boolean>(null);
    this.ObservableAllVehicles = new BehaviorSubject<any>(null);
    this.ObservableUser = new BehaviorSubject<User>(null);
    this.ObservableUserVehicles = new BehaviorSubject<any>(null);
    this.ObservableUserVehiclesInternal = new BehaviorSubject<any>(null);
    this.ObservableUserSettings = new BehaviorSubject<any>(null);
    this.ObservableSelectedUserCar = new BehaviorSubject<any>(null);
    this.ObservableOpenCarSelectorDialog = new BehaviorSubject<any>(null);
    var evtoken = this.cookieService.get("evt");
    if (evtoken && evtoken != "null") {
      this.token = evtoken;
      this.setIsAuthorized(true);
      this.loadUserProfile().subscribe((resp) => { });
    }

    this.ObservableUserSettings.subscribe((resp) => {
      if (resp) {
        if (resp.routing != undefined && resp.routing != null) {
          localStorage.setItem('routing', resp.routing);
          this.paramsService.paramsUpdate();
        }

        if (resp.voiceguidance != undefined && resp.voiceguidance != null) {
          localStorage.setItem('voiceguidance', resp.voiceguidance);
        }

        var rangeParams = JSON.parse(JSON.stringify(this.paramsService.getRangeInputParams()));
        if (resp.batteryLevel != undefined && resp.batteryLevel != null) {
          localStorage.setItem('batteryLevel', resp.batteryLevel);
          rangeParams.batteryLevel = resp.batteryLevel;
        }
        if (resp.batterySafetyLimit != undefined && resp.batterySafetyLimit != null) {
          localStorage.setItem('batterySafetyLimit', resp.batterySafetyLimit);
          rangeParams.batterySafetyLimit = resp.batterySafetyLimit;
        }
        if (resp.minBatteryAtDestination != undefined && resp.minBatteryAtDestination != null) {
          localStorage.setItem('minBatteryAtDestination', resp.minBatteryAtDestination);
          rangeParams.minBatteryAtDestination = resp.minBatteryAtDestination;
        }
        if (resp.speedLimiter != undefined && resp.speedLimiter != null) {
          localStorage.setItem('speedLimiter', resp.speedLimiter);
          rangeParams.speedLimiter = resp.speedLimiter;
        }
        if (resp.tirePressure != undefined && resp.tirePressure != null) {
          localStorage.setItem('tirePressure', resp.tirePressure);
          rangeParams.tirePressure = resp.tirePressure;
        }
        if (resp.userBehavior != undefined && resp.userBehavior != null) {
          localStorage.setItem('userBehavior', resp.userBehavior);
          rangeParams.userBehavior = resp.userBehavior;
        }
        if (resp.weight != undefined && resp.weight != null) {
          localStorage.setItem('weight', resp.weight);
          rangeParams.weight = resp.weight;
        }
        this.paramsService.setRangeInputParams(rangeParams);

        // selecedCar ?
      }
    });


    this.ObservableAllVehicles.subscribe((resp) => {
      if (!resp || resp === "error") {
        return;
      }
      this.ECars = resp;
      if (this.user.Ecars != null && this.user.Ecars != undefined && this.user.Ecars.length > 0) {
        for (var userCarIdx = 0; userCarIdx < this.user.Ecars.length; ++userCarIdx) {
          var userEcar = this.user.Ecars[userCarIdx];
          for (var i = 0; i < this.ECars.length; ++i) {
            if (this.ECars[i].Id == userEcar.userVehicleID) {
              userEcar.Ecar = this.ECars[i];
              break;
            }
          }
        }
        this.ObservableUserVehicles.next(this.user.Ecars);
      }
    });

    this.ObservableUserVehiclesInternal.subscribe((resp) => {
      if (resp) {
        this.user.Ecars = [];
        for (var carIdx = 0; carIdx < resp.length; carIdx++) {
          var carData = resp[carIdx];
          var userEcar = new UserECar();
          userEcar.userVehicleID = carData.id;
          userEcar.name = (carData.name ? carData.name : "");
          userEcar.Ecar = null;
          userEcar.selected = ((carData.selected != undefined) && (carData.selected != undefined) && (parseInt(carData.selected) === 1));
          if (this.ECars != null && this.ECars != undefined && this.ECars.length > 0) {
            for (var i = 0; i < this.ECars.length; ++i) {
              if (this.ECars[i].Id == carData.vehicle_id) {
                userEcar.Ecar = this.ECars[i];
                break;
              }
            }
          }
          this.user.Ecars.push(userEcar);

          if (userEcar.selected === true && userEcar.Ecar != null) {
            this.ObservableSelectedUserCar.next(userEcar);
          }
        }
        if (this.ECars != null && this.ECars != undefined && this.ECars.length > 0) {
          this.ObservableUserVehicles.next(this.user.Ecars);
        }
      }
    });
  }

  getHeader() {
    return {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      })
    }
  }

  setIsAuthorized(isAuthorized: boolean) {
    this.isAuthorized = isAuthorized;
    this.ObservableIsAuthorized.next(isAuthorized);
  }

  getIsAuthorized() {
    return this.isAuthorized;
  }

  isExist(emailAddress: string) {
    return new Observable((observer: Observer<any>) => {
      this.http.post(this.apiUrl + "user/exist", { "email": emailAddress }).subscribe((resp: any) => {
        observer.next(resp);
        observer.complete();
      }, (error) => {
        observer.next({ failed: { error: "Cannot reach backend" } });
        observer.complete();
      });
    });
  }

  registerUser(profile: UserProfile, password) {
    return new Observable((observer: Observer<any>) => {
      var formData = new FormData();
      formData.append("first_name", profile.FirstName);
      formData.append("last_name", profile.LastName);
      formData.append("email", profile.Email);
      formData.append("password", password);
      formData.append("c_password", password);
      formData.append("country", profile.Country);
      formData.append("phone_number", profile.Phone.toString());
      formData.append("newsletter", profile.Newsletter ? "1" : "0");
      if (profile.Avatar) {
        formData.append("avatar", profile.Avatar);
      }
      this.http.post(this.apiUrl + "register", formData).subscribe((resp: any) => {
        observer.next(resp);
        observer.complete();
      }, (error) => {
        observer.next({ failed: { error: "please try again later." } });
        observer.complete();
      });
    });
  }

  resendActivationEmail(emailAddress: string) {
    return new Observable((observer: Observer<any>) => {
      this.http.post(this.apiUrl + "resend", { 'email': emailAddress }).subscribe((resp: any) => {
        observer.next(resp);
        observer.complete();
      }, (error) => {
        observer.next({ failed: { error: "please try again later." } });
        observer.complete();
      });
    });
  }

  setUser(profile: UserProfile) {
    return new Observable((observer: Observer<any>) => {
      var formData = new FormData();
      formData.append("first_name", profile.FirstName);
      formData.append("last_name", profile.LastName);
      formData.append("country", profile.Country);
      formData.append("phone_number", profile.Phone.toString());
      formData.append("newsletter", profile.Newsletter ? "1" : "0");
      if (profile.Avatar && typeof (profile.Avatar) == "object") {
        formData.append("avatar", profile.Avatar);
      }
      if (profile.Avatar == null) {
        formData.append("avatar", "DELETE");
      }
      this.http.post(this.apiUrl + "user/profile", formData, this.getHeader()).subscribe((resp: any) => {
        if (resp && resp.success) {
          this.user.Profile.FirstName = profile.FirstName;
          this.user.Profile.LastName = profile.LastName;
          this.user.Profile.Country = profile.Country;
          this.user.Profile.Phone = profile.Phone;
          this.user.Profile.Newsletter = profile.Newsletter;
          this.user.Profile.Avatar = resp.success.avatar;
          if (this.user.Profile.Avatar == "users/default.png") {
            this.user.Profile.Avatar = null;
          }
          this.ObservableUser.next(this.user);
        }
        observer.next(resp);
        observer.complete();
      }, (error) => {
        observer.next("error");
        observer.complete();
      });
    });
  }

  login(login) {
    return new Observable((observer: Observer<any>) => {
      var formData = new FormData();
      formData.append("email", login.email);
      formData.append("password", login.password);
      this.http.post(this.apiUrl + "login", formData).subscribe((resp: any) => {
        if (resp.success) {
          this.setIsAuthorized(true);
          this.token = resp.success.token;
          if (login.rememberMe == true) {
            this.cookieService.set("evt", this.token, 365, "/");
          }
          else {
            this.cookieService.set("evt", this.token, 1, "/");
          }
          this.loadUserProfile().subscribe();
          observer.next(resp);
          observer.complete();
        }
        if (resp.failed && resp.failed == "Email is not verified") {
          observer.next(resp);
          observer.complete();
        }

      }, (error) => {
        observer.next("error");
        observer.complete();
      });
    });
  }

  verifyEmail(urlParam: string) {
    return new Observable((observer: Observer<any>) => {
      this.http.get(urlParam).subscribe((resp: any) => {
        if (resp.success) {
          this.setIsAuthorized(true);
          this.token = resp.success.token;
          this.cookieService.set("evt", this.token, 1, "/");
          this.loadUserProfile().subscribe();
        }
        observer.next(resp);
        observer.complete();

      }, (error) => {
        observer.next(error);
        observer.complete();
      });
    });
  }

  authenticateSocial(provider: string, providerId: string, accessToken: string) {
    return new Observable((observer: Observer<any>) => {
      var formData = new FormData();
      formData.append("provider", provider);
      formData.append("provider_id", providerId);
      formData.append("access_token", accessToken);
      this.http.post(this.apiUrl + "register_external", formData).subscribe((resp: any) => {
        if (resp.success) {
          this.setIsAuthorized(true);
          this.token = resp.success.token;
          this.cookieService.set("evt", this.token, 1, "/");
          this.loadUserProfile().subscribe();
          observer.next(resp);
          observer.complete();
        }
        if (resp.failed && resp.failed == "Email is not verified") {
          observer.next(resp);
          observer.complete();
        }

      }, (error) => {
        observer.next("error");
        observer.complete();
      });
    });
  }

  private loadUserProfile() {
    return new Observable((observer: Observer<any>) => {
      this.http.get(this.apiUrl + "user", this.getHeader()).subscribe((resp: any) => {
        if (resp.first_name && resp.last_name) {
          this.user.Profile.Email = resp.email;
          this.user.Profile.FirstName = resp.first_name;
          this.user.Profile.LastName = resp.last_name;
          this.user.Profile.Country = resp.country;
          this.user.Profile.Phone = resp.phone_number;
          this.user.Profile.Newsletter = resp.newsletter;
          this.user.Profile.Avatar = resp.avatar;
          if (this.user.Profile.Avatar == "users/default.png") {
            this.user.Profile.Avatar = null;
          }
          this.ObservableUser.next(this.user);
          this.getUserSettings().subscribe();
        }
        observer.next(resp);
        observer.complete();
      }, (error) => {
        observer.next("error");
        observer.complete();
      });
    });
  }

  getUserProfile(): User {
    return this.user;
  }

  getUserAvatar() {
    return this.user.Profile.Avatar;
  }

  getAllVehicles() {
    var carUrl;
    if (document.URL.includes("localhost") || document.URL.includes("staging") || document.URL.includes("192.168.")) {
      carUrl = "https://staging-api.evnavigation.com/api/vehicles/all";
    }
    else {
      carUrl = "https://api.evnavigation.com/api/vehicles/all";
    }

    this.http.get(carUrl).subscribe((resp: any) => {
      resp.sort((a, b) => { return a.vehicle_order - b.vehicle_order });
      var ECars = [];
      for (let i = 0; i < resp.length; i++) {
        // selecting active cars
        if (resp[i].is_active == 1) {
          ECars.push(new ECar(resp[i].id, JSON.parse(resp[i].chargertypes), resp[i].designcapacity, JSON.parse(resp[i].gradeweights), resp[i].name,
            resp[i].range, resp[i].factory_range, resp[i].range_source,
            JSON.parse(resp[i].speedweights), resp[i].topspeed, resp[i].totalpower, resp[i].usedcapacity, resp[i].image,
            parseFloat(resp[i].chargepower), resp[i].fastchargepower, resp[i].type, resp[i].subtype));
        }
      }
      this.ObservableAllVehicles.next(ECars);
    }, (error) => {
      this.ObservableAllVehicles.next("error");
    });
  }

  getUserVehicles() {
    return new Observable((observer: Observer<any>) => {
      this.http.get(this.apiUrl + "user/vehicles", this.getHeader()).subscribe((resp: any) => {
        observer.next(resp);
        observer.complete();
        if (resp.success && resp.success.length > 0) {
          this.ObservableUserVehiclesInternal.next(resp.success);
        }
        else {
          this.ObservableOpenCarSelectorDialog.next(true);
        }
      }, (error) => {
        observer.next("error");
        observer.complete();
      });
    });
  }

  addDefaultVehicleToUser() {
    var defaultCar: ECar;
    let defCarId: number = this.utilsService.defaultCarIndex;
    for (let i = 0; i < this.ECars.length; ++i) {
      if (this.ECars[i].Id == defCarId) {
        defaultCar = this.ECars[i];
        break;
      }
    }
    this.addVehicle(defaultCar.Id, defaultCar.Name, defaultCar.ChargerTypes).subscribe();
  }

  addVehicle(vehicleId, vehicleName, chargerTypes) {
    return new Observable((observer: Observer<any>) => {
      this.http.post(this.apiUrl + "user/vehicles/add", { vehicle_id: vehicleId, name: vehicleName, charger_types: "[" + chargerTypes.toString() + "]" }, this.getHeader()).subscribe((resp: any) => {
        observer.next(resp);
        observer.complete();
        // select the first vehicle
        console.log(this.user.getSelectedCar(), resp);
        if (resp.success && this.user.getSelectedCar()==undefined){
          this.selectVehicle(resp.success.id).subscribe();
        }
        this.getUserVehicles().subscribe();
      }, (error) => {
        observer.next("error");
        observer.complete();
      });
    });
  }

  removeVehicle(vehicleId) {
    return new Observable((observer: Observer<any>) => {
      this.http.post(this.apiUrl + "user/vehicles/remove", { user_vehicle_id: vehicleId }, this.getHeader()).subscribe((resp: any) => {
        observer.next(resp);
        observer.complete();
        this.getUserVehicles().subscribe();
      }, (error) => {
        observer.next("error");
        observer.complete();
      });
    });
  }

  selectVehicle(vehicleId) {
    return new Observable((observer: Observer<any>) => {
      this.http.post(this.apiUrl + "user/vehicles/select", { user_vehicle_id: vehicleId }, this.getHeader()).subscribe((resp: any) => {
        observer.next(resp);
        observer.complete();
        this.getUserVehicles().subscribe();
      }, (error) => {
        observer.next("error");
        observer.complete();
      });
    });
  }

  editVehicle(vehicleId, vehicleName, chargerTypes) {
    return new Observable((observer: Observer<any>) => {
      this.http.post(this.apiUrl + "user/vehicles/modify", { user_vehicle_id: vehicleId, name: vehicleName, charger_types: "[" + chargerTypes.toString() + "]" }, this.getHeader()).subscribe((resp: any) => {
        observer.next(resp);
        observer.complete();
        this.getUserVehicles().subscribe();
      }, (error) => {
        observer.next("error");
        observer.complete();
      });
    });
  }

  changePassword(emailAddress, currentPassword, newPassword, cNewPassword) {
    return new Observable((observer: Observer<any>) => {
      var formData = new FormData();
      formData.append("email", emailAddress);
      formData.append("current_password", currentPassword);
      formData.append("new_password", newPassword);
      formData.append("new_confirm_password", cNewPassword);
      this.http.post(this.apiUrl + "user/changepassword", formData, this.getHeader()).subscribe((resp: any) => {
        observer.next(resp);
        observer.complete();
      }, (error) => {
        observer.next("error");
        observer.complete();
      });
    });
  }

  getForgottenPassword(emailAddress: string) {
    return new Observable((observer: Observer<any>) => {
      this.http.post(this.apiUrl + "reset/request", { email: emailAddress }).subscribe((resp: any) => {
        observer.next(resp);
        observer.complete();
      }, (error) => {
        observer.next("error");
        observer.complete();
      });
    });
  }

  getUserSettings() {
    return new Observable((observer: Observer<any>) => {
      this.http.get(this.apiUrl + "user/settings", this.getHeader()).subscribe((resp: any) => {
        observer.next(resp);
        observer.complete();
        this.ObservableUserSettings.next(resp.success);
        this.getUserVehicles().subscribe();
      }, (error) => {
        observer.next("error");
        observer.complete();
      });
    });
  }

  setUserSettings() {
    return new Observable((observer: Observer<any>) => {
      var headers: any = this.getHeader();
      headers.params = new HttpParams();

      var setting_unit = localStorage.getItem('unit');
      if (setting_unit != undefined && setting_unit != null)
        headers.params = headers.params.append('unit', setting_unit);

      var setting_routing = localStorage.getItem('routing');
      if (setting_routing != undefined && setting_routing != null) {
        headers.params = headers.params.append('routing', setting_routing);
      }

      var setting_voiceguidance = localStorage.getItem('voiceguidance');
      if (setting_voiceguidance != undefined && setting_voiceguidance != null) {
        headers.params = headers.params.append('voiceguidance', setting_voiceguidance);
      }

      var setting_mapsettings = localStorage.getItem('mapsettings');
      if (setting_mapsettings != undefined && setting_mapsettings != null) {
        headers.params = headers.params.append('mapsettings', setting_mapsettings);
      }

      var setting_batteryLevel = localStorage.getItem('batteryLevel');
      if (setting_batteryLevel != undefined && setting_batteryLevel != null) {
        headers.params = headers.params.append('batteryLevel', setting_batteryLevel);
      }

      var setting_batterySafetyLimit = localStorage.getItem('batterySafetyLimit');
      if (setting_batterySafetyLimit != undefined && setting_batterySafetyLimit != null) {
        headers.params = headers.params.append('batterySafetyLimit', setting_batterySafetyLimit);
      }

      var setting_minBatteryAtDestination = localStorage.getItem('minBatteryAtDestination');
      if (setting_minBatteryAtDestination != undefined && setting_minBatteryAtDestination != null) {
        headers.params = headers.params.append('minBatteryAtDestination', setting_minBatteryAtDestination);
      }

      var setting_speedLimiter = localStorage.getItem('speedLimiter');
      if (setting_speedLimiter != undefined && setting_speedLimiter != null) {
        headers.params = headers.params.append('speedLimiter', setting_speedLimiter);
      }

      var setting_tirePressure = localStorage.getItem('tirePressure');
      if (setting_tirePressure != undefined && setting_tirePressure != null) {
        headers.params = headers.params.append('tirePressure', setting_tirePressure);
      }

      var setting_userBehavior = localStorage.getItem('userBehavior');
      if (setting_userBehavior != undefined && setting_userBehavior != null) {
        headers.params = headers.params.append('userBehavior', setting_userBehavior);
      }

      var setting_weight = localStorage.getItem('weight');
      if (setting_weight != undefined && setting_weight != null) {
        headers.params = headers.params.append('weight', setting_weight);
      }

      if (headers.params.updates.length > 0) {
        this.http.post(this.apiUrl + "user/settings", null, headers).subscribe((resp: any) => {
          observer.next(resp);
          observer.complete();
          this.ObservableUserSettings.next(resp.success);
        }, (error) => {
          observer.next("error");
          observer.complete();
        });
      }
    });
  }

  deleteUser() {
    return new Observable((observer: Observer<any>) => {
      this.http.post(this.apiUrl + "user/delete", {}, this.getHeader()).subscribe((resp: any) => {
        observer.next(resp);
        observer.complete();
      }, (error) => {
        observer.next("error");
        observer.complete();
      });
    });
  }

  logout() {
    this.setIsAuthorized(false);
    this.cookieService.delete("evt");
  }
}

export class UserECar {
  userVehicleID: number;
  name: string;
  Ecar: ECar;
  selected: boolean;
}

export class User {
  Profile: UserProfile;
  Ecars: UserECar[] = [];

  getSelectedCar(): ECar {
    for (var carIdx = 0; carIdx < this.Ecars.length; carIdx++) {
      if (this.Ecars[carIdx].selected === true)
        return this.Ecars[carIdx].Ecar;
    }
  }

  getSelectedUserCar(): UserECar {
    for (var carIdx = 0; carIdx < this.Ecars.length; carIdx++) {
      if (this.Ecars[carIdx].selected === true)
        return this.Ecars[carIdx];
    }
  }

  getCarById(userVehicleID: number): UserECar {
    console.log(this.Ecars);
    for (var carIdx = 0; carIdx < this.Ecars.length; carIdx++) {
      if (this.Ecars[carIdx].userVehicleID === userVehicleID)
        return this.Ecars[carIdx];
    }
  }

  constructor() {
  }
}

export class ECar {
  Id: number;
  ChargerTypes: number[];
  DesignCapacity: number;
  GradeWeights: number[];
  Name: string;
  Type: string;
  Subtype: string;
  Range: number;
  FactoryRange: number;
  FactoryRangeSource: string;
  SpeedWeights: number[];
  TopSpeed: number;
  TotalPower: number;
  UsedCapacity: number;
  Icon: string;
  ChargePower: number;
  FastChargePower: number;

  constructor(id: number, chargerTypes: number[], designCapacity: number, gradeWeights: number[], name: string, range: number, factoryRange: number, factoryRangeSource: string,
    speedWeights: number[], topSpeed: number, totalPower: number, usedCapacity: number, icon: string, chargePower: number, fastChargePower, type: string, subtype: string) {
    this.Id = id;
    this.ChargerTypes = chargerTypes;
    this.DesignCapacity = designCapacity;
    this.GradeWeights = gradeWeights;
    this.Name = name;
    this.Range = range;
    this.FactoryRange = factoryRange;
    this.FactoryRangeSource = factoryRangeSource;
    this.SpeedWeights = speedWeights;
    this.TopSpeed = topSpeed;
    this.TotalPower = totalPower;
    this.UsedCapacity = usedCapacity;
    this.Icon = icon;
    this.ChargePower = chargePower;
    this.FastChargePower = fastChargePower;
    this.Type = type;
    this.Subtype = subtype;
  }
}

/* user profile */
export class UserProfile {
  Email: string;
  FirstName: string;
  LastName: string;
  Country: string;
  Avatar: string;
  Phone: number;
  Newsletter: boolean;

  constructor() {

  }
}