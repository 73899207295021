import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MatDialog } from '@angular/material/dialog';
import { NavigationDisclaimerComponent } from '../modals/navigation-disclaimer/navigation-disclaimer.component';
import { NavigationService } from 'src/app/services/navigation.service';
import { GpsErrorDialogComponent } from '../modals/gps-error-dialog/gps-error-dialog.component';
import { MapService } from 'src/app/services/map.service';
import { ConfirmationDialogComponent } from '../modals/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.css']
})
export class NavigationMenuComponent implements OnInit {
  @Input() noRoute: boolean;
  @Input() failedPlan: boolean;
  @Input() loadingMap: boolean;
  @Input() navigation: boolean;
  constructor(public matDialog: MatDialog, private navigationService: NavigationService,
    private cdr: ChangeDetectorRef, private inputParamsService: InputParamsService,
    private mapService: MapService) { }

  ngOnInit() {
  }

  startNavigation() {
    if (!this.failedPlan && !this.noRoute && this.loadingMap == null) {
      if (!window.navigator.userAgent.includes("GPSTWebView") && !window.navigator.userAgent.includes("FB_IAB")) {
        // navigation disclaimer
        navigator.geolocation.getCurrentPosition((position) => {
          if (document.getElementsByClassName("mat-dialog-title").length == 0) {
            let dialogRef = this.matDialog.open(NavigationDisclaimerComponent, { data: {} })
            dialogRef.afterClosed().subscribe((resp) => {
              if (resp == "start") {
                this.navigationService.ObservableChangeNavigation.next("start");
                this.navigation = true;
              }
            });
          }
        }, () => {
          this.openGpsErrorDialog();
        }, { enableHighAccuracy: true });
      }
      else {
        window['webViewObj'].startNavigation();
        if (this.inputParamsService.lastWebViewLocation) {
          if (document.getElementsByClassName("mat-dialog-title").length == 0) {
            let dialogRef = this.matDialog.open(NavigationDisclaimerComponent, { data: {} })
            dialogRef.afterClosed().subscribe((resp) => {
              if (resp == "start") {
                this.navigationService.ObservableChangeNavigation.next("start");
              }
            });
          }
        }
        else {
          this.inputParamsService.watchGPSData();
        }
      }
    }
  }

  // open error dialog modal
  openGpsErrorDialog(): void {
    let dialogRef = this.matDialog.open(GpsErrorDialogComponent);
  }

  openGoogleNavigation() {
    if (!this.failedPlan && !this.noRoute && this.loadingMap == null) {
      this.inputParamsService.setOpenGoogleUrl(true);
      this.setStartCoordsToYourGeolocation();
    }
  }

  setStartCoordsToYourGeolocation() {
    this.inputParamsService.setStartCoordsToYourGeolocation().subscribe((resp) => {
      if (resp == "nogps") {
        this.openGpsErrorDialog();
      }
      else {
        var startcoord = this.inputParamsService.getStartCoordsParams();
        this.mapService.getReverseGeocode(startcoord.lat, startcoord.lng).subscribe((reversegeocode) => {
          if (reversegeocode) {
            this.inputParamsService.ObservableSetStartPointGeocode.next({
              display_name: reversegeocode,
              lat: startcoord.lat,
              lon: startcoord.lng,
              wp: 0
            });
          }
        }, () => { });
      }
    });
  }

  backToRha() {
    var dialogRef = this.matDialog.open(ConfirmationDialogComponent, { data: { message: "Do you really want to exit planning mode?" } });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.inputParamsService.setSelectedMode("rha");
        var startcoord = this.inputParamsService.getWaypointsParams()[0];
        this.inputParamsService.setWaypointParams([startcoord]);
        this.inputParamsService.setRangeInputParams(this.inputParamsService.getRangeInputParams());
        this.mapService.ObservableMoveTo.next(startcoord);
      }
      else {

      }
    });
  }
}
