import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { toStringHDMS } from 'ol/coordinate';
import { MapService } from 'src/app/services/map.service';
import { InputParamsService } from 'src/app/services/input-params.service';
import { timer } from 'rxjs';
import { LatLng } from 'leaflet';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-tesla-module',
  templateUrl: './tesla-module.component.html',
  styleUrls: ['./tesla-module.component.css']
})
export class TeslaModuleComponent implements OnInit {

  /**
   * implementation for tesla api
   */
  @Input() navigation: boolean;
  subscriptions = [];
  cars = [];
  email: string;
  password: string;
  access_token: string = "";
  activeTracking: number = -1;
  teslaOptionsCodes = [
    ["MDLS", "Model S"],
    ["MS03", "Model S"],
    ["MS04", "Model S"],
    ["MDLX", "Model X"],
    ["MDL3", "Model 3"],
    ["MDLY", "Model Y"]
  ];

  constructor(private http: HttpClient, private mapService: MapService, private inputParamsService: InputParamsService,
    private mobileResolutionService: MobileResolutionService, private utilsService: UtilsService) { }

  ngOnInit() {
    this.subscriptions.push(this.inputParamsService.ObservableTrackingTimer.subscribe((resp: any) => {
      if (resp == false) {
        this.unsubscribeTimer();
        this.activeTracking = -1;
        this.lastBattery = null;
        this.lastLatLon = null;
      }
    }));
  }

  getProfileData() {
    /**
     * {
        email: this.email,
        password: this.password,
        grant_type: "password"
      }
     */
    /*this.http.get("https://dev.evnavigation.com/tesla/telsa.php"
      
    ).subscribe((resp:any) => {
      this.access_token = resp.access_token;
      this.getCars();
    });*/

    this.getCars();
  }

  getCars() {
    var resp = {
      "response": [
        {
          "id": "123456",
          "vehicle_id": "vehicle_id",
          "vin": ":vin",
          "display_name": "tesla model 3",
          "option_codes": "AD15,AF02,AH00,APF0,APH2,APPA,AU00,BCMB,BP00,BR00,BS00,BTX4,CC02,CDM0,CH05,COUS,CW02,DRLH,DSH7,DV4W,FG02,FR01,GLFR,HC00,HP00,IDBO,INBPB,IX01,LP01,LT3B,MDLX,ME02,MI02,PF00,PI01,PK00,PMBL,QLPB,RCX0,RENA,RFPX,S02B,SP00,SR04,ST02,SU01,TIC4,TM00,TP03,TR01,TRA1,TW01,UM01,USSB,UTAB,WT20,X001,X003,X007,X011,X014,X021,X025,X026,X028,X031,X037,X040,X042,YFFC,SC05",
          "color": null,
          "tokens": [
            ":token1",
            ":token2"
          ],
          "state": "online",
          "in_service": null,
          "id_s": ":ids",
          "calendar_enabled": true,
          "backseat_token": null,
          "backseat_token_updated_at": null
        }
      ],
      "count": 1
    }

    for (let i = 0; i < resp.response.length; i++) {
      var car = { name: "" };
      for (let j = 0; j < this.teslaOptionsCodes.length; j++) {
        if (resp.response[i].option_codes.includes(this.teslaOptionsCodes[j][0])) {
          car.name = this.teslaOptionsCodes[j][1];
          break;
        }
      }
      this.cars.push(car);
    }

    /*this.http.post("https://dev.evnavigation.com/tesla/cars.php",
      {
        email: this.email,
        password: this.password,
        grant_type: "password"
      }
    ).subscribe((resp:any) => {
      this.access_token = resp.access_token;
    });*/
  }

  carDataTimer;

  trackDevice(car, idx) {
    this.activeTracking = idx;
    // select car
    var nameWords = car.name.split(" ");
    var matchArray = [];
    for (let i = 0; i < this.mapService.ECars.length; i++) {
      matchArray.push(0);
    }

    for (let i = 0; i < this.mapService.ECars.length; i++) {
      for (let j = 0; j < nameWords.length; j++) {
        if (this.mapService.ECars[i].Name.includes(nameWords[j])) {
          matchArray[i]++;
        }
      }
    }

    var maxValId = 0;
    for (let i = 1; i < matchArray.length; i++) {
      if (matchArray[maxValId] < matchArray[i]) {
        maxValId = i;
      }
    }

    this.mapService.setSelectedCar(this.mapService.ECars[maxValId], true);

    if (this.carDataTimer) {
      this.carDataTimer.unsubscribe();
    }

    this.inputParamsService.setTrackingTimer(true);
    this.inputParamsService.setWaypointParams([this.inputParamsService.getWaypointsParams()[0]]);
    this.mobileResolutionService.setMobileVisiblePanel("");
    this.carDataTimer = timer(0, 1000).subscribe((count) => {
      if (this.navigation || count % 10 == 0) {
        this.getBattery();
        this.getLocation();
      }
    });
  }

  lastLatLon;
  lastBattery;

  getBattery() {
    var batteryData = {
      "response": {
        "charging_state": "Complete",
        "fast_charger_type": "\u003cinvalid\u003e",
        "fast_charger_brand": "\u003cinvalid\u003e",
        "charge_limit_soc": 86,
        "charge_limit_soc_std": 90,
        "charge_limit_soc_min": 50,
        "charge_limit_soc_max": 100,
        "charge_to_max_range": false,
        "max_range_charge_counter": 0,
        "fast_charger_present": false,
        "battery_range": 219.06,
        "est_battery_range": 157.1,
        "ideal_battery_range": 280.53,
        "battery_level": 86,
        "usable_battery_level": 86,
        "charge_energy_added": 18.77,
        "charge_miles_added_rated": 58.0,
        "charge_miles_added_ideal": 74.0,
        "charger_voltage": 0,
        "charger_pilot_current": 7,
        "charger_actual_current": 0,
        "charger_power": 0,
        "time_to_full_charge": 0.0,
        "trip_charging": false,
        "charge_rate": 0.0,
        "charge_port_door_open": true,
        "conn_charge_cable": "SAE",
        "scheduled_charging_start_time": null,
        "scheduled_charging_pending": false,
        "user_charge_enable_request": null,
        "charge_enable_request": true,
        "charger_phases": null,
        "charge_port_latch": "Engaged",
        "charge_current_request": 7,
        "charge_current_request_max": 7,
        "managed_charging_active": false,
        "managed_charging_user_canceled": false,
        "managed_charging_start_time": null,
        "battery_heater_on": false,
        "not_enough_power_to_heat": false,
        "timestamp": 1532915238524
      }
    };

    if (batteryData.response.battery_level != this.lastBattery) {
      this.lastBattery = batteryData.response.battery_level;
      this.inputParamsService.setBattery(batteryData.response.battery_level);
    }
  }

  getLocation() {
    var driverState = {
      "response": {
        "shift_state": null,
        "speed": null,
        "power": 0,
        "latitude": 46,
        "longitude": 19,
        "heading": 340,
        "gps_as_of": 1532927048,
        "native_location_supported": 1,
        "native_latitude": 40.459729,
        "native_longitude": -79.923444,
        "native_type": "wgs",
        "timestamp": 1532927316568
      }
    }

    if (!this.lastLatLon || this.utilsService.distanceBetweenCoordinates(this.lastLatLon, [driverState.response.latitude, driverState.response.longitude]) > 1000) {
      this.lastLatLon = [driverState.response.latitude, driverState.response.longitude];
      this.inputParamsService.setStartCoordsParams(new LatLng(driverState.response.latitude, driverState.response.longitude));
      this.mapService.setMoveTo(new LatLng(driverState.response.latitude, driverState.response.longitude));
    }
  }

  unsubscribeTimer() {
    if (this.carDataTimer) {
      this.carDataTimer.unsubscribe();
    }
  }

  logout() {
    this.unsubscribeTimer();
    this.activeTracking = -1;
    this.cars = [];
    this.email = "";
    this.password = "";
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    /*for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];*/
  }
}
