import { Component, ViewChild, HostListener, NgZone, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { InputParamsService, RangeParams } from './services/input-params.service';
import { MobileResolutionService } from './services/mobile-resolution.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MapService } from './services/map.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WelcomeDialogComponent } from './components/modals/welcome-dialog/welcome-dialog.component';
import L, { LatLng } from 'leaflet';
import * as screenfull from 'screenfull';
import * as JSZip from 'jszip';
import device from 'current-device';
declare function addGtag(): any;

import * as NoSleep from 'nosleep.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  @ViewChild('windComponent', { static: false }) windComponent: WindForceComponent;
  @ViewChild('windTabComponent', { static: false }) windTabComponent: WindTabComponent;
  @ViewChild('windMobileTabComponent', { static: false }) windMobileTabComponent: WindTabComponent;
  @HostListener('window:resize') onResize() {
    //var orientationChange = (this.lastSize.height != window.innerHeight && this.lastSize.width != window.innerWidth) ? true : false;
    this.lastSize = { height: window.innerHeight, width: window.innerWidth };
    // guard against resize before view is rendered
    this.mobileResolution = (window.innerWidth <= 1050 || window.innerHeight <= 700) ? true : false;
    this.mobileResolutionService.setMobileResolution(this.mobileResolution);
    /*if (orientationChange) {
      document.body.classList.add("hide-panels");
    }*/
    document.body.classList.add("no-animation");
    setTimeout(() => {
      if (this.isPortrait()) {
        document.documentElement.classList.remove("landscape");
        document.documentElement.classList.add("portrait");
      }
      else {
        document.documentElement.classList.remove("portrait");
        document.documentElement.classList.add("landscape");
      }

      if (this.mobileResolution && device.mobile()) {
        //document.body.classList.remove("hide-panels");
        setTimeout(() => {
          document.body.classList.remove("no-animation");
        }, 1050);
        if (!device.ios()) {
          this.setMobileMenu();
        }
        else {
          var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') == -1 &&
            navigator.userAgent.indexOf('FxiOS') == -1;
          /*if (!this.navigation && isSafari) {
            document.getElementById("leaflet").setAttribute("style", "height: " + window.innerHeight + "px;");
          }*/
          window.scrollTo(0, 0);
          this.setMobileMenu();

        }

      }
      else {
        this.setAddedStyleParamsToDefault();
      }
    }, 1000);
  }

  lastSize = { height: window.innerHeight, width: window.innerWidth };
  noSleep = new NoSleep();

  selectedMode: string = "rha";
  drawEnabled: boolean;
  routeOnMap: boolean;
  startRouteOnMap: boolean;
  title = 'RangeCalculator';
  cookiePolicyAgreed: boolean = null;

  weight: number = 100;
  tirePressure: number = 3.3;
  rangeSliderParams: any = 1111;
  mobileResolution: boolean;
  mobileVisiblePanel: string = "";
  mobileVisibleRoutePanel: string = "routepoints";
  drawing: boolean;

  flatRangeImg: string = "./assets/slider_button_safetylimit.svg";
  weightImg: string = "./assets/slider_button_weight.svg";
  tirePressureImg: string = "./assets/slider_small_button_pressure.svg";

  // map
  @ViewChild('map', { static: false }) map: MapComponent;
  @ViewChild('navigation', { static: false }) navigationComponent: NavigationComponent;
  @ViewChild('pacinput', { static: false }) pacinputElement: any;

  windLayer;

  // service subscriptions
  subscriptions: any = [];
  httpSub = null;

  // map elements
  polygons: any;
  cursorMarker: any;
  markers: Array<any> = [];
  loadingMap: string = null;

  // input elements
  flatRange: number = 100;
  manactText: string = "Act";
  manactTextX: number = 24;

  // constants
  maxWindSpeed: number = 15;
  colorSteps: number = 6;
  windColors: string[] = ["#6fcef1", "#2f8cb7", "#344dc6", "#852fc9", "#c4006c", "#e1043d"];
  loaderColor: string = "accent";

  paramsInterval: any;
  windMode: boolean = true;

  svgStore: any = [];
  tracking: boolean = false;
  tesla: boolean = false;
  trackingActive: boolean = false;
  trike: boolean = false;
  tori: boolean = false;
  compare: boolean = false;
  unit: any = { "speed": "metric", "distance": "metric", "temperature": "metric", "pressure": "metric" };
  smartcar: boolean = false;
  demo: boolean = false;
  googleUrl = "";

  isFullscreen: boolean = false;
  mobileLeftSidebar: boolean = true;
  navigation: boolean = false;
  navigationRecalculateRoute: boolean = false;
  lastWaypoints: any = null;
  failedPlan: boolean = false;
  noRoute: boolean = false;
  windHider: boolean = false;
  windHiderAnim: boolean = false;
  feedbackCounter: number = -1;
  iosApp: boolean = (device.ios() && window.navigator.userAgent.includes("GPSTWebView"));

  isAuthorized: boolean = false;
  userMonogram: string = "";

  // subscribe to the coordinate change
  constructor(private mobileResolutionService: MobileResolutionService,
    private mapService: MapService, private _ngZone: NgZone, private router: Router, public matDialog: MatDialog,
    private http: HttpClient,
    private route: ActivatedRoute,
    public errorDialog: MatDialog,
    public infoDialog: MatDialog,
    private inputService: InputParamsService,
    private cdr: ChangeDetectorRef,
    private utilsService: UtilsService,
    private navigationService: NavigationService,
    private audioService: AudioService,
    private accountService: AccountService) {

    this.subscriptions.push(this.router.events.subscribe(value => {

      if (value instanceof NavigationEnd) {
        if (value.url.length > 1 && value.url.includes("tracking")) {
          this.tracking = true;
        }
        if (value.url.length > 1 && value.url.includes("tesla")) {
          this.tesla = true;
        }
        if (value.url.length > 1 && value.url.includes("trike") || value.url.includes("tori")) {
          this.trike = true;
          this.tracking = true;
        }
        if (value.url.length > 1 && value.url.includes("tori")) {
          this.tori = true;
        }
        if (value.url.length > 1 && value.url.includes("compare")) {
          this.compare = true;
        }
        if (value.url.length > 1 && value.url.includes("smartcar")) {
          this.smartcar = true;
        }
        if (value.url.length > 1 && value.url.includes("demo")) {
          this.demo = true;
          document.body.classList.add("demo");
        }
        if (value.url.length > 1 && value.url.includes("privacy-policy")) {
          this.openPrivacyPolicyDialog();
        }
        if (value.url.length > 1 && value.url.includes("verify-email")) {
          var urlParam = this.route.snapshot.queryParamMap.get("queryURL");
          window.history.pushState("", "", '/');
          this.accountService.verifyEmail(urlParam).subscribe((resp)=>{
            if (resp.success) {
              this.matDialog.open(AccountDialogComponent, {
                data: { process: "verifySuccess" },
                panelClass: 'dialog-snap-to-fullscreen'
              });
            }
            else {
              this.matDialog.open(AccountDialogComponent, {
                data: { process: "verifyFailed" },
                panelClass: 'dialog-snap-to-fullscreen'
              });
            }
          });
          
        }
      }
    }));

    if (window.navigator.userAgent.includes("GPSTWebView") && localStorage.getItem("cookiesAccepted") != "true") {
      this.openWelcomeScreenDialog();
      this.acceptCookies();
    }
    if (localStorage.getItem("cookiesAccepted") == "true") {
      this.cookiePolicyAgreed = true;
      this.inputService.AcceptCookies = true;
      this.inputService.loadCookies();
      addGtag();
      var manactText = localStorage.getItem("manactText");
      if (manactText) {
        this.manactText = manactText;
        if (this.manactText == "Act") {
          this.manactTextX = 27;
        }
      }
      var feedbackCounter = localStorage.getItem("feedbackCounter");
      if (feedbackCounter) {
        this.feedbackCounter = parseInt(feedbackCounter);
      }
      else {
        this.feedbackCounter = 0;
      }
    }
    else {
      this.inputService.setStartCoordsParams(new LatLng(52.520008, 13.404954));
      if (!document.URL.includes("trike") && !document.URL.includes("tori")) {
        this.openWelcomeScreenDialog();
      }
    }
    // subscribe variable changes

    this.mobileResolution = (window.innerWidth <= 1050 || window.innerHeight <= 700) ? true : false;
    this.mobileResolutionService.setMobileResolution(this.mobileResolution);
    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((panelName) => {
      if (this.mobileVisiblePanel == "search" && panelName != "search") {
        setTimeout(() => {
          this.setMobileWindHeight();
        }, 400);
      }
      this.mobileVisiblePanel = panelName;
    }));
    this.subscriptions.push(this.inputService.ObservableSelectedMode.subscribe((resp) => {
      this.selectedMode = resp;
      if (resp && this.mobileResolution && device.mobile()) {
        setTimeout(() => {
          this.setMobileWindHeight();
        }, 50);
      }
    }));

    //ios10 scrollbax fix
    if (navigator.userAgent.includes('iPhone OS 10') || navigator.userAgent.includes('iPhone OS 9') ||
      navigator.userAgent.includes('iPhone OS 8') || navigator.userAgent.includes('iPhone OS 7')) {
      document.body.classList.add("oldIOS");
    }
  }

  //Mobile resolution
  setMobileVisiblePanel(panelName: string): void {
    this.mobileVisiblePanel = panelName;
    this.mobileResolutionService.setMobileVisiblePanel(panelName);
    this.cdr.detectChanges();
  }

  setMobileVisibleRoutePanel(panelName: string): void {
    this.mobileVisibleRoutePanel = panelName;
    this.mobileResolutionService.setMobileVisibleRoutePanel(panelName);
    this.cdr.detectChanges();
  }

  // open error dialog modal
  openCarSelectorDialog(): void {
    if (!document.URL.includes("trike")) {
      if (!this.accountService.getIsAuthorized()) {
        let dialogRef = this.matDialog.open(CarSelectorDialogComponent, { data: {}, panelClass: 'car-selector-outer' });
      }
      else{
        this.setMobileVisiblePanel('manageCars');
      }
    }
  }

  openWelcomeScreenDialog(): void {
    let dialogRef = this.matDialog.open(WelcomeDialogComponent, {});
    dialogRef.afterClosed().subscribe(() => {
      this.openCarSelectorDialog();
    })
  }

  // open error dialog modal
  openGoogleDialog(): void {
    let dialogRef = this.matDialog.open(GoogleDialogComponent, { data: { googleUrl: this.googleUrl } });
  }

  // open privacy policy dialog modal
  openPrivacyPolicyDialog(): void {
    let dialogRef = this.matDialog.open(TabbedInfoDialogComponent, {
      panelClass: 'dialog-snap-to-fullscreen',
      data: { openMenu: "privacy" }
    });
  }

  openUrl(url: string) {
    //var windowReference:any = window.open();
    //windowReference.location = url;

    if (device.ios() && !device.desktop()) {
      //chrome
      if (navigator.userAgent.match('CriOS')) {
        window.open(url, "_blank");
      }
      //safari
      else {
        location.assign(url);
      }
    }
    else {
      window.open(url, "_blank");
    }
  }

  // initalizing, subscribe to click event on the map
  ngOnInit() {
    // pre load audio files
    if (window.navigator.userAgent.includes("GPSTWebView")) {
      for (let i = 0; i < this.utilsService.turnList.length; i++) {
        this.http.get(this.utilsService.turnList[i].sound, { responseType: "arraybuffer" }).subscribe();
      }
    }

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileResolution.subscribe((res) => this.mobileResolution = res));
    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((panelName) => { this.mobileVisiblePanel = panelName; this.cdr.detectChanges(); }));
    this.subscriptions.push(
      this.mapService.ObservableUnit.subscribe((resp) => {
        if (resp) {
          this.unit = resp;
          this.cdr.detectChanges();
        }
      }
      ));
    this.initChargingStations();

    /*Notification.requestPermission(function (status) {
      if (Notification.permission == 'granted') {
        navigator.serviceWorker.getRegistration().then(function(reg) {
          reg.showNotification('Hello world!');
        });
      }
    });*/

    if (document.URL.includes("localhost") || document.URL.includes("staging") || document.URL.includes("192.168.")) {
      document.getElementsByClassName("beta-text")[0].innerHTML = "Beta version S";
    }
  }

  ngAfterViewInit(): void {
    document.getElementById("loading-screen").remove();
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.subscriptions.push(this.inputService.ObservableRangeInputParams.subscribe((RangeParams: RangeParams) => {
      if (RangeParams != undefined) {
        clearTimeout(this.paramsInterval);
        this.paramsInterval = setTimeout(() => {
          this.getData(this.inputService.getRangeParams());
        }, 400);
      }
    }));

    this.subscriptions.push(this.mapService.ObservableChargingStations.subscribe((resp) => {
      if (resp != undefined) {
        clearTimeout(this.paramsInterval);
        this.paramsInterval = setTimeout(() => {
          this.getData(this.inputService.getRangeParams());
        }, 400);
      }
    }));

    this.subscriptions.push(this.mapService.ObservableSelectedCar.subscribe((resp) => {
      if (resp != undefined) {
        clearTimeout(this.paramsInterval);
        this.paramsInterval = setTimeout(() => {
          this.getData(this.inputService.getRangeParams());
        }, 400);
      }
    }));

    if (screenfull && screenfull.enabled) {
      screenfull.on('change', () => {
        //this.isFullscreen = this.isScreenFull(screenfull);
        this.cdr.detectChanges();
      });
    }
    if (device.mobile()) {
      this.setMobileMenu();
      if (navigator.userAgent.includes('FBIOS')) {
        setTimeout(() => {
          this.mobileResolution = (window.innerWidth <= 1050 || window.innerHeight <= 700) ? true : false;
          this.mobileResolutionService.setMobileResolution(this.mobileResolution);

          if (this.isPortrait()) {
            document.documentElement.classList.remove("landscape");
            document.documentElement.classList.add("portrait");
          }
          else {
            document.documentElement.classList.remove("portrait");
            document.documentElement.classList.add("landscape");
          }
          this.setMobileMenu();
        }, 500);
      }
    }

    this.subscriptions.push(this.inputService.ObservableTrackingTimer.subscribe((resp) => {
      if (resp != undefined) {
        this.trackingActive = resp;
      }
    })
    );

    /*navigator.serviceWorker.register('sw.js');
    Notification.requestPermission(function (result) {
      if (result === 'granted') {
        navigator.serviceWorker.ready.then(function (registration) {
          registration.showNotification('Notification with ServiceWorker');
        }); 
      }
    });*/

    this.subscriptions.push(this.navigationService.ObservableChangeNavigation.subscribe((resp) => {
      if (resp != undefined && resp == "start" && this.navigation == false) {
        if (this.lastWaypoints == null) {
          this.lastWaypoints = this.inputService.getWaypointsParams();
        }
        this.navigationRecalculateRoute = true;
        this.navigation = true;
        this.mapService.setNavigation(true);
        this.cdr.detectChanges();
        if (device.mobile() && !window.navigator.userAgent.includes("GPSTWebView")) {
          this.noSleep.enable();
        }
      }
      if (resp != undefined && resp == "exit") {
        this.navigationRecalculateRoute = false;
        this.navigation = false;
        this.mapService.setNavigation(false);
        this.cdr.detectChanges();
        if (device.mobile() && !window.navigator.userAgent.includes("GPSTWebView")) {
          this.noSleep.disable();
          this.setMobileMenu();
        }
        this.inputService.setWaypointParams(this.lastWaypoints);
        this.lastWaypoints = null;
        this.getData(this.inputService.getRangeParams());
      }
      if (resp != undefined && resp == "arrive") {
        this.inputService.setWaypointParams([this.inputService.getWaypointsParams()[this.inputService.getWaypointsParams().length - 1]]);
        this.inputService.setSelectedMode("rha");
        this.navigation = false;
        this.mapService.setNavigation(false);
        this.cdr.detectChanges();
        if (device.mobile() && !window.navigator.userAgent.includes("GPSTWebView")) {
          this.noSleep.disable();
          this.setMobileMenu();
        }
        this.lastWaypoints = null;
        this.getData(this.inputService.getRangeParams());
      }
    })
    );

    this.subscriptions.push(this.inputService.ObservableSameCoordsError.subscribe((resp) => {
      if (resp) {
        this._ngZone.run(() => this.openErrorDialog("planfail", "Start/end location same"));
      }
    }));

    this.subscriptions.push(this.inputService.ObservableGpsErrorDialog.subscribe((resp) => {
      if (resp) {
        this._ngZone.run(() => this.openGpsErrorDialog());
      }
    }));

    this.subscriptions.push(this.inputService.ObservableReverseGeocodingLoader.subscribe((resp) => {
      if (resp) {
        this.setLoadingMap("circle");
      }
    }));

    this.subscriptions.push(this.inputService.ObservableNoRoute.subscribe((resp) => {
      if (resp != undefined && resp != null) {
        this.noRoute = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.accountService.ObservableIsAuthorized.subscribe((resp) => {
      if (resp != null && resp != undefined) {
        this.isAuthorized = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.accountService.ObservableUser.subscribe((resp) => {
      if (resp != null && resp != undefined) {
        this.userMonogram = resp.Profile.FirstName[0] + resp.Profile.LastName[0];
        this.cdr.detectChanges();
      }
    }));
  }

  isScreenFull(sf: screenfull.Screenfull | false): sf is screenfull.Screenfull {
    return (sf as screenfull.Screenfull).isFullscreen;
  }

  getData(RangeParams) {
    this.resolveOngoingHttpCall();
    if (RangeParams.Waypoints.length >= 2) {
      this.getRoute(RangeParams);
    }
    else {
      if (this.selectedMode == "rha") {
        this.getPolys(RangeParams);
      }
      else if (!this.navigation) {
        this.map.drawOnePointRouteToMap();
      }
    }
  }

  // unsubscribe when element destroy
  ngOnDestroy() {
    this.mapService.setLastCoordinates(this.map.map.getCenter().lat, this.map.map.getCenter().lng);
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }

  chargingIcon1 = L.icon({
    iconAnchor: [9, 9],
    iconUrl: "./assets/chargingstation_big1.png",
    iconSize: [18, 18]
  });

  chargingIcon2 = L.icon({
    iconAnchor: [9, 9],
    iconUrl: "./assets/chargingstation_big2.png",
    iconSize: [18, 18]
  });

  chargingIcon3 = L.icon({
    iconAnchor: [9, 9],
    iconUrl: "./assets/chargingstation_big3.png",
    iconSize: [18, 18]
  });

  setLoadingMap(loading: string) {
    if (this.loadingMap == "line" && loading != "line" && !this.navigation) {
      this.map.polyLayer.clearLayers();
      this.map.markerLayer.clearLayers();
      this.map.clearWind();
    }
    this.loadingMap = loading;
    this.cdr.detectChanges();
  }


  // load charging stations data from json
  initChargingStations() {
    var chargingPoiArray = [];

    this.http.get("../../assets/World_chargingstations.zip?ver2", { responseType: 'arraybuffer' }).subscribe((zipfile: any) => {
      // unzip charging stations
      JSZip.loadAsync(zipfile).then((zipdata) => {
        zipdata.file("World_chargingstations.json").async('text').then((blobFile) => {
          var resp = JSON.parse(blobFile);
          for (let i = 0; i < resp.chargingstations.length; i++) {
            if (resp.chargingstations[i].maxpower > 1) {
              var types = "";
              for (let j = 0; j < resp.chargingstations[i].plugs.length; j++) {
                types += resp.chargingstations[i].plugs[j].type.toString();
              }
              resp.chargingstations[i].types = types;
              if (resp.chargingstations[i].maxpower > 35) {
                resp.chargingstations[i].img = "cs_big3";
              }
              else if (resp.chargingstations[i].maxpower > 10) {
                resp.chargingstations[i].img = "cs_big2";
              }
              else {
                resp.chargingstations[i].img = "cs_big1";
              }
              if (this.mapService.getMapColor() == "light") {
                resp.chargingstations[i].img += "_light"
              }
              /*var tooltip = "";
              for (let j = 0; j < resp.chargingstations[i].plugs.length; j++) {
   
                tooltip += "<div class='plug'> <img src='" + chargingstationsImg[resp.chargingstations[i].plugs[j].type - 1][0] + "'/><p>" + resp.chargingstations[i].plugs[j].count + " x " + chargingstationsImg[resp.chargingstations[i].plugs[j].type - 1][1] + " | " + resp.chargingstations[i].plugs[j].power + "kW</p></div>";
              }
              if (resp.chargingstations[i].maxpower > 35) {
                chargingPoiArray.push(new L.Marker([resp.chargingstations[i].lat, resp.chargingstations[i].lon], { icon: this.chargingIcon3, alt: types }).bindTooltip(tooltip));
              }
              else if (resp.chargingstations[i].maxpower > 10) {
                chargingPoiArray.push(new L.Marker([resp.chargingstations[i].lat, resp.chargingstations[i].lon], { icon: this.chargingIcon2, alt: types }).bindTooltip(tooltip));
              }
              else {
                chargingPoiArray.push(new L.Marker([resp.chargingstations[i].lat, resp.chargingstations[i].lon], { icon: this.chargingIcon1, alt: types }).bindTooltip(tooltip));
              }*/
            }
          }

          this.mapService.chargingStationsArray = resp.chargingstations;
          if (!this.navigation) {
            this.map.setChargingStations(resp.chargingstations);
            this.map.canvasLayer.needRedraw();
          }
        });
      });
    });
  }

  // place cursor http call for rha data
  getPolys(rangeParams: RangeParams) {
    var polyParams: any = {};
    polyParams.Car = this.mapService.getSelectedCar();
    if (polyParams.Car && polyParams.Car.ChargerTypes && polyParams.Car.ChargerTypes.length == 0) {
      this._ngZone.run(() => this.openErrorDialog("nochargingstation", "Select a charging station"));
    }
    else if (polyParams.Car.Name != null) {
      polyParams.Params = rangeParams;
      delete polyParams.Params.EndCoordsParams;
      var man = this.manactText == "Man" ? 1 : 0;
      polyParams.Params.WindMan = man;
      if (!this.demo) {
        polyParams.Params.WindMan = 0;
      }
      if (this.inputService.getSelectedMode() == "route") {
        polyParams.Params.RangeInputParams.batterySafetyLimit = 50;
      }

      this.cdr.detectChanges();

      if (this.compare) {
        polyParams.RHAHeightCompare = 1;
      }

      if (!this.trackingActive) {
        this.setLoadingMap("circle");
      }

      if (this.tori) {
        polyParams.Car.VehicleType = "EMoped";
      }
      else {
        polyParams.Car.VehicleType = "ECar";
      }

      var rhaUrl;
      if (document.URL.includes("localhost") || document.URL.includes("staging") || document.URL.includes("192.168.")) {
        rhaUrl = "https://dev.evnavigation.com/ecar/rha_vector.php";
      }
      else {
        rhaUrl = "https://evnavigation.com/ecar/rha_vector.php";
      }

      var VqrzrxFGyHNUVIRU = this.utilsService.VqrzrxFGyHNUVIRU(polyParams);
      var headers = new HttpHeaders({
        'Vqrzrxfgyhnuviru': VqrzrxFGyHNUVIRU.toString()
      });

      this.httpSub = this.http.post(rhaUrl, polyParams, { headers: headers })
        .subscribe((resp: any) => {
          if (resp.error == "country_not_supported") {
            this.setLoadingMap(null);
            this._ngZone.run(() => this.openErrorDialog("nomap", "Country not supported"));

          }
          else if (resp.error == "closest_route_too_far") {
            this.setLoadingMap(null);
            this._ngZone.run(() => this.openErrorDialog("nomap", "Closest route is too far"));
            this.map.polyLayer.clearLayers();
            this.map.markerLayer.clearLayers();
            this.map.setMarker(rangeParams.Waypoints[0], 0);
            this.map.clearWind();

          }
          else if (resp.range1.length == 0 && resp.range2.length == 0) {
            this.setLoadingMap(null);
            if (!this.trackingActive) {
              this._ngZone.run(() => this.openErrorDialog("nomap", "Cannot calculate range"));
            }
            this.map.polyLayer.clearLayers();
            this.map.markerLayer.clearLayers();
            this.map.setMarker(rangeParams.Waypoints[0], 0);
            this.map.clearWind();
          }
          else {
            this.setLoadingMap(null);
            if (this.feedbackCounter != -1 && this.feedbackCounter < 30) {
              this.feedbackCounter++;
              localStorage.setItem("feedbackCounter", this.feedbackCounter.toString());
              if (this.feedbackCounter == 30) {
                timer(10000).pipe(first()).subscribe(() => {
                  this.openFeedbackDialog();
                });
              }
            }
            if (this.inputService.AcceptCookies) {
              localStorage.setItem("waypoints", JSON.stringify(polyParams.Params.Waypoints));
            }
            if (this.cursorMarker) {
              this.cursorMarker.setPosition(null);
            }
            this.map.setMarker(new LatLng(rangeParams.Waypoints[0].lat, rangeParams.Waypoints[0].lng), this.inputService.getStartCoordsBearingParams());

            this.map.updatePolys(resp, this.compare);
            if (man == 0 || !this.demo) {
              this.map.initWind(resp.wind, true);
              this.setWindComponent(resp);
            }
            if (this.tori) {
              this.map.setMultipleMarkers(this.map.multipleMarkers[0], this.map.multipleMarkers[1]);
            }
          }
          this.cdr.detectChanges();
        },
          error => {
            this.setLoadingMap(null);
            if (!this.trackingActive) {
              this._ngZone.run(() => this.openErrorDialog("nomap", "Cannot calculate range"));
            }
            this.cdr.detectChanges();
          });
    }
  }

  // plan route http call for route data
  getRoute(rangeParams: RangeParams) {
    this.cdr.detectChanges();
    /*var routeLimit = 2500;
    if (this.unit == "imperial") {
      routeLimit = 2414;
    }*/
    var distanceBetweenCoordinates = 0;
    for (let i = 0; i < rangeParams.Waypoints.length - 1; i++) {
      distanceBetweenCoordinates += this.utilsService.distanceBetweenCoordinates([rangeParams.Waypoints[i].lat, rangeParams.Waypoints[i].lng],
        [rangeParams.Waypoints[i + 1].lat, rangeParams.Waypoints[i + 1].lng]);
    }
    if (distanceBetweenCoordinates / 1000 > 4500) {
      this._ngZone.run(() => this.openErrorDialog("toofar", "Route length exceeds the limit"));
      this.failedPlan = true;
      this.inputService.setFailedPlan({ failedPlan: true, maxReachedWaypoint: 0 });
      this.inputService.setOpenGoogleUrl(false);
      this.navigationRecalculateRoute = false;
      this.setLoadingMap(null);
      if (this.inputService.getSelectedMode() == "rha") {
        this.inputService.setWaypointParams([this.inputService.getWaypointsParams()[0]]);
      }
      if (this.map) {
        this.map.drawFailedRouteToMap(0);
      }
    }
    else {
      var polyParams: any = {};
      polyParams.Car = this.mapService.getSelectedCar();
      if (polyParams.Car.ChargerTypes && polyParams.Car.ChargerTypes.length == 0) {
        this._ngZone.run(() => this.openErrorDialog("nochargingstation", "Select a charging station"));
      }
      else {
        if (polyParams.Car.Name != null) {
          polyParams.Params = rangeParams;
          var man = this.manactText == "Man" ? 1 : 0;
          polyParams.Params.WindMan = man;
          if (!this.demo) {
            polyParams.Params.WindMan = 0;
          }
          if (this.inputService.getSelectedMode() == "rha") {
            polyParams.Params.RangeInputParams.batterySafetyLimit = 15;
          }
          if (polyParams.Params.RangeInputParams.batteryLevel < 10) {
            polyParams.Params.RangeInputParams.batteryLevel = 10;
          }
          if (this.inputService.getBearing()) {
            polyParams.Params.Bearing = this.inputService.getBearing();
            this.inputService.setBearing(null);
          }

          // routing options
          var routingsettings = localStorage.getItem("routing");
          var routeparams = null;
          try { routeparams = JSON.parse(routingsettings); } catch (e) { }
          if (routeparams) {
            polyParams.Params.RouteParams = {
              "Tollroads": routeparams['tollroads'] == true ? 1 : 0,
              "Ferries": routeparams['ferries'] == true ? 1 : 0,
              "Highways": routeparams['highways'] == true ? 1 : 0
            };
          }

          // user not selected a charger type
          if (polyParams.Car.ChargerTypes && polyParams.Car.ChargerTypes.length == 0 && !this.navigation) {
            this.map.polyLayer.clearLayers();
            this.map.markerLayer.clearLayers();
            this.map.clearWind();
          }
          else {
            this.setLoadingMap("line");
            if (!this.navigation) {
              this.inputService.setFailedPlan({ failedPlan: false, maxReachedWaypoint: 0 });
              this.map.drawRoutePlanningAnimationToMap(polyParams.Params.Waypoints);
            }

            if (this.tori) {
              polyParams.Car.VehicleType = "EMoped";
            }
            else {
              polyParams.Car.VehicleType = "ECar";
            }

            var routeUrl;
            if (document.URL.includes("localhost") || document.URL.includes("staging") || document.URL.includes("192.168.")) {
              routeUrl = "https://dev.evnavigation.com/ecar/routing.php";
            }
            else {
              routeUrl = "https://evnavigation.com/ecar/routing.php";
            }

            var VqrzrxFGyHNUVIRU = this.utilsService.VqrzrxFGyHNUVIRU(polyParams);
            var headers = new HttpHeaders({
              'Vqrzrxfgyhnuviru': VqrzrxFGyHNUVIRU.toString()
            });

            this.httpSub = this.http.post(routeUrl, polyParams, {headers: headers})
              .subscribe((resp: any) => {

                if (resp.error == "country_not_supported") {
                  this.setLoadingMap(null);
                  this.failedPlan = true;
                  this.inputService.setFailedPlan({ failedPlan: true, maxReachedWaypoint: 0 });
                  this.inputService.setOpenGoogleUrl(false);
                  this._ngZone.run(() => this.openErrorDialog("nomap", "Country not supported"));
                  this.navigationRecalculateRoute = false;
                  if (this.inputService.getSelectedMode() == "rha") {
                    //this.inputService.deleteEndCoordsParams();
                    this.inputService.setWaypointParams([this.inputService.getWaypointsParams()[0]]);
                  }
                  if (this.map) {
                    this.map.drawFailedRouteToMap(0);
                  }
                }
                else if (resp.error && resp.error.message == "can_not_calculate_route") {
                  this.setLoadingMap(null);
                  this.failedPlan = true;
                  var maxReachedWaypoint = resp.error.maxReachedWaypoint != undefined ? resp.error.maxReachedWaypoint : 0;
                  this.inputService.setFailedPlan({ failedPlan: true, maxReachedWaypoint: maxReachedWaypoint });
                  this.inputService.setOpenGoogleUrl(false);
                  this._ngZone.run(() => this.openErrorDialog("nomap", "Cannot calculate route"));
                  this.navigationRecalculateRoute = false;

                  if (this.inputService.getSearchedRangeOrRoute() == false) {
                    for (let i = 0; i < resp.waypointsAddresses.length; i++) {
                      resp.waypointsAddresses[i].LatLon = polyParams.Params.Waypoints[i];
                    }
                    this.inputService.setReverseGeocodedLocations(resp.waypointsAddresses);
                  }
                  if (this.inputService.getSelectedMode() == "rha") {
                    this.inputService.setWaypointParams([this.inputService.getWaypointsParams()[0]]);
                  }
                  if (this.map) {
                    this.map.drawFailedRouteToMap(resp.error.maxReachedWaypoint);
                  }
                }
                else if (resp == null || resp.route == 0) {
                  this.setLoadingMap(null);
                  this.failedPlan = true;
                  this.inputService.setFailedPlan({ failedPlan: true, maxReachedWaypoint: 0 });
                  this.inputService.setOpenGoogleUrl(false);
                  this._ngZone.run(() => this.openErrorDialog("nomap", "Cannot calculate route"));
                  this.navigationRecalculateRoute = false;

                  if (this.inputService.getSearchedRangeOrRoute() == false) {
                    for (let i = 0; i < resp.waypointsAddresses.length; i++) {
                      resp.waypointsAddresses[i].LatLon = polyParams.Params.Waypoints[i];
                    }
                    this.inputService.setReverseGeocodedLocations(resp.waypointsAddresses);
                  }
                  if (this.inputService.getSelectedMode() == "rha") {
                    this.inputService.setWaypointParams([this.inputService.getWaypointsParams()[0]]);
                  }
                  if (this.map) {
                    this.map.drawFailedRouteToMap(0);
                  }
                }
                else {
                  this.setLoadingMap(null);
                  this.failedPlan = false;
                  this.inputService.setFailedPlan({ failedPlan: false, maxReachedWaypoint: 0 });
                  if (this.inputService.AcceptCookies) {
                    localStorage.setItem("waypoints", JSON.stringify(polyParams.Params.Waypoints));
                  }
                  if (this.inputService.getSearchedRangeOrRoute() == false) {
                    for (let i = 0; i < resp.waypointsAddresses.length; i++) {
                      resp.waypointsAddresses[i].LatLon = polyParams.Params.Waypoints[i];
                    }
                    this.inputService.setReverseGeocodedLocations(resp.waypointsAddresses);
                  }
                  if (this.inputService.getOpenGoogleUrl() == true) {
                    this.inputService.setOpenGoogleUrl(false);
                    this.googleUrl = "https://www.google.com/maps/dir/?api=1&destination=" + resp.route[resp.route.length - 1][0] + "," + resp.route[resp.route.length - 1][1] + "&travelmode=driving&waypoints=";
                    for (let i = 0; i < resp.turns.length; i++) {
                      // add waypoint
                      if (this.utilsService.turnList[resp.turns[i][0]].id == "CHARGE" || this.utilsService.turnList[resp.turns[i][0]].id == "ARRIVE_TO_WAYPOINT") {
                        if (this.googleUrl.endsWith("waypoints=") == false) {
                          this.googleUrl += "%7C";
                        }
                        this.googleUrl += resp.route[resp.turns[i][1]][0] + "," + resp.route[resp.turns[i][1]][1];
                      }
                    }
                    resp.googleUrl = this.googleUrl;
                    if (window.navigator.userAgent.includes("GPSTWebView") && device.ios()) {
                      window['webViewObj'].openUrl(this.googleUrl);
                    }
                    else {
                      this.openUrl(this.googleUrl);
                    }
                  }

                  var route = this.utilsService.CalculateDistanceBetweenRoutePoints(resp.route);
                  route = this.utilsService.CalculateTimeBetweenRoutePoints(route, resp.routeSegments, resp.chargeplan);
                  let totalTime = 0;
                  for (let i = 0; i < route.length; i++) {
                    totalTime += route[i][6];
                  }
                  resp.routeData.time = Math.round(totalTime);
                  this.mapService.setPlannedRouteParams(resp);

                  if (!this.navigation) {
                    this.map.drawRouteToMap(resp.route, resp.chargingstations, resp.chargeplan, resp.turns, resp.routeSegments);
                    if (this.feedbackCounter != -1 && this.feedbackCounter < 30) {
                      this.feedbackCounter++;
                      localStorage.setItem("feedbackCounter", this.feedbackCounter.toString());
                      if (this.feedbackCounter == 30) {
                        timer(10000).pipe(first()).subscribe(() => {
                          if (this.navigation) {
                            this.feedbackCounter--;
                          }
                          else {
                            this.openFeedbackDialog();
                          }
                        });
                      }
                    }
                  }
                  else {
                    this.navigationRecalculateRoute = false;
                    this.navigationComponent.setPlannedRoute(resp.route, resp.chargingstations, resp.routeSegments, resp.chargeplan, resp.turns);
                  }

                  if (man == 0 || !this.demo) {
                    if (!this.navigation) {
                      this.map.initWind(resp.wind, true);
                    }
                    this.setWindComponent(resp);
                  }
                }
                this.setMobileVisibleRoutePanel('routepoints');
                this.cdr.detectChanges();
              },
                error => {
                  if (this.navigation) {
                    timer(5000).pipe(first()).subscribe(() => {
                      this.getRoute(this.inputService.getRangeParams());
                    });
                  }
                  else {
                    this.setLoadingMap(null);
                    this.failedPlan = true;
                    this.inputService.setOpenGoogleUrl(false);
                    this.inputService.setFailedPlan({ failedPlan: true, maxReachedWaypoint: 0 });
                    this._ngZone.run(() => this.openErrorDialog("nomap", "Cannot calculate route"));
                    this.navigationRecalculateRoute = false;
                    if (this.map) {
                      this.map.drawFailedRouteToMap(0);
                    }
                    this.cdr.detectChanges();
                  }
                });
          }
        }
      }
    }
  }

  resolveOngoingHttpCall() {
    if (this.httpSub && !this.httpSub.closed) {
      this.httpSub.unsubscribe();
      this.setLoadingMap(null);
    }
  }

  //swap longitude and latitude coordinates inside an array
  latLonArrayToLonLatArray(latLonArray: any): any {
    var lonLatArray: any[] = [];

    for (let i = 0; i < latLonArray.length; i++) {
      lonLatArray[i] = [latLonArray[i][1], latLonArray[i][0]];
    }

    return lonLatArray;
  }

  // changing wind-temperature component mode
  ChangeManActMode(): void {
    if (this.manactText == "Man") {
      this.manactText = "Act";
      this.manactTextX = 27;
    }
    else {
      this.map.windDatas = [];
      this.map.canvasLayer.needRedraw();
      this.manactText = "Man";
      this.manactTextX = 24;
    }
    if (this.inputService.AcceptCookies) {
      localStorage.setItem("manactText", this.manactText);
    }
    // reload rha/route with wind
    this.resolveOngoingHttpCall();
    if (this.inputService.getRangeParams().Waypoints.length >= 2) {
      this.getRoute(this.inputService.getRangeParams());
    }
    else {
      this.getPolys(this.inputService.getRangeParams());
    }
  }

  // open error dialog modal
  openErrorDialog(errorTitle: string, errorMsg: string): void {
    if (!this.navigation || this.navigationRecalculateRoute) {
      var dialogWidth = errorTitle == "toofar" ? 320 : 280;
      let dialogRef = this.errorDialog.open(ErrorDialogComponent, { width: dialogWidth + 'px', data: { errorTitle: errorTitle, errorMsg: errorMsg } });
      if (this.navigation) {
        this.navigationService.ObservableChangeNavigation.next("exit");
      }
    }
  }

  // open info dialog modal
  openInfoDialog() {
    let dialogRef = this.infoDialog.open(InfoDialogComponent);
  }

  openGpsErrorDialog() {
    if (document.getElementsByClassName("mat-dialog-title").length == 0) {
      let dialogRef = this.errorDialog.open(GpsErrorDialogComponent);
    }
  }

  openFeedbackDialog() {
    let dialogRef = this.matDialog.open(TabbedInfoDialogComponent, {
      panelClass: 'dialog-snap-to-fullscreen',
      data: { openMenu: "feedback" }
    });
  }

  openAccountDialog() {
    this.matDialog.open(AccountDialogComponent, {
      data: { process: "signIn" },
      panelClass: 'dialog-snap-to-fullscreen'
    });
  }

  openProfileMenuDialog() {
    this.matDialog.open(ProfileMobileMenuDialogComponent, {
      data: {}
    });
  }

  // enable fullscreen mode
  toggleFullscreen() {
    if (screenfull && screenfull.enabled) {
      screenfull.toggle();
    }
    this.cdr.detectChanges();
  }

  windHiderClick() {
    this.windHider = !this.windHider;
    if (!this.windHiderAnim) {
      this.windHiderAnim = true;
    }
    else {
      setTimeout(() => {
        this.windHiderAnim = false;
      }, 400);
    }
  }

  setStartCoordsToYourGeolocation() {
    if (this.tracking) {
      var lastActive = this.mapService.getLastActiveTrip();
      if (lastActive.length > 0) {
        this.map.map.flyTo(new LatLng(lastActive[lastActive.length - 1][0], lastActive[lastActive.length - 1][1]));
      }
      else {
        this.map.map.flyTo(this.inputService.getStartCoordsParams());
      }
    }
    else if (!this.navigation) {
      this.inputService.setStartCoordsToYourGeolocation().subscribe((resp) => {
        if (resp == "nogps") {
          this._ngZone.run(() => this.openGpsErrorDialog());
        }
        else {
          var startcoord = this.inputService.getStartCoordsParams();
          this.map.map.flyTo(startcoord);

          this.mapService.getReverseGeocode(startcoord.lat, startcoord.lng).subscribe((reversegeocode) => {
            if (reversegeocode) {
              this.inputService.ObservableSetStartPointGeocode.next({
                display_name: reversegeocode,
                lat: startcoord.lat,
                lon: startcoord.lng,
                wp: 0
              });
            }
          }, () => { });
        }
      });
    }
    else {
      this.navigationComponent.setToCenter();
    }
  }

  // calling after wind or temperature changed
  windValueChangedEvent(event) {
    var rangeParams: RangeParams = this.inputService.getRangeParams();
    rangeParams.TempWindParams = event;
    clearTimeout(this.paramsInterval);
    this.paramsInterval = setTimeout(() => {
      this.resolveOngoingHttpCall();
      this.getPolys(rangeParams);
    }, 500);
  }

  setWindComponent(resp) {
    var StartCoordsParams = this.inputService.getStartCoordsParams();
    var closestIdx = 0;
    var closestDist = this.utilsService.distanceBetweenCoordinates([StartCoordsParams.lat, StartCoordsParams.lng], resp.wind[0][0]);
    // search for the closest wind to startpoint
    for (let i = 1; i < resp.wind.length; i++) {
      if (this.utilsService.distanceBetweenCoordinates([StartCoordsParams.lat, StartCoordsParams.lng], resp.wind[i][0]) < closestDist) {
        closestIdx = i;
        closestDist = this.utilsService.distanceBetweenCoordinates([StartCoordsParams.lat, StartCoordsParams.lng], resp.wind[i][0]);
      }
    }
    this.windComponent.setElementToActualValue(resp.wind[closestIdx][1], resp.wind[closestIdx][2]);
    this.windComponent.setTemperatureValue(resp.temperature);
    if (!this.demo) {
      this.windMobileTabComponent.setTemperature(resp.temperature, resp.weathercode, resp.wind[closestIdx][1], resp.wind[closestIdx][2]);
      this.windTabComponent.setTemperature(resp.temperature, resp.weathercode, resp.wind[closestIdx][1], resp.wind[closestIdx][2]);
    }
  }

  // change unit

  openSettings() {
    this.matDialog.open(SettingsDialogComponent, {});
  }

  acceptCookies() {
    this.cookiePolicyAgreed = true;
    this.inputService.AcceptCookies = true;
    localStorage.setItem("cookiesAccepted", "true");
    this.addAnalytics();
  }

  addAnalytics() {
    addGtag();
  }

  closeSidebar() {
    this.mobileLeftSidebar = !this.mobileLeftSidebar;
    if (device.mobile() && !this.isPortrait()) {
      if (!this.mobileLeftSidebar) {
        document.getElementsByClassName('info-sidebar')[0].setAttribute("style", "height: " + window.innerHeight + "px;");
        document.getElementsByClassName('info-sidebar-scrollbar')[0].setAttribute("style", "height: " + window.innerHeight + "px;");
      }
      else {
        var vh = window.innerHeight;
        var calculatedEdge = Math.round((vh - 9) / 6 * 100) / 100;
        document.getElementsByClassName('info-sidebar')[0].setAttribute("style", "height: " + (window.innerHeight - calculatedEdge - 5) + "px;");
        document.getElementsByClassName('info-sidebar-scrollbar')[0].setAttribute("style", "height: " + (window.innerHeight - calculatedEdge - 5) + "px;");
      }
    }
  }

  setMobileMenu() {
    var calculatedEdge, padding;
    // portrait
    if (this.isPortrait()) {
      var vw = window.innerWidth;
      calculatedEdge = Math.round((vw - 9) / 6 * 100) / 100;
      padding = Math.round(((vw - 9) / 12 - 7) * 100) / 100;
    }
    // landscape
    if (!this.isPortrait()) {
      var vh = window.innerHeight;
      calculatedEdge = Math.round((vh - 9) / 6 * 100) / 100;
      padding = Math.round(((vh - 9) / 12 - 7) * 100) / 100;
    }

    var sidebarElementsCount = 7;
    if (this.tesla) {
      sidebarElementsCount = 8;
    }

    document.getElementsByClassName('close-btn')[0].setAttribute("style", "width: " + calculatedEdge + "px; padding-top: " + padding + "px; padding-bottom: " + padding + "px;");

    // portrait
    if (this.isPortrait()) {
      //document.getElementById('mobile-menu-left').setAttribute("style", "height: " + (window.innerHeight - 65) + "px;");
      document.getElementById('mobile-menu-left').setAttribute("style", "");
      document.getElementsByClassName("mobile-right-panel")[0].setAttribute("style", "width: " + (window.innerWidth - calculatedEdge - 5) + "px; height: " + calculatedEdge + "px;");
      document.getElementsByClassName('info-sidebar')[0].setAttribute("style", "");
      document.getElementsByClassName('info-sidebar-scrollbar')[0].setAttribute("style", "");
      if (window.innerHeight >= (calculatedEdge * 8 + 20)) {
        var menuElements = document.getElementsByClassName('imageOuter');
        for (let i = 0; i < menuElements.length; i++) {
          if (window.innerHeight < 525) {
            menuElements[i].setAttribute("style", "height: " + calculatedEdge * 0.87 + "px; width: " + calculatedEdge + "px;");
          }
          else {
            menuElements[i].setAttribute("style", "height: " + calculatedEdge + "px; width: " + calculatedEdge + "px;");
          }

        }
        document.getElementsByClassName('windOuter')[0].setAttribute("style", "height: " + (calculatedEdge + 20) + "px; width: " + calculatedEdge + "px;");
      }
      else {
        var leftMenuElements = document.querySelectorAll("#mobile-menu-left .imageOuter");
        var bottomMenuElements = document.querySelectorAll(".mobile-right-panel .imageOuter");
        for (let i = 0; i < bottomMenuElements.length; i++) {
          bottomMenuElements[i].setAttribute("style", "height: " + calculatedEdge + "px; width: " + calculatedEdge + "px;");
        }
        var vh = window.innerHeight;
        var calculatedHeight = Math.round((vh - 29 - calculatedEdge) / 7 * 100) / 100;
        for (let i = 0; i < leftMenuElements.length; i++) {
          leftMenuElements[i].setAttribute("style", "height: " + calculatedHeight + "px; width: " + calculatedEdge + "px;");
        }
        document.getElementsByClassName('windOuter')[0].setAttribute("style", "height: " + (calculatedHeight + 20) + "px; width: " + calculatedEdge + "px;");
      }

      /*if (!this.demo) {
        if (window.innerHeight < 525) {
          var windHeight = window.innerHeight - (calculatedEdge * 0.87 * 6) - 28;
          document.getElementsByClassName("mobile-wind-tab")[0].setAttribute("style", "height: " + windHeight + "px; width: " + calculatedEdge + "px; border-radius: 0px;");
        }
        else {
          var windHeight = window.innerHeight - (calculatedEdge * 6) - 28;
          console.log(window.innerHeight, calculatedEdge, windHeight);
          document.getElementsByClassName("mobile-wind-tab")[0].setAttribute("style", "height: " + windHeight + "px; width: " + calculatedEdge + "px; border-radius: 0px;");
        }
      }*/
    }
    // landscape
    if (!this.isPortrait()) {
      var menuElements = document.getElementsByClassName('imageOuter');
      for (let i = 0; i < menuElements.length; i++) {
        menuElements[i].setAttribute("style", "");

      }
      document.getElementsByClassName('close-btn')[0].setAttribute("style", document.getElementsByClassName('close-btn')[0].getAttribute("style") + "bottom: auto; top: " + (window.innerHeight - calculatedEdge - 5) + "px;");
      document.getElementById('mobile-menu-left').setAttribute("style", "height: " + calculatedEdge + "px;" + "left: " + (calculatedEdge + 5) + "px; width: " + (window.innerWidth - calculatedEdge - 5) + "px; top: " + (window.innerHeight - calculatedEdge - 5) + "px;");
      document.getElementsByClassName('mobile-right-panel')[0].setAttribute("style", "height: " + (window.innerHeight - calculatedEdge - 5) + "px; width: " + calculatedEdge + "px;");
      if (!this.mobileLeftSidebar) {
        document.getElementsByClassName('info-sidebar')[0].setAttribute("style", "height: " + window.innerHeight + "px;");
        document.getElementsByClassName('info-sidebar-scrollbar')[0].setAttribute("style", "height: " + window.innerHeight + "px;");
      }
      else {
        document.getElementsByClassName('info-sidebar')[0].setAttribute("style", "height: " + (window.innerHeight - calculatedEdge - 5) + "px;");
        document.getElementsByClassName('info-sidebar-scrollbar')[0].setAttribute("style", "height: " + (window.innerHeight - calculatedEdge - 5) + "px;");
      }
      var bottomMenuElements = document.querySelectorAll("#mobile-menu-left .imageOuter");
      for (let i = 0; i < bottomMenuElements.length; i++) {
        bottomMenuElements[i].setAttribute("style", "height: " + calculatedEdge + "px; width: " + calculatedEdge + "px; padding: 0px " + (window.innerWidth - (calculatedEdge * sidebarElementsCount) - 50) / (sidebarElementsCount * 2) + "px;");
      }
      document.getElementsByClassName('windOuter')[0].setAttribute("style", "height: " + calculatedEdge + "px; width: " + (calculatedEdge + 50) + "px; padding: 0px " + (window.innerWidth - (calculatedEdge * sidebarElementsCount) - 50) / (sidebarElementsCount * 2) + "px;");
      /*if (!this.demo) {
        document.getElementsByClassName("mobile-wind-tab")[0].setAttribute("style", "border-radius: 0px;");
      }*/
    }

    this.setMobileWindHeight();
  }

  setMobileWindHeight() {
    // portrait
    if (document.getElementById("mobile-menu-left")) {
      if (this.isPortrait()) {
        var menuItemCount = document.getElementById("mobile-menu-left").querySelectorAll(".imageOuter:not([hidden])").length + 1;
        var calculatedEdge = document.querySelectorAll('.imageOuter:not([hidden])')[0].clientWidth;
        if (!this.demo) {
          if (window.innerHeight < 525) {
            var windHeight = window.innerHeight - (calculatedEdge * 0.87 * menuItemCount) - 24;
            document.getElementsByClassName("mobile-wind-tab")[0].setAttribute("style", "height: " + windHeight + "px; width: " + calculatedEdge + "px; border-radius: 0px;");
          }
          else {
            var windHeight = window.innerHeight - (calculatedEdge * menuItemCount) - 24;
            document.getElementsByClassName("mobile-wind-tab")[0].setAttribute("style", "height: " + windHeight + "px; width: " + calculatedEdge + "px; border-radius: 0px;");
          }
        }
      }
      // landscape
      if (!this.isPortrait()) {
        if (!this.demo) {
          document.getElementsByClassName("mobile-wind-tab")[0].setAttribute("style", "border-radius: 0px;");
        }
      }
    }
  }

  setAddedStyleParamsToDefault() {
    document.getElementsByClassName('close-btn')[0].setAttribute("style", "");
    document.getElementsByClassName('windOuter')[0].setAttribute("style", "");
    document.getElementById('mobile-menu-left').setAttribute("style", "");
    document.getElementsByClassName('mobile-right-panel')[0].setAttribute("style", "");
    document.getElementsByClassName('info-sidebar')[0].setAttribute("style", "");
    var menuElements = document.getElementsByClassName('imageOuter');
    for (let i = 0; i < menuElements.length; i++) {
      menuElements[i].setAttribute("style", "");
    }
    document.getElementsByClassName('info-sidebar')[0].setAttribute("style", "");
  }

  isPortrait() {
    if (window.innerHeight > window.innerWidth) {
      return true;
    }
    else {
      return false;
    }
  }
}

// decimal value convertation pipe

import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, VirtualTimeScheduler, timer } from 'rxjs';
import { MapComponent } from './components/map/map.component';
import { CarModelSelectorDialogComponent } from './components/modals/car-selector-dialog/car-selector-dialog.component';
import { ErrorDialogComponent } from './components/modals/error-dialog/error-dialog.component';
import { WindForceComponent } from './components/wind-force/wind-force.component';
import { InfoDialogComponent } from './components/modals/info-dialog/info-dialog.component';
import { UtilsService } from './services/utils.service';
import { GoogleDialogComponent } from './components/modals/google-dialog/google-dialog.component';
import { timeout, first } from 'rxjs/operators';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NavigationService } from './services/navigation.service';
import { runInThisContext } from 'vm';
import { AudioService } from './services/audio.service';
import { GpsErrorDialogComponent } from './components/modals/gps-error-dialog/gps-error-dialog.component';
import { toBase64String } from '@angular/compiler/src/output/source_map';
import { CarSelectorDialogComponent } from './components/modals/car-properties-selector-dialog/car-properties-selector-dialog.component';
import { WindTabComponent } from './components/wind-tab/wind-tab.component';
import { TabbedInfoDialogComponent } from './components/modals/tabbed-info-dialog/tabbed-info-dialog.component';
import { SettingsDialogComponent } from './components/modals/settings-dialog/settings-dialog.component';
import { error } from 'protractor';
import { NavigationDisclaimerComponent } from './components/modals/navigation-disclaimer/navigation-disclaimer.component';
import { InfoSidebarComponent } from './components/info-sidebar/info-sidebar.component';
import { AccountDialogComponent } from './components/modals/account-dialog/account-dialog.component';
import { AccountService } from './services/account.service';
import { ProfileMobileMenuDialogComponent } from './components/modals/profile-mobile-menu-dialog/profile-mobile-menu-dialog.component';

@Pipe({
  name: 'valueDecimal'
})
export class ValueDecimalPipe implements PipeTransform {

  transform(val: number): string {
    if (val !== undefined && val !== null) {
      if (val >= 100) {
        return val.toFixed(0);
      }
      else if (val < 100 && val >= 10) {
        return parseFloat(val.toFixed(1)).toString();
      }
      else {
        return parseFloat(val.toFixed(2)).toString();
      }
    } else {
      return "";
    }
  }
}

@Pipe({
  name: 'valueInteger'
})
export class ValueIntegerPipe implements PipeTransform {

  transform(val: number): string {
    if (val !== undefined && val !== null) {
      return val.toFixed(0);
    } else {
      return "";
    }
  }
}

@Pipe({
  name: 'turnValueIntegerThousands'
})
export class TurnValueIntegerThousandsPipe implements PipeTransform {

  transform(val: number): string {
    if (val !== undefined && val !== null) {
      if (val < 10 && val.toFixed(1)[val.toFixed(1).length - 1] != "0") {
        return val.toFixed(1);
      }
      else {
        return val.toFixed(0);
      }
    } else {
      return "";
    }
  }
}

@Pipe({
  name: 'turnValueInteger'
})
export class TurnValueIntegerPipe implements PipeTransform {

  transform(val: number): string {
    if (val !== undefined && val !== null) {
      return (val / 10 + 1).toFixed(0) + "0";
    } else {
      return "";
    }
  }
}

@Pipe({
  name: 'carFilter'
})
export class CarFilterPipe implements PipeTransform {

  transform(items: any[], carType: string, teslaCarType): any[] {
    if (!items) return [];
    if (!carType || carType == "all") return items;

    return items.filter(it => {
      if (it.Type != "tesla") {
        return it.Type == carType;
      }
      else {
        if (teslaCarType == "all") {
          return it.Type == carType;
        }
        else {
          return (it.Type == carType && it.Subtype == teslaCarType);
        }
      }
    });
  }
}

@Pipe({
  name: 'carNameFilter'
})
export class CarNamePipe implements PipeTransform {

  transform(carName: string, carType: string, teslaCarType): string {
    var splitted = carName.split(" ");
    var carSplitName = "";
    var startIdx = 1;
    if (carType == "tesla") {
      if (teslaCarType == "Cybertruck") {
        startIdx = 2;
      }
      else if (teslaCarType == "Roadster") { }
      else {
        startIdx = 3;
      }
    }
    for (let i = startIdx; i < splitted.length; i++) {
      carSplitName += splitted[i] + " ";
    }
    return carSplitName;
  }
}
