import { Injectable } from '@angular/core';
import { icon } from 'leaflet';
import {Md5} from 'ts-md5/dist/md5';

@Injectable({
  providedIn: 'root'
})
/**
 * adding utils functions
 */
export class UtilsService {

  constructor() {
  }

  chargingstationsImg = [["/assets/plug_css.png", "CCS"], ["/assets/plug_chademo.png", "CHADEMO"], ["/assets/plug_schuko.png", "SCHUKO"],
  ["/assets/plug_supercharger.png", "TESLA"], ["/assets/plug_type1.png", "TYPE 1"], ["/assets/plug_type2.png", "TYPE 2"]];

  defaultCarIndex: number = 69;

  turnList = [
    /*  0*/{ id: "NONE", name: "NONE", icon: "" },
    /*  1*/{ id: "ENTER_HIGHWAY", name: "ENTER HIGHWAY", icon: "enter_highway.png", sound: "assets/turn/audio/en-US_Kendra/Commands/EnterHighway.mp3" },
    /*  2*/{ id: "EXIT_HIGHWAY", name: "EXIT HIGHWAY", icon: "exit_highway.png", sound: "assets/turn/audio/en-US_Kendra/Commands/ExitHighway.mp3" },
    /*  3*/{ id: "KEEP_LEFT", name: "KEEP LEFT", icon: "keep_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepLeft.mp3" },
    /*  4*/{ id: "KEEP_RIGHT", name: "KEEP RIGHT", icon: "keep_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepRight.mp3" },
    /*  5*/{ id: "KEEP_STRAIGHT", name: "KEEP STRAIGHT", icon: "keep_straight.png", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepStraight.mp3" },
    /*  6*/{ id: "TURN_LEFT", name: "TURN LEFT", icon: "turn_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnLeft.mp3" },
    /*  7*/{ id: "TURN_RIGHT", name: "TURN RIGHT", icon: "turn_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnRight.mp3" },
    /*  8*/{ id: "TURN_SLIGHT_LEFT", name: "TURN SLIGHT LEFT", icon: "turn_slight_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightLeft.mp3" },
    /*  9*/{ id: "TURN_SLIGHT_RIGHT", name: "TURN SLIGHT RIGHT", icon: "turn_slight_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightRight.mp3" },
    /* 10*/{ id: "TURN_SHARP_LEFT", name: "TURN SHARP LEFT", icon: "turn_sharp_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpLeft.mp3" },
    /* 11*/{ id: "TURN_SHARP_RIGHT", name: "TURN SHARP RIGHT", icon: "turn_sharp_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpRight.mp3" },
    /* 12*/{ id: "KEEP_LEFT_AND_KEEP_LEFT", name: "KEEP LEFT AND KEEP LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepLeftThenKeepLeft.mp3" },
    /* 13*/{ id: "KEEP_LEFT_AND_KEEP_RIGHT", name: "KEEP LEFT AND KEEP RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepLeftThenKeepRight.mp3" },
    /* 14*/{ id: "KEEP_LEFT_AND_KEEP_STRAIGHT", name: "KEEP LEFT AND KEEP STRAIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepLeftThenKeepStraight.mp3" },
    /* 15*/{ id: "KEEP_LEFT_AND_TURN_LEFT", name: "KEEP LEFT AND TURN LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepLeftThenTurnLeft.mp3" },
    /* 16*/{ id: "KEEP_LEFT_AND_TURN_RIGHT", name: "KEEP LEFT AND TURN RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepLeftThenTurnRight.mp3" },
    /* 17*/{ id: "KEEP_LEFT_AND_TURN_SLIGHT_LEFT", name: "KEEP LEFT AND TURN SLIGHT LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepLeftThenTurnSlightLeft.mp3" },
    /* 18*/{ id: "KEEP_LEFT_AND_TURN_SLIGHT_RIGHT", name: "KEEP LEFT AND TURN SLIGHT RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepLeftThenTurnSharpLeft.mp3" },
    /* 19*/{ id: "KEEP_LEFT_AND_TURN_SHARP_LEFT", name: "KEEP LEFT AND TURN SHARP LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepLeftThenTurnSharpLeft.mp3" },
    /* 20*/{ id: "KEEP_LEFT_AND_TURN_SHARP_RIGHT", name: "KEEP LEFT AND TURN SHARP RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepLeftThenTurnSharpRight.mp3" },
    /* 21*/{ id: "KEEP_RIGHT_AND_KEEP_LEFT", name: "KEEP RIGHT AND KEEP LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepRightThenKeepLeft.mp3" },
    /* 22*/{ id: "KEEP_RIGHT_AND_KEEP_RIGHT", name: "KEEP RIGHT AND KEEP RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepRightThenKeepRight.mp3" },
    /* 23*/{ id: "KEEP_RIGHT_AND_KEEP_STRAIGHT", name: "KEEP RIGHT AND KEEP STRAIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepRightThenKeepStraight.mp3" },
    /* 24*/{ id: "KEEP_RIGHT_AND_TURN_LEFT", name: "KEEP RIGHT AND TURN LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepRightThenTurnLeft.mp3" },
    /* 25*/{ id: "KEEP_RIGHT_AND_TURN_RIGHT", name: "KEEP RIGHT AND TURN RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepRightThenTurnRight.mp3" },
    /* 26*/{ id: "KEEP_RIGHT_AND_TURN_SLIGHT_LEFT", name: "KEEP RIGHT AND TURN SLIGHT LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepRightThenTurnSlightLeft.mp3" },
    /* 27*/{ id: "KEEP_RIGHT_AND_TURN_SLIGHT_RIGHT", name: "KEEP RIGHT AND TURN SLIGHT RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepRightThenTurnSlightRight.mp3" },
    /* 28*/{ id: "KEEP_RIGHT_AND_TURN_SHARP_LEFT", name: "KEEP RIGHT AND TURN SHARP LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepRightThenTurnSharpLeft.mp3" },
    /* 29*/{ id: "KEEP_RIGHT_AND_TURN_SHARP_RIGHT", name: "KEEP RIGHT AND TURN SHARP RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepRightThenTurnSharpRight.mp3" },
    /* 30*/{ id: "KEEP_STRAIGHT_AND_KEEP_LEFT", name: "KEEP STRAIGHT AND KEEP LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepStraightThenKeepLeft.mp3" },
    /* 31*/{ id: "KEEP_STRAIGHT_AND_KEEP_RIGHT", name: "KEEP STRAIGHT AND KEEP RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepStraightThenKeepRight.mp3" },
    /* 32*/{ id: "KEEP_STRAIGHT_AND_KEEP_STRAIGHT", name: "KEEP STRAIGHT AND KEEP STRAIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepStraightThenKeepStraight.mp3" },
    /* 33*/{ id: "KEEP_STRAIGHT_AND_TURN_LEFT", name: "KEEP STRAIGHT AND TURN LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepStraightThenTurnLeft.mp3" },
    /* 34*/{ id: "KEEP_STRAIGHT_AND_TURN_RIGHT", name: "KEEP STRAIGHT AND TURN RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepStraightThenTurnRight.mp3" },
    /* 35*/{ id: "KEEP_STRAIGHT_AND_TURN_SLIGHT_LEFT", name: "KEEP STRAIGHT AND TURN SLIGHT LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepStraightThenTurnSlightLeft.mp3" },
    /* 36*/{ id: "KEEP_STRAIGHT_AND_TURN_SLIGHT_RIGHT", name: "KEEP STRAIGHT AND TURN SLIGHT RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepStraightThenTurnSlightRight.mp3" },
    /* 37*/{ id: "KEEP_STRAIGHT_AND_TURN_SHARP_LEFT", name: "KEEP STRAIGHT AND TURN SHARP LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepStraightThenTurnSharpLeft.mp3" },
    /* 38*/{ id: "KEEP_STRAIGHT_AND_TURN_SHARP_RIGHT", name: "KEEP STRAIGHT AND TURN SHARP RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/KeepStraightThenTurnSharpRight.mp3" },
    /* 39*/{ id: "TURN_RIGHT_AND_KEEP_LEFT", name: "TURN RIGHT AND KEEP LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnRightThenKeepLeft.mp3" },
    /* 40*/{ id: "TURN_RIGHT_AND_KEEP_RIGHT", name: "TURN RIGHT AND KEEP RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnRightThenKeepRight.mp3" },
    /* 41*/{ id: "TURN_RIGHT_AND_KEEP_STRAIGHT", name: "TURN RIGHT AND KEEP STRAIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnRightThenKeepStraight.mp3" },
    /* 42*/{ id: "TURN_RIGHT_AND_TURN_LEFT", name: "TURN RIGHT AND TURN LEFT", icon: "turn_right_and_turn_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnRightThenTurnLeft.mp3" },
    /* 43*/{ id: "TURN_RIGHT_AND_TURN_RIGHT", name: "TURN RIGHT AND TURN RIGHT", icon: "turn_right_and_turn_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnRightThenTurnRight.mp3" },
    /* 44*/{ id: "TURN_RIGHT_AND_TURN_SLIGHT_LEFT", name: "TURN RIGHT AND TURN SLIGHT LEFT", icon: "turn_right_and_turn_slight_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnRightThenTurnSlightLeft.mp3" },
    /* 45*/{ id: "TURN_RIGHT_AND_TURN_SLIGHT_RIGHT", name: "TURN RIGHT AND TURN SLIGHT RIGHT", icon: "turn_right_and_turn_slight_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnRightThenTurnSlightRight.mp3" },
    /* 46*/{ id: "TURN_RIGHT_AND_TURN_SHARP_LEFT", name: "TURN RIGHT AND TURN SHARP LEFT", icon: "turn_right_and_turn_sharp_left.png.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnRightThenTurnSharpLeft.mp3" },
    /* 47*/{ id: "TURN_RIGHT_AND_TURN_SHARP_RIGHT", name: "TURN RIGHT AND TURN SHARP RIGHT", icon: "turn_right_and_turn_sharp_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnRightThenTurnSharpRight.mp3" },
    /* 48*/{ id: "TURN_LEFT_AND_KEEP_LEFT", name: "TURN LEFT AND KEEP LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnLeftThenKeepLeft.mp3" },
    /* 49*/{ id: "TURN_LEFT_AND_KEEP_RIGHT", name: "TURN LEFT AND KEEP RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnLeftThenKeepRight.mp3" },
    /* 50*/{ id: "TURN_LEFT_AND_KEEP_STRAIGHT", name: "TURN LEFT AND KEEP STRAIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnLeftThenKeepStraight.mp3" },
    /* 51*/{ id: "TURN_LEFT_AND_TURN_LEFT", name: "TURN LEFT AND TURN LEFT", icon: "turn_left_and_turn_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnLeftThenTurnLeft.mp3" },
    /* 52*/{ id: "TURN_LEFT_AND_TURN_RIGHT", name: "TURN LEFT AND TURN RIGHT", icon: "turn_left_and_turn_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnLeftThenTurnRight.mp3" },
    /* 53*/{ id: "TURN_LEFT_AND_TURN_SLIGHT_LEFT", name: "TURN LEFT AND TURN SLIGHT LEFT", icon: "turn_left_and_turn_slight_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnLeftThenTurnSlightLeft.mp3" },
    /* 54*/{ id: "TURN_LEFT_AND_TURN_SLIGHT_RIGHT", name: "TURN LEFT AND TURN SLIGHT RIGHT", icon: "turn_left_and_turn_slight_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnLeftThenTurnSlightRight.mp3" },
    /* 55*/{ id: "TURN_LEFT_AND_TURN_SHARP_LEFT", name: "TURN LEFT AND TURN SHARP LEFT", icon: "turn_left_and_turn_sharp_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnLeftThenTurnSharpLeft.mp3" },
    /* 56*/{ id: "TURN_LEFT_AND_TURN_SHARP_RIGHT", name: "TURN LEFT AND TURN SHARP RIGHT", icon: "turn_left_and_turn_sharp_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnLeftThenTurnSharpRight.mp3" },
    /* 57*/{ id: "TURN_SLIGHT_LEFT_AND_KEEP_LEFT", name: "TURN SLIGHT LEFT AND KEEP LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightLeftThenKeepLeft.mp3" },
    /* 58*/{ id: "TURN_SLIGHT_LEFT_AND_KEEP_RIGHT", name: "TURN SLIGHT LEFT AND KEEP RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightLeftThenKeepRight.mp3" },
    /* 59*/{ id: "TURN_SLIGHT_LEFT_AND_KEEP_STRAIGHT", name: "TURN SLIGHT LEFT AND KEEP STRAIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightLeftThenKeepStraight.mp3" },
    /* 60*/{ id: "TURN_SLIGHT_LEFT_AND_TURN_LEFT", name: "TURN SLIGHT LEFT AND TURN LEFT", icon: "turn_slight_left_and_turn_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightLeftThenTurnLeft.mp3" },
    /* 61*/{ id: "TURN_SLIGHT_LEFT_AND_TURN_RIGHT", name: "TURN SLIGHT LEFT AND TURN RIGHT", icon: "turn_slight_left_and_turn_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightLeftThenTurnRight.mp3" },
    /* 62*/{ id: "TURN_SLIGHT_LEFT_AND_TURN_SLIGHT_LEFT", name: "TURN SLIGHT LEFT AND TURN SLIGHT LEFT", icon: "turn_slight_left_and_turn_slight_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightLeftThenTurnSlightLeft.mp3" },
    /* 63*/{ id: "TURN_SLIGHT_LEFT_AND_TURN_SLIGHT_RIGHT", name: "TURN SLIGHT LEFT AND TURN SLIGHT RIGHT", icon: "turn_slight_left_and_turn_slight_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightLeftThenTurnSlightRight.mp3" },
    /* 64*/{ id: "TURN_SLIGHT_LEFT_AND_TURN_SHARP_LEFT", name: "TURN SLIGHT LEFT AND TURN SHARP LEFT", icon: "turn_slight_left_and_turn_sharp_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightLeftThenTurnSharpLeft.mp3" },
    /* 65*/{ id: "TURN_SLIGHT_LEFT_AND_TURN_SHARP_RIGHT", name: "TURN SLIGHT LEFT AND TURN SHARP RIGHT", icon: "turn_slight_left_and_turn_sharp_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightLeftThenTurnSharpRight.mp3" },
    /* 66*/{ id: "TURN_SLIGHT_RIGHT_AND_KEEP_LEFT", name: "TURN SLIGHT RIGHT AND KEEP LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightRightThenKeepLeft.mp3" },
    /* 67*/{ id: "TURN_SLIGHT_RIGHT_AND_KEEP_RIGHT", name: "TURN SLIGHT RIGHT AND KEEP RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightRightThenKeepRight.mp3" },
    /* 68*/{ id: "TURN_SLIGHT_RIGHT_AND_KEEP_STRAIGHT", name: "TURN SLIGHT RIGHT AND KEEP STRAIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightRightThenKeepStraight.mp3" },
    /* 69*/{ id: "TURN_SLIGHT_RIGHT_AND_TURN_LEFT", name: "TURN SLIGHT RIGHT AND TURN LEFT", icon: "turn_slight_right_and_turn_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightRightThenTurnLeft.mp3" },
    /* 70*/{ id: "TURN_SLIGHT_RIGHT_AND_TURN_RIGHT", name: "TURN SLIGHT RIGHT AND TURN RIGHT", icon: "turn_slight_right_and_turn_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightRightThenTurnRight.mp3" },
    /* 71*/{ id: "TURN_SLIGHT_RIGHT_AND_TURN_SLIGHT_LEFT", name: "TURN SLIGHT RIGHT AND TURN SLIGHT LEFT", icon: "turn_slight_right_and_turn_slight_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightRightThenTurnSlightLeft.mp3" },
    /* 72*/{ id: "TURN_SLIGHT_RIGHT_AND_TURN_SLIGHT_RIGHT", name: "TURN SLIGHT RIGHT AND TURN SLIGHT RIGHT", icon: "turn_slight_right_and_turn_slight_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightRightThenTurnSlightRight.mp3" },
    /* 73*/{ id: "TURN_SLIGHT_RIGHT_AND_TURN_SHARP_LEFT", name: "TURN SLIGHT RIGHT AND TURN SHARP LEFT", icon: "turn_slight_right_and_turn_sharp_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightRightThenTurnSharpLeft.mp3" },
    /* 74*/{ id: "TURN_SLIGHT_RIGHT_AND_TURN_SHARP_RIGHT", name: "TURN SLIGHT RIGHT AND TURN SHARP RIGHT", icon: "turn_slight_right_and_turn_sharp_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSlightRightThenTurnSharpRight.mp3" },
    /* 75*/{ id: "TURN_SHARP_LEFT_AND_KEEP_LEFT", name: "TURN SHARP LEFT AND KEEP LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpLeftThenKeepLeft.mp3" },
    /* 76*/{ id: "TURN_SHARP_LEFT_AND_KEEP_RIGHT", name: "TURN SHARP LEFT AND KEEP RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpLeftThenKeepRight.mp3" },
    /* 77*/{ id: "TURN_SHARP_LEFT_AND_KEEP_STRAIGHT", name: "TURN SHARP LEFT AND KEEP STRAIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpLeftThenKeepStraight.mp3" },
    /* 78*/{ id: "TURN_SHARP_LEFT_AND_TURN_LEFT", name: "TURN SHARP LEFT AND TURN LEFT", icon: "turn_sharp_left_and_turn_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpLeftThenTurnLeft.mp3" },
    /* 79*/{ id: "TURN_SHARP_LEFT_AND_TURN_RIGHT", name: "TURN SHARP LEFT AND TURN RIGHT", icon: "turn_sharp_left_and_turn_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpLeftThenTurnRight.mp3" },
    /* 80*/{ id: "TURN_SHARP_LEFT_AND_TURN_SLIGHT_LEFT", name: "TURN SHARP LEFT AND TURN SLIGHT LEFT", icon: "turn_sharp_left_and_turn_slight_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpLeftThenTurnSlightLeft.mp3" },
    /* 81*/{ id: "TURN_SHARP_LEFT_AND_TURN_SLIGHT_RIGHT", name: "TURN SHARP LEFT AND TURN SLIGHT RIGHT", icon: "turn_sharp_left_and_turn_slight_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpLeftThenTurnSlightRight.mp3" },
    /* 82*/{ id: "TURN_SHARP_LEFT_AND_TURN_SHARP_LEFT", name: "TURN SHARP LEFT AND TURN SHARP LEFT", icon: "turn_sharp_left_and_turn_sharp_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpLeftThenTurnSharpLeft.mp3" },
    /* 83*/{ id: "TURN_SHARP_LEFT_AND_TURN_SHARP_RIGHT", name: "TURN SHARP LEFT AND TURN SHARP RIGHT", icon: "turn_sharp_left_and_turn_sharp_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpLeftThenTurnSharpRight.mp3" },
    /* 84*/{ id: "TURN_SHARP_RIGHT_AND_KEEP_LEFT", name: "TURN SHARP RIGHT AND KEEP LEFT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpRightThenKeepLeft.mp3" },
    /* 85*/{ id: "TURN_SHARP_RIGHT_AND_KEEP_RIGHT", name: "TURN SHARP RIGHT AND KEEP RIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpRightThenKeepRight.mp3" },
    /* 86*/{ id: "TURN_SHARP_RIGHT_AND_KEEP_STRAIGHT", name: "TURN SHARP RIGHT AND KEEP STRAIGHT", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpRightThenKeepStraight.mp3" },
    /* 87*/{ id: "TURN_SHARP_RIGHT_AND_TURN_LEFT", name: "TURN SHARP RIGHT AND TURN LEFT", icon: "turn_sharp_right_and_turn_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpRightThenTurnLeft.mp3" },
    /* 88*/{ id: "TURN_SHARP_RIGHT_AND_TURN_RIGHT", name: "TURN SHARP RIGHT AND TURN RIGHT", icon: "turn_sharp_right_and_turn_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpRightThenTurnRight.mp3" },
    /* 89*/{ id: "TURN_SHARP_RIGHT_AND_TURN_SLIGHT_LEFT", name: "TURN SHARP RIGHT AND TURN SLIGHT LEFT", icon: "turn_sharp_right_and_turn_slight_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpRightThenTurnSlightLeft.mp3" },
    /* 90*/{ id: "TURN_SHARP_RIGHT_AND_TURN_SLIGHT_RIGHT", name: "TURN SHARP RIGHT AND TURN SLIGHT RIGHT", icon: "turn_sharp_right_and_turn_slight_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpRightThenTurnSlightRight.mp3" },
    /* 91*/{ id: "TURN_SHARP_RIGHT_AND_TURN_SHARP_LEFT", name: "TURN SHARP RIGHT AND TURN SHARP LEFT", icon: "turn_sharp_right_and_turn_sharp_left.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpRightThenTurnSharpLeft.mp3" },
    /* 92*/{ id: "TURN_SHARP_RIGHT_AND_TURN_SHARP_RIGHT", name: "TURN SHARP RIGHT AND TURN SHARP RIGHT", icon: "turn_sharp_right_and_turn_sharp_right.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TurnSharpRightThenTurnSharpRight.mp3" },
    /* 93*/{ id: "U_TURN", name: "U TURN", icon: "u_turn.png", sound: "assets/turn/audio/en-US_Kendra/Commands/UTurn.mp3" },
    /* 94*/{ id: "ENTER_ROUNDABOUT", name: "ENTER ROUNDABOUT", icon: "enter_roundabout.png", sound: "assets/turn/audio/en-US_Kendra/Commands/EnterRoundabout.mp3" },
    /* 95*/{ id: "EXIT_ROUNDABOUT", name: "EXIT ROUNDABOUT", icon: "exit_roundabout.png", sound: "assets/turn/audio/en-US_Kendra/Commands/ExitRoundabout.mp3" },
    /* 96*/{ id: "TAKE_FERRY", name: "TAKE FERRY", icon: "take_ferry.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TakeFerry.mp3" },
    /* 97*/{ id: "EXIT_FERRY", name: "EXIT FERRY", icon: "exit_ferry.png", sound: "assets/turn/audio/en-US_Kendra/Commands/ExitFerry.mp3" },
    /* 98*/{ id: "ARRIVE", name: "ARRIVE", icon: "arrive.png", sound: "assets/turn/audio/en-US_Kendra/Commands/ArriveAtDestination.mp3" },
    /* 99*/{ id: "FOLLOW_THE_ROUTE", name: "FOLLOW THE ROUTE", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/FollowRoute.mp3" },
    /*100*/{ id: "CHARGE", name: "CHARGE", icon: "charge.png", sound: "assets/turn/audio/en-US_Kendra/Commands/Charge.mp3" },
    /*101*/{ id: "REPLAN", name: "REPLAN", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/Replan.mp3" },
    /*102*/{ id: "CONDITION_CHANGED_REPLAN", name: "CONDITION CHANGED REPLAN", icon: "", sound: "assets/turn/audio/en-US_Kendra/Commands/ConditionChangedReplan.mp3" },
    /*103*/{ id: "ARRIVE_TO_WAYPOINT", name: "ARRIVE TO WAYPOINT", icon: "waypoint.png", sound: "assets/turn/audio/en-US_Kendra/Commands/Waypoint.mp3" },
    /*104*/{ id: "TAKE_SHUTTLE_TRAIN", name: "TAKE SHUTTLE TRAIN", icon: "take_shuttle_train.png", sound: "assets/turn/audio/en-US_Kendra/Commands/TakeShuttleTrain.mp3" },
    /*105*/{ id: "EXIT_SHUTTLE_TRAIN", name: "EXIT SHUTTLETRAIN", icon: "exit_shuttle_train.png", sound: "assets/turn/audio/en-US_Kendra/Commands/ExitShuttleTrain.mp3" }
  ];

  laneList = ["TURN_TYPE_U_TURN_LEFT",
    "TURN_TYPE_SHARP_LEFT",
    "TURN_TYPE_LEFT",
    "TURN_TYPE_SLIGHT_LEFT",
    "TURN_TYPE_STRAIGHT",
    "TURN_TYPE_SLIGHT_RIGHT",
    "TURN_TYPE_RIGHT",
    "TURN_TYPE_SHARP_RIGHT",
    "TURN_TYPE_U_TURN_RIGHT"];

  laneTable = [4, 3, 5, 2, 6, 1, 7, 0, 8];

  startIcon: L.Icon = icon({
    iconUrl: "../assets/route_point_start.svg",
    iconSize: [84, 84],
    iconAnchor: [42, 42],
  });
  startIconLight: L.Icon = icon({
    iconUrl: "../assets/route_point_start_light.svg",
    iconSize: [84, 84],
    iconAnchor: [42, 42],
  });
  waypointIcon: L.Icon = icon({
    iconUrl: "../assets/route_point_waypoint.svg",
    iconSize: [84, 84],
    iconAnchor: [42, 42],
  });
  endIcon: L.Icon = icon({
    iconUrl: '../assets/route_point_target.svg',
    iconSize: [84, 84],
    iconAnchor: [42, 42],
  });
  endIconLight: L.Icon = icon({
    iconUrl: '../assets/route_point_target_light.svg',
    iconSize: [84, 84],
    iconAnchor: [42, 42],
  });
  endIconFailed: L.Icon = icon({
    iconUrl: '../assets/route_point_target_failed.svg',
    iconSize: [84, 84],
    iconAnchor: [42, 42],
  });
  endIconFailedLight: L.Icon = icon({
    iconUrl: '../assets/route_point_target_failed_light.svg',
    iconSize: [84, 84],
    iconAnchor: [42, 42],
  });
  chargingBigIcon1 = icon({
    iconAnchor: [16.8, 42],
    iconUrl: "./assets/charging_stations1_route.png",
    iconSize: [33.75, 42]
  });
  chargingBigIcon2 = icon({
    iconAnchor: [16.8, 42],
    iconUrl: "./assets/charging_stations2_route.png",
    iconSize: [33.75, 42]
  });
  chargingBigIcon3 = icon({
    iconAnchor: [16.8, 42],
    iconUrl: "./assets/charging_stations3_route.png",
    iconSize: [33.75, 42]
  });
  // RHA center marker icon definition
  rhaCursor = icon({
    iconAnchor: [16, 16],
    iconUrl: "./assets/cursor.png",
    iconSize: [32, 32],
    className: 'rha-cursor'
  });

  kmToMile(km: number) {
    return km * 0.621371192;
  }

  mToFeet(m: number) {
    return m * 3.2808399;
  }

  mToMiles(m: number) {
    return m * 0.0006213712;
  }

  mphToKmph(mph: number) {
    return mph / 0.621371192;
  }

  kmphToMph(kmph: number) {
    return kmph * 0.621371192;
  }

  mpsToKmph(mps: number) {
    return mps * 3.6;
  }

  KmphToMps(kmph: number) {
    return kmph / 3.6;
  }

  mToYard(m: number) {
    return m * 1.0936133;
  }

  yardToM(yard: number) {
    return yard / 1.0936133;
  }

  mileToM(mile: number) {
    return mile / 0.00062137
  }
  barToPsi(bar: number) {
    return bar * 14.5037738;
  }
  toRad(deg) {
    return deg * Math.PI / 180;
  }

  toDeg(rad) {
    return rad * 180 / Math.PI;
  }

  // celsius fahrenheit conversion
  ConvertCelsiusToFahrenheit(c: number): number {
    return Math.round(((9.0 / 5.0) * c) + 32);
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  VqrzrxFGyHNUVIRU(polyParams){
    var SXihtOZnuC = new Md5();
    SXihtOZnuC.appendStr(JSON.stringify(polyParams)+"OLiFNGTFc5T92WNGSRgBnpcG4crUlTg7tFII0YoNZiDPJSLcnaI5sSwU4jMw5V21");
    return SXihtOZnuC.end();
  }

  timeConverter(num) {
    var roundedNum = Math.round(num);
    var hours: any = Math.floor(roundedNum / 60);
    var minutes: any = roundedNum % 60;

    if (hours.toString().length < 2) {
      hours = "0" + hours.toString();
    }
    if (minutes.toString().length < 2) {
      minutes = "0" + minutes.toString();
    }
    return hours + ":" + minutes;
  }

  blobToUint8Array(b) {
    var uri = URL.createObjectURL(b),
      xhr = new XMLHttpRequest(),
      i,
      ui8;

    xhr.open('GET', uri, false);
    xhr.send();

    URL.revokeObjectURL(uri);

    ui8 = new Uint8Array(xhr.response.length);

    for (i = 0; i < xhr.response.length; ++i) {
      ui8[i] = xhr.response.charCodeAt(i);
    }

    return ui8;
  }

  angleFromCoordinate(lat1, lon1, lat2, lon2) {
    var dLon = this.toRad(lon2 - lon1);
    var y = Math.sin(dLon) * Math.cos(this.toRad(lat2));
    var x = Math.cos(this.toRad(lat1)) * Math.sin(this.toRad(lat2)) - Math.sin(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) * Math.cos(dLon);
    var brng = this.toDeg(Math.atan2(y, x));
    return ((brng + 360) % 360);
  }

  distanceBetweenCoordinates(origin, destination) {
    // return distance in meters
    var lon1 = this.toRad(origin[1]),
      lat1 = this.toRad(origin[0]),
      lon2 = this.toRad(destination[1]),
      lat2 = this.toRad(destination[0]);

    var deltaLat = lat2 - lat1;
    var deltaLon = lon2 - lon1;

    var a = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
    var c = 2 * Math.asin(Math.sqrt(a));
    var EARTH_RADIUS = 6366707;
    return c * EARTH_RADIUS;
  }

  distanceBetweenCoordinatesLonLat(origin, destination) {
    // return distance in meters
    var lon1 = this.toRad(origin[0]),
      lat1 = this.toRad(origin[1]),
      lon2 = this.toRad(destination[0]),
      lat2 = this.toRad(destination[1]);

    var deltaLat = lat2 - lat1;
    var deltaLon = lon2 - lon1;

    var a = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
    var c = 2 * Math.asin(Math.sqrt(a));
    var EARTH_RADIUS = 6366707;
    return c * EARTH_RADIUS;
  }

  getRandomBetweenCoordinates(min, max) {
    return parseFloat((Math.random() * (max - min) + min).toFixed(6));
  }

  // close geometry
  closeGeometry(geometry) {
    geometry.forEach(element => {
      if (element[0][0] != element[element.length - 1][0] || element[0][1] != element[element.length - 1][1]) {
        element.push(element[0]);
      }
    });

    return geometry;
  }

  // rgb to hex color conversion
  rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  rectContains(cursorX, cursorY, rectX, rectY, rectWidth, rectHeight) {
    return rectX - (rectWidth / 2) <= cursorX && cursorX <= rectX + (rectWidth / 2) &&
      rectY - (rectHeight / 2) <= cursorY && cursorY <= rectY + (rectHeight / 2);
  }

  rotate(cx, cy, x, y, angle) {
    var radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
      ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
    return [nx, ny];
  }

  RotatePoint(x: number, y: number, angle: number) {
    var sinValue = Math.sin(angle);
    var cosValue = Math.cos(angle);
    return { x: x * cosValue - y * sinValue, y: x * sinValue + y * cosValue };
  }

  // getting the actual lod level
  getLodLevel(map: any): number {
    var lodCurrent;
    if (map.getZoom() <= 4) {
      lodCurrent = 0;
    }
    else if (map.getZoom() <= 6) {
      lodCurrent = 1;
    }
    else if (map.getZoom() <= 8) {
      lodCurrent = 2;
    }
    else if (map.getZoom() <= 10) {
      lodCurrent = 3;
    }
    else if (map.getZoom() <= 12) {
      lodCurrent = 4;
    }
    else if (map.getZoom() <= 14) {
      lodCurrent = 5;
    }
    else if (map.getZoom() <= 16) {
      lodCurrent = 6;
    }
    else if (map.getZoom() <= 22) {
      lodCurrent = 7;
    }
    return lodCurrent;
  }

  Lerpd(p1, p2, alpha) {
    return p1 * (1 - alpha) + p2 * alpha;
  }

  ConvertToEnglishNumbers(number: number) {
    if (number == 1) {
      return number + "st";
    }
    else if (number == 2) {
      return number + "nd";
    }
    else if (number == 3) {
      return number + "rd";
    }
    else {
      return number + "th";
    }
  }

  CalculateDistanceBetweenRoutePoints(planPoints) {
    planPoints[0].push(0);
    for (let i = 1; i < planPoints.length; i++) {
      planPoints[i].push(Math.round(this.distanceBetweenCoordinates([planPoints[i - 1][0], planPoints[i - 1][1]], [planPoints[i][0], planPoints[i][1]])));
    }
    return planPoints;
  }

  CalculateTimeBetweenRoutePoints(planPoints, routeSegments, chargePlan) {
    planPoints[0].push(0);
    for (let i = 1; i < planPoints.length; i++) {
      var actDist = planPoints[i][5];
      var timeToDest = 0;
      var actSpeed = 0;

      if (planPoints[i][4] != 0) {
        actSpeed = planPoints[i][4];
      }
      else {
        actSpeed = planPoints[i][3];
      }

      if (actSpeed > 30) {
        if (actSpeed < 80) {
          actSpeed = actSpeed * 0.6;
        }
        else {
          actSpeed = actSpeed * 0.95;
        }
      }

      planPoints[i].push(actDist / 1000 / actSpeed * 60);
    }
    //add charging station
    if (routeSegments && routeSegments.length > 1) {
      for (let i = 1; i < routeSegments.length; i++) {
        planPoints[routeSegments[i][1]][6] += chargePlan[i - 1][2];
      }
    }
    return planPoints;
  }
}
