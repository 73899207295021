import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { InputParamsService } from './input-params.service';
import { UtilsService } from './utils.service';
import { AccountService } from './account.service';
import * as JSZip from 'jszip';

declare var Smartcar;

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  /** Memory cache of stored logs. */
  logs: Array<any> = null;

  /** Flag indicating upload is in progress. */
  uploading = false;

  /** Smartcar tokens and expiration info. */
  accessToken:string = null; // @smartcar
  accessTokenExpireDate:Date = null; // @smartcar
  refreshToken:string = null; // @smartcar

  /** Smartcar vehicle info. */
  smartCarVehicle: any = null; // @smartcar
  smartCarLastQuery: any = null; // @smartcar

  /** Smartcar API handlers. */
  smartcar: any = new Smartcar({  // @smartcar
    /** API configuration. */
    testMode: true,
    clientId: 'eb3f3667-8fd2-43f7-8ea1-cbac68301885',
    redirectUri: 'https://dev.evnavigation.com/assets/smartcar_redirect.html',
    scope: ['read_vehicle_info', 'read_battery', 'read_location', 'read_vin', 'read_charge', 'read_odometer', 'read_tires'],

    /** Login completion handler. */
    onComplete: (err, code) => {
      if (err) {
        // @todo
        return;
      }

      // Forward authentication code to evnavi smartcar API
      var httpopt = {headers: new HttpHeaders().append("Content-Type", "application/x-www-form-urlencoded")};
      var params = new HttpParams().append("grant", "auth").append("code", code);
      this.http.post("https://dev.evnavigation.com/ecar/smartcar.php", params.toString(), httpopt).subscribe((resp: any) => {
        // Parse response data
        this.accessToken = resp.access_token;
        this.refreshToken = resp.refresh_token;
        this.accessTokenExpireDate = new Date();
        this.accessTokenExpireDate.setSeconds(this.accessTokenExpireDate.getSeconds() + resp.expires_in);

        // Query available vehicles
        var httpopt = {headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Bearer ' + resp.access_token})};
        this.http.get("https://api.smartcar.com/v1.0/vehicles", httpopt).subscribe((carsresp: any) => {
          // Select first available car (@todo select / save other cars?)
          this.smartCarVehicle = carsresp.vehicles[0];
        });
      });
    },
  });

  /** Constructs a new LoggerService object. */
  constructor(private utilsService: UtilsService, private paramsService: InputParamsService, private http: HttpClient, private accountService: AccountService) {
    // Custom database format:
    // Every entry is a json array, the first entry is a number that identifies the data type (id).
    // The following types are allowed in version 1:
    // GPS Id: 1, Timestamp: int64 (milliseconds since track start), Lat: float (deg), Lon: float (deg), Alt: int (m), Speed: float (m/s). Example: [1,69726,47.500985,19.032034,166,13.88889]
    // CAR Id: 9, {custom smartcar data}

    // header data for upload @todo ?
    /* version, totalTime, totalDistance, avgSpeed, avgConsumption, startPointLon, startPointLat, endPointLon, endPointLat, startTimeLocal, startTimeUTC, endTimeLocal, endTimeUTC ,userVehicleID (user_vehicle_id) ,boundary box [minlon, minlat, maxlon, maxlat], tempMin, tempMax, windStartDirDeg, windStartStrength, windEndDirDeg, windEndStrength */

    this.initLocationWatch();
  }

  private initLocationWatch() {
    if (!window.navigator.userAgent.includes("GPSTWebView") && !window.navigator.userAgent.includes("FB_IAB")) {
      navigator.geolocation.watchPosition(
        (position) => {
          this.locationReceived([position.coords.latitude, position.coords.longitude, position.coords.speed, position.coords.altitude, position.timestamp]);
        },
        (error) => {},
        {enableHighAccuracy: true, timeout: 4000, maximumAge: 0});
    } else {
      if (!this.paramsService.lastWebViewLocation) {
        this.paramsService.watchGPSData();
      }
      this.paramsService.ObservableWebViewPosition.subscribe((resp) => {
        this.locationReceived(resp);
      });
    }
  }

  locationReceived(data) {
    // Check if upload is needed
    var lastLatLon = null;
    this.prepareLog();
    for (var logIdx = this.logs.length - 1; logIdx >= 0; logIdx--) {
      if (this.logs[logIdx][0] == 1) {
        lastLatLon = [this.logs[logIdx][1], this.logs[logIdx][2], this.logs[logIdx][3]];
        break;
      }
    }
    if (lastLatLon != null && (data[4] - lastLatLon[0] > (30 * 60 * 1000) || this.utilsService.distanceBetweenCoordinates([data[0], data[1]], [lastLatLon[1], lastLatLon[2]]) > 2000)) {
      // 30 minutes or 2km away from last point, upload
      this.uploading = true;

      // Generate header statistics data
      var stats = this.generateStats();
      if (stats != null && stats['total_distance'] > 0) {
        // Compress first
        let Zipper:any = JSZip;
        let zip: any = new Zipper();
        zip.file("log.json", JSON.stringify(this.logs, null, 0));
        zip.generateAsync({type: "blob", compression: "DEFLATE", compressionOptions: {level: 9}}).then(zipBlob => {
          // Convert blob to string
          var target = this;
          var reader = new FileReader();
          reader.onload = function() {
            // Start upload
            target.uploadLog(stats, reader.result).subscribe((resp: any) => {
              // Upload finished
              if (resp != "error") {
                target.logs = null;
                localStorage.setItem("tripdata", "[]");
              }
              target.uploading = false;
            }, (error) => {
              // Upload failed
              target.uploading = false;
            });
          }
          reader.readAsText(zipBlob);
        });
      }
    }

    // No logging during upload
    if (this.uploading == true) {
      return;
    }

    // Log gps data
    this.logGps(data[4], data[0], data[1], data[3], data[2]);

    // Check when last time smartcar data was logged
    var carDataNeeded = false; // @smartcar
    if (this.smartCarLastQuery == null) { // @smartcar
      carDataNeeded = true;
    } else { // @smartcar
      var nowTime = new Date().getTime();
      var seconds = Math.round(((nowTime - this.smartCarLastQuery) / 1000) % 60);
      if (seconds > 30) {
        carDataNeeded = true;
        this.smartCarLastQuery = nowTime;
      }
    }

    // If needed, query smart car data
    if (carDataNeeded == true) { // @smartcar
      this.getSmartCarData();
    }
  }

  getSmartCarData() {  // @smartcar
    // Check if we are logged in to the service
    if (this.accessTokenExpireDate == null || this.smartCarVehicle == null) {
      return;
    }

    // Check if we need to refresh our access token
    if (this.accessTokenExpireDate.getTime() < Date.now()) {
      // Get a new token using evnavi smartcar API refresh
      var httpopt = {headers: new HttpHeaders().append("Content-Type", "application/x-www-form-urlencoded")};
      var params = new HttpParams().append("grant", "refresh").append("refresh_token", this.refreshToken);
      this.http.post("https://dev.evnavigation.com/ecar/smartcar.php", params.toString(), httpopt).subscribe((resp: any) => {
        // Parse response data
        resp = JSON.parse(resp);
        this.accessToken = resp.access_token;
        this.refreshToken = resp.refresh_token;
        this.accessTokenExpireDate = new Date();
        this.accessTokenExpireDate.setSeconds(this.accessTokenExpireDate.getSeconds() + resp.expires_in);
      });
      return;
    }

    // Authentication OK, make the requests towards smartcar API
    var batchopt = {headers: new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.accessToken})};
    var batchbody = {"requests": [
      { "path" : "/battery" },
      { "path" : "/charge" },
      { "path" : "/location" },
      { "path" : "/odometer" },
      { "path" : "/tires/pressure" }
    ]};
    this.http.post("https://api.smartcar.com/v1.0/vehicles/" + this.smartCarVehicle + "/batch", batchbody, batchopt).subscribe((resp: any) => {
      // Parse response
      if (resp != undefined && resp != null && resp.length != undefined && resp.length > 0) {
        for (var respIdx = 0; respIdx < resp.length; respIdx++) {
          // Data availability check
          var single = resp[respIdx];
          if (single['code'] != 200 || single['body'] == undefined || single['body'] == null || single['path'] == undefined || single['path'] == null) {
            continue;
          }

          // Store
          this.pushLogEntry([9, single['body']]);
        }
      }
    });
  }

  generateStats() {
    // Check of enough data is available
    this.prepareLog();
    if (this.logs.length < 2) {
      return null;
    }

    // Version
    var stats = {};
    stats['version'] = 1;

    // Vehicle id
    if (!this.accountService.getUserProfile() || !this.accountService.getUserProfile().getSelectedUserCar())
      return null;
    stats['user_vehicle_id'] = this.accountService.getUserProfile().getSelectedUserCar().userVehicleID;

    // Location stats
    var start_lat = null;
    var start_lon = null;
    var end_lat = null;
    var end_lon = null;
    var prev_lat = null;
    var prev_lon = null;
    var total_distance = 0;
    var avg_speed = 0;
    var start_time_utc = "";
    var end_time_utc = "";
    var start_time_local = "";
    var end_time_local = "";
    var boundary = [100, 100, -100, -100];
    var num_points = 0;

    for (var logIdx = 0; logIdx < this.logs.length; logIdx++) {
      if (this.logs[logIdx][0] == 1) {
        // Start coordinates
        if (start_lat == null || start_lon == null) {
          start_lat = this.logs[logIdx][2];
          start_lon = this.logs[logIdx][3];
          start_time_local = this.formatTime(this.logs[logIdx][2]);
          start_time_utc = this.formatTime(this.localToUTC(this.logs[logIdx][2]));
        }

        // End coordinates
        end_lat = this.logs[logIdx][2];
        end_lon = this.logs[logIdx][3];
        end_time_local = this.formatTime(this.logs[logIdx][2]);
        end_time_utc = this.formatTime(this.localToUTC(this.logs[logIdx][2]));

        // Boundary
        if (end_lon < boundary[0]) boundary[0] = end_lon;
        if (end_lat < boundary[1]) boundary[1] = end_lat;
        if (end_lon > boundary[2]) boundary[2] = end_lon;
        if (end_lat > boundary[3]) boundary[3] = end_lat;

        // Average speed
        avg_speed += this.logs[logIdx][5];

        // Total distance
        if (prev_lat != null && prev_lon != null) {
          var distance = this.utilsService.distanceBetweenCoordinates([end_lat, end_lon], [prev_lat, prev_lon]);
          total_distance += distance;
        }
        prev_lat = end_lat;
        prev_lon = end_lon;
        num_points++;
      }
    }
    avg_speed /= num_points;

    // Supported stats
    stats['start_lat'] = start_lat;
    stats['start_lon'] = start_lon;
    stats['end_lat'] = end_lat;
    stats['end_lon'] = end_lon;
    stats['total_distance'] = total_distance;
    stats['avg_speed'] = avg_speed;
    stats['start_time_utc'] = start_time_utc;
    stats['end_time_utc'] = end_time_utc;
    stats['start_time_local'] = start_time_local;
    stats['end_time_local'] = end_time_local;
    stats['boundary_box'] = boundary;

    // Not yet supported stats @todo
    stats['avg_consumption'] = 0;
    stats['min_temp'] = 0;
    stats['max_temp'] = 0;
    stats['start_wind_dir'] = 0;
    stats['end_wind_dir'] = 0;
    stats['start_wind_strength'] = 0;
    stats['end_wind_strength'] = 0;

    return stats;
  }

  prepareLog() {
    // In case log is not ready yet
    if (this.logs == null) {
      // Load log to memory
      var logStorage:any = localStorage.getItem("tripdata");
      if (logStorage == undefined || logStorage == null) {
        logStorage = [];
      } else {
        logStorage = JSON.parse(logStorage);
      }
      this.logs = logStorage;
    }
  }

  save() {
    // Convert data to minified json and store locally
    localStorage.setItem("tripdata", JSON.stringify(this.logs, null, 0));
  }

  pushLogEntry(logEntry:any) {
    // Check if log is available, add and save
    this.prepareLog();
    this.logs.push(logEntry);
    this.save();
  }

  logGps(timestamp: number, latitudeDeg: number, longitudeDeg: number, altitudeMeter: number, speedMS: number) {
    // Id: 1, Timestamp: int64 (milliseconds since track start), Lat: float (deg), Lon: float (deg), Alt: int (m), Speed: float (m/s). Example: [1,69726,47.500985,19.032034,166,13.88889]
    var logEntry = [
      1,
      Math.round(timestamp), // @todo This should be milliseconds since track start!!!
      Math.floor(latitudeDeg * 1000000) / 1000000.0,
      Math.floor(longitudeDeg * 1000000) / 1000000.0,
      Math.round(altitudeMeter),
      Math.floor(speedMS * 100) / 100.0
    ];

    // Store
    this.pushLogEntry(logEntry);
  }

  /** Converts unix timestamp in milliseconds to string format. */
  formatTime(timestampMillis: number): string {
    // Output format is e.g. "2020-06-18 18:40"
    var d = new Date(timestampMillis);
    return ('' + d.getFullYear() + '-' + ((d.getMonth() + 1) <= 9 ? '0' : '') + (d.getMonth() + 1) + '-' + (d.getDate() <= 9 ? '0' : '') +  d.getDate() + ' ' + (d.getHours() <= 9 ? '0' : '') + d.getHours() + ':' + (d.getMinutes() <= 9 ? '0' : '') + d.getMinutes());
  }

  /** Converts timestamp in UTC to timestamp in local time. */
  utcToLocal(utcTimestampMillis: number): number {
    var date = new Date(utcTimestampMillis);
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate.getTime();
  }

  /** Converts timestamp in local time to timestamp in UTC time. */
  localToUTC(localTimestampMillis: number): number {
    var d = new Date(localTimestampMillis);
    return d.getTime() + (d.getTimezoneOffset() * 60000);
  }

  /** Tries to upload log file with the given header information. Returns an observable. */
  uploadLog(header, compressedData): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      // Check login
      if (!this.accountService.getIsAuthorized()) {
        observer.next("error");
        observer.complete();
        return;
      }

      // Assemble HTTP multipart form data request
      let boundary = "koLDbbli05G81n3bgni6fkjoa37vC2";
      var body = "";

      // Add header values
      Object.keys(header).forEach(function(key, index) {
        var value = header[key];
        if (value === null)
          return;

        if (typeof value === 'object')
          value = JSON.stringify(value);

        body += "--" + boundary
          + "\r\nContent-Disposition: form-data; name=\"" + key + "\""
          + "\r\nContent-Type: text/plain"
          + "\r\n\r\n" + value + "\r\n";
      });

      // Add file data
      body += "--" + boundary
        + "\r\nContent-Disposition: form-data; name=\"logfile\"; filename=\"gpslog.zip\""
        + "\r\nContent-Type: application/zip"
        + "\r\n\r\n" + compressedData + "\r\n"
        + "--" + boundary + "--\r\n";

      // Set up handlers
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          // Sending succeeded, inform subscribers
          observer.next(xhttp.responseText);
          observer.complete();
        }
      };

      // Send
      if (document.URL.includes("localhost") || document.URL.includes("staging") || document.URL.includes("192.168.")) {
        xhttp.open("POST", "https://staging-api.evnavigation.com/api/vehicles/log");
      }
      else {
        xhttp.open("POST", "https://api.evnavigation.com/api/vehicles/log");
      }
      xhttp.setRequestHeader("Authorization", "Bearer " + this.accountService.token);
      xhttp.setRequestHeader("Accept", 'application/json');
      xhttp.setRequestHeader("Content-Type", "multipart/form-data; boundary=" + boundary);
      xhttp.setRequestHeader("Content-Length", "" + body.length);
      xhttp.send(body);
    });
  }
}
