import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import GeotabApi from 'mg-api-js/dist/api.js';
import L, { LatLng } from 'leaflet';
import device from 'current-device';
import { timer, } from 'rxjs';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MapService } from 'src/app/services/map.service';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-tori-module',
  templateUrl: './tori-module.component.html',
  styleUrls: ['./tori-module.component.css']
})
export class ToriModuleComponent implements OnInit {

  username: string = "";
  password: string = "";
  error: boolean = false;
  token: string = "";
  vehicles = [];
  lastTripDevice = "";
  lastLatLon = [0, 0];
  trackingDevicesTimer = null;
  lastRecordedTripDate = "";
  timer = timer(0, 2500);
  batteryData = null;
  lastRhaCoords = null;
  activeTracking: number = -1;
  isMobile: boolean;
  Trips: any;
  subscriptions = [];
  trackingOldDataCount: number = 0;

  exampledata = [{ "id": "ID074B93B8", "last_seen": "2020-01-15 02:12:32", "bearing": null, "soc": null, "lat": null, "lon": null },
  { "id": "ID5DF0363B", "last_seen": "2020-01-08 16:44:19", "bearing": 160, "soc": 50, "lat": 49.156, "lon": 19.5565 },
  { "id": "ID5DF0360B", "last_seen": "2020-01-10 12:01:57", "bearing": 150, "soc": 60, "lat": 49.154345, "lon": 19.54345 }];

  /* fleet solution for tori devices */
  constructor(private inputParamsService: InputParamsService, private mapService: MapService, private mobileResolutionService: MobileResolutionService,
    private cdr: ChangeDetectorRef, private http: HttpClient, private utilsService: UtilsService) {
  }

  ngOnInit() {
    // set inputparams to default
    var inputParams = this.inputParamsService.getRangeInputParams();
    inputParams.weight = 1;
    inputParams.tirePressure = 2.3;
    inputParams.userBehavior = 50;
    inputParams.speedLimiter = 100;
    inputParams.batterySafetyLimit = 50;
    inputParams.minBatteryAtDestination = 10;
    this.inputParamsService.setRangeInputParams(inputParams);

    this.isMobile = device.mobile();

    // tracking subscription
    this.subscriptions.push(this.inputParamsService.ObservableTrackingTimer.subscribe((resp: any) => {
      if (resp == false) {
        this.unsubscribeTimer();
        this.activeTracking = -1;
        this.mapService.ObservableAddMultipleMarkers.next(null);
        this.lastLatLon = [0, 0];
        this.inputParamsService.setStartCoordsBearingParams(0);
        this.cdr.detectChanges();
      }
    }));

    // select tori vehicle (from the map)
    this.subscriptions.push(this.inputParamsService.ObservableSelectedToriVehicle.subscribe((resp:number)=>{
      if (resp != null && resp != undefined){
        this.trackVehicle(resp);
      }
    }));

    // auto login if valid token available
    if (localStorage.getItem("trackingToken") != null && localStorage.getItem("trackingToken") != undefined) {
      this.getToriVehicles(localStorage.getItem("trackingToken")).subscribe((resp: any) => {
        this.token = localStorage.getItem("trackingToken");
        this.processToriVehicles(resp);
      }, () => {
        localStorage.setItem("trackingToken", null);
      })
    }
  }

  // login api call
  login() {
    this.error = false;
    this.cdr.detectChanges();
    var formData = new FormData();
    formData.append("username", this.username);
    formData.append("password", this.password);
    this.http.post("https://api-dev.evnavigation.com/login", formData)
      .subscribe((resp: any) => {
        if (resp.token) {
          localStorage.setItem("trackingToken", resp.token);
          this.token = resp.token;
          this.getToriVehicles(this.token).subscribe((resp: any) => {
            this.processToriVehicles(resp);
          })
        }
        else {
          this.error = true;
          this.cdr.detectChanges();

          setTimeout(() => {
            this.error = false;
            this.cdr.detectChanges();
          }, 1250);
        }
      });
  }

  // get vehicle list api call
  getToriVehicles(token) {
    return this.http.get("https://api-dev.evnavigation.com/getvehiclelist?token=" + token);
  }

  // load tori vehicles
  processToriVehicles(resp) {
    this.vehicles = resp.vehicles;
    // remove devices with not valid coordinate value
    for (let i = 0; i < this.vehicles.length; i++) {
      if (this.vehicles[i].lat == null || this.vehicles[i].lat == undefined || this.vehicles[i].lon == null || this.vehicles[i].lat == undefined) {
        this.vehicles.splice(i, 1);
        i--;
      }
    }
    this.trackVehicle(0);
    this.cdr.detectChanges();
  }

  // track selected device
  trackVehicle(vehicle) {
    this.activeTracking = vehicle;
    this.mapService.ObservableAddMultipleMarkers.next([this.vehicles, vehicle]);
    this.inputParamsService.setStartCoordsBearingParams(this.vehicles[vehicle].bearing);
    this.inputParamsService.setStartCoordsParams(new L.LatLng(this.vehicles[vehicle].lat, this.vehicles[vehicle].lon));
    this.mapService.ObservableMoveTo.next(new L.LatLng(this.vehicles[vehicle].lat, this.vehicles[vehicle].lon));
    this.inputParamsService.setBattery(this.vehicles[vehicle].soc);

    // vehicle previous tracks
    this.getVehicleData(this.vehicles[vehicle].id, this.token, 10000).subscribe((resp: any) => {
      this.processVehicleData(resp.vehicles[this.vehicles[vehicle].id].history);
    })

    // getting real time data

  }

  // create tracks from vehicle data
  processVehicleData(tracks) {
    var Trips = [];
    var lastTrip = [];
    var lastTripTrackPoints = [];
    for (let i = 0; i < tracks.length ; i++) {
      // check for valid lat lon
      if ((parseFloat(tracks[i].lat) != 0 && parseFloat(tracks[i].lon) != 0) && (parseFloat(tracks[i].lat) >= -90 && parseFloat(tracks[i].lat) <= 90 && parseFloat(tracks[i].lat) >= -180 && parseFloat(tracks[i].lat) <= 180) && tracks[i].created_at) {
        if (this.lastRecordedTripDate == "") {
          this.lastRecordedTripDate = tracks[i].created_at.replace(" ", "T");
        }

        // create a trip
        if (lastTripTrackPoints.length == 0) {
          lastTripTrackPoints.push([parseFloat(tracks[i].lat), parseFloat(tracks[i].lon), parseFloat(tracks[i].soc), tracks[i].created_at]);
        }
        else if (parseFloat(tracks[i].lat) == lastTripTrackPoints[lastTripTrackPoints.length - 1][0] && parseFloat(tracks[i].lon) == lastTripTrackPoints[lastTripTrackPoints.length - 1][1]) {
        }
        else if ((tracks[i].created_at && new Date(lastTripTrackPoints[lastTripTrackPoints.length - 1][3].replace(" ", "T")).getTime() - new Date(tracks[i].created_at.replace(" ", "T")).getTime() < 7200000) && (this.utilsService.distanceBetweenCoordinates([lastTripTrackPoints[lastTripTrackPoints.length - 1][0], lastTripTrackPoints[lastTripTrackPoints.length - 1][1]], [tracks[i].lat, tracks[i].lon]) < 1000)) {
          lastTripTrackPoints.push([parseFloat(tracks[i].lat), parseFloat(tracks[i].lon), parseFloat(tracks[i].soc), tracks[i].created_at]);
        }
        // set a new trip if the data recorded in more than 2 hours
        else {
          if (lastTripTrackPoints.length >= 100) {
            lastTrip.push(lastTripTrackPoints);
            lastTrip.push(Trips.length);
            Trips.push(lastTrip);

          }
          lastTripTrackPoints = [];
          lastTrip = [];
          lastTripTrackPoints.push([parseFloat(tracks[i].lat), parseFloat(tracks[i].lon), parseFloat(tracks[i].soc), tracks[i].created_at]);
        }
      }
    }

    if (Trips.length > 10) {
      Trips.length = 10;
    }

    var lastTrikeSignal;

    if (Trips.length > 0) {
      this.inputParamsService.setStartCoordsParams(new LatLng(Trips[0][0][0][0], Trips[0][0][0][1]));
      this.Trips = Trips;
      this.lastLatLon = [Trips[0][0][0][0], Trips[0][0][0][1]];

      // set battery
      if (Trips[0][0][0][0] < 255) {
        var rangeInput = this.inputParamsService.getRangeInputParams();
        if (rangeInput.batteryLevel != Trips[0][0][0][0]) {
          rangeInput.batteryLevel = Trips[0][0][0][0];
          this.inputParamsService.setRangeInputParams(rangeInput);
        }
      }
      this.mapService.setLastTrip(Trips);
      lastTrikeSignal = this.lastLatLon;
    }
    else{
      this.mapService.setLastTrip(null);
    }

    this.cdr.detectChanges();
    this.inputParamsService.setTrackingTimer(true);
    this.inputParamsService.setWaypointParams([this.inputParamsService.getWaypointsParams()[0]]);
    this.mobileResolutionService.setMobileVisiblePanel("");
  }

  subscribeTracking(){
    /*this.trackingDevicesTimer = this.timer.subscribe((val) => {
      //lastTrikeSignal = [this.utilsService.getRandomBetweenCoordinates(lastTrikeSignal[0], lastTrikeSignal[0] + 0.0005),
      //this.utilsService.getRandomBetweenCoordinates(lastTrikeSignal[1], lastTrikeSignal[1] + 0.0005)];
      //this.processLastTrikeSignal(lastTrikeSignal);
      this.trackingOldDataCount++;
      this.http.get(lastLogUrl, { responseType: 'text' }).subscribe((resp) => {
        if (this.lastTrikeSignal != resp) {
          this.lastTrikeSignal = resp;

          resp = resp.substring(0, resp.indexOf("\n"));
          var lineValue = resp.split(";");
          var property: any = {};
          for (let j = 0; j < lineValue.length; j++) {
            var seperatorIdx = lineValue[j].indexOf(":");
            property[lineValue[j].substring(0, seperatorIdx)] = lineValue[j].substring(seperatorIdx + 1);
          }
          if (property.lat && property.lon && property.soc) {
            this.processLastTrikeSignal([parseFloat(property.lat), parseFloat(property.lon), parseFloat(property.soc)], val, property.servertime);
          }
        }
        if (this.trackingOldDataCount == 12) {
          this.mapService.ObservableCursorColor.next(false);
        }
      });
    });*/
  }

  getVehicleData(vehicleId, token, limit) {
    return this.http.get("https://api-dev.evnavigation.com/getvehicledata/" + vehicleId + "?token=" + token + "&limit=" + limit);
  }

  // unsubscribe tracking
  unsubscribeTimer() {
    if (this.trackingDevicesTimer) {
      this.trackingDevicesTimer.unsubscribe();
      this.trackingOldDataCount = 0;
    }
  }

  // clear data during logout
  logout() {
    this.unsubscribeTimer();
    this.activeTracking = -1;
    this.password = "";
    this.username = "";
    this.vehicles = [];
    this.mapService.ObservableAddMultipleMarkers.next(null);
    this.inputParamsService.setStartCoordsBearingParams(0);
    this.inputParamsService.setStartCoordsParams(this.inputParamsService.getStartCoordsParams());
    this.inputParamsService.setTrackingTimer(false);
    localStorage.setItem("trackingToken", null);
    this.trackingDevicesTimer.unsubscribe();

    this.cdr.detectChanges();
  }

  // remove subscriptions
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }

}
