import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Input, HostListener } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import L, { LatLng } from 'leaflet';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { skip, timeout, repeat } from 'rxjs/operators';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { MapService } from 'src/app/services/map.service';
import { UtilsService } from 'src/app/services/utils.service';
import { TimeoutError } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { AudioService } from 'src/app/services/audio.service';
import { ThrowStmt } from '@angular/compiler';
import { MatDialog } from '@angular/material/dialog';
import { NavigationDisclaimerComponent } from '../modals/navigation-disclaimer/navigation-disclaimer.component';
import { ErrorDialogComponent } from '../modals/error-dialog/error-dialog.component';
import { GpsErrorDialogComponent } from '../modals/gps-error-dialog/gps-error-dialog.component';
import device from 'current-device';
import { runInThisContext } from 'vm';
import { ConfirmationDialogComponent } from '../modals/confirmation-dialog/confirmation-dialog.component';
@Component({
  selector: 'app-info-sidebar',
  templateUrl: './info-sidebar.component.html',
  styleUrls: ['./info-sidebar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoSidebarComponent implements OnInit {
  @Input() windHider: boolean;
  @Input() loadingMap: string;
  @ViewChild('infoScrollbar', { static: true }) infoScrollbar: NgScrollbar;
  @ViewChild('multiwpScrollbar', { static: true }) multiwpScrollbar: NgScrollbar;
  @HostListener('window:resize') onResize() {
    setTimeout(() => {
      this.updateMultiwaypointScrollbar();
      this.updateInfoScrollbar();
    }, 1000);
  }

  menuSelected = "info";
  mobileVisiblePanel: string = "";
  mobileVisibleRoutePanel: string = "routepoints";
  mobileResolution: boolean = undefined;
  routeList: any = [{
    display_name: "Karl-Liebknecht-Straße Berlin, Berlin, Germany",
    lat: 52.520008,
    lon: 13.404954,
    wp: -1
  }];
  routeData: any = [];
  routeTurnList = [];
  routeChargingPlan = [];
  unit: any = { "speed": "metric", "distance": "metric", "temperature": "metric", "pressure": "metric" };
  googleUrl = "";
  oldIOS: boolean = false;
  selectedMode: string = "rha";
  searched: boolean = false;
  refreshStartText: boolean = false;
  refreshEndText: boolean = false;
  navigation: boolean = false;
  itineraryNextTurnIdx: number = -1;
  failedPlan: boolean = false;
  maxReachedWaypoint: number = 0;
  noRoute: boolean = false;
  subscriptions: any = [];
  multiwayScrollFromTop: number = 0;
  demo: boolean = false;
  replayMode: boolean = false;
  roadLanesLength: number = 0;
  emptySearchInput: boolean = false;

  /* display planned route default data, itinerary, charging plan */
  constructor(private inputParamsService: InputParamsService, private mobileResolutionService: MobileResolutionService, private mapService: MapService,
    private cdr: ChangeDetectorRef, private utilsService: UtilsService, private router: Router, private navigationService: NavigationService,
    private audioService: AudioService, public MatDialog: MatDialog) {
    //ios10 scrollbax fix
    if (navigator.userAgent.includes('iPhone OS 10') || navigator.userAgent.includes('iPhone OS 9') ||
      navigator.userAgent.includes('iPhone OS 8') || navigator.userAgent.includes('iPhone OS 7')) {
      this.oldIOS = true;
    }

    // set rha routeList
    var waypointsStorage: any = localStorage.getItem("waypoints");
    if (waypointsStorage && waypointsStorage != 'null') {
      var waypoints = JSON.parse(waypointsStorage);

      if (waypoints.length == 1) {
        this.mapService.getReverseGeocode(waypoints[0].lat, waypoints[0].lng).subscribe((reversegeocode) => {
          if (reversegeocode) {
            this.inputParamsService.ObservableSetStartPointGeocode.next({
              display_name: reversegeocode,
              lat: waypoints[0].lat,
              lon: waypoints[0].lng,
              wp: 0
            });
          }
        }, () => { });
      }
    }

    if (document.URL.includes("demo")) {
      this.demo = true;
    }

    /*setTimeout(() => {
      this.infoScrollbar.scrollTo({ left: 0, top: 300, duration: 1 })
    }, 5000);*/
  }

  ngOnChanges() {
    if (this.infoScrollbar && this.mobileResolution != undefined) {
      this.updateInfoScrollbar();
    }
  }

  updateInfoScrollbar() {
    if (!this.mobileResolution) {
      if (this.navigation) {
        document.getElementsByClassName("info-sidebar-scrollbar")[0].setAttribute("style", "");
        this.infoScrollbar.update();
      }
      else {
        var scrollbarHeight;
        if (this.demo) {
          scrollbarHeight = 561 + (46 * (this.routeList.length - 2))
        }
        else {
          scrollbarHeight = 321 + (46 * (this.routeList.length - 2))
        }
        if (this.routeList.length == 5) {
          scrollbarHeight -= 19;
        }
        if (this.windHider) {
          scrollbarHeight -= 270;
        }
        if (!this.windHider) {
          setTimeout(() => {
            document.getElementsByClassName("info-sidebar-scrollbar")[0].setAttribute("style", "height: calc(100vh - " + scrollbarHeight + "px); transition: 0s;");
            this.infoScrollbar.update();
          }, 300);
        }
        else {
          document.getElementsByClassName("info-sidebar-scrollbar")[0].setAttribute("style", "height: calc(100vh - " + scrollbarHeight + "px); transition: 0s;");
          this.infoScrollbar.update();
        }
      }
    }
    else {
      this.infoScrollbar.update();
    }
  }

  updateMultiwaypointScrollbar() {
    if (this.mobileResolution) {
      if (device.mobile()) {
        if (window.innerWidth > window.innerHeight) {
          var calculatedEdge = Math.round((window.innerHeight - 9) / 6 * 100) / 100;
          document.querySelector(".multiwaypoint-search-scrollbar").setAttribute("style", "height: " + (window.innerHeight - calculatedEdge) + "px; transition: 0s;");
          this.multiwpScrollbar.update();
        }
        else {
          document.querySelector(".multiwaypoint-search-scrollbar").setAttribute("style", "height: " + (window.innerHeight / 3.5) + "px; transition: 0s;");
          this.multiwpScrollbar.update();
        }
      }
      else {
        if (window.innerHeight < 685) {
          document.querySelector(".multiwaypoint-search-scrollbar").setAttribute("style", "height: 138px; transition: 0s;");
          this.multiwpScrollbar.update();
        }
        else if (window.innerHeight < 750) {
          document.querySelector(".multiwaypoint-search-scrollbar").setAttribute("style", "height: 198px; transition: 0s;");
          this.multiwpScrollbar.update();
        }
        else {
          document.querySelector(".multiwaypoint-search-scrollbar").setAttribute("style", "");
          this.multiwpScrollbar.update();
        }
      }
    }
    else {
      document.querySelector(".multiwaypoint-search-scrollbar").setAttribute("style", "");
      this.multiwpScrollbar.update();
    }
  }

  ngOnInit() {
    if (document.URL.includes("demo")) {
      this.replayMode = true;
    }

    // observe when get route is selected
    this.subscriptions.push(this.inputParamsService.ObservableSelectedMode.subscribe((resp) => {
      if (resp == "route") {
        // if the user search for location, but set cursor with context menu
        this.searched = false;
        this.infoScrollbar.update();
      }
      if (resp) {
        this.selectedMode = resp;
        this.cdr.detectChanges();
      }
      if (resp == "rha") {
        this.failedPlan = false;
      }
    }));

    this.subscriptions.push(this.inputParamsService.ObservableWaypointsParams.subscribe((resp) => {
      if (resp && this.inputParamsService.getSearchedRangeOrRoute() == false) {
        this.refreshStartText = true;
      }
      if (resp && resp.length == 1) {
        this.routeList = [this.routeList[0]];
        this.inputParamsService.setWaypointRouteList(this.routeList);
        this.noRoute = true;
        this.inputParamsService.ObservableNoRoute.next(true);
      }

      this.checkEmptySearchInput();
    }));

    // observe mobile resolution
    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((resp) => {
      this.mobileVisiblePanel = resp;
      if ((window.innerWidth < 1050 || window.innerHeight < 700) && resp != undefined) {
        this.menuSelected = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisibleRoutePanel.subscribe((resp) => {
      if (resp) {
        this.mobileVisibleRoutePanel = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileResolution.subscribe((resp) => {
      this.mobileResolution = resp;
      this.cdr.detectChanges();
    }));

    // observe route planned
    this.subscriptions.push(this.mapService.ObservablePlannedRouteParams.subscribe((resp) => {
      if (resp) {
        this.searched = false;
        this.infoScrollbar.scrollToTop();
        this.googleUrl = resp.googleUrl;

        // route basic data
        this.routeData = resp.routeData;
        this.routeData.time = this.utilsService.timeConverter(this.routeData.time);
        this.routeData.chargetime = this.utilsService.timeConverter(this.routeData.chargetime);

        // route itinerary
        var waypointIdx = 0;
        this.routeTurnList = [];
        for (let i = 0; i < resp.turns.length; i++) {
          if (resp.turns[i][0] < 12 || resp.turns[i][0] >= 93) {
            if (resp.turns[i][0] == 95) {
              this.routeTurnList.push({
                instruction: this.utilsService.turnList[resp.turns[i][0]].name, icon: "exit_roundabout" + resp.turns[i][2] + ".png", distance: resp.turns[i][3],
                combinedTurnText: null, combinedTurnIcon: null, latlon: resp.route[resp.turns[i][1]]
              });
            } else if (resp.turns[i][0] == 94) {
              // skip "enter roundabout"
            } else {
              this.routeTurnList.push({
                instruction: this.utilsService.turnList[resp.turns[i][0]].name, icon: this.utilsService.turnList[resp.turns[i][0]].icon,
                combinedTurnText: null, combinedTurnIcon: null, distance: resp.turns[i][3], latlon: resp.route[resp.turns[i][1]]
              });
            }
            // left sided roundabouts
            if (resp.turns[i][5] == true && (resp.turns[i][0] == 2 || resp.turns[i][0] == 93 || resp.turns[i][0] == 95)) {
              this.routeTurnList[this.routeTurnList.length - 1].icon = this.routeTurnList[this.routeTurnList.length - 1].icon.substr(0, this.routeTurnList[this.routeTurnList.length - 1].icon.length - 4) + "_eng.png";
            }
            // waypoint indices
            if (resp.turns[i][0] == 103) {
              waypointIdx++;
              this.routeTurnList[this.routeTurnList.length - 1].icon = this.routeTurnList[this.routeTurnList.length - 1].icon.substr(0, this.routeTurnList[this.routeTurnList.length - 1].icon.length - 4) + waypointIdx + ".png";
            }
          }
          else {
            /// COMBINED TURNS
            var actTurn = JSON.parse(JSON.stringify(this.utilsService.turnList[resp.turns[i][0]]));
            var icon = actTurn.id.toLowerCase().split("_and_");
            var combinedFirst = icon[0] + ".png";
            var combinedSecond = icon[1] + ".png";
            var combinedName = actTurn.name.split(" AND ");
            this.routeTurnList.push({
              instruction: combinedName[0] + " AND", icon: combinedFirst,
              combinedTurnText: combinedName[1], combinedTurnIcon: combinedSecond, distance: resp.turns[i][3], latlon: resp.route[resp.turns[i][1]]
            });
          }
        }

        // route charging plan
        this.routeChargingPlan = [];
        for (let i = 0; i < resp.chargeplan.length; i++) {
          var chargeTimeMin: any = Math.round(resp.chargeplan[i][2] / 5) * 5;
          var chargeTimeHour = Math.floor(chargeTimeMin / 60);
          chargeTimeMin -= (60 * chargeTimeHour);
          if (chargeTimeMin.toString().length < 2) {
            chargeTimeMin = "0" + chargeTimeMin.toString();
          }
          var chargetime;
          if (chargeTimeHour > 0) {
            if (chargeTimeMin == "00") {
              chargetime = chargeTimeHour + "H";
            }
            else {
              chargetime = chargeTimeHour + "H" + chargeTimeMin + "MIN";
            }
          }
          else {
            chargetime = chargeTimeMin + "MIN";
          }
          this.routeChargingPlan.push({
            icon: this.utilsService.chargingstationsImg[resp.chargeplan[i][4] - 1][0], name: this.utilsService.chargingstationsImg[resp.chargeplan[i][4] - 1][1], km: (resp.chargeplan[i][3] / 1000),
            chargeTime: chargetime, minPercent: resp.chargeplan[i][0], maxPercent: resp.chargeplan[i][1], latlon: [resp.chargingstations[i][0], resp.chargingstations[i][1]]
          });
        }
        this.cdr.detectChanges();

        this.noRoute = false;
        this.inputParamsService.ObservableNoRoute.next(false);

        // position map after route loaded on the mobile devices
        if (this.mobileResolution) {
          setTimeout(() => {
            this.mapService.ObservablePositionMapToRoute.next(this.inputParamsService.getWaypointsParams());
          }, 100);
        }
      }
    }));

    // observe unit
    this.subscriptions.push(this.mapService.ObservableUnit.subscribe((resp) => {
      if (resp) {
        this.unit = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.navigationService.ObservableChangeNavigation.subscribe((resp) => {
      if (resp && resp == "start") {
        this.navigation = true;
      }
      if (resp && resp == "exit") {
        this.navigation = false;
        this.routeList = [this.routeList[0]];
        this.inputParamsService.setWaypointRouteList(this.routeList);
      }
      if (resp && resp == "arrive") {
        this.navigation = false;
        this.routeList = [this.routeList[0]];
        this.inputParamsService.setWaypointRouteList(this.routeList);
      }
      this.checkEmptySearchInput();
      this.cdr.detectChanges();
    }));

    // highlight the next turn
    this.subscriptions.push(this.navigationService.ObservableNextTurn.subscribe((resp) => {
      if (resp && this.navigation) {
        this.itineraryNextTurnIdx = resp.NextTurnInstruction.TurnIdx;
        this.roadLanesLength = resp.NextTurnInstruction.Roadlanes.length;
        this.cdr.detectChanges();
      }
    }));

    // a failed plan set navigaton inactive
    this.subscriptions.push(this.inputParamsService.ObservableFailedPlan.subscribe((resp) => {
      if (resp != undefined && resp != null) {
        this.failedPlan = resp.failedPlan;
        this.maxReachedWaypoint = resp.maxReachedWaypoint;
        if (this.failedPlan == true) {
          this.changeSelectedMenu('info');
        }
        this.cdr.detectChanges();
      }
    }));

    // a failed plan set navigaton inactive
    this.subscriptions.push(this.inputParamsService.ObservableNoRoute.subscribe((resp) => {
      if (resp != undefined && resp != null) {
        this.noRoute = resp;
        if (this.noRoute == true) {
          this.changeSelectedMenu('info');
          this.failedPlan = false;
        }
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.inputParamsService.ObservableAddEndPoint.subscribe((resp) => {
      if (resp != undefined && resp != null) {
        for (let i = 0; i < this.routeList.length; i++) {
          if (this.routeList[i].lat == 0 && this.routeList[i].lon == 0) {
            this.routeList.splice(i, 1);
            i--;
          }
        }
        this.routeList.push(resp);
        this.inputParamsService.setWaypointRouteList(this.routeList);
        this.checkEmptySearchInput();
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.inputParamsService.ObservableSetStartPointGeocode.subscribe((resp) => {
      if (resp != undefined && resp != null) {
        this.routeList[0] = resp;
        this.inputParamsService.setWaypointRouteList(this.routeList);
        this.checkEmptySearchInput();
      }
    }));
  }

  // changing searchbox text, after context menu action
  ngAfterViewInit(): void {
    this.subscriptions.push(this.inputParamsService.ObservableReverseGeocodedLocations.subscribe((resp) => {
      if (resp) {
        this.routeList = [];
        for (let i = 0; i < resp.length; i++) {
          var address = "";
          if (resp[i].Street) {
            address += resp[i].Street + " ";
          }
          if (resp[i].Street && resp[i].Housenumber) {
            address += resp[i].Housenumber + ", ";
          }
          if (resp[i].City) {
            address += resp[i].City + ", ";
          }
          if (resp[i].County) {
            address += resp[i].County + ", ";
          }
          if (resp[i].Country) {
            address += resp[i].Country;
          }
          if (resp[i].Country == "#country_") {
            address = Math.round(resp[i].LatLon.lat * 1000) / 1000 + " " + Math.round(resp[i].LatLon.lng * 1000) / 1000;
          }
          this.routeList[i] = { display_name: address, lat: resp[i].LatLon.lat, lon: resp[i].LatLon.lng, wp: i };
        }
        this.inputParamsService.setWaypointRouteList(this.routeList);
        this.checkEmptySearchInput();
        this.updateInfoScrollbar();
        this.cdr.detectChanges();
      }
    }));

    if (!device.ios()) {
      this.multiwpScrollbar.scrollable.elementScrolled().subscribe((resp) => {
        this.multiwayScrollFromTop = this.multiwpScrollbar.scrollable.measureScrollOffset('top');
        this.cdr.detectChanges();
      });
    }
    this.updateMultiwaypointScrollbar();
  }

  // set start coord after search
  setStartCoordsParamToSearchedResult() {
    var searchData = this.mapService.getSearchedLocation();
    this.routeList[0] = searchData;
    this.routeList[0].wp = 0;
    var latlon = new L.LatLng(searchData.lat, searchData.lon);
    this.inputParamsService.setSearchedRangeOrRoute(true);
    this.inputParamsService.setStartCoordsParams(latlon);
    this.inputParamsService.setWaypointRouteList(this.routeList);
    this.checkEmptySearchInput();
  }

  // set end coord after search
  setEndCoordsParamToSearchedResult() {
    this.searched = false;
    var searchData = this.mapService.getSearchedLocation();
    this.routeList[1] = searchData;
    this.routeList[1].wp = 1;
    var latlon = new L.LatLng(searchData.lat, searchData.lon);
    this.inputParamsService.setSearchedRangeOrRoute(true);
    var waypoints = JSON.parse(JSON.stringify(this.inputParamsService.getWaypointsParams()));
    waypoints.push(latlon);
    this.inputParamsService.setWaypointParams(waypoints);
    this.inputParamsService.setWaypointRouteList(this.routeList);
    this.checkEmptySearchInput();
  }

  addRoutePoint() {
    if (!this.emptySearchInput && !this.failedPlan) {
      this.routeList.push({
        display_name: "",
        lat: 0,
        lon: 0,
        wp: -1
      });
      this.updateInfoScrollbar();
      this.inputParamsService.setWaypointRouteList(this.routeList);
      this.checkEmptySearchInput();
      this.cdr.detectChanges();
    }
  }

  // drag and drop, drop event
  drop(event) {
    if (event.previousIndex != event.currentIndex) {
      if (this.mobileResolutionService.getMobileVisiblePanel() == 'search') {
        this.mobileResolutionService.setMobileVisiblePanel('');
      }
      moveItemInArray(this.routeList, event.previousIndex, event.currentIndex);

      this.inputParamsService.setSearchedRangeOrRoute(true);
      var newWaypoints = [];
      for (let i = 0; i < this.routeList.length; i++) {
        if (this.routeList[i].lat != 0 && this.routeList[i].lon != 0) {
          newWaypoints.push(new LatLng(this.routeList[i].lat, this.routeList[i].lon));
          this.routeList[i].wp = newWaypoints.length - 1;
        } else {
          this.routeList[i].wp = -1;
        }
      }
      this.inputParamsService.setWaypointParams(newWaypoints);
      this.inputParamsService.setWaypointRouteList(this.routeList);
      this.checkEmptySearchInput();
    }
  }

  // remove a searched point
  deletePoint(i) {
    if (this.mobileResolutionService.getMobileVisiblePanel() == 'search') {
      this.mobileResolutionService.setMobileVisiblePanel('');
    }

    var wp = this.routeList[i].wp;
    this.routeList.splice(i, 1);

    this.updateInfoScrollbar();
    this.inputParamsService.setSearchedRangeOrRoute(true);
    if (wp >= 0) {
      this.inputParamsService.deleteWaypoint(wp);
      for (let i = 0; i < this.routeList.length; i++) {
        if (this.routeList[i].wp > wp) {
          this.routeList[i].wp -= 1;
        }
      }
    }
    this.inputParamsService.setWaypointRouteList(this.routeList);
    this.checkEmptySearchInput();
  }

  setStartCoordsToYourGeolocation() {
    var unshiftWaypoints = (this.routeList[0].lat == 0 && this.routeList[0].lat == 0);
    this.inputParamsService.setStartCoordsToYourGeolocation(unshiftWaypoints).subscribe((resp) => {
      if (resp == "nogps") {
        this.openGpsErrorDialog();
      }
      else {
        var startcoord = this.inputParamsService.getStartCoordsParams();
        this.mapService.getReverseGeocode(startcoord.lat, startcoord.lng).subscribe((reversegeocode) => {
          if (reversegeocode) {
            this.inputParamsService.ObservableSetStartPointGeocode.next({
              display_name: reversegeocode,
              lat: startcoord.lat,
              lon: startcoord.lng,
              wp: 0
            });
            if (unshiftWaypoints) {
              for (let i = 0; i < this.routeList.length; i++) {
                this.routeList[i].wp = i;
              }
            }
            this.inputParamsService.setWaypointRouteList(this.routeList);
            this.checkEmptySearchInput();
          }
        }, () => { });
      }
    });
  }

  // adding searched location to service
  searchChangedHandler(searchData) {
    if (this.mobileResolutionService.getMobileVisiblePanel() == 'search') {
      this.mobileResolutionService.setMobileVisiblePanel('');
    }
    if (this.inputParamsService.getTrackingTimer()) {
      let dialogRef = this.MatDialog.open(ConfirmationDialogComponent, { data: { message: "Do you really want to stop tracking mode?" } });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.mapService.setSearchedLocation(searchData);
          this.searched = true;
          this.inputParamsService.setTrackingTimer(false);
          this.cdr.detectChanges();
        }
      });
    }
    else {
      this.mapService.setSearchedLocation(searchData);
      this.searched = true;
      this.cdr.detectChanges();
    }
  }

  // handle search waypoint changed event
  waypointChangedHandler(searchData, i) {
    if (this.mobileResolutionService.getMobileVisiblePanel() == 'search') {
      this.mobileResolutionService.setMobileVisiblePanel('');
    }
    if (this.inputParamsService.getTrackingTimer()) {
      let dialogRef = this.MatDialog.open(ConfirmationDialogComponent, { data: { message: "Do you really want to stop tracking mode?" } });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.routeList[i] = searchData;
          this.inputParamsService.setTrackingTimer(false);
          this.cdr.detectChanges();
          this.inputParamsService.setSearchedRangeOrRoute(true);
          var newWaypoints = [];
          for (let ix = 0; ix < this.routeList.length; ix++) {
            if (this.routeList[ix].lat != 0 && this.routeList[ix].lon != 0) {
              newWaypoints.push(new LatLng(this.routeList[ix].lat, this.routeList[ix].lon));
              this.routeList[ix].wp = newWaypoints.length - 1;
            }
            else {
              this.routeList[ix].wp = -1;
            }
          }
          this.inputParamsService.setWaypointParams(newWaypoints);
          this.inputParamsService.setWaypointRouteList(this.routeList);
          this.checkEmptySearchInput();
        }
      });
    }
    else {
      this.routeList[i] = searchData;
      this.cdr.detectChanges();
      this.inputParamsService.setSearchedRangeOrRoute(true);
      var newWaypoints = [];
      for (let ix = 0; ix < this.routeList.length; ix++) {
        if (this.routeList[ix].lat != 0 && this.routeList[ix].lon != 0) {
          newWaypoints.push(new LatLng(this.routeList[ix].lat, this.routeList[ix].lon));
          this.routeList[ix].wp = newWaypoints.length - 1;
        }
        else {
          this.routeList[ix].wp = -1;
        }
      }
      this.inputParamsService.setWaypointParams(newWaypoints);
      this.inputParamsService.setWaypointRouteList(this.routeList);
      this.checkEmptySearchInput();
    }
    this.updateInfoScrollbar();
  }

  checkEmptySearchInput() {
    var emptyCount = 0;
    for (let i = 0; i < this.routeList.length; i++) {
      if (this.routeList[i].lat == 0 && this.routeList[i].lon == 0) {
        emptyCount++;
      }
    }

    if (emptyCount == 0) {
      this.emptySearchInput = false;
    }
    else {
      this.emptySearchInput = true;
    }
  }

  // navigate between itinerary and charging plan
  changeSelectedMenu(name: string) {
    this.menuSelected = name;
    this.infoScrollbar.scrollToTop();
    this.cdr.detectChanges();
    /*setTimeout(() => {
      this.infoScrollbar.scrollTo({ top: 600});
    }, 300);*/
  }

  gpxNavigation(event: any) {
    var file = event.target.files[0];
    if (!file) {
      return;
    }
    var reader = new FileReader();
    reader.onload = (e: any) => {
      var contents = e.target.result;
      var parser = new DOMParser();
      var xmlDoc = parser.parseFromString(contents, "application/xml");
      this.navigationService.selectedReplayTrack = xmlDoc;
      this.navigationService.ObservableChangeNavigation.next("start");
      this.navigation = true;
      this.cdr.detectChanges();
    };
    reader.readAsText(file);
  }

  // navigate to location
  openCoordinate(latlon: any) {
    if (!this.navigation) {
      this.mapService.setSelectedCoordinate(latlon);
    }
  }

  showMetricMeter(m: number): boolean {
    if (m < 1000 && this.unit['distance'] == 'metric') {
      return true;
    }
    else {
      return false;
    }
  }
  showMetricKMeter(m: number): boolean {
    if (m >= 1000 && this.unit['distance'] == 'metric') {
      return true;
    }
    else {
      return false;
    }
  }
  showImperialYard(m: number): boolean {
    if (this.utilsService.mToYard(m) < 1760 && this.unit['distance'] == 'imperial') {
      return true;
    }
    else {
      return false;
    }
  }
  showImperialMile(m: number): boolean {
    if (this.utilsService.mToYard(m) >= 1760 && this.unit['distance'] == 'imperial') {
      return true;
    }
    else {
      return false;
    }
  }
  /*******************************************************
   * implmentation of battery element
   * after a meeting we decided to not use this
   *      |
   *      |
   *      |
   *      ˇ
   */
  exampleBatteryData = [
    {
      startDistance: 0,
      endDistance: 71,
      startBattery: 82,
      endBattery: 21
    },
    {
      startDistance: 71,
      endDistance: 101,
      startBattery: 80,
      endBattery: 31
    },
    {
      startDistance: 101,
      endDistance: 148,
      startBattery: 70,
      endBattery: 44
    }
  ];

  drawBatteryChartToCanvas() {
    // define canvas
    var canvas: any = document.getElementById('battery-canvas');
    var ctx = canvas.getContext('2d');
    var batteryWidth = 480;

    // get battery subdivision
    var subdivision = [];
    var distance = this.exampleBatteryData[this.exampleBatteryData.length - 1].endDistance;
    for (let i = 0; i < this.exampleBatteryData.length; i++) {
      subdivision.push((this.exampleBatteryData[i].endDistance - this.exampleBatteryData[i].startDistance) / distance);
    }

    var leftPos = 4;
    ctx.font = '13px Roboto';
    ctx.strokeStyle = "#fff";
    ctx.lineWidth = 2;
    this.roundRect(ctx, 0, 23, 490, 32, 6, false, true);
    ctx.fillStyle = "#fff";
    this.roundRect(ctx, 493, 32, 5, 14, { tl: 0, tr: 6, br: 6, bl: 0 }, true, false);

    // draw battery segments
    for (let i = 0; i < subdivision.length; i++) {
      // add battery gradient rect
      var grd = ctx.createLinearGradient(leftPos, 0, leftPos + subdivision[i] * batteryWidth, 0);
      grd.addColorStop(0, '#81d4fa');
      grd.addColorStop(1, '#3a0614');
      ctx.fillStyle = grd;
      ctx.fillRect(leftPos, 23 + 4, subdivision[i] * batteryWidth, 24);

      // add gradient indicator texts
      ctx.fillStyle = "#000";
      ctx.textAlign = "left";
      ctx.fillText(this.exampleBatteryData[i].startBattery + "%", leftPos + 6, 43);

      ctx.fillStyle = "#fff";
      ctx.textAlign = "right";
      ctx.fillText(this.exampleBatteryData[i].endBattery + "%", leftPos - 6 + subdivision[i] * batteryWidth, 43);

      // add distance indicator texts
      if (i == 0) {
        ctx.textAlign = "left";
      }
      else {
        ctx.textAlign = "center";
      }
      ctx.fillText(this.exampleBatteryData[i].startDistance + " KM", leftPos, 18);

      // set new left position for the next battery segment
      leftPos += subdivision[i] * batteryWidth;
    }
    ctx.textAlign = "right";
    ctx.fillText(this.exampleBatteryData[this.exampleBatteryData.length - 1].endDistance + " KM", leftPos, 18);
  }

  roundRect(ctx, x, y, width, height, radius, fill, stroke) {
    if (typeof stroke == 'undefined') {
      stroke = true;
    }
    if (typeof radius === 'undefined') {
      radius = 5;
    }
    if (typeof radius === 'number') {
      radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
      for (var side in defaultRadius) {
        radius[side] = radius[side] || defaultRadius[side];
      }
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }

  }

  // open error dialog modal
  openGpsErrorDialog(): void {
    let dialogRef = this.MatDialog.open(GpsErrorDialogComponent);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }
}
