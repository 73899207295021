import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { point } from 'leaflet';
import { MapService } from './map.service';
import { BehaviorSubject, range } from 'rxjs';
import { AudioService } from './audio.service';
import { InputParamsService } from './input-params.service';
import { Geolocation } from 'ol';
const INVALID_TARGET = -1;
const REPLAN_TARGET_IDX = -2;
const BASE_REPLAN_TOLERANCE_M = 40;
const NumberMaxValue = 9007199254740991;

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  TargetIdx: number = INVALID_TARGET;
  TargetWithCommandIdx: number = INVALID_TARGET;
  LastTargetWithCommandIdx: number = INVALID_TARGET;
  LastReplanPos = [0, 0, 0];
  ReplanningNow: boolean = false;
  RoutePointMinDist: number = NumberMaxValue;
  LastCommandMinDist: number = NumberMaxValue;
  LastCommandDistToShow: number = 0;
  CommandNowDistance: number = 45;
  Last5Speed = [50, 50, 50, 50, 50];

  NavigationRoute = null;
  MinDist = NumberMaxValue;
  CurrentPoint;
  avg5Speed: number = 51;
  NextTurnInstruction: any;

  public ObservableChangeNavigation: BehaviorSubject<string>;
  public ObservableNextTurn: BehaviorSubject<any>;
  public ObservableShowRoadLane: BehaviorSubject<boolean>;
  NextWaypointIdx: number = 0;
  Waypoints: any = [];
  LastReplanning: any;
  LastReplanTime;

  ClosingInOnTarget: boolean = false;

  selectedReplayTrack: any = null;

  subscriptions: any = [];

  navigation: boolean = false;

  constructor(private utilsService: UtilsService, private mapService: MapService, private audioService: AudioService, private inputParamsService: InputParamsService) {
    this.ObservableChangeNavigation = new BehaviorSubject<string>(null);
    this.ObservableNextTurn = new BehaviorSubject<any>(null);
    this.ObservableShowRoadLane = new BehaviorSubject<boolean>(null);

    this.subscriptions.push(this.ObservableChangeNavigation.subscribe((resp) => {
      if (resp != undefined && resp == "start") {
        this.navigation = true;
      }
      if (resp != undefined && resp == "exit") {
        this.navigation = false;
      }
      if (resp != undefined && resp == "arrive") {
        this.navigation = false;
      }
    })
    );
  }

  private DegreeDiff(gpsData, idx): number {
    var bearing = this.Bearing(gpsData.Location.Latitude, gpsData.Location.Longitude, this.NavigationRoute.RoutePoints[idx].Location.Latitude, this.NavigationRoute.RoutePoints[idx].Location.Longitude);
    var course = gpsData.Location.Course;
    var angle = Math.abs(bearing - course);
    var normAngle = this.NormAngle(angle);
    return normAngle;
  }

  /*private RefreshNavigation(gpsData) {
    if (this.Points.length > 0) {
      this.Last5Speed += gpsData.Location.Speed;

      this.RefreshTarget(gpsData);

      if (this.TargetIdx >= 0 && this.TargetIdx < this.Points.length && this.NextTurnInstruction.RoutePointIdx != NumberMaxValue) {
        var val, unit;
        this.ConvertHorizontalDistanceString(this.NextTurnDistance(gpsData), val, unit);
      }
    }
  }*/

  commandNowSaid: boolean = false;
  command300mSaid: boolean = false;
  command800mSaid: boolean = false;
  commandDistanceSchema: number = 0;
  lastCommandDist: number = NumberMaxValue;

  private ClearSaidCommands() {
    this.commandNowSaid = false;
    this.command300mSaid = false;
    this.command800mSaid = false;
    this.commandDistanceSchema = 0;
    this.lastCommandDist = NumberMaxValue;
  }

  // CURR COMMAND DIST
  private ReadNextCommand(currCommandDist) {

    //var currCommandDist = Math.round(this.utilsService.distanceBetweenCoordinates([Pos.Location.Latitude, Pos.Location.Longitude], [NextTurn.Location.Latitude, NextTurn.Location.Longitude]) / 10) * 10;

    var andCommand: boolean = false;
    var commandNow: boolean = false;
    var sayPureCommand: boolean = false;

    if (this.mapService.getUnit()['speed'] == "metric") {
      if (this.avg5Speed < 60) {
        if (currCommandDist < 300 * 3 / 2) {
          andCommand = true;
          this.commandDistanceSchema = 300;
          this.command800mSaid = true;
          if (currCommandDist < 200) {
            this.command300mSaid = true;
            if (currCommandDist > this.CommandNowDistance * this.SpeedMul()) {
              sayPureCommand = true;
            }
          }
          if (currCommandDist < this.CommandNowDistance * this.SpeedMul()) {
            this.commandNowSaid = true;
            commandNow = true;
          }
        }
      }
      else if (this.avg5Speed < 100) {
        if (currCommandDist < 800 * 3 / 2) {
          andCommand = true;
          this.commandDistanceSchema = 800;
          this.command800mSaid = true;
          if (currCommandDist < 500) {
            this.command300mSaid = true;
            if (currCommandDist > this.CommandNowDistance * this.SpeedMul()) {
              sayPureCommand = true;
            }
          }
          if (currCommandDist < this.CommandNowDistance * this.SpeedMul()) {
            this.commandNowSaid = true;
            commandNow = true;
          }
        }
      }
      else {
        if (currCommandDist < 3000 * 3 / 2) {
          andCommand = true;
          this.commandDistanceSchema = 3000;
          this.command800mSaid = true;
          if (currCommandDist < 1500) {
            this.command300mSaid = true;
            if (currCommandDist > this.CommandNowDistance * this.SpeedMul()) {
              sayPureCommand = true;
            }
          }
          if (currCommandDist < this.CommandNowDistance * this.SpeedMul()) {
            this.commandNowSaid = true;
            commandNow = true;
          }
        }
      }
    }
    else {
      if (this.avg5Speed < 60) {
        if (currCommandDist < this.utilsService.yardToM(300 * 3 / 2)) {
          andCommand = true;
          this.commandDistanceSchema = 300;
          this.command800mSaid = true;
          if (currCommandDist < this.utilsService.yardToM(200)) {
            this.command300mSaid = true;
            if (currCommandDist > this.CommandNowDistance * this.SpeedMul()) {
              sayPureCommand = true;
            }
          }
          if (currCommandDist < this.CommandNowDistance * this.SpeedMul()) {
            this.commandNowSaid = true;
            commandNow = true;
          }
        }
      }
      else if (this.avg5Speed < 100) {
        if (currCommandDist < this.utilsService.yardToM(800 * 3 / 2)) {
          andCommand = true;
          this.commandDistanceSchema = 800;
          this.command800mSaid = true;
          if (currCommandDist < this.utilsService.yardToM(500)) {
            this.command300mSaid = true;
            if (currCommandDist > this.CommandNowDistance * this.SpeedMul()) {
              sayPureCommand = true;
            }
          }
          if (currCommandDist < this.CommandNowDistance * this.SpeedMul()) {
            this.commandNowSaid = true;
            commandNow = true;
          }
        }
      }
      else {
        if (currCommandDist < this.utilsService.mileToM(2 * 3 / 2)) {
          andCommand = true;
          this.commandDistanceSchema = 3000;
          this.command800mSaid = true;
          if (currCommandDist < this.utilsService.mileToM(0.65 * 3 / 2)) {
            this.command300mSaid = true;
            if (currCommandDist > this.CommandNowDistance * this.SpeedMul()) {
              sayPureCommand = true;
            }
          }
          if (currCommandDist < this.CommandNowDistance * this.SpeedMul()) {
            this.commandNowSaid = true;
            commandNow = true;
          }
        }
      }
    }

    if (!sayPureCommand) {
      if (!commandNow) {
        if (andCommand) {
          this.audioService.readCommand(currCommandDist, this.NextTurnInstruction, this.mapService.getUnit());
          this.ObservableShowRoadLane.next(true);
        }
        else {
          this.audioService.readFollowTheRoute(currCommandDist, this.mapService.getUnit());
          //this.ObservableShowRoadLane.next(false);
        }
      }
      else {
        this.audioService.readCommand(0, this.NextTurnInstruction, this.mapService.getUnit());
        this.ObservableShowRoadLane.next(true);
      }
    }
    else {
      this.audioService.readCommand(currCommandDist, this.NextTurnInstruction, this.mapService.getUnit());
      this.ObservableShowRoadLane.next(true);
    }
  }

  private ReadCommands(currCommandDist) {
    //var currCommandDist = Math.round(this.utilsService.distanceBetweenCoordinates([Pos.Location.Latitude, Pos.Location.Longitude], [NextTurn.Location.Latitude, NextTurn.Location.Longitude]) / 10) * 10;

    // check if arrived
    if ((this.NextTurnInstruction && this.NextTurnInstruction.Maneuver.id == "ARRIVE") || this.TargetIdx >= this.NavigationRoute.RoutePoints.length) {
      if ((this.ClosingInOnTarget && this.lastCommandDist < currCommandDist) || currCommandDist < 5) {
        this.audioService.readCommand(0, this.NextTurnInstruction, this.mapService.getUnit());
        this.ObservableShowRoadLane.next(true);
        this.commandNowSaid = true;
        this.ObservableChangeNavigation.next("arrive");

        this.TargetIdx = INVALID_TARGET;
        this.TargetWithCommandIdx = INVALID_TARGET;
        this.LastTargetWithCommandIdx = INVALID_TARGET;
        this.NavigationRoute = [];
        this.NextTurnInstruction = null;

        this.ClosingInOnTarget = false;

        return;
      }
      if (!this.ClosingInOnTarget && currCommandDist < 30) {
        this.ClosingInOnTarget = true;
      }
    }
    else {
      this.ClosingInOnTarget = false;
    }

    if (this.lastCommandDist > currCommandDist) {
      if (currCommandDist < this.CommandNowDistance * this.SpeedMul() && !this.commandNowSaid) {
        if (this.NextTurnInstruction && this.NextTurnInstruction.Maneuver.id != "ARRIVE") {
          this.audioService.readCommand(0, this.NextTurnInstruction, this.mapService.getUnit());
          this.ObservableShowRoadLane.next(true);
          this.commandNowSaid = true;
        }
      }
      else {
        if (this.mapService.getUnit()['distance'] == "metric") {
          if (this.commandDistanceSchema == 0) {
            if (this.avg5Speed < 60) {
              if (currCommandDist < 300 * 3 / 2) {
                this.commandDistanceSchema = 300;
              }
            }
            else if (this.avg5Speed < 100) {
              if (currCommandDist < 800 * 3 / 2) {
                this.commandDistanceSchema = 800;
              }
            }
            else {
              if (currCommandDist < 3000 * 3 / 2) {
                this.commandDistanceSchema = 3000;
              }
            }
          }
          else if (this.commandDistanceSchema == 300) {
            if (currCommandDist > 100 && currCommandDist < 100 + 35 * this.SpeedMul() && !this.command300mSaid) {
              this.audioService.readCommand(100, this.NextTurnInstruction, this.mapService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command300mSaid = true;
            }
            else if (currCommandDist > 300 && currCommandDist < 300 + 35 * this.SpeedMul() && !this.command800mSaid) {
              this.audioService.readCommand(300, this.NextTurnInstruction, this.mapService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command800mSaid = true;
            }
          }
          else if (this.commandDistanceSchema == 800) {
            if (currCommandDist > 300 && currCommandDist < 300 + 35 * this.SpeedMul() && !this.command300mSaid) {
              this.audioService.readCommand(300, this.NextTurnInstruction, this.mapService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command300mSaid = true;
            }
            else if (currCommandDist > 800 && currCommandDist < 800 + 35 * this.SpeedMul() && !this.command800mSaid) {
              this.audioService.readCommand(800, this.NextTurnInstruction, this.mapService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command800mSaid = true;
            }
          }
          else if (this.commandDistanceSchema == 3000) {
            if (currCommandDist > 1000 && currCommandDist < 1000 + 35 * this.SpeedMul() && !this.command300mSaid) {
              this.audioService.readCommand(1000, this.NextTurnInstruction, this.mapService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command300mSaid = true;
            }
            else if (currCommandDist > 3000 && currCommandDist < 3000 + 35 * this.SpeedMul() && !this.command800mSaid) {
              this.audioService.readCommand(3000, this.NextTurnInstruction, this.mapService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command800mSaid = true;
            }
          }
        }
        else {
          if (this.commandDistanceSchema == 0) {
            if (this.avg5Speed < 60) {
              if (currCommandDist < this.utilsService.yardToM(300 * 3 / 2)) {
                this.commandDistanceSchema = 300;
              }
            }
            else if (this.avg5Speed < 100) {
              if (currCommandDist < this.utilsService.mileToM(0.5 * 3 / 2)) {
                this.commandDistanceSchema = 800;
              }
            }
            else {
              if (currCommandDist < this.utilsService.mileToM(2 * 3 / 2)) {
                this.commandDistanceSchema = 3000;
              }
            }
          }
          else if (this.commandDistanceSchema == 300) {
            if (currCommandDist > this.utilsService.yardToM(100) && currCommandDist < this.utilsService.yardToM(100) + 35 * this.SpeedMul() && !this.command300mSaid) {
              this.audioService.readCommand(100, this.NextTurnInstruction, this.mapService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command300mSaid = true;
            }
            else if (currCommandDist > this.utilsService.yardToM(300) && currCommandDist < this.utilsService.yardToM(300) + 35 * this.SpeedMul() && !this.command800mSaid) {
              this.audioService.readCommand(300, this.NextTurnInstruction, this.mapService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command800mSaid = true;
            }
          }
          else if (this.commandDistanceSchema == 800) {
            if (currCommandDist > this.utilsService.yardToM(300) && currCommandDist < this.utilsService.yardToM(300) + 35 * this.SpeedMul() && !this.command300mSaid) {
              this.audioService.readCommand(300, this.NextTurnInstruction, this.mapService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command300mSaid = true;
            }
            else if (currCommandDist > this.utilsService.mileToM(0.5) && currCommandDist < this.utilsService.mileToM(0.5) + 35 * this.SpeedMul() && !this.command800mSaid) {
              this.audioService.readCommand(500, this.NextTurnInstruction, this.mapService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command800mSaid = true;
            }
          }
          else if (this.commandDistanceSchema == 3000) {
            if (currCommandDist > this.utilsService.mileToM(1) && currCommandDist < this.utilsService.mileToM(1) + 35 * this.SpeedMul() && !this.command300mSaid) {
              this.audioService.readCommand(1000, this.NextTurnInstruction, this.mapService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command300mSaid = true;
            }
            else if (currCommandDist > this.utilsService.mileToM(2) && currCommandDist < this.utilsService.mileToM(2) + 35 * this.SpeedMul() && !this.command800mSaid) {
              this.audioService.readCommand(2000, this.NextTurnInstruction, this.mapService.getUnit());
              this.ObservableShowRoadLane.next(true);
              this.command800mSaid = true;
            }
          }
        }
      }
    }

    this.lastCommandDist = currCommandDist;
  }

  FindNextTargetWidthCommand() {
    var LastTargetWithCommandIdx = this.TargetWithCommandIdx;
    this.TargetWithCommandIdx = this.TargetIdx;

    for (let i = 0; i < this.NavigationRoute.Turns.length; i++) {
      if (this.NavigationRoute.Turns[i].RoutePointIdx >= this.TargetWithCommandIdx) {
        this.TargetWithCommandIdx = this.NavigationRoute.Turns[i].RoutePointIdx;
        this.NextTurnInstruction = this.NavigationRoute.Turns[i];
        break;
      }
    }

    if (LastTargetWithCommandIdx != this.TargetWithCommandIdx) {
      this.ObservableShowRoadLane.next(false);
    }
  }

  RefreshTarget(geoPosition) {
    if (!this.navigation || !this.NavigationRoute.RoutePoints) {
      return;
    }

    this.ObserveWaypoints(geoPosition); // @todo Instead of this, we should check if waypoint is reached in FindNextTargetWidthCommand() and increment reached NextWaypointIdx

    // set actual location, calculating lastavgerage speed, distance from routeline, replanningtolerance
    this.CurrentPoint = geoPosition;
    this.avg5Speed = this.getLastAverageSpeed(geoPosition);
    var distFromRouteLine: number = this.RoadCurrSegmentAndPosMinDist(geoPosition, true);
    let replanningTolerance = 0.0003;

    // replaning
    if (this.TargetIdx == REPLAN_TARGET_IDX) {
      if (distFromRouteLine > replanningTolerance && this.LastReplanningEarlier(geoPosition.Location)) {
        this.Replan(geoPosition.Location);
      }
      return;
    }
    // not valid targetidx
    else if (this.TargetIdx == INVALID_TARGET) {
      var minDist: number = NumberMaxValue;
      // find the nearest route point
      for (let i = 0; i < this.NavigationRoute.RoutePoints.length; i++) {
        var dist = this.utilsService.distanceBetweenCoordinates([geoPosition.Location.Latitude, geoPosition.Location.Longitude], [this.NavigationRoute.RoutePoints[i].Location.Latitude, this.NavigationRoute.RoutePoints[i].Location.Longitude]);

        if (dist < minDist) {
          minDist = dist;
          this.TargetIdx = i;
        }
      }

      // havent reached the last coordinate of the route, and the deviation from the direction of travel greater than 140
      if (this.NextTurnInstruction && (this.TargetIdx >= this.NavigationRoute.RoutePoints.length - 2 || this.DegreeDiff(geoPosition, this.TargetIdx + 1) > 140)) {
        /* REPLAN
        if (LastReplanningEarlier(geoPosition.Location)) {
          Replan(true, geoPosition.Location);
        }*/
      }
      else {
        // find the acutal targetidx
        if (this.DegreeDiff(geoPosition, this.TargetIdx) > 90 && this.TargetIdx < this.NavigationRoute.RoutePoints.length - 1 && this.DegreeDiff(geoPosition, this.TargetIdx + 1) < 90) {
          this.TargetIdx++;
        }
        var roadAndPointMinDist: number = this.RoadAndPosMinDist(geoPosition, true);

        this.TargetWithCommandIdx = this.TargetIdx;

        this.FindNextTargetWidthCommand();

        this.ClearSaidCommands();
        this.LastTargetWithCommandIdx = this.TargetWithCommandIdx;
        if (roadAndPointMinDist < 0.00015) {
          this.ReadNextCommand(this.NextTurnDistance(geoPosition));
        }
        this.MinDist = NumberMaxValue;
      }
    }
    // valid idx
    else if (this.TargetIdx < this.NavigationRoute.RoutePoints.length) {
      var currDist = this.utilsService.distanceBetweenCoordinates([geoPosition.Location.Latitude, geoPosition.Location.Longitude], [this.NavigationRoute.RoutePoints[this.TargetIdx].Location.Latitude, this.NavigationRoute.RoutePoints[this.TargetIdx].Location.Longitude]);

      if (this.MinDist > currDist) {
        this.MinDist = currDist;
      }

      if (this.LastTargetWithCommandIdx != this.TargetWithCommandIdx) {
        // remove show road lane parameter
        var distanceCommands = this.utilsService.distanceBetweenCoordinates([this.NavigationRoute.RoutePoints[this.TargetWithCommandIdx].Location.Latitude, this.NavigationRoute.RoutePoints[this.TargetWithCommandIdx].Location.Longitude],
          [this.NavigationRoute.RoutePoints[this.LastTargetWithCommandIdx].Location.Latitude, this.NavigationRoute.RoutePoints[this.LastTargetWithCommandIdx].Location.Longitude]);

        var max: number = distanceCommands - Math.max(150, 50 * this.SpeedMul());
        max = this.Clamp(max, 12.5, 500);

        var distance = this.utilsService.distanceBetweenCoordinates([geoPosition.Location.Latitude, geoPosition.Location.Longitude], [this.NavigationRoute.RoutePoints[this.LastTargetWithCommandIdx].Location.Latitude, this.NavigationRoute.RoutePoints[this.LastTargetWithCommandIdx].Location.Longitude]);
        var distanceNew = this.NextTurnDistance(geoPosition);

        if (distance < this.LastCommandMinDist) {
          this.LastCommandMinDist = distance;
        }


        if (distance > this.Clamp(50 * this.SpeedMul(), 10, max) && distanceNew < this.LastCommandDistToShow && distance > this.LastCommandMinDist + 12.5) {
          this.ClearSaidCommands();
          this.ReadNextCommand(distanceNew);
          this.LastTargetWithCommandIdx = this.TargetWithCommandIdx;
        }
        else {
          //this.ObservableShowRoadLane.next(true);
        }

        this.LastCommandDistToShow = distanceNew;
      }
      // targetidx > routepoints length
      else {
        var currCommandDist = this.NextTurnDistance(geoPosition);
        this.ReadCommands(currCommandDist);
      }
      if (currDist < this.Clamp(geoPosition.Location.Speed * 0.8, 55, 500) && currDist > this.MinDist + 3.5) {
        var targerPods = [this.NavigationRoute.RoutePoints[this.TargetIdx].Location.Latitude, this.NavigationRoute.RoutePoints[this.TargetIdx].Location.Longitude];
        this.TargetIdx++;

        while (this.TargetIdx < this.NavigationRoute.RoutePoints.length && this.utilsService.distanceBetweenCoordinates(targerPods, [this.NavigationRoute.RoutePoints[this.TargetIdx].Location.Latitude, this.NavigationRoute.RoutePoints[this.TargetIdx].Location.Longitude]) < geoPosition.Location.Speed / 3.6) {
          this.TargetIdx++;
        }
        if (this.TargetIdx < this.NavigationRoute.RoutePoints.length) {
          this.MinDist = NumberMaxValue;
          if (this.LastTargetWithCommandIdx == this.TargetWithCommandIdx) {
            this.LastTargetWithCommandIdx = this.TargetWithCommandIdx;
          }
          this.FindNextTargetWidthCommand();

          if (this.LastTargetWithCommandIdx != this.TargetWithCommandIdx) {
            if (this.LastTargetWithCommandIdx == -1) this.LastTargetWithCommandIdx = this.TargetWithCommandIdx;
            this.LastCommandDistToShow = 0;
            this.LastCommandMinDist = NumberMaxValue;
          }

          distFromRouteLine = this.RoadCurrSegmentAndPosMinDist(geoPosition, true);
        }
        else {
          // Arrived
          this.audioService.readCommand(0, this.NextTurnInstruction, this.mapService.getUnit());
          this.ObservableShowRoadLane.next(true);
          this.commandNowSaid = true;
          this.ObservableChangeNavigation.next("arrive");

          this.TargetIdx = INVALID_TARGET;
          this.TargetWithCommandIdx = INVALID_TARGET;
          this.LastTargetWithCommandIdx = INVALID_TARGET;
          this.NavigationRoute = [];
          this.NextTurnInstruction = null;

          return;
        }
      }

      else if (currDist > this.MinDist + this.Clamp(geoPosition.Location.Speed * 0.8, 85, 500)) {
        this.SearchNearestPoint(geoPosition);
      }

      let NextTurnDistance: any = this.NextTurnDistance(geoPosition);
      if (this.NextTurnInstruction && NextTurnDistance != NumberMaxValue && this.TargetIdx != -1) {
        // calculate distance to dest with last routepoints sum + dist to the next routepoint
        var distToDest = 0;
        for (let i = this.TargetIdx + 1; i < this.NavigationRoute.RoutePoints.length; i++) {
          distToDest += this.NavigationRoute.RoutePoints[i].DistanceFromPreviousPoint;
        }
        var currRoutePoint = this.NavigationRoute.RoutePoints[this.Clamp(this.TargetIdx, 0, this.NavigationRoute.RoutePoints.length - 1)];
        var currDist = this.utilsService.distanceBetweenCoordinates([geoPosition.Location.Latitude, geoPosition.Location.Longitude], [currRoutePoint.Location.Latitude, currRoutePoint.Location.Longitude]);
        distToDest += currDist;

        // calculate time to dest
        var timeToDest = 0;
        for (let i = this.TargetIdx + 1; i < this.NavigationRoute.RoutePoints.length; i++) {
          timeToDest += this.NavigationRoute.RoutePoints[i].TimeFromPreviousPoint;
        }
        var timeToNextTarget = 0;
        if (this.NavigationRoute.RoutePoints[this.TargetIdx].EvSpeedLimit != 0) {
          timeToNextTarget = (currDist / 1000) / this.NavigationRoute.RoutePoints[this.TargetIdx].EvSpeedLimit * 60;
        }
        else {
          timeToNextTarget = (currDist / 1000) / this.NavigationRoute.RoutePoints[this.TargetIdx].RouteSpeedLimit * 60;
        }
        timeToDest += timeToNextTarget;

        // calculate distance & time to next waypoint
        var distToWp: any = 0;
        var timeToWp: any = 0;
        var nextWaypointDisplayName = "next waypoint";
        var nextWaypointRouteIdx = this.GetNextWaypointIndexOnRoute();
        if (nextWaypointRouteIdx == this.NavigationRoute.RoutePoints.length - 1) {
          distToWp = false;
          timeToWp = false;
        }
        else {
          for (let i = this.TargetIdx + 1; i < nextWaypointRouteIdx; i++) {
            distToWp += this.NavigationRoute.RoutePoints[i].DistanceFromPreviousPoint;
            timeToWp += this.NavigationRoute.RoutePoints[i].TimeFromPreviousPoint;
          }
          distToWp += currDist;
          timeToWp += timeToNextTarget;
          
          var nextWaypointTurnListIdx = this.GetNextWaypointIndexOnTurnList();
          nextWaypointDisplayName = this.NavigationRoute.Turns[nextWaypointTurnListIdx].DisplayName;
        }

        var distToChargingStation: any = 0;
        var timeToChargingStation: any = 0;
        var nextChargeStationRouteIdx = this.GetNextChargeStationIndexOnRoute();
        if (nextChargeStationRouteIdx == 0) {
          distToChargingStation = false;
          timeToChargingStation = false;
        }
        else {
          for (let i = this.TargetIdx + 1; i < nextChargeStationRouteIdx; i++) {
            distToChargingStation += this.NavigationRoute.RoutePoints[i].DistanceFromPreviousPoint;
            timeToChargingStation += this.NavigationRoute.RoutePoints[i].TimeFromPreviousPoint;
          }
          distToChargingStation += currDist;
          timeToChargingStation += timeToNextTarget;
        }

        this.ObservableNextTurn.next({
          NextTurnInstruction: this.NextTurnInstruction, NextTurnDistance: NextTurnDistance, SpeedLimit: this.NavigationRoute.RoutePoints[this.TargetIdx].EvSpeedLimit,
          DistToDest: Math.round(distToDest), TimeToDest: Math.round(timeToDest), EstimatedBatteryLevel: this.NavigationRoute.RoutePoints[this.TargetIdx].EstimatedBatteryLevel,
          DistToNextWaypoint: distToWp, TimeToNextWaypoint: timeToWp, NextWaypointDisplayName: nextWaypointDisplayName,
          DistToNextChargingStation: distToChargingStation, TimeToNextChargingStation: timeToChargingStation
        });
      }
    }

    if (distFromRouteLine > replanningTolerance && this.LastReplanningEarlier(geoPosition.Location)) {
      this.lastDeletedIdx = -1;
      this.Replan(geoPosition.Location);
    }

    // set the next turn
    if (this.TargetIdx > 0 && this.TargetIdx < this.NavigationRoute.RoutePoints.length) {
      this.mapService.setNextTurn([this.NavigationRoute.RoutePoints[this.TargetIdx]]);
    }
  }

  private ObserveWaypoints(geoPosition) {
    if (this.NextWaypointIdx < this.Waypoints.length
      && this.utilsService.distanceBetweenCoordinates([geoPosition.Location.Latitude, geoPosition.Location.Longitude], [this.Waypoints[this.NextWaypointIdx].lat, this.Waypoints[this.NextWaypointIdx].lng]) < 150) {
      this.NextWaypointIdx++;
    }
  }

  public SkipNextWaypoint(){
    this.NextWaypointIdx++;
    this.Replan(this.CurrentPoint);
  }

  lastDeletedIdx = -1;

  private getLastAverageSpeed(geoPosition: any): number {
    if (this.Last5Speed == null) {
      this.Last5Speed = [geoPosition.Location.Speed, geoPosition.Location.Speed, geoPosition.Location.Speed, geoPosition.Location.Speed, geoPosition.Location.Speed];
    }
    else {
      this.Last5Speed.shift();
      this.Last5Speed.push(geoPosition.Location.Speed)
    }
    var last5speedSum = 0;
    for (let i = 0; i < this.Last5Speed.length; i++) {
      last5speedSum += this.Last5Speed[i];
    }
    return last5speedSum / this.Last5Speed.length;
  }

  private SearchNearestPointNoParam() {
    this.TargetIdx = -1;
    this.TargetWithCommandIdx = -1;
    this.LastTargetWithCommandIdx = -1;
    this.ObservableShowRoadLane.next(false);
    if (this.CurrentPoint != null) {
      this.SearchNearestPoint(this.CurrentPoint);
    }
  }

  private SearchNearestPoint(geoPosition) {
    this.TargetIdx = -1;
    this.TargetWithCommandIdx = -1;
    this.LastTargetWithCommandIdx = -1;
    this.ObservableShowRoadLane.next(false);
    this.RefreshTarget(geoPosition);
  }

  StartNavigation(Points) {
    this.StopNavigation();
    this.NavigationRoute = Points;
    this.NextWaypointIdx = 0;
    this.Waypoints = JSON.parse(JSON.stringify(this.inputParamsService.getWaypointsParams()));
    this.Waypoints.shift();
    this.Waypoints.pop();
    this.ClosingInOnTarget = false;
    this.ClearSaidCommands();

    this.RefreshTarget({
      Location: {
        Latitude: this.inputParamsService.getStartCoordsParams().lat,
        Longitude: this.inputParamsService.getStartCoordsParams().lng,
        Speed: 0,
        HorizontalAccuracy: 3,
        Course: this.inputParamsService.getBearing()
      }
    });
  }

  StopNavigation() {
    this.TargetIdx = INVALID_TARGET;
    this.TargetWithCommandIdx = INVALID_TARGET;
    this.LastTargetWithCommandIdx = INVALID_TARGET;
    this.ObservableShowRoadLane.next(false);
    this.NavigationRoute = null;
    this.ClosingInOnTarget = false;
  }

  NextTurnDistance(gpsData) {
    if (this.NavigationRoute.RoutePoints && this.TargetIdx < this.NavigationRoute.RoutePoints.length && this.TargetIdx != INVALID_TARGET && this.NavigationRoute.RoutePoints.length > this.TargetWithCommandIdx) {
      var distance = this.utilsService.distanceBetweenCoordinates([gpsData.Location.Latitude, gpsData.Location.Longitude], [this.NavigationRoute.RoutePoints[this.TargetIdx].Location.Latitude, this.NavigationRoute.RoutePoints[this.TargetIdx].Location.Longitude]);
      for (let i = this.TargetIdx; i < this.TargetWithCommandIdx; i++) {
        distance += this.utilsService.distanceBetweenCoordinates([this.NavigationRoute.RoutePoints[i].Location.Latitude, this.NavigationRoute.RoutePoints[i].Location.Longitude], [this.NavigationRoute.RoutePoints[i + 1].Location.Latitude, this.NavigationRoute.RoutePoints[i + 1].Location.Longitude]);
      }
      return distance;
    }
    return NumberMaxValue;
  }

  private SpeedMul() {
    return this.Clamp(this.avg5Speed / 35, 1, 5);
  }

  private Replan(loc) {
    if (!this.ReplanningNow) {
      var waypointsCopy = JSON.parse(JSON.stringify(this.inputParamsService.getWaypointsParams()));
      waypointsCopy.splice(0, this.NextWaypointIdx);
      this.inputParamsService.setWaypointParams(waypointsCopy);

      if (this.NavigationRoute && this.NavigationRoute.RoutePoints && this.NavigationRoute.RoutePoints.length > 0) {
        this.inputParamsService.setBattery(this.NavigationRoute.RoutePoints[this.TargetIdx >= 0 ? this.TargetIdx : 0].EstimatedBatteryLevel);
      }
      this.LastReplanPos = [loc.Latitude, loc.Longitude];
      this.ReplanningNow = true;
      this.audioService.mergeAndPlayAudio(["assets/turn/audio/en-US_Kendra/Commands/Replan.mp3"]);
      this.inputParamsService.setBearing(loc.Course);

      this.NextWaypointIdx = 0;
      this.TargetIdx = INVALID_TARGET;
      this.TargetWithCommandIdx = INVALID_TARGET;
      this.LastTargetWithCommandIdx = INVALID_TARGET;
      this.NavigationRoute = [];
      this.NextTurnInstruction = null;

      this.ObservableChangeNavigation.next("replan");
    }
    this.ReplanningNow = false;
  }

  private LastReplanningEarlier(loc) {
    var dif = Date.now() - new Date(this.LastReplanTime).getTime();
    var Seconds_from_T1_to_T2 = dif / 1000;
    var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);

    return Seconds_Between_Dates > 5;
  }

  private RoadCurrSegmentAndPosMinDist(geoPosition, onlyBeforeWaypoint: boolean) {
    if (this.TargetWithCommandIdx != -1 && this.TargetWithCommandIdx > 0) {
      var minDist = NumberMaxValue;
      var segment = 0;
      let maxPtIndex = onlyBeforeWaypoint ? (this.GetNextWaypointIndexOnRoute() - 1) : (this.TargetWithCommandIdx - 1);

      for (let i = maxPtIndex; i >= 0; i--) {
        var dist = this.LineDist(this.NavigationRoute.RoutePoints[i].Location.Longitude * Math.cos(this.utilsService.toRad(this.NavigationRoute.RoutePoints[i].Location.Latitude)),
          this.NavigationRoute.RoutePoints[i].Location.Latitude,
          this.NavigationRoute.RoutePoints[i + 1].Location.Longitude * Math.cos(this.utilsService.toRad(this.NavigationRoute.RoutePoints[i + 1].Location.Latitude)),
          this.NavigationRoute.RoutePoints[i + 1].Location.Latitude,
          geoPosition.Location.Longitude * Math.cos(this.utilsService.toRad(geoPosition.Location.Latitude)),
          geoPosition.Location.Latitude);
        if (dist < minDist) {
          minDist = dist;
          segment = i;
        }
      }
      return minDist;
    }
    else {
      return this.RoadAndPosMinDist(geoPosition, onlyBeforeWaypoint);
    }
  }

  private RoadAndPosMinDist(geoPosition, onlyBeforeWaypoint: boolean) {
    var minDist = NumberMaxValue;
    var segment = 0;
    let maxPtIndex = onlyBeforeWaypoint ? (this.GetNextWaypointIndexOnRoute() - 1) : (this.NavigationRoute.RoutePoints.length - 1);
    for (let i = 0; i < maxPtIndex; i++) {
      var dist = this.LineDist(this.NavigationRoute.RoutePoints[i].Location.Longitude * Math.cos(this.utilsService.toRad(this.NavigationRoute.RoutePoints[i].Location.Latitude)),
        this.NavigationRoute.RoutePoints[i].Location.Latitude,
        this.NavigationRoute.RoutePoints[i + 1].Location.Longitude * Math.cos(this.utilsService.toRad(this.NavigationRoute.RoutePoints[i + 1].Location.Latitude)),
        this.NavigationRoute.RoutePoints[i + 1].Location.Latitude,
        geoPosition.Location.Longitude * Math.cos(this.utilsService.toRad(geoPosition.Location.Latitude)),
        geoPosition.Location.Latitude);
      if (dist < minDist) {
        minDist = dist;
        segment = i;
      }
    }
    return minDist;
  }

  public RoadAndPosMinDistCoords(geoPosition) {
    var minDist = NumberMaxValue;
    var segment = 0;
    for (let i = 0; i < this.NavigationRoute.RoutePoints.length - 2; i++) {
      var dist = this.LineDist(this.NavigationRoute.RoutePoints[i].Location.Longitude * Math.cos(this.utilsService.toRad(this.NavigationRoute.RoutePoints[i].Location.Latitude)),
        this.NavigationRoute.RoutePoints[i].Location.Latitude,
        this.NavigationRoute.RoutePoints[i + 1].Location.Longitude * Math.cos(this.utilsService.toRad(this.NavigationRoute.RoutePoints[i + 1].Location.Latitude)),
        this.NavigationRoute.RoutePoints[i + 1].Location.Latitude,
        geoPosition.Location.Longitude * Math.cos(this.utilsService.toRad(geoPosition.Location.Latitude)),
        geoPosition.Location.Latitude);
      if (dist < minDist) {
        minDist = dist;
        segment = i;
      }
    }
    return [this.NavigationRoute.RoutePoints[segment].Location.Latitude, this.NavigationRoute.RoutePoints[segment].Location.Longitude];
  }

  public GetClosestPoint(geoPosition) {
    if (this.TargetIdx > 0 && this.TargetIdx < this.NavigationRoute.RoutePoints.length - 2) {
      var iMin = 0;
      var iMax = 1;
      if (this.TargetIdx > 1) {
        iMin = -1;
      }

      var minDist = NumberMaxValue;
      var resp = null;
      for (let i = iMin; i <= iMax; i++) {
        var lastTarget = [this.NavigationRoute.RoutePoints[this.TargetIdx + i - 1].Location.Latitude, this.NavigationRoute.RoutePoints[this.TargetIdx + i - 1].Location.Longitude];
        var actTarget = [this.NavigationRoute.RoutePoints[this.TargetIdx + i].Location.Latitude, this.NavigationRoute.RoutePoints[this.TargetIdx + i].Location.Longitude];
        var distAndPoint: any = this.LineDistAndPoint(lastTarget[0], lastTarget[1], actTarget[0], actTarget[1], geoPosition[0], geoPosition[1]);
        if (distAndPoint[0] < minDist) {
          minDist = distAndPoint[0];
          resp = [distAndPoint[1], i];
        }
      }
      if (this.utilsService.distanceBetweenCoordinates(geoPosition, resp[0]) > 50) {
        return [geoPosition, -2];
      }
      else {
        return resp;
      }
    }
    else {
      return [geoPosition, -2];
    }
  }

  private GetNextWaypointIndexOnRoute() {
    var passedWaypointsCount = this.NextWaypointIdx;
    /*for (let i = 0; i < this.ActiveWaypoints.length; i++) {
      passedWaypointsCount += ((this.ActiveWaypoints[i] == false) ? 1 : 0);
    }*/

    if (this.NavigationRoute && this.NavigationRoute.Turns) {
      for (let i = 0; i < this.NavigationRoute.Turns.length - 1; i++) {
        if (this.NavigationRoute.Turns[i].Maneuver.id == "ARRIVE_TO_WAYPOINT") {
          if ((--passedWaypointsCount) < 0) {
            return this.NavigationRoute.Turns[i].RoutePointIdx;
          }
        }
      }
    }

    if (this.NavigationRoute) {
      return this.NavigationRoute.RoutePoints.length - 1;
    } else {
      return 0;
    }
  }

  private GetNextWaypointIndexOnTurnList() {
    var passedWaypointsCount = this.NextWaypointIdx;
    /*for (let i = 0; i < this.ActiveWaypoints.length; i++) {
      passedWaypointsCount += ((this.ActiveWaypoints[i] == false) ? 1 : 0);
    }*/

    if (this.NavigationRoute && this.NavigationRoute.Turns) {
      for (let i = 0; i < this.NavigationRoute.Turns.length - 1; i++) {
        if (this.NavigationRoute.Turns[i].Maneuver.id == "ARRIVE_TO_WAYPOINT") {
          if ((--passedWaypointsCount) < 0) {
            return i;
          }
        }
      }
    }
    else {
      return null;
    }
  }

  private GetNextChargeStationIndexOnRoute() {
    if (this.NavigationRoute) {
      for (let i = 0; i < this.NavigationRoute.Turns.length; i++) {
        if (this.NavigationRoute.Turns[i].RoutePointIdx >= this.TargetWithCommandIdx) {
          if (this.NavigationRoute.Turns[i].Maneuver.id == "CHARGE") {
            return this.NavigationRoute.Turns[i].RoutePointIdx;
          }
        }
      }
    }
    return 0;
  }

  //calculate navigation angle
  public GetNavigationCursorAngle(index) {
    var lastTarget = [this.NavigationRoute.RoutePoints[this.TargetIdx + index - 1].Location.Latitude, this.NavigationRoute.RoutePoints[this.TargetIdx + index - 1].Location.Longitude];
    var actTarget = [this.NavigationRoute.RoutePoints[this.TargetIdx + index].Location.Latitude, this.NavigationRoute.RoutePoints[this.TargetIdx + index].Location.Longitude];
    return this.utilsService.angleFromCoordinate(lastTarget[0], lastTarget[1], actTarget[0], actTarget[1]);
  }


  private Clamp(x: number, min: number, max: number) {
    return x < min ? min : (x < max ? x : max);
  }

  private Bearing(lat_1, lon_1, lat_2, lon_2) {
    var degree = Math.atan2(-1 * Math.sin(this.utilsService.toRad(lon_1 - lon_2)) * Math.cos(this.utilsService.toRad(lat_2)),
      Math.cos(this.utilsService.toRad(lat_1)) * Math.sin(this.utilsService.toRad(lat_2)) - Math.sin(this.utilsService.toRad(lat_1)) * Math.cos(this.utilsService.toRad(lat_2)) * Math.cos(this.utilsService.toRad(lon_1 - lon_2))) * 180 / Math.PI;
    degree = this.NormDegree(degree);
    return degree;
  }

  private NormDegree(degree) {
    if (degree < 0) {
      return degree + 360;
    }
    else {
      if (degree >= 360) {
        return degree - 360;
      }
      else {
        return degree;
      }
    }
  }

  private NormAngle(angle) {
    if (angle > 180) {
      angle -= 360;
      angle = Math.abs(angle);
    }
    return angle;
  }

  private LineDist(x1, y1, x2, y2, x, y) {
    var A = x - x1;
    var B = y - y1;
    var C = x2 - x1;
    var D = y2 - y1;

    var dot = A * C + B * D;
    var len_sq = C * C + D * D;
    var param = dot / len_sq;

    var xx, yy;

    if (param < 0) {
      xx = x1;
      yy = y1;
    }
    else if (param > 1) {
      xx = x2;
      yy = y2;
    }
    else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }

    return this.dist(x, y, xx, yy);
  }

  private LineDistAndPoint(x1, y1, x2, y2, x, y) {
    var A = x - x1;
    var B = y - y1;
    var C = x2 - x1;
    var D = y2 - y1;

    var dot = A * C + B * D;
    var len_sq = C * C + D * D;
    var param = dot / len_sq;

    var xx, yy;

    if (param < 0) {
      xx = x1;
      yy = y1;
    }
    else if (param > 1) {
      xx = x2;
      yy = y2;
    }
    else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }

    return [this.dist(x, y, xx, yy), [xx, yy]];
  }

  private distance(lat_1, lon_1, lat_2, lon_2, alt1, alt2) {
    var distance = 0;
    var d_lat = 0;
    var d_lon = 0;
    var a = 0;
    var c = 0;
    d_lat = lat_2 - lat_1;
    d_lon = lon_2 - lon_1;
    a = Math.sin(this.utilsService.toRad(d_lat / 2)) * Math.sin(this.utilsService.toRad(d_lat / 2)) + Math.cos(this.utilsService.toRad(lat_1)) * Math.cos(this.utilsService.toRad(lat_2)) * Math.sin(this.utilsService.toRad(d_lon / 2)) * Math.sin(this.utilsService.toRad(d_lon / 2));
    c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    distance = c * 6366707;
    return distance;
  }

  private dist(x1, y1, x2, y2) {
    return Math.sqrt(((x1 - x2) * (x1 - x2)) + ((y1 - y2) * (y1 - y2)));
  }

  /*private GetCommand(rp) {
    if (rp.Maneuver.id == "ENTER_ROUNDABOUT" ||
      rp.Maneuver.id == "EXIT_ROUNDABOUT") {
      if (rp.ManeuverData) {
        if (rp.Maneuver.id == "EXIT_ROUNDABOUT") {
          files.Add(string.Format(Utils.GetVoiceFile("Commands/Take1Exit.wav"), rp.ManeuverData));
          this.AddToAudioPlayList(this.voiceFolder + "Commands/Take1Exit.mp3");
        }
        else {   // EnterRoundabout
          if (Settings.Instance.VoiceDirectory.StartsWith("es-ES")) {
            files.Add(string.Format(Utils.GetVoiceFile("Commands/AtRoundaboutTake{0}Exit.wav"), rp.ManeuverData));
          }
          else {
            files.Add(Utils.GetVoiceFile("Commands/AtRoundabout.wav"));
            files.Add(string.Format(Utils.GetVoiceFile("Commands/Take{0}Exit.wav"), rp.ManeuverData));
          }
        }
      }
      else {
        files.Add(Utils.GetVoiceFile(CommandFiles[(int)rp.Maneuver]));
      }
    }
    else {
      files.Add(Utils.GetVoiceFile(CommandFiles[(int)rp.Maneuver]));
    }
    return files;
  }*/

  loadGpxAsRoute(xmlRoot) {
    var lastBearing = 0;
    var pointarray = [];
    var lastPoint = null;
    var tracks = xmlRoot.documentElement.getElementsByTagName("trk");
    for (var i = 0; i < tracks.length; i++) {
      var segments = tracks[i].getElementsByTagName("trkseg");
      for (var i = 0; i < segments.length; i++) {
        var trackpoints = segments[i].getElementsByTagName("trkpt");
        for (var i = 0; i < trackpoints.length; i++) {
          var point = { mPosition: null, mDateTime: null, mHeight: null, mBearing: null, mSpeed: null };
          point.mPosition = [
            parseFloat(trackpoints[i].getAttribute("lat")),
            parseFloat(trackpoints[i].getAttribute("lon"))
          ];
          var times = trackpoints[i].getElementsByTagName("time");
          if (times.length > 0) {
            point.mDateTime = new Date(times[0].innerHTML);
          }
          var elevations = trackpoints[i].getElementsByTagName("ele");
          if (elevations.length > 0) {
            point.mHeight = parseFloat(elevations[0].innerHTML);
          }
          var extensions = trackpoints[i].getElementsByTagName("extensions");
          if (extensions.length > 0) {
            var gpss = extensions[0].getElementsByTagName("gte:gps");
            if (gpss.length > 0) {
              point.mSpeed = parseFloat(gpss[0].getAttribute("speed"));
            }
          }
          if (lastPoint != null && ((lastPoint[0] != point.mPosition[0]) && (lastPoint[1] != point.mPosition[1]))) {
            point.mBearing = this.utilsService.angleFromCoordinate(lastPoint[0], lastPoint[1], point.mPosition[0], point.mPosition[1]);
            lastBearing = point.mBearing;
          }
          else {
            point.mBearing = lastBearing;
          }
          lastPoint = point.mPosition;
          pointarray.push(point);
        }
      }
    }
    return pointarray;
  }

  // adding random points between two planpoints for simulating a real life gps situation
  generateFakeGpsData(planPoints) {
    // if a gpx is selected, replay that, instead of the planned route
    if (this.selectedReplayTrack != null) {
      return this.loadGpxAsRoute(this.selectedReplayTrack);
    }

    // consts
    const DGTEARTHRADIUS_M: number = 6366707;
    const DGTEARTHCIRCUMFERENCE_M: number = 2 * DGTEARTHRADIUS_M * Math.PI;
    var pointsWithTimeArray = [];
    var DateTime = new Date();
    var lastBearing = this.utilsService.angleFromCoordinate(planPoints[0][0], planPoints[0][1], planPoints[1][0], planPoints[1][1]);

    for (let i = 1; i < planPoints.length; i++) {
      var BikeSpeedInMeterPerSecond = this.utilsService.KmphToMps(planPoints[i][3]);
      var DistanceInMeter = this.utilsService.distanceBetweenCoordinates([planPoints[i - 1][0], planPoints[i - 1][1]], [planPoints[i][0], planPoints[i][1]]);
      var DistanceInSecond = 0.0;
      var randomNoise = ((Math.PI * 2) / DGTEARTHCIRCUMFERENCE_M) * 5; // 3 meter random in radian

      if (BikeSpeedInMeterPerSecond > 0.0) {
        DistanceInSecond = DistanceInMeter / BikeSpeedInMeterPerSecond;
      }

      var Grade = 0.0;
      if (DistanceInMeter != 0) {
        Grade = 1 / DistanceInMeter;
      }

      let PointWithTime = { mPosition: null, mDateTime: null, mHeight: null, mBearing: null, mSpeed: null };

      if (DistanceInSecond < 1.0) {

        PointWithTime.mPosition = [this.utilsService.toRad(planPoints[i][0]), this.utilsService.toRad(planPoints[i][1])];

        var lonRand = randomNoise * Math.cos(PointWithTime.mPosition[0]);

        PointWithTime.mPosition[1] += this.utilsService.getRandomBetweenCoordinates(-lonRand, lonRand);
        PointWithTime.mPosition[0] += this.utilsService.getRandomBetweenCoordinates(-randomNoise, randomNoise);
        DateTime.setSeconds(DateTime.getSeconds() + DistanceInSecond);
        PointWithTime.mDateTime = DateTime;
        PointWithTime.mHeight = 100;
        PointWithTime.mSpeed = this.avg5Speed;
        if ((planPoints[i - 1][0] != planPoints[i][0]) && (planPoints[i - 1][0] != planPoints[i][0])) {
          PointWithTime.mBearing = this.utilsService.angleFromCoordinate(planPoints[i - 1][0], planPoints[i - 1][1], planPoints[i][0], planPoints[i][1]);
          lastBearing = PointWithTime.mBearing;
        }
        else {
          PointWithTime.mBearing = lastBearing;
        }
        PointWithTime.mPosition[0] = this.utilsService.toDeg(PointWithTime.mPosition[0]);
        PointWithTime.mPosition[1] = this.utilsService.toDeg(PointWithTime.mPosition[1]);
        pointsWithTimeArray.push(PointWithTime);
      }
      else {
        var PosFrom = [this.utilsService.toRad(planPoints[i - 1][0]), this.utilsService.toRad(planPoints[i - 1][1])];

        var brng = this.utilsService.toRad(this.utilsService.angleFromCoordinate(planPoints[i - 1][0], planPoints[i - 1][1], planPoints[i][0], planPoints[i][1]));
        for (let t = 0; t < DistanceInSecond; t += 1) {
          let PointWithTime = { mPosition: null, mDateTime: null, mHeight: null, mBearing: null, mSpeed: null };
          var d = BikeSpeedInMeterPerSecond * t;
          var dR = d / DGTEARTHRADIUS_M;
          var lon1 = PosFrom[1];
          var lat1 = PosFrom[0];

          var cosLat1 = Math.cos(lat1);
          var sinLat1 = Math.sin(lat1);
          var cosDR = Math.cos(dR);
          var sinDR = Math.sin(dR);

          var lonRand = randomNoise * cosLat1;

          let lat2 = Math.asin(sinLat1 * cosDR + cosLat1 * sinDR * Math.cos(brng)) + this.utilsService.getRandomBetweenCoordinates(-randomNoise, randomNoise);
          let lon2 = lon1 + Math.atan2(Math.sin(brng) * sinDR * cosLat1, cosDR - sinLat1 * Math.sin(lat2)) + this.utilsService.getRandomBetweenCoordinates(-lonRand, lonRand);
          var Height = 100 + Grade * d;
          PointWithTime.mPosition = [planPoints[i][0], planPoints[i][1]];
          PointWithTime.mPosition[0] = lat2;
          PointWithTime.mPosition[1] = lon2;
          PointWithTime.mDateTime = DateTime;
          PointWithTime.mHeight = Height;
          DateTime.setSeconds(DateTime.getSeconds() + 1);
          PointWithTime.mSpeed = planPoints[i][3];
          if ((planPoints[i - 1][0] != planPoints[i][0]) && (planPoints[i - 1][0] != planPoints[i][0])) {
            PointWithTime.mBearing = this.utilsService.angleFromCoordinate(planPoints[i - 1][0], planPoints[i - 1][1], planPoints[i][0], planPoints[i][1]);
            lastBearing = PointWithTime.mBearing;
          }
          else {
            PointWithTime.mBearing = lastBearing;
          }
          PointWithTime.mPosition[0] = this.utilsService.toDeg(PointWithTime.mPosition[0]);
          PointWithTime.mPosition[1] = this.utilsService.toDeg(PointWithTime.mPosition[1]);
          pointsWithTimeArray.push(PointWithTime);
        }
      }
    }

    pointsWithTimeArray.push({ mPosition: [planPoints[planPoints.length - 1][0], planPoints[planPoints.length - 1][1]], mDateTime: new Date(), mHeight: 0, mBearing: 0, mSpeed: this.avg5Speed })

    return pointsWithTimeArray;
  }
}
