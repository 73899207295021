import { Component, OnInit, ChangeDetectionStrategy, Input, HostListener } from '@angular/core';
import { tileLayer, latLng, icon, LatLng, marker, polygon, LatLngBounds } from 'leaflet';
import 'leaflet';

import * as martinez from 'martinez-polygon-clipping';
import { MatDialog } from '@angular/material/dialog';
import { CanvasLayer } from 'leaflet-canvas-layer';
import { fromEvent, Observable, of, from, timer } from 'rxjs';
import { AntPath } from 'leaflet-ant-path';
import { MapService } from 'src/app/services/map.service';
import { InputParamsService } from 'src/app/services/input-params.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NavigationService } from 'src/app/services/navigation.service';
import device from 'current-device';
import { switchMap, takeUntil, tap, first, skip } from 'rxjs/operators';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { ToastrService } from 'ngx-toastr';
import { WSATYPE_NOT_FOUND } from 'constants';
import { ConfirmationDialogComponent } from '../modals/confirmation-dialog/confirmation-dialog.component';

declare const L: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MapComponent implements OnInit {
  @HostListener('window:resize') onResize() {
    document.getElementById("leaflet").setAttribute("style", "width: " + window.innerWidth + "px; height: " + window.innerHeight + "px;");
    setTimeout(() => {
      if (this.map) {
        document.getElementById("leaflet").setAttribute("style", "width: " + window.innerWidth + "px; height: " + window.innerHeight + "px;");
        this.map.invalidateSize();
      }
    }, 750);
  }
  map: L.Map;
  lodCurrent: number;
  lodMultiply = [600000, 150000, 35000, 10000, 1800, 500, 200, 100];
  blueToGrey = ["#6FCEF1", "#7bcdea", "#89cce4", "#8dbed0", "#8baebb", "#859ca5", "#748186", "#62696b", "#4c4f50", "#3b3b3c"];
  mapLayer: any;
  polyLayer: any;
  markerLayer: any;
  multipleMarkersLayer: any;
  windLayer: any;
  chargingStationLayer: any;
  chargingStationsTileLayer: any;
  chargingStationsDetailed: boolean = false;
  contextMenuLayer: any;
  searchLayer: any;
  tripLayer: any;
  activeTripLayer: any;
  canvasLayer: any;
  rangePolys:any = [];

  /** temporary */
  tempLayer1: any;
  tempLayer2: any;

  //chargingStationsArray: any;
  chargingStationsInsideBoundingBox: any = [];
  canvasHoverSubscriptions: any = [];
  chargingStationsPixelPoints: any = [];
  bigChargingStations = [];
  multipleMarkers = [];
  lastBoundingBox: L.LatLngBounds = null;
  lastTracklistsBoundingBox: L.LatLngBounds = null;
  isFullscreen: boolean;

  tracks = null;
  geotabLastTrip = [];
  geotabLastActiveTrip = [];
  geotabTripArrows = [];
  pulseInterval: any;
  subscriptions = [];
  tileVer = "2";
  unit;

  // leaflet map options
  mapOptions = {
    zoom: 6,
    center: this.inputParamsService.getStartCoordsParams(),
    zoomControl: false,
    zoomAnimationThreshold: 2,
    renderer: L.canvas(),
    attributionControl: false
  };
  darkMapLayer = tileLayer('https://tiles.gpstuner.net/tile_evdark_en_v2/{z}/{x}/{y}.png', { maxZoom: 18, maxNativeZoom: 18, minZoom: 3 } as TileLayerOptions);
  lightMapLayer = tileLayer('https://tiles.gpstuner.net/tile_evlight_en_v2/{z}/{x}/{y}.png', { maxZoom: 18, maxNativeZoom: 18, minZoom: 3 } as TileLayerOptions);
  darkAdminLayer = L.tileLayer('https://tiles.gpstuner.net/tile_evdark_admin_en_v2/{z}/{x}/{y}.png', { maxZoom: 11, minZoom: 3, zIndex: 100 });
  lightAdminLayer = L.tileLayer('https://tiles.gpstuner.net/tile_evlight_admin_en_v2/{z}/{x}/{y}.png', { maxZoom: 11, minZoom: 3, zIndex: 100 });

  cs0Layer; cs1Layer; cs2Layer; cs3Layer; cs4Layer; cs5Layer; cs6Layer;

  constructor(public matDialog: MatDialog, private mapService: MapService, private inputParamsService: InputParamsService, private utilsService: UtilsService,
    private navigationService: NavigationService, private mobileResolutionService: MobileResolutionService, private toastr: ToastrService) {

    if (document.URL.includes("localhost") || document.URL.includes("staging") || document.URL.includes("192.168.")) {
      this.cs0Layer = tileLayer('https://dev.evnavigation.com/chargingstations/0/{z}_{x}_{y}.png?ver=' + this.tileVer, { maxZoom: 11, maxNativeZoom: 11, minZoom: 3, keepBuffer: 4, noWrap: true } as TileLayerOptions);
      this.cs1Layer = tileLayer('https://dev.evnavigation.com/chargingstations/1/{z}_{x}_{y}.png?ver=' + this.tileVer, { maxZoom: 11, maxNativeZoom: 11, minZoom: 3, keepBuffer: 4, noWrap: true } as TileLayerOptions);
      this.cs2Layer = tileLayer('https://dev.evnavigation.com/chargingstations/2/{z}_{x}_{y}.png?ver=' + this.tileVer, { maxZoom: 11, maxNativeZoom: 11, minZoom: 3, keepBuffer: 4, noWrap: true } as TileLayerOptions);
      this.cs3Layer = tileLayer('https://dev.evnavigation.com/chargingstations/3/{z}_{x}_{y}.png?ver=' + this.tileVer, { maxZoom: 11, maxNativeZoom: 11, minZoom: 3, keepBuffer: 4, noWrap: true } as TileLayerOptions);
      this.cs4Layer = tileLayer('https://dev.evnavigation.com/chargingstations/4/{z}_{x}_{y}.png?ver=' + this.tileVer, { maxZoom: 11, maxNativeZoom: 11, minZoom: 3, keepBuffer: 4, noWrap: true } as TileLayerOptions);
      this.cs5Layer = tileLayer('https://dev.evnavigation.com/chargingstations/5/{z}_{x}_{y}.png?ver=' + this.tileVer, { maxZoom: 11, maxNativeZoom: 11, minZoom: 3, keepBuffer: 4, noWrap: true } as TileLayerOptions);
      this.cs6Layer = tileLayer('https://dev.evnavigation.com/chargingstations/6/{z}_{x}_{y}.png?ver=' + this.tileVer, { maxZoom: 11, maxNativeZoom: 11, minZoom: 3, keepBuffer: 4, noWrap: true } as TileLayerOptions);
    }
    else {
      this.cs0Layer = tileLayer('https://evnavigation.com/chargingstations/0/{z}_{x}_{y}.png?ver=' + this.tileVer, { maxZoom: 11, maxNativeZoom: 11, minZoom: 3, keepBuffer: 4, noWrap: true } as TileLayerOptions);
      this.cs1Layer = tileLayer('https://evnavigation.com/chargingstations/1/{z}_{x}_{y}.png?ver=' + this.tileVer, { maxZoom: 11, maxNativeZoom: 11, minZoom: 3, keepBuffer: 4, noWrap: true } as TileLayerOptions);
      this.cs2Layer = tileLayer('https://evnavigation.com/chargingstations/2/{z}_{x}_{y}.png?ver=' + this.tileVer, { maxZoom: 11, maxNativeZoom: 11, minZoom: 3, keepBuffer: 4, noWrap: true } as TileLayerOptions);
      this.cs3Layer = tileLayer('https://evnavigation.com/chargingstations/3/{z}_{x}_{y}.png?ver=' + this.tileVer, { maxZoom: 11, maxNativeZoom: 11, minZoom: 3, keepBuffer: 4, noWrap: true } as TileLayerOptions);
      this.cs4Layer = tileLayer('https://evnavigation.com/chargingstations/4/{z}_{x}_{y}.png?ver=' + this.tileVer, { maxZoom: 11, maxNativeZoom: 11, minZoom: 3, keepBuffer: 4, noWrap: true } as TileLayerOptions);
      this.cs5Layer = tileLayer('https://evnavigation.com/chargingstations/5/{z}_{x}_{y}.png?ver=' + this.tileVer, { maxZoom: 11, maxNativeZoom: 11, minZoom: 3, keepBuffer: 4, noWrap: true } as TileLayerOptions);
      this.cs6Layer = tileLayer('https://evnavigation.com/chargingstations/6/{z}_{x}_{y}.png?ver=' + this.tileVer, { maxZoom: 11, maxNativeZoom: 11, minZoom: 3, keepBuffer: 4, noWrap: true } as TileLayerOptions);
    }
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    document.getElementById("leaflet").setAttribute("style", "width: " + window.innerWidth + "px; height: " + window.innerHeight + "px;");
    if (navigator.userAgent.includes('FBIOS')) {
      setTimeout(() => {
        document.getElementById("leaflet").setAttribute("style", "width: " + window.innerWidth + "px; height: " + window.innerHeight + "px;");
        this.map.invalidateSize();
      }, 500);
    }
    this.map = L.map('leaflet', this.mapOptions);
    this.map.invalidateSize();
    this.map.createPane("windPane");

    // IOS13  context menu fix
    if (device.ios() || device.iphone() || device.ipad() || device.ipod()) {
      document.body.classList.add("ios13");
      var longPress: boolean = false;
      const leafletMap = document.getElementById('leaflet');
      const touchStart = fromEvent(leafletMap, 'touchstart');
      const touchMove = fromEvent(leafletMap, 'touchmove');
      const touchEnd = fromEvent(leafletMap, 'touchend');
      var pressTimeout;
      var startXY;

      touchStart.subscribe((event: any) => {
        longPress = true;
        startXY = [event.touches[0].clientX, event.touches[0].clientY];
        let latlng = this.map.containerPointToLatLng([event.touches[0].clientX, event.touches[0].clientY]);
        clearTimeout(pressTimeout);
        pressTimeout = setTimeout(() => {
          if (longPress) {
            event.stopImmediatePropagation();
            event.preventDefault();
            event.stopPropagation();
            this.openContextMenu(latlng);
          }
        }, 600);
      });

      touchMove.subscribe((event: any) => {
        if (/ OS 13_/.test(navigator.userAgent)) {
          event.stopImmediatePropagation();
          event.preventDefault();
          event.stopPropagation();
        }
        if (longPress && (Math.abs(event.touches[0].clientX - startXY[0]) + Math.abs(event.touches[0].clientY - startXY[1])) > 50) {
          longPress = false;
        }
      });

      touchEnd.subscribe((event) => {
        longPress = false;
      });
    }

    L.control.scale().addTo(this.map);
    var southWest = L.latLng(-89.98155760646617, -320);
    var northEast = L.latLng(89.99346179538875, 320);
    var bounds = L.latLngBounds(southWest, northEast);
    this.map.setMaxBounds(bounds);
    this.mapService.setMapBounds(this.map.getBounds());
    //this.inputParamsService.setStartCoordsParams(this.map.getCenter());
    this.lodCurrent = this.utilsService.getLodLevel(this.map);
    this.map.options.zoomAnimation == true;

    // define layer group
    this.mapLayer = L.featureGroup().addTo(this.map);
    if (this.mapService.getMapColor()) {
      if (this.mapService.getMapColor() == "light") {
        this.lightMapLayer.addTo(this.mapLayer);
        this.lightAdminLayer.addTo(this.mapLayer);
      }
      else {
        this.darkMapLayer.addTo(this.mapLayer);
        this.darkAdminLayer.addTo(this.mapLayer);
      }
    }
    else {
      // default
      this.darkMapLayer.addTo(this.mapLayer);
      this.darkAdminLayer.addTo(this.mapLayer);
    }
    this.markerLayer = L.featureGroup().addTo(this.map);
    this.multipleMarkersLayer = L.featureGroup().addTo(this.map);
    this.polyLayer = L.featureGroup().addTo(this.map);
    this.tripLayer = L.featureGroup().addTo(this.map);
    this.activeTripLayer = L.featureGroup().addTo(this.map);
    this.tempLayer1 = L.featureGroup().addTo(this.map);
    this.tempLayer2 = L.featureGroup().addTo(this.map);
    this.chargingStationLayer = L.featureGroup().addTo(this.map);
    this.contextMenuLayer = L.featureGroup().addTo(this.map);
    this.searchLayer = L.featureGroup().addTo(this.map);
    this.chargingStationsTileLayer = L.layerGroup().addTo(this.map);
    // canvas layer for wind icons
    this.canvasLayer = new CanvasLayer().delegate(this).addTo(this.map);

    this.map.on('zoomstart', (evt) => {
      var ctx = this.canvasLayer._canvas.getContext('2d');
      ctx.clearRect(0, 0, this.canvasLayer._canvas.width, this.canvasLayer._canvas.height);
    });

    // zoom change subscription, re set lod level, add route and arrows depending on lod level
    this.map.on('zoomend', (evt) => {
      if (this.map.getZoom() <= 6) {
        this.map.getPane('windPane').style.display = 'none';
      }
      else {
        this.map.getPane('windPane').style.display = 'block';
      }
      if (this.map.getZoom() > 11 && !this.chargingStationsDetailed) {
        this.chargingStationsTileLayer.removeFrom(this.map);
        this.chargingStationsDetailed = true;
        /*this.canvasHoverSubscriptions.push(fromEvent(this.map, 'mousemove').subscribe((e) => {
          this.canvasMouseMove(e);
        }));*/
        this.canvasHoverSubscriptions.forEach(element => {
          element.unsubscribe();
        });
        this.canvasHoverSubscriptions.push(fromEvent(this.map, 'click').subscribe((e) => {
          this.canvasMouseMove(e);
        }));
        this.setMultipleMarkers(this.multipleMarkers[0], this.multipleMarkers[1]);
      }
      if (this.map.getZoom() <= 11 && this.chargingStationsDetailed) {
        this.chargingStationLayer.clearLayers();
        this.chargingStationsTileLayer.addTo(this.map);
        this.chargingStationsDetailed = false;
        this.canvasHoverSubscriptions.forEach(element => {
          element.unsubscribe();
        });
        this.chargingStationsInsideBoundingBox = [];
        this.chargingStationsPixelPoints = [];
        this.lastTooltip = null;
        this.setMultipleMarkers(this.multipleMarkers[0], this.multipleMarkers[1]);
      }
      this.getExtendedBoundingBox();

      // lod level changed event
      if (this.lodCurrent != this.utilsService.getLodLevel(this.map)) {
        this.lodCurrent = this.utilsService.getLodLevel(this.map);
        if (this.inputParamsService.getTrackingTimer() == true && this.geotabLastActiveTrip[0] && this.geotabLastActiveTrip[0].length > 0) {
          // reload arrows
          this.drawActiveTripToMap();
        }
      }

      this.mapService.setMapBounds(this.map.getBounds());

      var clampedLod = (this.map.getZoom() < 11 ? 11 : this.map.getZoom());
      var innerOpacity: number = 0.3 * (1.0 - (((clampedLod > 16 ? 16 : clampedLod) - 11) / 5.0));
      for (var polyIdx = 0; polyIdx < this.rangePolys.length; polyIdx++) {
        this.rangePolys[polyIdx].options.fillOpacity = innerOpacity;
      }
    });

    // map area changed subscription
    this.map.on('dragend', (e) => {
      if (this.mapService.chargingStationsArray) {
        if (this.lastBoundingBox == null || !this.lastBoundingBox.contains(this.map.getBounds())) {
          this.getExtendedBoundingBox();
        }
      }
      this.mapService.setMapBounds(this.map.getBounds());
    });

    // context menu subscription
    if (!device.ios() && !device.iphone() && !device.ipad() && !device.ipod()) {
      // context menu subscription
      this.map.on('contextmenu', (e: any) => {
        this.openContextMenu(e.latlng);
      });
    }
    this.subscriptions.push(this.mapService.ObservableMapColor.pipe(skip(1)).subscribe((resp) => {
      if (resp) {
        var zoomlevel = this.map.getZoom();
        this.mapLayer.clearLayers();
        if (resp == "light") {
          this.lightMapLayer.addTo(this.mapLayer);
          this.lightAdminLayer.addTo(this.mapLayer);
          if (this.inputParamsService.getSelectedMode() == "rha") {
            var cursor = document.querySelectorAll("#rhamarker-outer .cursor-marker");
            if (cursor != undefined && cursor.length > 0) {
              cursor[0].setAttribute("src", "assets/cursor_light.png?v=2");
            }
          }
          else {
            var failedPlan = this.inputParamsService.getFailedPlan();
            if (failedPlan && failedPlan.failedPlan) {
              this.drawFailedRouteToMap(failedPlan.maxReachedWaypoint);
            }
            else {
              this.drawRouteToMap(this.lastPlan.planPoints, this.lastPlan.chargingStations, this.lastPlan.chargePlan, this.lastPlan.turns, this.lastPlan.routeSegments);
            }
          }

        }
        else {
          this.darkMapLayer.addTo(this.mapLayer);
          this.darkAdminLayer.addTo(this.mapLayer);
          if (this.inputParamsService.getSelectedMode() == "rha") {
            var cursor = document.querySelectorAll("#rhamarker-outer .cursor-marker");
            if (cursor != undefined && cursor.length > 0) {
              cursor[0].setAttribute("src", "assets/cursor.png?v=2");
            }
          }
          else {
            var failedPlan = this.inputParamsService.getFailedPlan();
            if (failedPlan && failedPlan.failedPlan) {
              this.drawFailedRouteToMap(failedPlan.maxReachedWaypoint);
            }
            else {
              this.drawRouteToMap(this.lastPlan.planPoints, this.lastPlan.chargingStations, this.lastPlan.chargePlan, this.lastPlan.turns, this.lastPlan.routeSegments);
            }
          }
        }
        if (zoomlevel <= 11) {
          this.chargingStationsTileLayer.removeFrom(this.map);
          this.chargingStationsTileLayer.addTo(this.map);
        }
        this.map.setZoom(zoomlevel);
      }
    }));

    // charging stations changed subscription
    this.subscriptions.push(this.mapService.ObservableChargingStations.subscribe((resp) => {
      if (resp != undefined) {
        this.setChargingStationsLayers(resp);
        if (this.chargingStationsDetailed) {
          this.drawChargingStations();
          this.canvasLayer.needRedraw();
        }
      }
    }));

    // searched location subscription
    this.subscriptions.push(this.mapService.ObservableSearchedLocation.subscribe((resp) => {
      if (resp != undefined) {
        this.navigateToLocation(resp);
      }
    }));

    // selected coordinate subscription
    this.subscriptions.push(this.mapService.ObservableSelectedCoordinate.subscribe((resp) => {
      if (resp != undefined) {
        this.map.setView(L.latLng(resp[0], resp[1]), 18);
        this.searchLayer.clearLayers();
        L.marker([resp[0], resp[1]], { icon: L.divIcon({ html: "<div class='pulse'></div>", iconAnchor: [11, 11], iconSize: [22, 22] }) }).addTo(this.searchLayer);
        clearTimeout(this.pulseInterval);
        this.pulseInterval = setTimeout(() => this.searchLayer.clearLayers(), 3000);
      }
    }));

    this.subscriptions.push(this.mapService.ObservableLastTrip.subscribe((resp) => {
      if (resp != undefined && resp.length > 0 && resp != "draw") {
        for (let i = 0; i < resp.length; i++) {
          let indexes = [];
          for (let j = 0; j < resp[i][0].length; j++) {
            indexes.push(j);
          }
          resp[i].push(indexes);
        }
        this.geotabLastTrip = resp;
        this.drawLastTripToMap();

        if (document.URL.length > 1 && document.URL.includes("trike") || document.URL.includes("tori")) {
          this.map.flyTo(new LatLng(resp[0][0][0][0], resp[0][0][0][1]), 12);
        }
        else {
          this.map.flyTo(new LatLng(resp[0][0][resp[0][0].length - 1][0], resp[0][0][resp[0][0].length - 1][1]), 12);
        }
      }
      else if (resp == null) {
        this.tripLayer.clearLayers();
      }
    }));

    this.subscriptions.push(this.mapService.ObservableMoveTo.subscribe((resp) => {
      if (resp) {
        this.map.flyTo(resp, 12, { animate: false });
      }
    }));

    this.subscriptions.push(this.mapService.ObservableLastActiveTrip.subscribe((resp) => {
      if (resp != undefined && resp.length > 0 && resp != "draw") {
        var indexes = [];
        for (let j = 0; j < resp.length; j++) {
          indexes.push(j);
        }
        this.geotabLastActiveTrip = [resp, indexes];
        this.drawActiveTripToMap();
      }
    }));

    this.subscriptions.push(this.inputParamsService.ObservableTrackingTimer.subscribe((resp) => {
      if (resp == false) {
        this.tripLayer.clearLayers();
        this.activeTripLayer.clearLayers();
      }
    }));

    this.subscriptions.push(this.mapService.ObservableCursorColor.subscribe((resp) => {
      if (resp == true) {
        this.setStartMarkerColor(resp);
      }
      if (resp == false) {
        this.setStartMarkerColor(resp);
      }
    }));

    this.subscriptions.push(this.mapService.ObservableUnit.subscribe((resp) => {
      if (resp) {
        this.unit = resp;
        var speedlimits = document.getElementsByClassName("speedlimit-value");
        if (this.unit['speed'] == "metric") {
          for (let i = 0; i < speedlimits.length; i++) {
            var speedVal = parseFloat(speedlimits[i].getAttribute("value"));
            speedlimits[i].innerHTML = speedVal.toString();
          }
        }
        if (this.unit['speed'] == "imperial") {
          for (let i = 0; i < speedlimits.length; i++) {
            var speedVal = parseFloat(speedlimits[i].getAttribute("value"));
            speedVal = Math.round(this.utilsService.kmphToMph(speedVal));
            speedlimits[i].innerHTML = speedVal.toString();
          }
        }
      }
    }));

    this.subscriptions.push(this.mapService.ObservableAddMultipleMarkers.subscribe((resp) => {
      if (resp != null && resp != undefined) {
        if (!this.multipleMarkers) {
          this.setMultipleMarkers(resp[0], resp[1]);
        }
        this.multipleMarkers = resp;
      }
      else {
        this.multipleMarkersLayer.clearLayers();
        this.multipleMarkers = [];
      }
    }));

    this.subscriptions.push(this.mapService.ObservablePositionMapToRoute.subscribe((resp) => {
      if (resp) {
        this.positionMapToRoute(resp);
      }
    }));

    /*this.mapService.ObserableNextTurn.subscribe((resp) => {
      if (resp && resp[0]) {
        this.tempLayer2.clearLayers();
        L.circle([resp[0].Location.Latitude, resp[0].Location.Longitude], { radius: 13, color: "red" }).addTo(this.tempLayer2);
      }
    })*/
  }

  rotateMap(deg: number): void {
    //this.map.panInsideBounds
  }

  openContextMenu(latlng) {
    this.contextMenuLayer.clearLayers();
    var rhaIcon: L.Icon, placeCursorIcon: L.Icon, routeCursorIcon: L.Icon, rhaOffset: L.Point, placeCursorOffset: L.Point, routeCursorOffset: L.Point;

    var placeCursorIcon = icon({
      iconUrl: '../assets/longtap_placecursor.png?v=2',
      iconSize: [50, 50],
      iconAnchor: [70, 100],
      className: 'move-in-right',

    });
    if (this.mapService.getMapColor() == "light") {
      placeCursorIcon.options.iconUrl = '../assets/longtap_placecursor_light.png?v=2'
    }
    placeCursorOffset = new L.Point(-45, -100);
    var routeCursorIcon = icon({
      iconUrl: '../assets/longtap_routetohere.png?v=2',
      iconSize: [50, 50],
      iconAnchor: [-18, 100],
      className: 'move-in-left'
    });
    if (this.mapService.getMapColor() == "light") {
      routeCursorIcon.options.iconUrl = '../assets/longtap_routetohere_light.png?v=2'
    }
    routeCursorOffset = new L.Point(42, -100);

    if (this.inputParamsService.getSelectedMode() == "route") {
      if (this.mapService.getMapColor() == "light") {
        routeCursorIcon.options.iconUrl = '../assets/longtap_addwaypoint_light.png?v=2';
      }
      else {
        routeCursorIcon.options.iconUrl = '../assets/longtap_addwaypoint.png?v=2';
      }

    }

    if (this.mapService.getMapColor() == "light") {
      L.circleMarker(latlng, { radius: 25, color: '#7ed3f3', fillColor: "#fff", fillOpacity: 0.7 }).addTo(this.contextMenuLayer);
    }
    else {
      L.circleMarker(latlng, { radius: 25, color: '#81d4fa' }).addTo(this.contextMenuLayer);
    }

    var mS = L.marker(latlng, { icon: placeCursorIcon, zIndexOffset: 2000 }).addTo(this.contextMenuLayer).on('click', (e: any) => {
      // normalize coords
      var wrappedLatLng = this.map.wrapLatLng(e.latlng);
      if (wrappedLatLng.lat != e.latlng.lat || wrappedLatLng.lng != e.latlng.lng) {
        e.latlng = wrappedLatLng;
        this.map.flyTo(e.latlng, this.map.getZoom(), { animate: false });
      }

      // leave tracking disclaimer
      if (this.inputParamsService.getTrackingTimer()) {
        let dialogRef = this.matDialog.open(ConfirmationDialogComponent, { data: { message: "Do you really want to stop tracking mode?" } });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.inputParamsService.setStartCoordsParams(e.latlng);
            this.inputParamsService.setTrackingTimer(false);
          }
        });
      }
      else {
        this.inputParamsService.setStartCoordsParams(e.latlng);
        this.mapService.getReverseGeocode(e.latlng.lat, e.latlng.lng).subscribe((reversegeocode) => {
          if (reversegeocode) {
            this.inputParamsService.ObservableSetStartPointGeocode.next({
              display_name: reversegeocode,
              lat: e.latlng.lat,
              lon: e.latlng.lng,
              wp: 0
            });
          }
        }, () => { });
      }
    });

    mS._icon.id = "set-start-icon";
    if (!device.ios() && !device.iphone() && !device.ipad() && !device.ipod()) {
      mS.bindTooltip("Set Start Coordinate", { direction: "top", offset: placeCursorOffset, className: "context-tooltip" });
    }

    if (this.inputParamsService.getSelectedMode() == "route") {
      mS._icon.classList.add("inactive");
    }

    var mE = L.marker(latlng, { icon: routeCursorIcon, zIndexOffset: 2000 }).addTo(this.contextMenuLayer).on('click', (e: any) => {
      var wrappedLatLng = this.map.wrapLatLng(e.latlng);
      if (wrappedLatLng.lat != e.latlng.lat || wrappedLatLng.lng != e.latlng.lng) {
        e.latlng = wrappedLatLng;
      }

      this.inputParamsService.ObservableReverseGeocodingLoader.next(true);
      this.mapService.getReverseGeocode(e.latlng.lat, e.latlng.lng).subscribe((reversegeocode) => {
        if (reversegeocode) {
          this.inputParamsService.addEndpoint({
            display_name: reversegeocode,
            lat: e.latlng.lat,
            lon: e.latlng.lng
          });
        }
      }, () => { });

      // remove timer from tracked device
      /*if (this.inputParamsService.getTrackingTimer() == true) {
        this.inputParamsService.setTrackingTimer(false);
      }*/
    });

    mE._icon.id = "set-end-icon";

    if (!device.ios() && !device.iphone() && !device.ipad() && !device.ipod()) {
      mE.bindTooltip("Set End Coordinate", { direction: "top", offset: routeCursorOffset, className: "context-tooltip" });
    }

    if (device.ios() || device.iphone() || device.ipad() || device.ipod()) {
      fromEvent(document, 'touchstart').pipe(first()).subscribe((event) => {
        fromEvent(document, 'click').pipe(first()).subscribe((event) => {
          event.preventDefault();
          this.contextMenuLayer.clearLayers();
        });
      });

    }
    else {
      fromEvent(document, 'click').pipe(first()).subscribe(() => {
        this.contextMenuLayer.clearLayers();
      });
    }

    if (this.inputParamsService.getWaypointsParams().length >= 5) {
      mE._icon.classList.add("inactive");
      this.toastr.info('Waypoint limit reached');
    }
  }

  // Draw RHA center marker to the map
  setMarker(pos: L.LatLng, bearing: number) {
    this.markerLayer.clearLayers();
    this.bigChargingStations = [];
    var rhaCursorHtml = "<span id='rhamarker-outer'><img class='cursor-marker'";
    if (this.mapService.getMapColor() == "light") {
      rhaCursorHtml += "src='assets/cursor_light.png'"
    }
    else {
      rhaCursorHtml += "src='assets/cursor.png?v=2'"
    }
    rhaCursorHtml += "style='transform:rotate(" + bearing + "deg);' /></span>";
    if (this.multipleMarkers.length > 0) {
      rhaCursorHtml = "<div id='rhamarker-outer'><img class='cursor-marker";
      if (this.multipleMarkers[0][this.multipleMarkers[1]].soc <= 10) {
        rhaCursorHtml += " battery-red";
      }
      else {
        rhaCursorHtml += " battery-blue";
      }
      rhaCursorHtml += "' src='assets/cursor.png?v=2' style='transform:rotate(" + this.multipleMarkers[0][this.multipleMarkers[1]].soc + "deg);' />";
      rhaCursorHtml += "<div class='leaflet-tooltip multiple-tooltip'>" + this.multipleMarkers[0][this.multipleMarkers[1]].id + " ";
      if (!this.multipleMarkers[0][this.multipleMarkers[1]].soc) {
        rhaCursorHtml += "<span class='battery-blue'>N/A</span>";
      }
      else if (this.multipleMarkers[0][this.multipleMarkers[1]].soc > 10) {
        rhaCursorHtml += "<span class='battery-blue'>" + this.multipleMarkers[0][this.multipleMarkers[1]].soc + "%</span>";
      }
      else {
        rhaCursorHtml += "<span class='battery-red'>" + this.multipleMarkers[0][this.multipleMarkers[1]].soc + "%</span>";
      }

      rhaCursorHtml += "</div></div>"
    }
    var rhaCursorIcon = L.divIcon({ html: rhaCursorHtml, iconAnchor: [25, 25], iconSize: [50, 50] });
    L.marker(pos, { icon: rhaCursorIcon }).addTo(this.markerLayer);
  }

  // drawing multiple markers on the map with latlon, battery
  setMultipleMarkers(markers: any, selectedMarker) {
    this.multipleMarkersLayer.clearLayers();
    this.bigChargingStations = [];
    if (markers && selectedMarker != null && selectedMarker != undefined) {
      for (let i = 0; i < markers.length; i++) {
        if (i != selectedMarker && this.multipleMarkers[0][i].lat && this.multipleMarkers[0][i].lon) {
          var rhaCursorHtml;
          if (this.map.getZoom() <= 11) {
            rhaCursorHtml = "<div class='rhamarkers-outer'><img class='fleet-cursor";
            if (markers[i].soc <= 10) {
              rhaCursorHtml += " battery-red";
            }
            rhaCursorHtml += "' src='assets/fleet_cursor.png' style='transform:rotate(" + markers[i].bearing + "deg);' /></div>";
          }
          else {
            rhaCursorHtml = "<div class='rhamarkers-outer'><img class='fleet-cursor";
            if (markers[i].soc <= 10) {
              rhaCursorHtml += " battery-red";
            }
            rhaCursorHtml += "' src='assets/fleet_cursor.png' style='transform:rotate(" + markers[i].bearing + "deg);' />";
            rhaCursorHtml += "<div class='leaflet-tooltip arrow-to-left multiple-tooltip'>" + markers[i].id + " ";
            if (markers[i].soc > 10) {
              rhaCursorHtml += "<span class='battery-blue'>" + markers[i].soc + "%</span>";
            }
            else {
              rhaCursorHtml += "<span class='battery-red'>" + markers[i].soc + "%</span>";
            }

            rhaCursorHtml += "</div></div>"
          }
          var rhaCursorIcon = L.divIcon({ html: rhaCursorHtml, iconAnchor: [10.5, 10.5], iconSize: [21, 21] });
          L.marker(new L.LatLng(this.multipleMarkers[0][i].lat, this.multipleMarkers[0][i].lon), { icon: rhaCursorIcon }).addTo(this.multipleMarkersLayer).on('click', (e) => {
            this.mapService.ObservableAddMultipleMarkers.next([this.multipleMarkers[0], i]);
            this.inputParamsService.setStartCoordsBearingParams(this.multipleMarkers[0][i].bearing);
            this.inputParamsService.setStartCoordsParams(new L.LatLng(this.multipleMarkers[0][i].lat, this.multipleMarkers[0][i].lon));
            this.inputParamsService.setBattery(this.multipleMarkers[0][i].soc);
            this.inputParamsService.ObservableSelectedToriVehicle.next(i);
          })
        }
      }
    }
  }

  setStartMarkerColor(colored: boolean) {
    var startMarker = document.getElementById("rhamarker-outer");
    if (startMarker) {
      if (colored) {
        startMarker.setAttribute("style", "");
      }
      else {
        startMarker.setAttribute("style", "-webkit-filter: grayscale(100%); filter: grayscale(100%);")
      }
    }
  }

  // for failed route planning
  setStartEndMarkers(startPos: L.LatLng, endPos: L.LatLng, failedPlan) {
    if (this.mapService.getMapColor() == "light") {
      L.marker(startPos, { interactive: false, icon: this.utilsService.startIconLight }).addTo(this.markerLayer);
      if (failedPlan) {
        L.marker(endPos, { interactive: false, icon: this.utilsService.endIconFailedLight }).addTo(this.markerLayer);
      }
      else {
        L.marker(endPos, { interactive: false, icon: this.utilsService.endIconLight }).addTo(this.markerLayer);
      }
    }
    else {
      L.marker(startPos, { interactive: false, icon: this.utilsService.startIcon }).addTo(this.markerLayer);
      if (failedPlan) {
        L.marker(endPos, { interactive: false, icon: this.utilsService.endIconFailed }).addTo(this.markerLayer);
      }
      else {
        L.marker(endPos, { interactive: false, icon: this.utilsService.endIcon }).addTo(this.markerLayer);
      }
    }
  }

  // Draw RHA polylines to the map
  updatePolys(resp: any, compare: boolean) {
    this.polyLayer.clearLayers();
    this.rangePolys = [];

    var clampedLod = (this.map.getZoom() < 11 ? 11 : this.map.getZoom());
    var innerOpacity: number = 0.3 * (1.0 - (((clampedLod > 16 ? 16 : clampedLod) - 11) / 5.0));

    if (compare) {
      // red poly
      resp.range2.forEach(innerRange => {
        L.polyline(innerRange, { color: "#c71c1c" }).addTo(this.polyLayer);
      });
      // green poly
      resp.range1.forEach(innerRange => {
        L.polyline(innerRange, { color: "#3CE6A7" }).addTo(this.polyLayer);
      });
    }
    else {
      if (document.URL.includes("polyline")) {

        // inner range
        resp.range2.forEach(innerRange => {
          var poly = L.polygon(innerRange, { color: "#3CE6A7", fillOpacity: innerOpacity });
          this.rangePolys.push(poly);
          poly.addTo(this.polyLayer);
        });

        // outer range
        if (resp.range1.length > 0 && resp.range2.length > 0) {
          var outerRange: any = martinez.xor([this.utilsService.closeGeometry(resp.range1)], [this.utilsService.closeGeometry(resp.range2)]);
          L.polygon(outerRange, { color: "#27DB99", fillOpacity: 0.5 }).addTo(this.polyLayer);
        }
        else if (resp.range1.length > 0) {
          resp.range1.forEach(innerRange => {
            var poly = L.polygon(innerRange, { color: "#27DB99", fillOpacity: innerOpacity });
            this.rangePolys.push(poly);
            poly.addTo(this.polyLayer);
          });
        }

        // inner range
        resp.range2.forEach(innerRange => {
          var poly = L.polyline(innerRange, { color: "#00FF00", fillOpacity: innerOpacity });
          this.rangePolys.push(poly);
          poly.addTo(this.polyLayer);
        });

        // outer range
        if (resp.range1.length > 0) {
          resp.range1.forEach(innerRange => {
            L.polyline(innerRange, { color: "#0000FF", fillOpacity: 0.3 }).addTo(this.polyLayer);
          });
        }
      }
      else {
        // inner range
        resp.range2.forEach(innerRange => {
          var poly = L.polygon(innerRange, { color: "#3CE6A7", fillOpacity: innerOpacity });
          this.rangePolys.push(poly);
          poly.addTo(this.polyLayer);
        });

        // outer range
        if (resp.range1.length > 0 && resp.range2.length > 0) {
          var outerRange: any = martinez.xor([this.utilsService.closeGeometry(resp.range1)], [this.utilsService.closeGeometry(resp.range2)]);
          L.polygon(outerRange, { color: "#27DB99", fillOpacity: 0.5 }).addTo(this.polyLayer);
        }
        else if (resp.range1.length > 0) {
          resp.range1.forEach(innerRange => {
            var poly = L.polygon(innerRange, { color: "#27DB99", fillOpacity: innerOpacity });
            this.rangePolys.push(poly);
            poly.addTo(this.polyLayer);
          });
        }
      }


      if (this.inputParamsService.getTrackingTimer()) {
        this.mapService.setLastTrip("draw");
        if (this.inputParamsService.SetMapToBoundingBox == true) {
          this.map.fitBounds(this.polyLayer.getBounds());
        }
      }

      if (this.inputParamsService.getSearchedRangeOrRoute()) {
        this.map.fitBounds(this.polyLayer.getBounds());
        this.inputParamsService.setSearchedRangeOrRoute(false);
      }
    }
  }

  windDatas = [];

  // draw wind svg when actual wind selected
  initWind(resp: any, alpha) {
    this.windDatas = [];
    this.windDatas.push(resp);
    this.windDatas.push(alpha);
    this.canvasLayer.needRedraw();
  }

  /* drawing with canvasLayer */
  onDrawLayer(canvasEl) {
    var ctx = canvasEl.canvas.getContext('2d');
    ctx.clearRect(0, 0, canvasEl.canvas.width, canvasEl.canvas.height);
    // drawing arrows if wind data
    if (this.windDatas.length > 0 && this.map.getZoom() > 5) {
      // init canvas
      ctx.lineWidth = 1.5;
      if (this.mapService.getMapColor() == "light") {
        ctx.fillStyle = 'rgba(130, 130, 130, 1)';
        ctx.strokeStyle = 'rgba(130, 130, 130, 1)';
      }
      else {
        ctx.fillStyle = 'rgba(204, 204, 204, 1)';
        ctx.strokeStyle = 'rgba(204, 204, 204, 1)';
      }

      //loop wind arrows array
      if (this.map.getZoom() > 9) {
        this.windDatas[0].forEach(element => {
          this.drawWindToCanvas(ctx, element, canvasEl);
        });
      }
      else if (this.map.getZoom() > 7) {
        // if wind zoom 8 or 9 draw half wind
        var wRow = 0;
        var wRowVal = this.windDatas[0][0][0][1];
        var colSize = 0;
        for (let i = 0; i < this.windDatas[0].length; i++) {
          if (wRowVal != this.windDatas[0][i][0][1]) {
            colSize = i;
            break;
          }
        }

        for (let i = 0; i < this.windDatas[0].length; i++) {
          if (wRowVal != this.windDatas[0][i][0][1]) {
            wRow++;
            wRowVal = this.windDatas[0][i][0][1];
          }
          if (wRow % 2 == 0 && (colSize % 2 == 0 && i % 2 == 0 || colSize % 2 == 1 && i % colSize % 2 == 0)) {
            this.drawWindToCanvas(ctx, this.windDatas[0][i], canvasEl);
          }
        }
      }
      else {
        // if wind zoom 6 or 7 draw quarter wind
        var wRow = 0;
        var wRowVal = this.windDatas[0][0][0][1];
        var colSize = 0;
        for (let i = 0; i < this.windDatas[0].length; i++) {
          if (wRowVal != this.windDatas[0][i][0][1]) {
            colSize = i;
            break;
          }
        }
        for (let i = 0; i < this.windDatas[0].length; i++) {
          if (wRowVal != this.windDatas[0][i][0][1]) {
            wRow++;
            wRowVal = this.windDatas[0][i][0][1];
          }
          if (wRow % 4 == 0 && (colSize % 2 == 0 && i % 4 == 0 || colSize % 2 == 1 && i % colSize % 4 == 0)) {
            this.drawWindToCanvas(ctx, this.windDatas[0][i], canvasEl);
          }
        }
      }
    }

    if (this.chargingStationsInsideBoundingBox.length > 0 && this.map.getZoom() > 11) {
      this.chargingStationsPixelPoints = [];
      for (let i = 0; i < this.chargingStationsInsideBoundingBox.length; i++) {
        var image = document.getElementById(this.chargingStationsInsideBoundingBox[i].img);
        var centerImg = this.canvasLayer._map.latLngToContainerPoint([this.chargingStationsInsideBoundingBox[i].lat, this.chargingStationsInsideBoundingBox[i].lon]);
        this.chargingStationsPixelPoints.push(centerImg);
        ctx.drawImage(image, centerImg.x - 9, centerImg.y - 9, 18, 18);
      }
    }
  }

  drawWindToCanvas(ctx, element, canvasEl) {
    if (this.windDatas[1]) {
      if (this.mapService.getMapColor() == "light") {
        ctx.fillStyle = 'rgba(130, 130, 130, ' + (element[3] / 255) + ')';
        ctx.strokeStyle = 'rgba(130, 130, 130, ' + (element[3] / 255) + ')';
      }
      else {
        ctx.fillStyle = 'rgba(204, 204, 204, ' + (element[3] / 255) + ')';
        ctx.strokeStyle = 'rgba(204, 204, 204, ' + (element[3] / 255) + ')';
      }

    }

    if (canvasEl.bounds.contains([element[0][0], element[0][1]]) && element[2] != 0 && (!this.windDatas[1] || element[3] != 0)) {
      var arrowCenterPoint = this.canvasLayer._map.latLngToContainerPoint([element[0][0], element[0][1]]);
      ctx.save();

      var wind = Math.round(element[2]);
      if (wind > 50) {
        wind = 50;
      }
      var height = wind / 2 + 15;

      var halfLength = height - 9;
      var dir = this.utilsService.toRad(element[1]);
      var pointStart = this.utilsService.RotatePoint(0, -halfLength, dir);
      pointStart.x += arrowCenterPoint.x;
      pointStart.y += arrowCenterPoint.y;
      var pointEnd = this.utilsService.RotatePoint(0, +halfLength, dir);
      pointEnd.x += arrowCenterPoint.x;
      pointEnd.y += arrowCenterPoint.y;
      var pointArrow1 = this.utilsService.RotatePoint(0, -8, dir + (12 * 3.14159265358979323846 / 180));
      pointArrow1.x += pointEnd.x;
      pointArrow1.y += pointEnd.y;
      var pointArrow2 = this.utilsService.RotatePoint(0, -8, dir - (12 * 3.14159265358979323846 / 180));
      pointArrow2.x += pointEnd.x;
      pointArrow2.y += pointEnd.y;

      ctx.beginPath();
      ctx.moveTo(pointStart.x, pointStart.y);
      ctx.lineTo(pointEnd.x, pointEnd.y);
      ctx.fill();

      ctx.moveTo(pointArrow1.x, pointArrow1.y);
      ctx.lineTo(pointEnd.x, pointEnd.y);
      ctx.lineTo(pointArrow2.x, pointArrow2.y);
      ctx.stroke();
    }
  }

  lastTooltip = null;

  canvasMouseMove(event) {
    this.chargingStationLayer.clearLayers();
    this.lastTooltip = null;
    if (!event.originalEvent.target.parentNode.classList.contains("cs-tooltip") && !event.originalEvent.target.parentNode.classList.contains("plug")) {
      for (let i = 0; i < this.chargingStationsPixelPoints.length; i++) {
        if (this.utilsService.rectContains(event.containerPoint.x, event.containerPoint.y, this.chargingStationsPixelPoints[i].x, this.chargingStationsPixelPoints[i].y, 18, 18)) {
          this.lastTooltip = { x: this.chargingStationsPixelPoints[i].x, y: this.chargingStationsPixelPoints[i].y };
          var height = 32 + (58 * this.chargingStationsInsideBoundingBox[i].plugs.length);
          var widthAnchor; var tooltip = "";
          if (this.map.getSize().x / 2 > this.chargingStationsPixelPoints[i].x) {
            widthAnchor = -20;
            tooltip += "<div class='leaflet-tooltip arrow-to-left cs-tooltip'>";
          }
          else {
            widthAnchor = 20;
            tooltip += "<div class='leaflet-tooltip arrow-to-right cs-tooltip' style='margin-left:-100%'>";
          }
          for (let j = 0; j < this.chargingStationsInsideBoundingBox[i].plugs.length; j++) {
            var chargePower = Math.round(this.chargingStationsInsideBoundingBox[i].plugs[j].power * 10) / 10;
            var chargePowerText = "";
            if ((this.mapService.getSelectedCar().FastChargePower > 0) && (this.mapService.getSelectedCar().FastChargePower < chargePower)) {
              chargePowerText = chargePower + "kW (" + this.mapService.getSelectedCar().FastChargePower + " kW)";
            }
            else {
              chargePowerText = chargePower + " kW";
            }
            if (j == this.chargingStationsInsideBoundingBox[i].plugs.length - 1) {
              tooltip += "<div class='plug last-plug'> <img src='" + this.utilsService.chargingstationsImg[this.chargingStationsInsideBoundingBox[i].plugs[j].type - 1][0]
                + "'/><p>" + this.chargingStationsInsideBoundingBox[i].plugs[j].count + " x " + this.utilsService.chargingstationsImg[this.chargingStationsInsideBoundingBox[i].plugs[j].type - 1][1]
                + " | " + chargePowerText + "</p></div>";
            }
            else {
              tooltip += "<div class='plug'> <img src='" + this.utilsService.chargingstationsImg[this.chargingStationsInsideBoundingBox[i].plugs[j].type - 1][0]
                + "'/><p>" + this.chargingStationsInsideBoundingBox[i].plugs[j].count + " x " + this.utilsService.chargingstationsImg[this.chargingStationsInsideBoundingBox[i].plugs[j].type - 1][1]
                + " | " + chargePowerText + "</p></div>";
            }
          }
          if (this.inputParamsService.getWaypointsParams().length >= 5) {
            tooltip += "<div class='plan-btn inactive'>Plan here</div></div>";
          }
          else {
            tooltip += "<div class='plan-btn'>Plan here</div></div>";
          }
          var divIcon = L.divIcon({ html: tooltip, iconAnchor: [widthAnchor, height / 2], iconSize: 'auto' });
          var marker = L.marker([this.chargingStationsInsideBoundingBox[i].lat, this.chargingStationsInsideBoundingBox[i].lon], { icon: divIcon, zIndexOffset: 1000 }).addTo(this.chargingStationLayer);
          marker._icon.setAttribute("style", marker._icon.style.cssText + "; width: " + marker._icon.children[0].offsetWidth + "px;");
          //var popup = L.popup().setLatLng(new LatLng(this.chargingStationsInsideBoundingBox[i].lat, this.chargingStationsInsideBoundingBox[i].lon)).setContent(tooltip).openOn(this.map).bringToFront();

          if (this.inputParamsService.getWaypointsParams().length >= 5) {
            this.toastr.info('Waypoint limit reached');
          }

          fromEvent(document.getElementsByClassName('plan-btn')[0], 'click').pipe(first()).subscribe((event) => {
            //this.inputParamsService.addEndpoint(new LatLng(this.chargingStationsInsideBoundingBox[i].lat, this.chargingStationsInsideBoundingBox[i].lon));
            this.inputParamsService.ObservableReverseGeocodingLoader.next(true);
            this.mapService.getReverseGeocode(this.chargingStationsInsideBoundingBox[i].lat, this.chargingStationsInsideBoundingBox[i].lon).subscribe((reversegeocode) => {
              if (reversegeocode) {
                this.inputParamsService.addEndpoint({
                  display_name: reversegeocode,
                  lat: this.chargingStationsInsideBoundingBox[i].lat,
                  lon: this.chargingStationsInsideBoundingBox[i].lon
                });
              }
            }, () => { });
          });
          break;
        }
      }

    }
  }

  // init charging stations
  setChargingStations(chargingStationsArray) {
    //this.chargingStationsArray = chargingStationsArray;
    if (this.chargingStationsDetailed) {
      this.drawChargingStations();
    }
  }

  // adding charging stations tile layers to the map
  setChargingStationsLayers(chargingStationsArray) {
    this.chargingStationsTileLayer.clearLayers();
    // layer 0 contains all charging station
    if (chargingStationsArray.every((item) => { return item == true })) {
      this.cs0Layer.addTo(this.chargingStationsTileLayer);
    }
    // add selected layers to map
    else {
      if (chargingStationsArray[2]) {
        this.cs3Layer.addTo(this.chargingStationsTileLayer);
      }
      if (chargingStationsArray[4]) {
        this.cs5Layer.addTo(this.chargingStationsTileLayer);
      }
      if (chargingStationsArray[5]) {
        this.cs6Layer.addTo(this.chargingStationsTileLayer);
      }
      if (chargingStationsArray[3]) {
        this.cs4Layer.addTo(this.chargingStationsTileLayer);
      }
      if (chargingStationsArray[0]) {
        this.cs1Layer.addTo(this.chargingStationsTileLayer);
      }
      if (chargingStationsArray[1]) {
        this.cs2Layer.addTo(this.chargingStationsTileLayer);
      }
    }
  }

  // adding zommed in mode charging station to map
  drawChargingStations() {
    this.chargingStationsInsideBoundingBox = [];
    var selectedCS = this.mapService.getChargingStations();
    this.chargingStationLayer.clearLayers();
    for (let i = 0; i < this.mapService.chargingStationsArray.length; i++) {
      if (this.lastBoundingBox.contains(new L.LatLng(this.mapService.chargingStationsArray[i].lat, this.mapService.chargingStationsArray[i].lon)) && this.mapService.chargingStationsArray[i].types &&
        this.isArrayContainsCS(selectedCS, this.mapService.chargingStationsArray[i].types) &&
        !this.isElementBigIcon(new L.LatLng(this.mapService.chargingStationsArray[i].lat, this.mapService.chargingStationsArray[i].lon))) {

        //recalculate charging station properties, only adding the selected charging stations to it
        var chargingStationEl = JSON.parse(JSON.stringify(this.mapService.chargingStationsArray[i]));

        var selectedPlugs = [];
        for (let j = 0; j < chargingStationEl.plugs.length; j++) {
          if (selectedCS[chargingStationEl.plugs[j].type - 1]) {
            selectedPlugs.push(chargingStationEl.plugs[j]);
          }
        }
        chargingStationEl.plugs = selectedPlugs;
        var maxpower = 0;
        for (let j = 0; j < chargingStationEl.plugs.length; j++) {
          if (chargingStationEl.plugs[j].power > maxpower) {
            maxpower = chargingStationEl.plugs[j].power;
          }
        }
        chargingStationEl.maxpower = maxpower;
        if (chargingStationEl.maxpower > 35) {
          chargingStationEl.img = "cs_big3";
        }
        else if (chargingStationEl.maxpower > 10) {
          chargingStationEl.img = "cs_big2";
        }
        else {
          chargingStationEl.img = "cs_big1";
        }
        if (this.mapService.getMapColor() == "light") {
          chargingStationEl.img += "_light"
        }
        this.chargingStationsInsideBoundingBox.push(chargingStationEl);
      }
    }
  }

  isElementBigIcon(latlon) {
    for (let i = 0; i < this.bigChargingStations.length; i++) {
      if (this.bigChargingStations[i][0] == latlon.lat && this.bigChargingStations[i][1] == latlon.lng) {
        return true;
      }
    }
    return false;
  }

  // check for currently selected charging stations includes a charging station type
  isArrayContainsCS(selectedCS, stationTypes): boolean {
    var contains = false;
    var i = 0;
    while (!contains && i < selectedCS.length) {
      if (selectedCS[i] && stationTypes.includes(i + 1)) {
        contains = true;
      }
      i++;
    }
    return contains;
  }

  drawRoutePlanningAnimationToMap(waypoints) {
    this.polyLayer.clearLayers();
    this.markerLayer.clearLayers();
    this.clearWind();
    var color;
    if (this.mapService.getMapColor()=="light"){
      color = "#059cdb"
    }
    else{
      color = "#81d4fa"
    }
    let antPolyline = new AntPath(waypoints, {
      "delay": 300,
      "weight": 6,
      "color": color,
      "pulseColor": "#FFFFFF",
      "paused": false,
      "reverse": false,
      "hardwareAccelerated": true
    });

    antPolyline.addTo(this.polyLayer);
    this.positionMapToRoute(waypoints)
  }

  // place the route of the center of the map
  positionMapToRoute(waypoints) {
    var bounds: LatLngBounds = L.polygon(waypoints).getBounds();
    var startPoint = bounds.getNorthEast();
    var endPoint = bounds.getSouthWest();
    var largerBoundsSW, largerBoundsNE;
    largerBoundsSW = new L.LatLng(startPoint.lat - (endPoint.lat - startPoint.lat) / 2, startPoint.lng - (endPoint.lng - startPoint.lng) / 2);
    largerBoundsNE = new L.LatLng(endPoint.lat + (endPoint.lat - startPoint.lat) / 2, endPoint.lng + (endPoint.lng - startPoint.lng) / 2);

    /*if (this.mobileResolutionService.getMobileResolution()) {
      largerBoundsSW = new L.LatLng(startPoint.lat - (endPoint.lat - startPoint.lat), startPoint.lng - (endPoint.lng - startPoint.lng));
      largerBoundsNE = new L.LatLng(endPoint.lat + (endPoint.lat - startPoint.lat), endPoint.lng + (endPoint.lng - startPoint.lng));
    }
    else {
 
    }*/
    var largerBounds = new L.LatLngBounds(largerBoundsSW, largerBoundsNE);
    var infoSidebarHeight = document.getElementsByClassName("info-sidebar")[0].clientHeight;
    var infoSidebarWidth = document.getElementsByClassName("info-sidebar")[0].clientWidth;
    var mobileRightPanelHeight = document.getElementsByClassName("mobile-right-panel")[0].clientHeight;
    var mobileRightPanelWidth = document.getElementsByClassName("mobile-right-panel")[0].clientWidth;

    if (this.mobileResolutionService.getMobileResolution()) {
      // tablet, small desktop view
      if (!device.mobile()){
        this.map.fitBounds(bounds,
          {
            paddingTopLeft: [100, infoSidebarHeight + 100],
            paddingBottomRight: [100, mobileRightPanelHeight + 100]
          });
      }
      // portrait
      else if (window.innerHeight > window.innerWidth) {
        this.map.fitBounds(bounds,
          {
            paddingTopLeft: [document.getElementById("mobile-menu-left").classList.contains("closed-mobile-sidebar") ? 50 : mobileRightPanelHeight + 50, infoSidebarHeight + 50],
            paddingBottomRight: [50, mobileRightPanelHeight + 50]
          });
      }
      // landscape
      else {
        this.map.fitBounds(bounds,
          {
            paddingTopLeft: [mobileRightPanelWidth + 50, 50],
            paddingBottomRight: [infoSidebarWidth + 50, document.getElementById("mobile-menu-left").classList.contains("closed-mobile-sidebar") ? 50 : mobileRightPanelWidth + 50]
          });
      }
    }
    else {
      this.map.fitBounds(largerBounds);
    }
  }

  lastPlan;

  clearWind() {
    if (this.canvasLayer._canvas) {
      this.windDatas = [];
      var ctx = this.canvasLayer._canvas.getContext('2d');
      ctx.clearRect(0, 0, this.canvasLayer._canvas.width, this.canvasLayer._canvas.height);
    }
  }

  drawOnePointRouteToMap() {
    this.polyLayer.clearLayers();
    this.markerLayer.clearLayers();
    var startpoint = this.inputParamsService.getWaypointsParams();
    if (this.mapService.getMapColor() == "light") {
      L.marker(new L.LatLng(startpoint[0].lat, startpoint[0].lng), { interactive: false, icon: this.utilsService.startIconLight }).addTo(this.markerLayer);
    }
    else {
      L.marker(new L.LatLng(startpoint[0].lat, startpoint[0].lng), { interactive: false, icon: this.utilsService.startIcon }).addTo(this.markerLayer);
    }
  }

  // draw a failed route waypoints and beeline(s) to the map
  drawFailedRouteToMap(maxReachedWaypoint) {
    this.polyLayer.clearLayers();
    this.markerLayer.clearLayers();

    // set start end markers
    this.setStartEndMarkers(this.inputParamsService.getWaypointsParams()[0], this.inputParamsService.getWaypointsParams()[this.inputParamsService.getWaypointsParams().length - 1], true);

    // darw waypoints
    var waypointsCopy = JSON.parse(JSON.stringify(this.inputParamsService.getWaypointsParams()));
    for (let i = 0; i < waypointsCopy.length - 1; i++) {
      var color;
      if (i < maxReachedWaypoint) {
        color = "#059cdb";
      }
      else {
        color = "#e0315d";
      }
      // draw route beelines
      let antPolyline = new AntPath([waypointsCopy[i], waypointsCopy[i + 1]], {
        "delay": 300,
        "weight": 6,
        "color": color,
        "pulseColor": "#FFFFFF",
        "paused": true,
        "reverse": false,
        "hardwareAccelerated": true
      });
      antPolyline.addTo(this.polyLayer);
    }

    waypointsCopy.shift();
    waypointsCopy.pop();
    for (let i = 0; i < waypointsCopy.length; i++) {
      var waypointsDivIconHtml = "<div class='waypoint-icon-outer'><div class='waypoint-icon'>";
      if (this.mapService.getMapColor() == "light") {
        waypointsDivIconHtml += "<img src='assets/route_point_waypoint_light.svg'></div>";
      }
      else {
        waypointsDivIconHtml += "<img src='assets/route_point_waypoint.svg'></div>";
      }
      waypointsDivIconHtml += "<div class='waypoint-idx'>" + (i + 1) + "</div></div>";
      var divIcon = L.divIcon({ html: waypointsDivIconHtml, iconAnchor: [42, 42], iconSize: [84, 84] });
      L.marker(new L.LatLng(waypointsCopy[i].lat, waypointsCopy[i].lng), { icon: divIcon }).addTo(this.markerLayer);
    }
  }

  // draw a planned route to map
  drawRouteToMap(planPoints, chargingStations, chargePlan, turns, routeSegments) {
    // clear layers
    this.lastPlan = { planPoints: planPoints, chargingStations: chargingStations, chargePlan: chargePlan, turns: turns, routeSegments: routeSegments };
    this.polyLayer.clearLayers();
    this.markerLayer.clearLayers();


    var waypointIdx = 0;
    for (let i = 0; i < turns.length; i++) {
      if (turns[i][0] == 103) {
        // waypoint marker icon
        waypointIdx++;
        var planidx = turns[i][1];
        //L.marker(new L.LatLng(planPoints[planidx][0], planPoints[planidx][1]), { interactive: false, icon: this.utilsService.waypointIcon }).addTo(this.markerLayer);

        var waypointsDivIconHtml = "<div class='waypoint-icon-outer'><div class='waypoint-icon'>";
        if (this.mapService.getMapColor() == "light") {
          waypointsDivIconHtml += "<img src='assets/route_point_waypoint_light.svg'></div>";
        }
        else {
          waypointsDivIconHtml += "<img src='assets/route_point_waypoint.svg'></div>";
        }
        waypointsDivIconHtml += "<div class='waypoint-idx'>" + waypointIdx + "</div></div>";
        var divIcon = L.divIcon({ html: waypointsDivIconHtml, iconAnchor: [42, 42], iconSize: [84, 84] });
        L.marker(new L.LatLng(planPoints[planidx][0], planPoints[planidx][1]), { icon: divIcon }).addTo(this.markerLayer);

      }
    }
    // add start end icon, route polyline
    this.setStartEndMarkers(new L.LatLng(planPoints[0][0], planPoints[0][1]), new L.LatLng(planPoints[planPoints.length - 1][0], planPoints[planPoints.length - 1][1]), false);

    // grey outline - light mode
    if (this.mapService.getMapColor() == "light") {
      for (let i = 1; i < planPoints.length; i++) {
        L.polyline([[planPoints[i - 1][0], planPoints[i - 1][1]], [planPoints[i][0], planPoints[i][1]]], { color: "#ccc", weight: 15 }).addTo(this.polyLayer);
      }
    }

    // white line
    for (let i = 1; i < planPoints.length; i++) {
      L.polyline([[planPoints[i - 1][0], planPoints[i - 1][1]], [planPoints[i][0], planPoints[i][1]]], { color: "#fff", weight: 13 }).addTo(this.polyLayer);
    }

    // colored line
    for (let i = 1; i < planPoints.length; i++) {
      L.polyline([[planPoints[i - 1][0], planPoints[i - 1][1]], [planPoints[i][0], planPoints[i][1]]], { color: this.utilsService.rgbToHex((planPoints[i][2] * 255), (255 - (planPoints[i][2] * 255)), 0), weight: 7 }).addTo(this.polyLayer);
    }

    this.bigChargingStations = [];
    // add charging stations markers and charge times
    for (let i = 0; i < chargingStations.length; i++) {
      // selected charging statinos database
      this.bigChargingStations.push([chargingStations[i][0], chargingStations[i][1]]);

      // chargining station divicon
      var chargingStationDivIconHtml = "<div class='map-charger'>";

      // charge time box
      var chargeTimeMin: any = chargingStations[i][3];
      var chargeTimeHour = Math.floor(chargeTimeMin / 60);
      chargeTimeMin -= (60 * chargeTimeHour);
      if (chargeTimeMin.toString().length < 2) {
        chargeTimeMin = "0" + chargeTimeMin.toString();
      }
      if (chargeTimeHour > 0) {
        if (chargeTimeMin == "00") {
          chargingStationDivIconHtml += "<div class='charge-time'><div class='charge-time-inner'> <h4>+" + chargeTimeHour + "</h4> <small>H</small> <h4></div></div>";
        }
        else {
          chargingStationDivIconHtml += "<div class='charge-time'><div class='charge-time-inner'> <h4>+" + chargeTimeHour + "</h4> <small>H</small> <h4>" + chargeTimeMin + "</h4><small>Min</small></div></div>";
        }
      }
      else {
        chargingStationDivIconHtml += "<div class='charge-time'><div class='charge-time-inner'> <h4>+" + chargeTimeMin + "</h4><small>Min</small></div></div>";
      }

      var iconSvg;
      // charge icon
      if (chargingStations[i][2] > 35) {
        iconSvg = "charging_station_3";
      }
      else if (chargingStations[i][2] > 11) {
        iconSvg = "charging_station_1";
      }
      else {
        iconSvg = "charging_station_2";
      }

      if (this.mapService.getMapColor() == "light") {
        iconSvg += "_light";
      }
      else {
        iconSvg += "_dark";
      }
      chargingStationDivIconHtml += "<div class='charge-icon'><img src='assets/" + iconSvg + ".svg  '/></div><div class='charge-index'><img src='assets/chargingstation_icon.png'/>" + (i + 1) + " </div></div>";

      // tooltip
      var tooltip = "<div class='plug last-plug'> <img src='";
      tooltip += this.utilsService.chargingstationsImg[chargePlan[i][4] - 1][0] + "'/><p>";
      tooltip += this.utilsService.chargingstationsImg[chargePlan[i][4] - 1][1] + " | ";
      tooltip += Math.round(chargingStations[i][2] * 10) / 10 + "kW</p></div>";


      var divIcon = L.divIcon({ html: chargingStationDivIconHtml, iconAnchor: [42, 66], iconSize: [84, 108] });
      L.marker([chargingStations[i][0], chargingStations[i][1]], { icon: divIcon }).addTo(this.markerLayer).bindTooltip(tooltip, { direction: 'top', offset: L.point(0, -58) });
    }

    var chargePercent = Math.round((1 - planPoints[planPoints.length - 1][2]) * 100);
    var remainingBatteryHtml = "<div class='charge-time'><div class='charge-time-inner'> <img class='remained-battery' src='assets/chargingplan_time_battery.png'/><h4>" + chargePercent + "%</h4> <h4></div></div>";
    var divIcon = L.divIcon({ html: remainingBatteryHtml, iconAnchor: [33, 70], iconSize: [66, 21] });
    L.marker([planPoints[planPoints.length - 1][0], planPoints[planPoints.length - 1][1]], { icon: divIcon }).addTo(this.markerLayer);


    for (let i = 0; i < routeSegments.length; i++) {
      if (routeSegments[i][0] < 130 && routeSegments[i][0] > 0) {
        var latlon = new L.LatLng(planPoints[routeSegments[i][3]][0], planPoints[routeSegments[i][3]][1]);
        var speedlimitHtml;
        if (this.unit['speed'] == 'metric') {
          speedlimitHtml = "<div class='speedlimit-outer'><div class='speedlimit'><img class='speedlimit-icon' src='assets/slider_small_button_battery_black.svg'/><p class='speedlimit-value' value=" + routeSegments[i][0] + ">" + routeSegments[i][0] + "</p></div></div>";
        }
        if (this.unit['speed'] == 'imperial') {
          speedlimitHtml = "<div class='speedlimit-outer'><div class='speedlimit'><img class='speedlimit-icon' src='assets/slider_small_button_battery_black.svg'/><p class='speedlimit-value' value=" + routeSegments[i][0] + ">" + Math.round(this.utilsService.kmphToMph(routeSegments[i][0])) + "</p></div></div>";
        }
        var divIcon = L.divIcon({ html: speedlimitHtml, iconAnchor: [20, 20], iconSize: [40, 40] });
        L.marker(latlon, { icon: divIcon }).addTo(this.markerLayer);
      }

    }

    if (this.inputParamsService.getSearchedRangeOrRoute()) {
      this.inputParamsService.setSearchedRangeOrRoute(false);
    }
    //this.replay(planPoints, turns);
  }

  // draw geotap track from tracking mode
  // old code
  /*L.polyline(this.geotabLastTrip[i][0], { color: "#fff", weight: 14 }).addTo(this.tripLayer);
  L.polyline(this.geotabLastTrip[i][0], { color: this.blueToGrey[this.geotabLastTrip[i][1]], weight: 8 }).addTo(this.tripLayer);*/
  /*for (let k = 1; k < this.geotabLastTrip[i][0].length; k++) {
    L.polyline([this.geotabLastTrip[i][0][k - 1], this.geotabLastTrip[i][0][k]], { color: this.utilsService.rgbToHex((this.geotabLastTrip[i][0][k][2] * 2.55), (255 - (this.geotabLastTrip[i][0][k][2] * 2.55)), 0), weight: 7 }).addTo(this.tripLayer);
  }*/

  drawLastTripToMap() {
    this.tripLayer.clearLayers();
    if (this.geotabLastTrip.length > 0) {
      // assert battery data
      var batteryData = false;
      if (this.geotabLastTrip[0][0][1][2] != null) {
        batteryData = true;
      }

      if (this.geotabLastTrip[0][0][0][3]) {
        for (let i = 0; i < this.geotabLastTrip.length; i++) {
          for (let j = 0; j < this.geotabLastTrip[i][0].length; j++) {
            this.geotabLastTrip[i][0][j].length = 3;
          }
        }
      }

      // loop trips
      for (let i = this.geotabLastTrip.length - 1; i >= 0; i--) {
        // last trip
        if (false) {
          let currArrows = this.mapService.generateTrackArrows(this.geotabLastTrip[i][0], this.geotabLastTrip[i][2], this.lodMultiply[this.lodCurrent]);
          L.polyline(this.geotabLastTrip[i][0], { color: "#fff", weight: 14 }).addTo(this.tripLayer);

          // with no battery data draw trip polyline
          if (!batteryData) {
            L.polyline(this.geotabLastTrip[i][0], { color: this.blueToGrey[this.geotabLastTrip[i][1]], weight: 8 }).addTo(this.tripLayer);
          }
          // with battery data draw trip polyline with battery info tooltip
          else {
            for (let k = 1; k < this.geotabLastTrip[i][0].length; k++) {
              if (this.geotabLastTrip[i][0][k][2] == 255) {
                L.polyline([this.geotabLastTrip[i][0][k - 1], this.geotabLastTrip[i][0][k]], { color: this.blueToGrey[this.geotabLastTrip[i][1]], weight: 8 }).bindTooltip("Battery level: N/A").addTo(this.tripLayer);
              }
              else {
                L.polyline([this.geotabLastTrip[i][0][k - 1], this.geotabLastTrip[i][0][k]], { color: this.blueToGrey[this.geotabLastTrip[i][1]], weight: 8 }).bindTooltip("Battery level: " + (Math.round(this.geotabLastTrip[i][0][k][2] * 100) / 100).toString() + "%").addTo(this.tripLayer);
              }
            }
          }

          for (let j = 0; j < currArrows.length; j++) {
            currArrows[j].addTo(this.tripLayer);
          }
        }

        // not the last trip
        else {
          // with no battery data draw trip polyline
          if (!batteryData) {
            //L.polyline(this.geotabLastTrip[i][0], { color: this.blueToGrey[this.geotabLastTrip[i][1]], weight: 8 }).addTo(this.tripLayer);
            for (let k = 0; i < this.geotabLastTrip[i][0].length; k++) {
              L.circle([this.geotabLastTrip[i][0][k][0], this.geotabLastTrip[i][0][k][1]], { radius: 5 }).addTo(this.tripLayer);
            }

          }
          // with battery data draw trip polyline with battery info tooltip
          else {
            for (let k = 1; k < this.geotabLastTrip[i][0].length; k++) {
              if (this.geotabLastTrip[i][0][k][2] == 255) {
                L.polyline([this.geotabLastTrip[i][0][k - 1], this.geotabLastTrip[i][0][k]], { color: this.blueToGrey[this.geotabLastTrip[i][1]], weight: 8 }).bindTooltip("Battery level: N/A").addTo(this.tripLayer);
              }
              else {
                L.polyline([this.geotabLastTrip[i][0][k - 1], this.geotabLastTrip[i][0][k]], { color: this.blueToGrey[this.geotabLastTrip[i][1]], weight: 8 }).bindTooltip("Battery level: " + (Math.round(this.geotabLastTrip[i][0][k][2] * 100) / 100).toString() + "%").addTo(this.tripLayer);
              }
              //L.circle([this.geotabLastTrip[i][0][k][0], this.geotabLastTrip[i][0][k][1]], {radius: 5, color: "red"}).addTo(this.tripLayer);
            }
          }
        }
      }
    }
  }

  drawActiveTripToMap() {
    this.activeTripLayer.clearLayers();
    var tripPoints = this.geotabLastActiveTrip[0];
    var indexes = this.geotabLastActiveTrip[1];

    var angle = 0;
    if (tripPoints.length > 1) {
      var angle = this.utilsService.angleFromCoordinate(tripPoints[tripPoints.length - 2][0], tripPoints[tripPoints.length - 2][1], tripPoints[tripPoints.length - 1][0], tripPoints[tripPoints.length - 1][1]);
    }
    this.setMarker(new LatLng(tripPoints[tripPoints.length - 1][0], tripPoints[tripPoints.length - 1][1]), angle);
    this.inputParamsService.setStartCoordsBearingParams(angle);
    let currArrows = this.mapService.generateTrackArrows(tripPoints, indexes, this.lodMultiply[this.lodCurrent]);
    L.polyline(tripPoints, { color: "#fff", weight: 14 }).addTo(this.activeTripLayer);

    for (let i = 1; i < tripPoints.length; i++) {
      // with battery data
      if (tripPoints[i][2] && tripPoints[i][2] != 255) {
        L.polyline([tripPoints[i - 1], tripPoints[i]], { color: this.blueToGrey[0], weight: 8 }).bindTooltip("Battery level: " + (Math.round(tripPoints[i][2] * 100) / 100).toString() + "%").addTo(this.activeTripLayer);
      }
      // no battery data
      else {
        L.polyline([tripPoints[i - 1], tripPoints[i]], { color: this.blueToGrey[0], weight: 8 }).bindTooltip("Battery level: N/A").addTo(this.activeTripLayer);
      }
    }

    for (let j = 0; j < currArrows.length; j++) {
      currArrows[j].addTo(this.activeTripLayer);
    }
  }

  // getting extneded bounds for drawing to map
  getExtendedBoundingBox() {
    var SW = this.map.getBounds().getSouthWest();
    var NE = this.map.getBounds().getNorthEast();
    var largerBoundsSW = new L.LatLng(SW.lat - (NE.lat - SW.lat) / 2, SW.lng - (NE.lng - SW.lng) / 2);
    var largerBoundsNE = new L.LatLng(NE.lat + (NE.lat - SW.lat) / 2, NE.lng + (NE.lng - SW.lng) / 2);
    var largerBounds = new L.LatLngBounds(largerBoundsSW, largerBoundsNE);
    this.lastBoundingBox = largerBounds;
    if (this.mapService.chargingStationsArray && this.chargingStationsDetailed) {
      this.drawChargingStations();
    }
  }

  // fit map to the searched location
  navigateToLocation(searchEl: any) {
    this.map.fitBounds(L.latLngBounds(
      [L.latLng(searchEl.boundingbox[0], searchEl.boundingbox[1]),
      L.latLng(searchEl.boundingbox[2], searchEl.boundingbox[3])]
    ));
    this.searchLayer.clearLayers();
    L.marker([searchEl.lat, searchEl.lon], { icon: L.divIcon({ html: "<div class='pulse'></div>", iconAnchor: [11, 11], iconSize: [22, 22] }) }).addTo(this.searchLayer);
    clearTimeout(this.pulseInterval);
    this.pulseInterval = setTimeout(() => this.searchLayer.clearLayers(), 3000);
  }

  replayIntervals: any = [];

  // N A V I G A T I O N
  replay(planPoints, turns) {
    for (let i = 0; i < this.replayIntervals.length; i++) {
      clearTimeout(this.replayIntervals[i]);
    }

    this.tempLayer1.clearLayers();
    this.tempLayer2.clearLayers();
    var rhaCursorHtml = "<img id='navi-icon' src='assets/cursor.png?v=2' style='width: 32px; height: 32px;' />";
    var rhaCursorIcon = L.divIcon({ html: rhaCursorHtml, iconAnchor: [16, 16], iconSize: [32, 32] });
    var naviMarker = L.marker(new LatLng(planPoints[0][0], planPoints[0][1]), { icon: rhaCursorIcon }).addTo(this.markerLayer);
    var pointsWithTime = this.navigationService.generateFakeGpsData(planPoints);

    // generate turn points array 
    var NavigationRoute = { RoutePoints: [], Turns: [] };
    for (let i = 0; i < turns.length; i++) {
      var currPoint = planPoints[turns[i][1]];
      NavigationRoute.Turns.push({ Location: { Latitude: currPoint[0], Longitude: currPoint[1] }, Maneuver: this.utilsService.turnList[turns[i][0]], RoutePointIdx: turns[i][1] });
    }

    for (let i = 0; i < planPoints.length; i++) {
      NavigationRoute.RoutePoints.push({
        Location: { Latitude: planPoints[i][0], Longitude: planPoints[i][1] }, RoutePointIdx: i
      })
    }

    // replaying generated fake gps data
    this.navigationService.StartNavigation(NavigationRoute);
    for (let i = 0; i < pointsWithTime.length; i++) {
      this.replayIntervals.push(
        setTimeout(() => {
          naviMarker.setLatLng(new LatLng(pointsWithTime[i].mPosition[0], pointsWithTime[i].mPosition[1]));
          if (document.getElementById('navi-icon') != null) {
            document.getElementById('navi-icon').setAttribute("style", "transform:rotate(" + pointsWithTime[i].mBearing + "deg); width: 32px; height: 32px;");
          }
          this.navigationService.RefreshTarget({
            Location: {
              Latitude: pointsWithTime[i].mPosition[0],
              Longitude: pointsWithTime[i].mPosition[1],
              Speed: pointsWithTime[i].mSpeed,
              HorizontalAccuracy: 3,
              Course: pointsWithTime[i].mBearing
            }
          })
        }, i * 25));
    }
  }

  ngOnDestroy(): void {
    // remove map when component destroyed
    this.map.off();
    this.map.remove();

    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];

  }
}

// override leaflet tile layer options
export interface TileLayerOptions extends L.GridLayerOptions {
  minZoom?: number;
  maxZoom?: number;
  maxNativeZoom?: number;
  minNativeZoom?: number;
  subdomains?: string | string[];
  errorTileUrl?: string;
  zoomOffset?: number;
  tms?: boolean;
  zoomReverse?: boolean;
  detectRetina?: boolean;
  crossOrigin?: L.CrossOrigin;
  id?: string;
  accessToken?: string;
  edgeBufferTiles?: number
  // [name: string]: any;
  // You are able add additional properties, but it makes this interface unchackable.
  // See: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/15313
  // Example:
  // tileLayer = L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png?{foo}&{bar}&{abc}', {foo: 'bar', bar: (data: any) => 'foo', abc: () => ''});
}
