import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MapService } from 'src/app/services/map.service';
import { MatDialog } from '@angular/material/dialog';
import { CarSelectorDialogComponent } from '../modals/car-properties-selector-dialog/car-properties-selector-dialog.component';
import { ECar, UserECar, AccountService } from 'src/app/services/account.service';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';

@Component({
  selector: 'app-manage-cars',
  templateUrl: './manage-cars.component.html',
  styleUrls: ['./manage-cars.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageCarsComponent implements OnInit {

  vehicleList: UserECar[] = [];
  mobileVisiblePanel: string = "";
  expandedView: boolean = false;

  constructor(private mapService: MapService, private cdr: ChangeDetectorRef,
    private matDialog: MatDialog, private accountService: AccountService,
    private mobileResolutionService: MobileResolutionService) {
  }

  ngOnInit() {
    this.accountService.ObservableUserVehicles.subscribe((resp) => {
      if (resp != null && resp != undefined) {
        this.vehicleList = resp;
        this.cdr.detectChanges();
      }
    });
    this.accountService.ObservableOpenCarSelectorDialog.subscribe((resp) => {
      if (resp) {
        var carSelectorRef = this.matDialog.open(CarSelectorDialogComponent, { data: { firstUserCar: true } });
        carSelectorRef.backdropClick().subscribe(() => {
          this.accountService.addDefaultVehicleToUser();
        });
      }
    });
    this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((panelName) => {
       this.mobileVisiblePanel = panelName; this.cdr.detectChanges();
     });
  }

  selectCar(vehicleId) {
    // @todo select it locally?
    this.accountService.selectVehicle(vehicleId).subscribe();
  }

  addCar() {
    this.matDialog.open(CarSelectorDialogComponent);
  }

  removeCar(vehicleId) {
    this.accountService.removeVehicle(vehicleId).subscribe();
  }

  editCar(vehicleId) {
    this.matDialog.open(CarSelectorDialogComponent, { data: { editedUserCarId: vehicleId } });
  }

  toggleView(){
    this.expandedView = !this.expandedView;
    this.cdr.detectChanges();
  }
}
