import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MapService } from 'src/app/services/map.service';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.css']
})
export class SettingsDialogComponent implements OnInit {

  settingValueTollRoad: any = 1;
  settingValueFerries: any = 1;
  settingValueHighways: any = 1;
  settingValueVoiceGuidance: any = 1;
  settingValueMapColors: any = 0;
  settingValueDistance: any = 1;
  settingValueTemperature: any = 1;
  settingValueSpeed: any = 1;
  settingValuePressure: any = 1;

  dialogOpenTollRoad: any = 1;
  dialogOpenFerries: any = 1;
  dialogOpenHighways: any = 1;

  constructor(public dialogRef: MatDialogRef<SettingsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private mapService: MapService, private paramsService: InputParamsService, private cdr: ChangeDetectorRef, private accountService: AccountService) {
  }

  ngOnInit() {
    // Init unit settings
    var unit;
    var unitsettings = localStorage.getItem("unit");
    if (!unitsettings) {
      unit = { "speed": "metric", "distance": "metric", "temperature": "metric", "pressure": "metric" };
    } else {
      try {
        unit = JSON.parse(unitsettings);
      } catch (e) { }
      if (typeof (unit) == 'object' && unit != null) {
        // Ok.
      } else if (typeof (unitsettings) === 'string') {
        if (unitsettings === 'metric') {
          unit = { "speed": "metric", "distance": "metric", "temperature": "metric", "pressure": "metric" };
        }
        else {
          unit = { "speed": "imperial", "distance": "imperial", "temperature": "imperial", "pressure": "imperial" };
        }
      }
    }
    if (unit) {
      this.settingValueDistance = (unit['distance'] == 'metric');
      this.settingValueTemperature = (unit['temperature'] == 'metric');
      this.settingValueSpeed = (unit['speed'] == 'metric');
      this.settingValuePressure = (unit['pressure'] == 'metric');
    }

    // Init routing settings
    if (localStorage.getItem("routing")) {
      var routing = JSON.parse(localStorage.getItem("routing"));
      this.settingValueTollRoad = (routing['tollroads'] == true ? 1 : 0);
      this.settingValueFerries = (routing['ferries'] == true ? 1 : 0);
      this.settingValueHighways = (routing['highways'] == true ? 1 : 0);
    }


    // Init voice settings
    if (localStorage.getItem("voiceguidance")) {
      var voiceguidance = JSON.parse(localStorage.getItem("voiceguidance"));
      this.settingValueVoiceGuidance = (voiceguidance['mute'] == true ? 0 : 1);
    }


    // Init map settings
    if (localStorage.getItem("mapsettings")) {
      var mapsettings = JSON.parse(localStorage.getItem("mapsettings"));
      this.settingValueMapColors = (mapsettings['skin'] === 'light' ? 1 : 0);
    }


    this.dialogOpenTollRoad = this.settingValueTollRoad;
    this.dialogOpenFerries = this.settingValueFerries;
    this.dialogOpenHighways = this.settingValueHighways;

    var dialog = this;
    this.dialogRef.afterClosed().subscribe(() => {
      if (
        dialog.dialogOpenTollRoad != dialog.settingValueTollRoad ||
        dialog.dialogOpenFerries != dialog.settingValueFerries ||
        dialog.dialogOpenHighways != dialog.settingValueHighways
      ) {
        dialog.paramsService.paramsUpdate();
        if (this.accountService.getIsAuthorized()) {
          this.accountService.setUserSettings().subscribe();
        }
      }
    });

    this.cdr.detectChanges();
  }

  switchParamsChanged(): void {
    // Save unit settings
    var unit = {};
    unit['distance'] = (this.settingValueDistance > 0 ? 'metric' : 'imperial');
    unit['temperature'] = (this.settingValueTemperature > 0 ? 'metric' : 'imperial');
    unit['speed'] = (this.settingValueSpeed > 0 ? 'metric' : 'imperial');
    unit['pressure'] = (this.settingValuePressure > 0 ? 'metric' : 'imperial');
    this.mapService.setUnit(unit);

    // Init routing settings
    var routing = {};
    routing['tollroads'] = (this.settingValueTollRoad > 0);
    routing['ferries'] = (this.settingValueFerries > 0);
    routing['highways'] = (this.settingValueHighways > 0);

    // Init voice settings
    var voiceguidance = {};
    voiceguidance['mute'] = (this.settingValueVoiceGuidance == 0)

    // Init map settings
    var mapsettings = {};
    mapsettings['skin'] = (this.settingValueMapColors > 0 ? 'light' : 'dark');
    if (this.mapService.getMapColor() != mapsettings['skin']) {
      this.mapService.setMapColor(mapsettings['skin']);
    }

    // Save
    if (this.paramsService.AcceptCookies) {
      localStorage.setItem("unit", JSON.stringify(unit));
      localStorage.setItem("routing", JSON.stringify(routing));
      localStorage.setItem("voiceguidance", JSON.stringify(voiceguidance));
      localStorage.setItem("mapsettings", JSON.stringify(mapsettings));
    }
  }

  dialogClose() {
    this.dialogRef.close(null);
    if (
      this.dialogOpenTollRoad != this.settingValueTollRoad ||
      this.dialogOpenFerries != this.settingValueFerries ||
      this.dialogOpenHighways != this.settingValueHighways
    ) {
      this.paramsService.paramsUpdate();
      if (this.accountService.getIsAuthorized()) {
        this.accountService.setUserSettings().subscribe();
      }
    }
  }
}
