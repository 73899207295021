import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrivacyPolicyDialogComponent } from '../privacy-policy-dialog/privacy-policy-dialog.component';
import { AudioService } from 'src/app/services/audio.service';

@Component({
  selector: 'app-navigation-disclaimer',
  templateUrl: './navigation-disclaimer.component.html',
  styleUrls: ['./navigation-disclaimer.component.css']
})
export class NavigationDisclaimerComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<NavigationDisclaimerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private audioService: AudioService
  ) { }

  ngOnInit() {
  }

  accept(){
    this.audioService.StartNavigation();
    this.dialogRef.close("start");
  }

  cancel(){
    this.dialogRef.close(null);
  }
}
