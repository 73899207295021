import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import device from 'current-device';

@Injectable({
  providedIn: 'root'
})

/**
 * playing voice instructios audio files
*/
export class AudioService {
  audio = new Audio();
  playPromise;

  constructor(private utilsService: UtilsService, private http: HttpClient) {
    //this.audio.volume = 0.2;
  }

  private isMuted():boolean {
    var voiceguidance = null;
    try {
      voiceguidance = JSON.parse(localStorage.getItem("voiceguidance"));
    }
    catch(e) {
      return false;
    }
    if (voiceguidance) {
      return voiceguidance['mute'];
    }
    else {
      return false;
    }
  }

  // merging mp3 files
  public mergeAndPlayAudio(audioPlayList: string[]) {
    if (this.isMuted()) {
      return;
    }

    var playlistHttpCalls = [];
    for (let i = 0; i < audioPlayList.length; i++) {
      playlistHttpCalls.push(this.http.get(audioPlayList[i], { responseType: 'arraybuffer' }));
    }
    forkJoin(playlistHttpCalls).subscribe((resp: any[]) => {
      /*for (let i=1; i<resp.length; i++){
        //resp[i] = resp[i].slice(32);
      }*/
      if (window.navigator.userAgent.includes("GPSTWebView") && device.ios()) {
        window['webViewObj'].playAudio(new Blob(resp, { type: "arraybuffer" }));
      }
      else {
        if (this.playPromise == undefined) {
          var url = window.URL.createObjectURL(new Blob(resp, { type: "audio/mp3" }));
          this.audio.src = url;
          this.audio.load();
          this.playPromise = this.audio.play();
        }
        else {
          this.playPromise.then(() => {
            var url = window.URL.createObjectURL(new Blob(resp, { type: "audio/mp3" }));
            this.audio.src = url;
            this.audio.load();
            this.playPromise = this.audio.play();
          })
        }
      }
    });
  }

  public downloadfile(data, fileName) {
    var a;
    a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
  };

  public StartNavigation() {
    if (this.isMuted()) {
      return;
    }

    if (this.playPromise == undefined) {
      this.audio.src = "assets/turn/audio/en-US_Kendra/Beep.mp3";;
      this.audio.load();
      this.playPromise = this.audio.play();
    }
    else {
      this.playPromise.then(() => {
        this.audio.src = "assets/turn/audio/en-US_Kendra/Beep.mp3";;
        this.audio.load();
        this.playPromise = this.audio.play();
      })
    }
  }

  public readCommand(dist, NextTurn, unit) {
    var audioPlayList: string[] = [];

    dist = this.RoundDistance(dist, unit);
    audioPlayList.push("assets/turn/audio/en-US_Kendra/Beep.mp3");

    if (dist != 0) {
      // in x distance
      audioPlayList.push("assets/turn/audio/en-US_Kendra/Commands/In.mp3");

      audioPlayList = audioPlayList.concat(this.getSoundFiles(dist, unit, "meter"));
      if (dist < 1000 && unit['distance'] == "metric") {
        audioPlayList.push("assets/turn/audio/en-US_Kendra/meters.mp3");
      }

      if (dist < 1760 && unit['distance'] == "imperial") {
        audioPlayList.push("assets/turn/audio/en-US_Kendra/yards.mp3");
      }
    }
    if (dist >= 1000 && dist < 2000 && unit['distance'] == "metric") {
      audioPlayList.push("assets/turn/audio/en-US_Kendra/kilometer.mp3");
    }
    if (dist >= 2000 && unit['distance'] == "metric") {
      audioPlayList.push("assets/turn/audio/en-US_Kendra/kilometers.mp3");
    }
    if (dist >= 1760 && unit['distance'] == "imperial") {
      audioPlayList.push("assets/turn/audio/en-US_Kendra/miles.mp3");
    }

    // turn instruction
    if (NextTurn.Maneuver.id == "ENTER_ROUNDABOUT") {
      audioPlayList.push("assets/turn/audio/en-US_Kendra/Commands/AtRoundabout.mp3");
      audioPlayList.push("assets/turn/audio/en-US_Kendra/Commands/Take" + NextTurn.Maneuver.exitNumber + "Exit.mp3");
    }
    else {
      audioPlayList.push(NextTurn.Maneuver.sound);
    }

    this.mergeAndPlayAudio(audioPlayList);
  }

  public readFollowTheRoute(dist, unit) {
    var audioPlayList: string[] = [];

    dist = this.RoundDistance(dist, unit);
    audioPlayList.push("assets/turn/audio/en-US_Kendra/Beep.mp3");
    audioPlayList.push("assets/turn/audio/en-US_Kendra/Commands/FollowRoute.mp3");
    audioPlayList = audioPlayList.concat(this.getSoundFiles(dist, unit, "meter"));
    if (dist < 1000 && unit['distance'] == "metric") {
      audioPlayList.push("assets/turn/audio/en-US_Kendra/meters.mp3");
    }

    if (dist >= 1000 && dist < 2000 && unit['distance'] == "metric") {
      audioPlayList.push("assets/turn/audio/en-US_Kendra/kilometer.mp3");
    }
    if (dist >= 2000 && unit['distance'] == "metric") {
      audioPlayList.push("assets/turn/audio/en-US_Kendra/kilometers.mp3");
    }
    if (dist < 1760 && unit['distance'] == "imperial") {
      audioPlayList.push("assets/turn/audio/en-US_Kendra/yards.mp3");
    }
    if (dist >= 1760 && unit['distance'] == "imperial") {
      audioPlayList.push("assets/turn/audio/en-US_Kendra/miles.mp3");
    }

    this.mergeAndPlayAudio(audioPlayList);
  }

  voiceFolder = "assets/turn/audio/en-US_Kendra/";

  private getSoundFiles(number, unit, unitType): string[] {
    var audioPlayList: string[] = [];

    // read dot
    if (number.toString().includes(".")) {
      audioPlayList.push(this.voiceFolder + "Numbers/" + Math.trunc(number) + ".mp3");
      audioPlayList.push(this.voiceFolder + "point.mp3");
      audioPlayList = audioPlayList.concat(this.getSoundFiles(parseInt(number.toString().split(".")[1]), unit, "km"));
    }
    // 1-19
    else if (number > 0 && number <= 19) {
      if (unit['distance'] == "imperial" && (number == 1 || number == 2)) {
        audioPlayList.push(this.voiceFolder + "Numbers/" + number + ".mp3");
      }
      else {
        audioPlayList.push(this.voiceFolder + "Numbers/" + number + ".mp3");
      }
    }
    // 100 200 ...
    else if (number % 100 == 0 && Math.trunc(number / 1000) == 0) {
      audioPlayList.push(this.voiceFolder + "Numbers/" + number + ".mp3");
    }
    // 10 20 ...
    else if (number % 10 == 0 && Math.trunc(number / 100) == 0) {
      audioPlayList.push(this.voiceFolder + "Numbers/" + number + ".mp3");
    }
    // Change to km
    else if (number >= 1000 && unit['distance'] == "metric" && unitType == "meter") {
      if (number >= 10000) {
        audioPlayList = audioPlayList.concat(this.getSoundFiles(Math.round(number / 1000), unit, "km"));
      }
      else {
        audioPlayList = audioPlayList.concat(this.getSoundFiles(Math.round(number / 100) / 10, unit, "km"));
      }
    }
    else if (number >= 1760 && unit['distance'] == "imperial" && unitType == "meter") {
      if (number >= 17600) {
        audioPlayList = audioPlayList.concat(this.getSoundFiles(Math.round(number / 1760), unit, "km"));
      }
      else {
        audioPlayList = audioPlayList.concat(this.getSoundFiles(Math.round(number / 176) / 10, unit, "km"));
      }
    }
    // 1000X
    else if (number >= 1000) {
      if (number % 100 == 1) {
        audioPlayList.push(this.voiceFolder + "Numbers/" + Math.trunc(number / 1000) * 1000 + "X.mp3");
      }
      else {
        audioPlayList.push(this.voiceFolder + "Numbers/" + Math.trunc(number / 1000) * 1000 + "X.mp3");
        number %= 1000;
        audioPlayList = audioPlayList.concat(this.getSoundFiles(number, unit, unitType));
      }
    }
    // 100X
    else if (number >= 100) {
      if (number % 100 == 1) {
        audioPlayList.push(this.voiceFolder + "Numbers/" + Math.trunc(number / 100) * 100 + "X.mp3");
      }
      else {
        audioPlayList.push(this.voiceFolder + "Numbers/" + Math.trunc(number / 100) * 100 + "X.mp3");
        number %= 100;
        audioPlayList = audioPlayList.concat(this.getSoundFiles(number, unit, unitType));
      }
    }
    // 10X
    else if (number >= 20) {
      if (number % 10 == 1) {
        audioPlayList.push(this.voiceFolder + "Numbers/" + Math.trunc(number / 10) * 10 + "X.mp3");
      }
      else {
        audioPlayList.push(this.voiceFolder + "Numbers/" + Math.trunc(number / 10) * 10 + "X.mp3");
        number %= 10;
        audioPlayList = audioPlayList.concat(this.getSoundFiles(number, unit, unitType));
      }
    }

    return audioPlayList;
  }

  RoundDistance(dist: number, unit: any) {
    if (unit['distance'] == "metric") {
      if (dist >= 0 && dist <= 20) {
        return Math.round(dist);
      }
      else if (dist > 20 && dist <= 100) {
        return Math.round(dist / 10) * 10;
      }
      else if (dist > 100 && dist <= 250) {
        return Math.round(dist / 10) * 10;
      }
      else if (dist > 250 && dist <= 1000) {
        return Math.round(dist / 50) * 50;
      }
      else if (dist > 1000 && dist <= 100000) {
        return Math.round(dist / 100) * 100;
      }
      else {
        return Math.round(dist / 1000) * 1000;
      }
    }
    else {
      var yards = this.utilsService.mToYard(dist);
      if (yards >= 0 && yards <= 20) {
        return Math.round(this.utilsService.mToYard(dist));
      }
      else if (yards > 20 && yards <= 100) {
        return Math.round(this.utilsService.mToYard(Math.round(dist / 10) * 10));
      }
      else if (yards > 100 && yards <= 250) {
        return Math.round(this.utilsService.mToYard(Math.round(dist / 10) * 10));
      }
      else if (yards > 250 && yards <= 850) {
        return Math.round(this.utilsService.mToYard(Math.round(dist / 50) * 50));
      }
      else {
        var miles = Math.round(this.utilsService.mToMiles(dist));
        if (miles < 100) {
          return Math.round(this.utilsService.mToYard(Math.round(dist * 100) / 100));
        }
        else {
          return Math.round(this.utilsService.mToYard(Math.round(dist * 1000) / 1000));
        }
      }
    }
  }
}
