import { Component, OnInit, Renderer2, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MapService } from 'src/app/services/map.service';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { ECar } from 'src/app/services/account.service';

@Component({
  selector: 'app-map-sidebar',
  templateUrl: './map-sidebar.component.html',
  styleUrls: ['./map-sidebar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapSidebarComponent implements OnInit {
  mobileResolution = false;
  trikeActive: boolean = false;
  mobileVisiblePanel = null;
  cs1: boolean = false;
  cs2: boolean = false;
  cs3: boolean = false;
  cs4: boolean = false;
  cs5: boolean = false;
  cs6: boolean = false;
  unit: any = {"speed": "metric", "distance": "metric", "temperature": "metric", "pressure": "metric"};
  subscriptions = [];

  /* contains charging stations selector, search and unit selector */
  constructor(private mapService: MapService, private http: HttpClient, private renderer2: Renderer2, private inputService: InputParamsService,
    private mobileResolutionService: MobileResolutionService, private cdr: ChangeDetectorRef) {
    this.mapService.setUnit(this.unit);
  }

  // setting charging station
  ngOnInit() {
    // observe mobile resolution changed
    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((resp) => {
      if (resp) {
        this.mobileVisiblePanel = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileResolution.subscribe((resp) => {
      this.mobileResolution = resp;
      this.cdr.detectChanges();
    }));

    this.subscriptions.push(this.mapService.ObservableChargingStations.subscribe((resp) => {
      if (resp) {
        this.cs1 = resp[0];
        this.cs2 = resp[1];
        this.cs3 = resp[2];
        this.cs4 = resp[3];
        this.cs5 = resp[4];
        this.cs6 = resp[5];
        var chargingStations = [this.cs1, this.cs2, this.cs3, this.cs4, this.cs5, this.cs6];
        if (this.inputService.AcceptCookies){
          localStorage.setItem("chargingStations", chargingStations.toString());
        }
        this.cdr.detectChanges();
      }
    }));
  }

  // get selected car supported charging stations and set charging stations selectors
  chargingStationsChanged() {
    var chargingStations = [this.cs1, this.cs2, this.cs3, this.cs4, this.cs5, this.cs6];
    if (this.inputService.AcceptCookies){
      localStorage.setItem("chargingStations", chargingStations.toString());
    }
    this.mapService.setChargingStations(chargingStations);
    var ecar: ECar = this.mapService.getSelectedCar();
    ecar.ChargerTypes = [];
    for (let i = 0; i < chargingStations.length; i++) {
      if (chargingStations[i]) {
        ecar.ChargerTypes.push(i + 1);
      }
    }
    this.mapService.setSelectedCar(ecar, true);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }
}
