import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import device from 'current-device';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.css']
})
export class TutorialComponent implements OnInit {

  @ViewChild('privacyScrollbar', { static: true }) privacyScrollbar: NgScrollbar;
  private step: number = 0;
  private nextbutton: boolean = true;
  private prevbutton: boolean = false;
  private ios: boolean = false;
  private contentArr = [
    {
      img: "assets/range_image.jpg",
      title: "Range",
      text: 'The filled area over the map indicates the range you can cover with the currently set battery percentage. It has two adjustable levels: The outer blot refers to the maximum range you can reach with the set battery state, while the inner blot stands for a cautionary distance, granting you the option to reserve a custom battery percentage ("Battery Reserved" slider).'
    },
    {
      img: "assets/search_click_image.jpg",
      title: "Right Click",
      text: 'You can set start and end points after using Right Click on any point of the map. The "Set Start Coordinate" repositions the cursor, the "Set End Coordinate" plans the route between the two spots.'
    },
    {
      img: "assets/navigation_image.jpg",
      title: "Navigation",
      text: 'Placing the end point plans the route, granting you the Itinerary and the Charging Plan through your trip. The "GO" button starts the turn-by-turn navigation along with voice guidance.'
    }
  ];
  private contentLogoArr = ["assets/"]
  private content: any = this.contentArr[0];

  constructor() {
    if (device.ios() || device.os=="macos"){
      this.ios = true;
    }
    if (!device.desktop()) {
      this.contentArr[1] = {
        img: "assets/search_tap_image.jpg",
        title: "Long Tap",
        text: 'You can set start and end points after long tapping the map on any point. The "Set Start Coordinate" repositions the cursor, the "Set End Coordinate" plans the route between the two spots.'
      }
    }

  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  onNextClick(): void {
    this.step++;
  }
  
  onPrevClick(): void {
    this.step--;
  }
}
