import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-battery',
  templateUrl: './battery.component.html',
  styleUrls: ['./battery.component.css']
})
export class BatteryComponent implements OnInit {
  @Input() batteryLevel;

  constructor() { }

  ngOnInit() {
  }

}
