import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent, ValueDecimalPipe, ValueIntegerPipe, TurnValueIntegerPipe, TurnValueIntegerThousandsPipe, CarFilterPipe, CarNamePipe } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { ToastrModule } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

import { InputParamsService } from './services/input-params.service';
import { MobileResolutionService } from './services/mobile-resolution.service';
import { MapService } from './services/map.service';

import { NgScrollbarModule } from 'ngx-scrollbar';
import 'hammerjs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { WindForceComponent } from './components/wind-force/wind-force.component';
import { ErrorDialogComponent } from './components/modals/error-dialog/error-dialog.component';
import { CarModelSelectorDialogComponent } from './components/modals/car-selector-dialog/car-selector-dialog.component';
import { InfoDialogComponent } from './components/modals/info-dialog/info-dialog.component';
import { RhaSliderComponent } from './components/rha-slider/rha-slider.component';
import { MapComponent } from './components/map/map.component';
import { SliderSidebarComponent } from './components/slider-sidebar/slider-sidebar.component';
import { MapSidebarComponent } from './components/map-sidebar/map-sidebar.component';
import { InfoSidebarComponent } from './components/info-sidebar/info-sidebar.component';
import { TrackingModuleComponent } from './components/tracking-module/tracking-module.component';
import { SearchComponent } from './components/search/search.component';
import { GoogleDialogComponent } from './components/modals/google-dialog/google-dialog.component';
import { PrivacyPolicyDialogComponent } from './components/modals/privacy-policy-dialog/privacy-policy-dialog.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NavigationHudComponent } from './components/navigation-hud/navigation-hud.component';
import { NavigationDisclaimerComponent } from './components/modals/navigation-disclaimer/navigation-disclaimer.component';
import { GpsErrorDialogComponent } from './components/modals/gps-error-dialog/gps-error-dialog.component';
import { TeslaModuleComponent } from './components/tesla-module/tesla-module.component';
import { ToriModuleComponent } from './components/tori-module/tori-module.component';
import { NavigationRoadlanesComponent } from './components/navigation-roadlanes/navigation-roadlanes.component';
import { ProfileComponent } from './components/profile/profile.component';
import { AccountDialogComponent } from './components/modals/account-dialog/account-dialog.component';
import { ManageCarsComponent } from './components/manage-cars/manage-cars.component';
import { CarSelectorDialogComponent } from './components/modals/car-properties-selector-dialog/car-properties-selector-dialog.component';
import { WindTabComponent } from './components/wind-tab/wind-tab.component';
import { SmartcarComponent } from './components/smartcar/smartcar.component';
import { TabbedInfoDialogComponent } from './components/modals/tabbed-info-dialog/tabbed-info-dialog.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { EulaComponent } from './components/eula/eula.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { SettingsDialogComponent } from './components/modals/settings-dialog/settings-dialog.component';
import { SwitchComponent } from './components/switch/switch.component';
import { WelcomeDialogComponent } from './components/modals/welcome-dialog/welcome-dialog.component';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';
import { ConfirmationDialogComponent } from './components/modals/confirmation-dialog/confirmation-dialog.component';
import { BatteryComponent } from './components/battery/battery.component';
import { ProfileMobileMenuDialogComponent } from './components/modals/profile-mobile-menu-dialog/profile-mobile-menu-dialog.component';

const routes: Routes = [
  { path: '', component: AppComponent },
  { path: 'tracking', component: AppComponent },
  /*{ path: 'tesla', component: AppComponent },
  { path: 'trike', component: AppComponent },*/
  { path: 'smartcartest', component: AppComponent },
  { path: 'tori', component: AppComponent },
  { path: 'compare', component: AppComponent },
  { path: 'privacy-policy', component: AppComponent },
  { path: 'demo', component: AppComponent },
  { path: 'polyline', component: AppComponent },
  { path: 'verify-email', component: AppComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

const globalSettings: RecaptchaSettings = { siteKey: "6LePecYUAAAAALxWbq0rs8n5cE_VLgHz5TChWoyv", theme: "dark" }

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatCardModule, MatDialogModule, MatGridListModule, MatProgressSpinnerModule, MatButtonModule, ReactiveFormsModule, MatCheckboxModule, MatSelectModule, MatTooltipModule, MatIconModule, MatSlideToggleModule,
    RouterModule.forRoot(routes),
    NgScrollbarModule,
    DragDropModule,
    RecaptchaModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
      preventDuplicates: true
    })
  ],

  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: globalSettings,
    },
    InputParamsService,
    MobileResolutionService,
    MapService,
    CookieService
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [ErrorDialogComponent, CarModelSelectorDialogComponent, InfoDialogComponent,
                    GoogleDialogComponent, PrivacyPolicyDialogComponent, NavigationDisclaimerComponent,
                    GpsErrorDialogComponent, AccountDialogComponent,
                    CarSelectorDialogComponent, TabbedInfoDialogComponent, SettingsDialogComponent,
                    WelcomeDialogComponent, ConfirmationDialogComponent, ProfileMobileMenuDialogComponent],
  declarations: [AppComponent, WindForceComponent, ErrorDialogComponent, InfoDialogComponent,
                 RhaSliderComponent, MapComponent, CarModelSelectorDialogComponent, SliderSidebarComponent,
                 MapSidebarComponent, InfoSidebarComponent, TrackingModuleComponent, ValueDecimalPipe,
                 ValueIntegerPipe, TurnValueIntegerPipe, TurnValueIntegerThousandsPipe, CarFilterPipe,
                 CarNamePipe, InfoDialogComponent, SearchComponent, GoogleDialogComponent,
                 PrivacyPolicyDialogComponent, NavigationComponent, NavigationHudComponent,
                 NavigationDisclaimerComponent, GpsErrorDialogComponent, TeslaModuleComponent,
                 ToriModuleComponent, NavigationRoadlanesComponent,
                 ProfileComponent, AccountDialogComponent, ManageCarsComponent, CarSelectorDialogComponent,
                 WindTabComponent, TabbedInfoDialogComponent, PrivacyPolicyComponent, EulaComponent,
                 FeedbackComponent, WelcomeComponent, TutorialComponent, SettingsDialogComponent,
                 SwitchComponent, SmartcarComponent, WelcomeDialogComponent, NavigationMenuComponent,
                 ConfirmationDialogComponent, BatteryComponent, ProfileMobileMenuDialogComponent],
  bootstrap: [AppComponent]
})

export class AppModule {
  // preventing iphone pinch zoom
  constructor() {
    //if (navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)) {
    document.addEventListener('gesturestart', function (e) {
      e.preventDefault();
    });
    document.addEventListener('gesturechange', function (e) {
      e.preventDefault();
    });
    document.addEventListener('gestureend', function (e) {
      e.preventDefault();
    });
    //}
    window.oncontextmenu = function (event) {
      var target: any = event.target;
      if (target.nodeName && target.nodeName != "INPUT") {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    };
  }
}
