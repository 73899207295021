import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-navigation-roadlanes',
  templateUrl: './navigation-roadlanes.component.html',
  styleUrls: ['./navigation-roadlanes.component.css']
})
export class NavigationRoadlanesComponent implements OnInit {
  @Input() Desktop: boolean;
  subscriptions = [];
  roadLanes = [];
  roadLanesVisible: boolean = false;
  constructor(private navigationService: NavigationService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.subscriptions.push(this.navigationService.ObservableShowRoadLane.subscribe((resp) => {
      if (resp != null) {
        this.roadLanesVisible = resp;
      }
    }
    ));
    this.subscriptions.push(this.navigationService.ObservableNextTurn.subscribe((resp) => {
      if (resp && resp.NextTurnInstruction && resp.NextTurnInstruction.Roadlanes.length > 0) {
        this.roadLanes = resp.NextTurnInstruction.Roadlanes;
        this.cdr.detectChanges();
      }
      else {
        this.roadLanes = [];
        this.cdr.detectChanges();
      }
    }
    ));
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }
}
