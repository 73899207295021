import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-privacy-policy-dialog',
  templateUrl: './privacy-policy-dialog.component.html',
  styleUrls: ['./privacy-policy-dialog.component.css']
})
export class PrivacyPolicyDialogComponent implements OnInit {

  @ViewChild('privacyScrollbar', { static: true }) privacyScrollbar: NgScrollbar;

  constructor(
    public dialogRef: MatDialogRef<PrivacyPolicyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    setTimeout(()=>{
      this.privacyScrollbar.scrollToTop();
    },500);
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

}
