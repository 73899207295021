import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { LatLng } from 'leaflet';
import { Observable, Observer } from 'rxjs';
import { MobileResolutionService } from './mobile-resolution.service';
import { GpsErrorDialogComponent } from '../components/modals/gps-error-dialog/gps-error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { createSnapToResolutions } from 'ol/resolutionconstraint';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { reverse } from 'dns';

export class InputParamsService {

  private RangeInputParams: any;
  private TempWindParams: any;
  private StartCoordsBearingParams: any = 0;
  private WaypointsParams: any = [];
  private SelectedMode: string = "rha";
  private TrackingTimer: boolean = false;
  private SearchedRangeOrRoute: boolean = false;
  private ReverseGeocodedLocations: any;
  private Bearing: number = null;
  private OpenGoogleUrl: boolean = false;
  private FailedPlan: any = null;
  private WaypointsRouteList = [];
  public SetMapToBoundingBox: boolean = false;
  public AcceptCookies: boolean = false;
  public ObservableRangeInputParams: BehaviorSubject<any>;
  public ObservableWindTempParams: BehaviorSubject<any>;
  public ObservableWaypointsParams: BehaviorSubject<any>;
  public ObservableStartCoordsBearingParams: BehaviorSubject<any>;
  public ObservableSelectedMode: BehaviorSubject<string>;
  public ObservableTrackingTimer: BehaviorSubject<boolean>;
  private ObservableSearchedRangeOrRoute: BehaviorSubject<boolean>;
  public ObservableReverseGeocodedLocations: BehaviorSubject<any>;
  public ObservableBearing: BehaviorSubject<number>;
  public ObservableSameCoordsError: BehaviorSubject<boolean>;
  public ObservableOpenGoogleUrl: BehaviorSubject<boolean>;
  public ObservableFailedPlan: BehaviorSubject<any>;
  public ObservableNoRoute: BehaviorSubject<boolean>;
  public ObservableWebViewPosition: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public ObservableSelectedToriVehicle: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public ObservableGpsErrorDialog: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public ObservableAddEndPoint: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public ObservableSetStartPointGeocode: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public ObservableReverseGeocodingLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public ObservableActualBattery: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor() {
    this.ObservableRangeInputParams = new BehaviorSubject<any>(this.RangeInputParams);
    this.ObservableWindTempParams = new BehaviorSubject<any>(this.TempWindParams);
    this.ObservableWaypointsParams = new BehaviorSubject<any>(this.WaypointsParams);
    this.ObservableStartCoordsBearingParams = new BehaviorSubject<any>(this.StartCoordsBearingParams);
    this.ObservableSelectedMode = new BehaviorSubject<string>(this.SelectedMode);
    this.ObservableTrackingTimer = new BehaviorSubject<boolean>(this.TrackingTimer);
    this.ObservableSearchedRangeOrRoute = new BehaviorSubject<boolean>(this.SearchedRangeOrRoute);
    this.ObservableReverseGeocodedLocations = new BehaviorSubject<any>(this.ReverseGeocodedLocations);
    this.ObservableBearing = new BehaviorSubject<number>(this.Bearing);
    this.ObservableSameCoordsError = new BehaviorSubject<boolean>(null);
    this.ObservableOpenGoogleUrl = new BehaviorSubject<boolean>(null);
    this.ObservableFailedPlan = new BehaviorSubject<boolean>(this.FailedPlan);
    this.ObservableNoRoute = new BehaviorSubject<boolean>(false);

    // watch position for ANDROID Facebook browser
    if (window.navigator.userAgent.includes("FB_IAB")) {
      this.watchGPSData();
    }
  }

  lastGpsDateTime = new Date();

  watchGPSData() {
    navigator.geolocation.watchPosition(
      (position) => {
        if (new Date().getTime() - this.lastGpsDateTime.getTime() > 800) {
          this.lastGpsDateTime = new Date();
          this.lastWebViewLocation = [position.coords.latitude, position.coords.longitude, position.coords.speed, position.coords.altitude, position.timestamp];
          this.ObservableWebViewPosition.next([position.coords.latitude, position.coords.longitude, position.coords.speed, position.coords.altitude, position.timestamp]);
        }
      },
      (error) => {
        this.ObservableGpsErrorDialog.next(true);
      },
      { enableHighAccuracy: true });
  }

  loadCookies() {
    //read cached data
    var waypointsStorage = localStorage.getItem("waypoints");
    if (waypointsStorage && waypointsStorage != 'null') {
      this.setWaypointParams(JSON.parse(waypointsStorage));
    }
    else {
      //default start coords params
      this.setStartCoordsParams(new LatLng(52.520008, 13.404954));
    }
    if (waypointsStorage && JSON.parse(waypointsStorage).length > 1) {
      this.setSelectedMode('route');
    }
  }

  setRangeInputParams(params: any): void {
    this.RangeInputParams = params;
    this.paramsUpdate();
  }

  getRangeInputParams(): any {
    return this.RangeInputParams;
  }

  setBattery(batteryLevel) {
    this.RangeInputParams.batteryLevel = batteryLevel
  }

  setTempWindParamsWithNoUpdate(params: any) {
    this.TempWindParams = params;
  }

  setTempWindParams(params: any): void {
    this.TempWindParams = params;
    this.paramsUpdate();
  }

  getTempWindParams(): any {
    return this.TempWindParams;
  }

  getWaypointsParams(): any {
    return this.WaypointsParams;
  }

  setWaypointParams(waypoints) {
    if (waypoints.length > 1 && this.getSelectedMode() == "rha") {
      this.setSelectedMode("route");
    }

    for (let i = 0; i < waypoints.length; i++) {
      if (waypoints[i].lat == 0 && waypoints[i].lng == 0) {
        waypoints.splice(i, 1);
        i--;
      }
    }

    var notChangedArray = true;
    if (waypoints.length == this.WaypointsParams.length) {
      for (let i = 0; i < waypoints.length; i++) {
        if (waypoints[i].lat != this.WaypointsParams[i].lat || waypoints[i].lon != this.WaypointsParams[i].lon) {
          notChangedArray = false;
        }
      }
    }
    else {
      notChangedArray = false
    }

    if (notChangedArray == false || this.getOpenGoogleUrl() == true) {
      // same coordinates error exception
      /*var samecoordErr = false;
      if (waypoints.length == 2) {
        for (let i = 1; i < waypoints.length; i++) {
          if (Math.abs(waypoints[i - 1].lat - waypoints[i].lat) < 0.0004 && Math.abs(waypoints[i - 1].lng - waypoints[i].lng)) {
            samecoordErr = true;
          }
        }
      }

      if (samecoordErr) {
        this.ObservableSameCoordsError.next(true);
      }
      else {*/
      this.WaypointsParams = waypoints;
      this.ObservableWaypointsParams.next(waypoints);

      this.paramsUpdate();
      /*}*/
    }
  }

  setWaypointRouteList(param){
    this.WaypointsRouteList = param;
  }

  getWaypointsRouteList(){
    return this.WaypointsRouteList;
  }

  getStartCoordsParams(): any {
    return this.WaypointsParams[0];
  }

  setStartCoordsParams(startcoords) {
    var waypointsCopy = JSON.parse(JSON.stringify(this.WaypointsParams));
    waypointsCopy[0] = startcoords;
    this.setWaypointParams(waypointsCopy);
  }

  unshiftStartCoordsParams(startcoords) {
    var waypointsCopy = JSON.parse(JSON.stringify(this.WaypointsParams));
    waypointsCopy.unshift(startcoords);
    this.setWaypointParams(waypointsCopy);
  }

  addEndpoint(endpoint) {
    // remove last endpoint if planning failed
    if (this.WaypointsParams.length > 1 && this.SelectedMode == "rha") {
      this.deleteWaypoint(1);
    }

    var waypointsCopy = JSON.parse(JSON.stringify(this.WaypointsParams));
    waypointsCopy.push(new LatLng(endpoint.lat, endpoint.lon));
    endpoint.wp = waypointsCopy.length - 1;
    this.ObservableAddEndPoint.next(endpoint);
    this.setWaypointParams(waypointsCopy);
  }

  deleteWaypoint(i) {
    var waypointsCopy = JSON.parse(JSON.stringify(this.WaypointsParams));
    waypointsCopy.splice(i, 1);
    this.setWaypointParams(waypointsCopy);
  }

  lastWebViewLocation;

  setStartCoordsToYourGeolocation(unshiftWaypoints?): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      if (!window.navigator.userAgent.includes("GPSTWebView") && !window.navigator.userAgent.includes("FB_IAB")) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            if (unshiftWaypoints) {
              this.unshiftStartCoordsParams(new LatLng(position.coords.latitude, position.coords.longitude));
            }
            else {
              this.setStartCoordsParams(new LatLng(position.coords.latitude, position.coords.longitude));
            }
            observer.next(new LatLng(position.coords.latitude, position.coords.longitude));
            observer.complete();
          },
            (error) => {
              this.setOpenGoogleUrl(false);
              observer.next("nogps");
              observer.complete();
            }, { enableHighAccuracy: true });
        }
        else {
          this.setOpenGoogleUrl(false);
          observer.next("nogps");
          observer.complete();
        }
      }
      else {
        window['webViewObj'].setStartCoordsToYourGeolocation();
        if (this.lastWebViewLocation) {
          if (unshiftWaypoints) {
            this.unshiftStartCoordsParams(new LatLng(this.lastWebViewLocation[0], this.lastWebViewLocation[1]));
          }
          else {
            this.setStartCoordsParams(new LatLng(this.lastWebViewLocation[0], this.lastWebViewLocation[1]));
          }
          observer.next(new LatLng(this.lastWebViewLocation[0], this.lastWebViewLocation[1]));
          observer.complete();
        }
        else {
          this.setOpenGoogleUrl(false);
          this.watchGPSData();
        }
      }
    });
  }

  setStartCoordsBearingParams(params: any): any {
    this.StartCoordsBearingParams = params;
  }

  getStartCoordsBearingParams() {
    return this.StartCoordsBearingParams;
  }

  setSelectedMode(param: string) {
    if (this.RangeInputParams && this.RangeInputParams.batterySafetyLimit && param == "rha") {
      this.RangeInputParams.batterySafetyLimit = 50;
    }
    if (this.RangeInputParams && this.RangeInputParams.batterySafetyLimit && param == "route") {
      this.RangeInputParams.batterySafetyLimit = 15;
    }
    this.SelectedMode = param;
    this.ObservableSelectedMode.next(param);
  }

  getSelectedMode(): string {
    return this.SelectedMode;
  }

  setTrackingTimer(param: boolean) {
    this.TrackingTimer = param;
    this.ObservableTrackingTimer.next(param);
  }

  getTrackingTimer() {
    return this.TrackingTimer;
  }

  setSearchedRangeOrRoute(param: boolean) {
    this.SearchedRangeOrRoute = param;
    this.ObservableSearchedRangeOrRoute.next(param);
  }

  getSearchedRangeOrRoute() {
    return this.SearchedRangeOrRoute;
  }

  paramsUpdate(): void {
    var RP: RangeParams = new RangeParams();
    RP.RangeInputParams = this.RangeInputParams;
    RP.TempWindParams = this.TempWindParams;
    RP.Waypoints = this.WaypointsParams;
    this.ObservableRangeInputParams.next(RP);
  }

  getRangeParams(): RangeParams {
    var RP: RangeParams = new RangeParams();
    RP.RangeInputParams = this.RangeInputParams;
    RP.TempWindParams = this.TempWindParams;
    RP.Waypoints = this.WaypointsParams
    return RP;
  }

  setReverseGeocodedLocations(params) {
    this.ReverseGeocodedLocations = params;
    this.ObservableReverseGeocodedLocations.next(params);
  }

  getReverseGeocodedLocations() {
    return this.ReverseGeocodedLocations;
  }

  setBearing(param: number) {
    this.Bearing = param;
    this.ObservableBearing.next(param);
  }

  getBearing(): number {
    return this.Bearing;
  }

  setOpenGoogleUrl(param: boolean) {
    this.OpenGoogleUrl = param;
    this.ObservableOpenGoogleUrl.next(param);
  }

  getOpenGoogleUrl(): boolean {
    return this.OpenGoogleUrl;
  }

  setFailedPlan(param: any): any {
    this.FailedPlan = param;
    this.ObservableFailedPlan.next(param);
  }

  getFailedPlan() {
    return this.FailedPlan;
  }

  transformLatLonToValidValues(latlon) {
    while (latlon.lat < -90) {
      latlon.lat += 180;
    }
    while (latlon.lat > 90) {
      latlon.lat -= 180;
    }
    while (latlon.lng < -180) {
      latlon.lng += 360;
    }
    while (latlon.lng > 180) {
      latlon.lng -= 360;
    }
    return latlon;
  }
}

export class RangeParams {
  public RangeInputParams;
  public TempWindParams;
  public Waypoints;
}