import { Component, OnInit, ChangeDetectorRef, Renderer2, Output, EventEmitter, Input, getModuleFactory, ViewChild, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { fromEvent } from 'rxjs';
import { map, first, skip } from 'rxjs/operators';
import device from 'current-device';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';
import { MapService } from 'src/app/services/map.service';
import { LatLng } from 'leaflet';
declare var google;
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @Input() routeIcon: string;
  @Input() inputValue: string;
  @Input() multiwayScrollFromTop: number;
  @Input() waypointIdx: number;
  @Input() failedPlan: boolean;
  @ViewChild('searchInput', { static: false }) searchInput: any;
  @ViewChild('leafletSearch', { static: false }) leafletSearch: any;
  @Output() searchChanged: EventEmitter<any> = new EventEmitter();
  placesService
  autocompleteService;

  constructor(private http: HttpClient, private cdr: ChangeDetectorRef, private renderer2: Renderer2, private mobileResolutionService: MobileResolutionService,
    private mapService: MapService) {
  }

  ngOnInit() {
    this.placesService = new google.maps.places.PlacesService(document.getElementById('attributions'));
    this.autocompleteService = new google.maps.places.AutocompleteService();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.searchDropdownListener) {
      this.searchDropdownListener.unsubscribe();
    }
  }

  searchResults: any;
  searchActive: boolean;
  searchDropdownListener: any;
  firstClick = fromEvent(document, 'click').pipe(first());
  documentClick = fromEvent(document, 'click').pipe(skip(1));
  searchInterval;

  // wait 0,5s after last keypress
  onSearchSubmit(value: string) {
    clearTimeout(this.searchInterval);
    this.searchInterval = setTimeout(() => {
      this.getSearchedList(value);
    }, 500);
  }

  // get map search results
  getSearchedList(value: string) {
    var bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(this.mapService.getMapBounds().getSouthWest().lat, this.mapService.getMapBounds().getSouthWest().lng),
      new google.maps.LatLng(this.mapService.getMapBounds().getNorthEast().lat, this.mapService.getMapBounds().getNorthEast().lng));

    // google autocomplete service for search
    this.autocompleteService.getPlacePredictions({ input: value, bounds: bounds }, (resp) => {
      this.searchResults = resp;
      this.searchActive = true;
      this.cdr.detectChanges();

      //create observable that emits click events
      if (this.searchDropdownListener) {
        this.searchDropdownListener.unsubscribe();
      }

      this.searchDropdownListener = this.firstClick.subscribe(val => {
        this.searchActive = false;
        this.cdr.detectChanges();
      });
    });
  }

  readyToFocusText: boolean = true;

  searchActivated() {
    if (device.mobile()) {
      this.mobileResolutionService.setMobileVisiblePanel("search");
    }
    if (this.readyToFocusText) {
      this.readyToFocusText = false;
      this.searchInput.nativeElement.focus();
      this.searchInput.nativeElement.setSelectionRange(0, this.searchInput.nativeElement.value.length);

      this.searchDropdownListener = this.documentClick.subscribe((val: any) => {
        if (val.target != this.searchInput.nativeElement) {
          this.readyToFocusText = true;
          this.searchDropdownListener.unsubscribe();
        }
      });
    }
  }

  searchInputChanged(value: string) {
    if (value != null && value != "") {
      this.onSearchSubmit(value);
    }
    else {
      this.searchActive = false;
      this.cdr.detectChanges();
    }
  }

  outputValue(searchEl) {
    this.placesService.getDetails({ placeId: searchEl.place_id, fields: ["geometry.location", "geometry.viewport"] }, (results, status) => {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        // lat,lon messed up, should fix
        var boundingbox = [results.geometry.viewport.getNorthEast().lat(), results.geometry.viewport.getNorthEast().lng(),
        results.geometry.viewport.getSouthWest().lat(), results.geometry.viewport.getSouthWest().lng()];
        this.inputValue = searchEl.description;
        var selected = {
          display_name: this.inputValue,
          lat: results.geometry.location.lat(),
          lon: results.geometry.location.lng(),
          boundingbox: boundingbox
        }
        this.searchChanged.emit(selected);
      }
    });
  }

}
