import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { timer } from 'rxjs';
import { MapService } from 'src/app/services/map.service';
import { InputParamsService } from 'src/app/services/input-params.service';
import { LatLng } from 'leaflet';
import { LoggerService } from 'src/app/services/logger.service';

declare var Smartcar;

@Component({
  selector: 'app-smartcar',
  templateUrl: './smartcar.component.html',
  styleUrls: ['./smartcar.component.css']
})
export class SmartcarComponent implements OnInit {

  loggedIn: boolean = false;
  timerSubscription;

  constructor(private http: HttpClient, private mapService: MapService, private inputParamsService: InputParamsService, private loggerService: LoggerService) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    const smartcar = new Smartcar({
      testMode: true,
      clientId: 'eb3f3667-8fd2-43f7-8ea1-cbac68301885',
      redirectUri: 'https://dev.evnavigation.com/assets/smartcar_redirect.html',
      scope: ['read_vehicle_info', 'read_battery', 'read_location', 'read_vin'],
      onComplete: (err, code) => {
        if (err) {
          // handle errors from Connect (i.e. user denies access)
        }
        // handle the returned code by sending it to your back-end server
        var httpopt = {
          headers: new HttpHeaders()
            .append("Content-Type", "application/x-www-form-urlencoded")
        };
        var params = new HttpParams()
          .append("grant", "auth")
          .append("code", code);
        this.http.post("https://dev.evnavigation.com/ecar/smartcar.php", params.toString(), httpopt).subscribe((resp: any) => {
          this.loggedIn = true;
          resp = JSON.parse(resp);
          var httpopt = {
            headers: new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': 'Bearer ' + resp.access_token
            })
          }

          this.http.get("https://api.smartcar.com/v1.0/vehicles", httpopt).subscribe((carsresp: any) => {

            this.http.get("https://api.smartcar.com/v1.0/vehicles/" + carsresp.vehicles[0] + "/battery", httpopt).subscribe((respbat: any) => {
              this.inputParamsService.setBattery(respbat.percentRemaining * 100);
            });

            this.http.get("https://api.smartcar.com/v1.0/vehicles/" + carsresp.vehicles[0] + "/location", httpopt).subscribe((resploc: any) => {
              this.mapService.setMoveTo(new LatLng(resploc.latitude, resploc.longitude));
              this.inputParamsService.setWaypointParams([new LatLng(resploc.latitude, resploc.longitude)]);
            });

            this.http.get("https://api.smartcar.com/v1.0/vehicles/" + carsresp.vehicles[0], httpopt).subscribe((resp: any) => {
              
            });

            this.http.get("https://api.smartcar.com/v1.0/vehicles/" + carsresp.vehicles[0] + "/vin", httpopt).subscribe((resp: any) => {
            });

            /* refresh battery data */
            this.timerSubscription = timer(300000, 300000).subscribe(() => {
              if (this.mapService.getNavigation()) {
                this.http.get("https://api.smartcar.com/v1.0/vehicles/" + carsresp.vehicles[0] + "/battery", httpopt).subscribe((respbat: any) => {
                  this.inputParamsService.ObservableActualBattery.next(respbat.percentRemaining * 100);
                  var rangeInput = this.inputParamsService.getRangeInputParams();
                  if (Math.abs(this.inputParamsService.getRangeInputParams().batteryLevel - (respbat.percentRemaining * 100)) >= 3) {
                    rangeInput.batteryLevel = respbat.percentRemaining * 100;
                    this.inputParamsService.setRangeInputParams(rangeInput);
                  }
                });
              }
            });
          });

        });
      },
    });

    //smartcar.addClickHandler({ id: 'smartcarbtn' });
    this.loggerService.smartcar.addClickHandler({ id: 'smartcarbtn' });
  }

  logout() {
    this.loggedIn = false;
    this.timerSubscription.unsubscribe();
  }
}
