import { BehaviorSubject } from 'rxjs';

export class MobileResolutionService {

  private mobileResolution: boolean;
  private mobileVisiblePanel: string;
  private mobilevisibleRoutePanel: string;
  public ObservableMobileResolution: BehaviorSubject<any>;
  public ObservableMobileVisiblePanel: BehaviorSubject<any>;
  public ObservableMobileVisibleRoutePanel: BehaviorSubject<any>;

  constructor() { 
    this.ObservableMobileResolution = new BehaviorSubject<any>(this.mobileResolution);
    this.ObservableMobileVisiblePanel = new BehaviorSubject<any>(this.mobileVisiblePanel);
    this.ObservableMobileVisibleRoutePanel = new BehaviorSubject<any>(this.mobilevisibleRoutePanel);
  }

  setMobileResolution(res: boolean): void {
    this.mobileResolution = res;
    this.ObservableMobileResolution.next(res);
  }

  getMobileResolution(): boolean{
    return this.mobileResolution;
  }

  setMobileVisiblePanel(name: string): void {
    this.mobileVisiblePanel = name;
    this.ObservableMobileVisiblePanel.next(name);
  }

  getMobileVisiblePanel(): string{
    return this.mobileVisiblePanel;
  }

  setMobileVisibleRoutePanel(name: string): void{
    this.mobilevisibleRoutePanel = name;
    this.ObservableMobileVisibleRoutePanel.next(name);
  }

  getMobileVisibleRoutePanel(){
    return this.mobilevisibleRoutePanel;
  }
}
