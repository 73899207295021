import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { tap, takeUntil, concatMap } from 'rxjs/operators';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MapService } from 'src/app/services/map.service';
import { UtilsService } from 'src/app/services/utils.service';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';

@Component({
  selector: 'app-wind-force',
  templateUrl: './wind-force.component.html',
  styleUrls: ['./wind-force.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindForceComponent implements OnInit, AfterViewInit {
  @HostListener('window:resize') onResize() {
    setTimeout(() => {
      if (window.innerWidth <= 300 || window.innerHeight <= 350) {
        document.getElementById("wind-svg-element").setAttribute("width", "200");
        document.getElementById("wind-svg-element").setAttribute("height", "200");
      }
      else {
        document.getElementById("wind-svg-element").setAttribute("width", "270");
        document.getElementById("wind-svg-element").setAttribute("height", "270");
      }
    }, 750);
  }
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @ViewChild('windCircle', { static: true }) windCircle: ElementRef;
  @ViewChild('windArrow', { static: true }) windArrow: ElementRef;
  @ViewChild('tempCircle', { static: true }) tempCircle: ElementRef;
  @ViewChild('bgCircle', { static: true }) bgCircle: ElementRef;
  @ViewChild('origo', { static: true }) origo: ElementRef;
  @ViewChild('tempButton', { static: true }) tempButton: ElementRef;
  @ViewChild('directionTextEl', { static: true }) directionTextEl: ElementRef;
  @ViewChild('windCenterIcon', { static: true }) windCenterIcon: ElementRef;
  handle: Subscription;
  arrowRotate: string = "rotate(126.44658084335737 169 171)";
  force: number = 32.86659516298563;
  circleClipPath;
  deg: number = 306.44658084335737;
  directionText: string = "NW";
  windForceText: number = 32;
  windUnitText: string = "km/h";
  windUnitTextX = 155;
  directionTextX = 157;
  windForceTextX = 157;
  TemperatureValue = 24;
  TemperatureValueText: string = "24";
  TemperatureUnit = "°C";
  tempvalueTextX = 290;
  tempunitTextX = 292;
  tempClip: string = "rotate(47 169 171)";
  unit = {"speed": "metric", "distance": "metric", "temperature": "metric", "pressure": "metric"};

  constructor(private paramsService: InputParamsService, private mapService: MapService, private cdr: ChangeDetectorRef,
    private utilsService: UtilsService, private mobileResolutionService: MobileResolutionService) {
    this.paramsService.setTempWindParams(this.getWindTempParams());
    var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    if (iOS) {
      window.addEventListener("resize", function () {
        setTimeout(() => {
          if (window.innerWidth <= 300 || window.innerHeight <= 350) {
            document.getElementById("wind-svg-element").setAttribute("width", "200");
            document.getElementById("wind-svg-element").setAttribute("height", "200");
          }
          else {
            document.getElementById("wind-svg-element").setAttribute("width", "270");
            document.getElementById("wind-svg-element").setAttribute("height", "270");
          }
        }, 1000);
      }, false);
    }
  }

  ngOnInit() {
    if (window.innerWidth <= 300 || window.innerHeight <= 350) {
      document.getElementById("wind-svg-element").setAttribute("width", "200");
      document.getElementById("wind-svg-element").setAttribute("height", "200");
    }
    if (navigator.userAgent.includes("FBIOS")) {
      document.getElementById("wind-svg-element").setAttribute("width", "270");
      document.getElementById("wind-svg-element").setAttribute("height", "270");
    }
  }

  // wind - temperature evenets
  ngAfterViewInit() {
    // --------------- Wind click events ---------------
    const wDown = fromEvent(this.windCircle.nativeElement, 'mousedown');
    const wMove = fromEvent(document, 'mousemove').pipe(tap((mm: MouseEvent) => mm.preventDefault()));
    const wUp = fromEvent(document, 'mouseup').pipe(tap((mu: MouseEvent) => mu.preventDefault()));
    const wDrag = wDown.pipe(concatMap(DragEvent => wMove.pipe(takeUntil(wUp))));

    this.handle = wDrag.subscribe((md: MouseEvent) => {
      md.preventDefault();
      this.moveHandle(md.pageX, md.pageY);
    });
    this.handle = wDown.subscribe((md: MouseEvent) => {
      md.preventDefault()
      this.moveHandle(md.pageX, md.pageY);
    });

    const wADown = fromEvent(this.windArrow.nativeElement, 'mousedown');
    const wAMove = fromEvent(document, 'mousemove').pipe(tap((mm: MouseEvent) => mm.preventDefault()));
    const wAUp = fromEvent(document, 'mouseup').pipe(tap((mu: MouseEvent) => mu.preventDefault()));
    const wADrag = wADown.pipe(concatMap(DragEvent => wAMove.pipe(takeUntil(wAUp))));

    this.handle = wADrag.subscribe((md: MouseEvent) => {
      md.preventDefault();
      this.moveHandle(md.pageX, md.pageY);
    });
    this.handle = wADown.subscribe((md: MouseEvent) => {
      md.preventDefault()
      this.moveHandle(md.pageX, md.pageY);
    });

    // --------------- Wind touch events ---------------
    const wTouchStart = fromEvent(this.windCircle.nativeElement, 'touchstart').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const wTouchMove = fromEvent(document, 'touchmove').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const wTouchEnd = fromEvent(document, 'touchend').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const wTouchDrag = wTouchStart.pipe(concatMap(DragEvent => wTouchMove.pipe(takeUntil(wTouchEnd))));

    this.handle = wTouchDrag.subscribe((td: TouchEvent) => {
      td.preventDefault();
      this.moveHandle(td.changedTouches[0].pageX, td.changedTouches[0].pageY);
    });
    this.handle = wTouchStart.subscribe((td: TouchEvent) => {
      td.preventDefault();
      this.moveHandle(td.changedTouches[0].pageX, td.changedTouches[0].pageY);
    });

    const wATouchStart = fromEvent(this.windArrow.nativeElement, 'touchstart').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const wATouchMove = fromEvent(document, 'touchmove').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const wATouchEnd = fromEvent(document, 'touchend').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const wATouchDrag = wATouchStart.pipe(concatMap(DragEvent => wATouchMove.pipe(takeUntil(wATouchEnd))));

    this.handle = wATouchDrag.subscribe((td: TouchEvent) => {
      td.preventDefault();
      this.moveHandle(td.changedTouches[0].pageX, td.changedTouches[0].pageY);
    });
    this.handle = wATouchStart.subscribe((td: TouchEvent) => {
      td.preventDefault();
      this.moveHandle(td.changedTouches[0].pageX, td.changedTouches[0].pageY);
    });

    // --------------- Origo click events ---------------
    const oDown = fromEvent(this.origo.nativeElement, 'mousedown').pipe(tap((md: MouseEvent) => md.preventDefault()));
    oDown.subscribe((md: MouseEvent) => {
      md.preventDefault();
      this.moveHandle(md.pageX, md.pageY);
    })
    const dDown = fromEvent(this.directionTextEl.nativeElement, 'mousedown').pipe(tap((md: MouseEvent) => md.preventDefault()));
    dDown.subscribe((md: MouseEvent) => {
      md.preventDefault();
      this.moveHandle(md.pageX, md.pageY);
    })
    const wiDown = fromEvent(this.windCenterIcon.nativeElement, 'mousedown').pipe(tap((md: MouseEvent) => md.preventDefault()));
    wiDown.subscribe((md: MouseEvent) => {
      md.preventDefault();
      this.moveHandle(md.pageX, md.pageY);
    })

    // --------------- Origo touch events ---------------
    const oTouchStart = fromEvent(this.origo.nativeElement, 'touchstart').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const oTouchMove = fromEvent(this.origo.nativeElement, 'touchmove').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const oTouchEnd = fromEvent(this.origo.nativeElement, 'touchend').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const oTouchDrag = oTouchStart.pipe(concatMap(DragEvent => oTouchMove.pipe(takeUntil(oTouchEnd))));

    this.handle = oTouchDrag.subscribe((td: TouchEvent) => {
      td.preventDefault();
    });
    this.handle = oTouchStart.subscribe((td: TouchEvent) => {
      td.preventDefault();
    });

    const dTouchStart = fromEvent(this.directionTextEl.nativeElement, 'touchstart').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const dTouchMove = fromEvent(this.directionTextEl.nativeElement, 'touchmove').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const dTouchEnd = fromEvent(this.directionTextEl.nativeElement, 'touchend').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const dTouchDrag = dTouchStart.pipe(concatMap(DragEvent => dTouchMove.pipe(takeUntil(dTouchEnd))));

    this.handle = dTouchDrag.subscribe((td: TouchEvent) => {
      td.preventDefault();
    });
    this.handle = dTouchStart.subscribe((td: TouchEvent) => {
      td.preventDefault();
    });

    const wiTouchStart = fromEvent(this.windCenterIcon.nativeElement, 'touchstart').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const wiTouchMove = fromEvent(this.windCenterIcon.nativeElement, 'touchmove').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const wiTouchEnd = fromEvent(this.windCenterIcon.nativeElement, 'touchend').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const wiTouchDrag = wiTouchStart.pipe(concatMap(DragEvent => wiTouchMove.pipe(takeUntil(wiTouchEnd))));

    this.handle = wiTouchDrag.subscribe((td: TouchEvent) => {
      td.preventDefault();
    });
    this.handle = wiTouchStart.subscribe((td: TouchEvent) => {
      td.preventDefault();
    });

    // --------------- Temperature click events (Filled circle) ---------------
    const tDown = fromEvent(this.tempCircle.nativeElement, 'mousedown');
    const tMove = fromEvent(document, 'mousemove').pipe(tap((mm: MouseEvent) => mm.preventDefault()));
    const tUp = fromEvent(document, 'mouseup').pipe(tap((mu: MouseEvent) => mu.preventDefault()));
    const tDrag = tDown.pipe(concatMap(DragEvent => tMove.pipe(takeUntil(tUp))));

    this.handle = tDrag.subscribe((md: MouseEvent) => {
      md.preventDefault();
      this.adjustTemperature(md.pageX, md.pageY);
    });
    this.handle = tDown.subscribe((md: MouseEvent) => {
      md.preventDefault();
      this.adjustTemperature(md.pageX, md.pageY);
    });

    // --------------- Temperature touch events (Filled circle) ---------------
    const tTouchStart = fromEvent(this.tempCircle.nativeElement, 'touchstart').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const tTouchMove = fromEvent(document, 'touchmove').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const tTouchEnd = fromEvent(document, 'touchend').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const tTouchDrag = tTouchStart.pipe(concatMap(DragEvent => tTouchMove.pipe(takeUntil(tTouchEnd))));

    this.handle = tTouchDrag.subscribe((td: TouchEvent) => {
      td.preventDefault();
      this.adjustTemperature(td.changedTouches[0].pageX, td.changedTouches[0].pageY);
    });
    this.handle = tTouchStart.subscribe((td: TouchEvent) => {
      td.preventDefault();
      this.adjustTemperature(td.changedTouches[0].pageX, td.changedTouches[0].pageY);
    });

    // --------------- Temperature click events (Background circle) ---------------
    const cDown = fromEvent(this.bgCircle.nativeElement, 'mousedown');
    const cMove = fromEvent(document, 'mousemove').pipe(tap((mm: MouseEvent) => mm.preventDefault()));
    const cUp = fromEvent(document, 'mouseup').pipe(tap((mu: MouseEvent) => mu.preventDefault()));
    const cDrag = cDown.pipe(concatMap(DragEvent => cMove.pipe(takeUntil(cUp))));

    this.handle = cDrag.subscribe((md: MouseEvent) => {
      md.preventDefault();
      this.adjustTemperature(md.pageX, md.pageY);
    });
    this.handle = cDown.subscribe((md: MouseEvent) => {
      md.preventDefault();
      this.adjustTemperature(md.pageX, md.pageY);
    });

    // --------------- Temperature touch events (Background circle) ---------------
    const cTouchStart = fromEvent(this.bgCircle.nativeElement, 'touchstart').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const cTouchMove = fromEvent(document, 'touchmove').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const cTouchEnd = fromEvent(document, 'touchend').pipe(tap((tm: TouchEvent) => tm.preventDefault()));
    const cTouchDrag = cTouchStart.pipe(concatMap(DragEvent => cTouchMove.pipe(takeUntil(cTouchEnd))));

    this.handle = cTouchDrag.subscribe((td: TouchEvent) => {
      td.preventDefault();
      this.adjustTemperature(td.changedTouches[0].pageX, td.changedTouches[0].pageY);
    });
    this.handle = cTouchStart.subscribe((td: TouchEvent) => {
      td.preventDefault();
      this.adjustTemperature(td.changedTouches[0].pageX, td.changedTouches[0].pageY);
    });

    // observe unit
    this.mapService.ObservableUnit.subscribe((resp: any) => {
      if (resp) {
        this.unit = resp;
        this.setUnit();
        this.cdr.detectChanges();
      }
    });

    // load cookies
    if (this.paramsService.AcceptCookies) {
      var windDeg = localStorage.getItem("windDeg");
      var windSpeed = localStorage.getItem("windSpeed");
      if (windDeg && windSpeed) {
        this.setElementToActualValue(parseFloat(windDeg), parseFloat(windSpeed));
      }
      var temperatureValue = localStorage.getItem("temperatureValue");
      if (temperatureValue) {
        this.setTemperatureValue(parseFloat(temperatureValue));
        this.paramsService.paramsUpdate();
      }
    }
  }

  // set wind component to the actual wind data
  public setElementToActualValue(windDeg: number, windSpeed: number) {
    this.deg = windDeg + 180;
    this.arrowRotate = "rotate(" + this.deg + " 169 171)";
    this.force = windSpeed;
    if (this.unit['speed'] == "imperial") {
      this.windForceText = Math.round(this.utilsService.kmphToMph(this.force));
    }
    else {
      this.windForceText = Math.round(this.force);
    }

    if (this.windForceText.toString().length > 1) {
      this.windForceTextX = 157;
    }
    else {
      this.windForceTextX = 163;
    }

    this.deg += 180;
    if (this.deg < 0) {
      this.deg += 360;
    }
    if (this.deg > 360) {
      this.deg -= 360;
    }

    if (this.windForceText != 0) {
      this.SetDirectionText(this.deg);
    }
    else {
      this.directionText = "";
    }

    this.cdr.detectChanges();
  }

  // moving wind handle to new position
  private moveHandle(pageX: number, pageY: number) {
    var origoY = this.origo.nativeElement.getBoundingClientRect().top + 25 + window.pageYOffset;
    var origoX = this.origo.nativeElement.getBoundingClientRect().left + 25 + window.pageXOffset;
    var mouseDeg = Math.atan2(pageY - origoY, pageX - origoX);
    this.deg = (mouseDeg * 180 / Math.PI) + 90;
    this.arrowRotate = "rotate(" + this.deg + " 169 171)";
    var mouseForce = Math.sqrt(Math.abs((pageX - origoX) * (pageX - origoX)) + Math.abs((pageY - origoY) * (pageY - origoY)));
    this.force = ((mouseForce / 120) * 150) - 36;
    if (this.force > 85) {
      this.force = 85;
    }
    if (this.force < 0) {
      this.force = 0;
    }

    if (this.unit['speed'] == "imperial") {
      this.windForceText = Math.round(this.utilsService.kmphToMph(this.force));
    }
    else {
      this.windForceText = Math.round(this.force);
    }

    if (this.windForceText.toString().length > 1) {
      this.windForceTextX = 157;
    }
    else {
      this.windForceTextX = 163;
    }

    //this.circleClipPath = "circle(" + (this.force + 1) + "px at 19px 85px)";

    this.deg += 180;
    if (this.deg < 0) {
      this.deg += 360;
    }
    if (this.deg > 360) {
      this.deg -= 360;
    }

    if (this.windForceText != 0) {
      this.SetDirectionText(this.deg);
    }
    else {
      this.directionText = "";
    }
    this.paramsService.setTempWindParams(this.getWindTempParams());
    if (this.paramsService.AcceptCookies) {
      localStorage.setItem("windDeg", this.deg.toString());
      localStorage.setItem("windSpeed", this.force.toString());
    }
    this.cdr.detectChanges();
  }

  public setTemperatureValue(temperatureValue: number) {
    if (temperatureValue == undefined) {
      this.TemperatureValueText = "- -";
      this.TemperatureValue = -999;
      this.cdr.detectChanges();
    }
    else {
      var deg = -(((temperatureValue + 20) / 60 * 180) - 180);
      this.tempClip = "rotate(" + Math.round(deg) + " 169 171)";
      this.TemperatureValue = temperatureValue;
      this.SetTempValueText();
      this.paramsService.setTempWindParamsWithNoUpdate(this.getWindTempParams());
      this.cdr.detectChanges();
    }
  }

  // moving temperature slider to a new position
  private adjustTemperature(pageX: number, pageY: number) {
    var origoY = this.origo.nativeElement.getBoundingClientRect().top + 25 + window.pageYOffset;
    var origoX = this.origo.nativeElement.getBoundingClientRect().left + 25 + window.pageXOffset;
    var mouseDeg = Math.atan2(pageY - origoY, pageX - origoX);
    var deg = (mouseDeg * 180 / Math.PI);

    // calculating temperature
    if (deg >= 0 && deg <= 180) {
      this.tempClip = "rotate(" + Math.round(deg) + " 169 171)";
      this.TemperatureValue = Math.round(((180 - deg) / 180 * 60) - 20);
      this.SetTempValueText();
      this.paramsService.setTempWindParams(this.getWindTempParams());
      if (this.paramsService.AcceptCookies) {
        localStorage.setItem("temperatureValue", this.TemperatureValue.toString());
      }
    }
    this.cdr.detectChanges();
  }

  // setting temperature value in digits
  private SetTempValueText() {
    if (this.TemperatureValue == -999) {
      this.TemperatureValueText = "- -";
    }
    else if (this.TemperatureUnit == "°F") {
      this.TemperatureValueText = this.utilsService.ConvertCelsiusToFahrenheit(this.TemperatureValue).toString();
      if (parseFloat(this.TemperatureValueText) > 99) {
        this.tempvalueTextX = 284;
      }
      else {
        this.tempvalueTextX = 290;
      }
    }
    else {
      this.TemperatureValueText = this.TemperatureValue.toString();
      this.tempvalueTextX = 290;
    }
    this.cdr.detectChanges();
  }

  // changing temperature unit
  private setUnit(): void {
    this.TemperatureUnit = this.unit['temperature'] == 'metric' ? "°C" : "°F";
    this.windUnitText = this.unit['speed'] == 'metric' ? "km/h" : "mph";
    this.windForceText = this.unit['speed'] == 'metric' ? Math.round(this.force) : Math.round(this.utilsService.kmphToMph(this.force));
    this.windUnitTextX = this.unit['speed'] == 'metric' ? 155 : 157;
    this.tempunitTextX = this.unit['temperature'] == 'metric' ? 292 : 293;

    this.SetTempValueText();

    this.cdr.detectChanges();
  }

  // setting wind direction text to a new direction
  private SetDirectionText(degree: number) {
    if (degree >= 338 || degree < 23) { this.directionText = "N"; this.directionTextX = 163; }
    else if (degree < 68) { this.directionText = "NE"; this.directionTextX = 158; }
    else if (degree < 113) { this.directionText = "E"; this.directionTextX = 164; }
    else if (degree < 158) { this.directionText = "SE"; this.directionTextX = 160; }
    else if (degree < 203) { this.directionText = "S"; this.directionTextX = 164; }
    else if (degree < 248) { this.directionText = "SW"; this.directionTextX = 158; }
    else if (degree < 293) { this.directionText = "W"; this.directionTextX = 162; }
    else { this.directionText = "NW"; this.directionTextX = 156; }
  }

  // return component parameters
  private getWindTempParams(): any {
    return { windDeg: this.deg, windSpeed: this.force, temperature: this.TemperatureValue }
  }
}
