import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import device from 'current-device';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  private ios: boolean = false;

  constructor() {
    if (device.ios() || device.os=="macos"){
      this.ios = true;
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  openUrl(url): void{
    window.open(url, '_blank');
  }
}
