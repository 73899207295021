import { Component, OnInit, Inject, HostListener, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AccountService, UserProfile } from 'src/app/services/account.service';
import { HttpClient } from '@angular/common/http';
import { TabbedInfoDialogComponent } from '../tabbed-info-dialog/tabbed-info-dialog.component';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgScrollbar } from 'ngx-scrollbar';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-account-dialog',
  templateUrl: './account-dialog.component.html',
  styleUrls: ['./account-dialog.component.css']
})
export class AccountDialogComponent implements OnInit {

  @ViewChild('accountScrollbar', { static: true }) accountScrollbar: NgScrollbar;
  activeStepIndex: number;
  AccountDialogTitles = ["Login", "Register", "Register", "Verify email",
    "Verify email", "Profile", "Change Password", "Forgot password"];
  error: boolean = false;
  countryList = [];
  showPassword = false;
  invalidImg: boolean = false;
  avatarImg: any = null;
  auth2: any;
  backendErrorMsg: string = null;
  emailSent: boolean = false;
  invalidActualPassword: boolean = false;
  existingEmailMsg: string = null;
  registerLoader: boolean = false;
  resendEmailAddress: string = null;
  verifyFailed:boolean = false;

  /* form groups */
  firstStepFormGroup: FormGroup;
  secondStepFormGroup: FormGroup;
  thirdStepFormGroup: FormGroup;
  sixthStepFormGroup: FormGroup;
  seventhStepFormGroup: FormGroup;

  @HostListener('window:resize') onResize() {
    setTimeout(() => {
      this.setDialogSize();
    }, 1000);
  }

  constructor(
    public dialogRef: MatDialogRef<AccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private accountService: AccountService,
    private http: HttpClient, private matDialog: MatDialog, private fb: FormBuilder,
    private utilsSerivce: UtilsService, private cdr: ChangeDetectorRef) {
  }

  /* set variables, load countrylist */
  ngOnInit() {
    this.firstStepFormGroup = this.fb.group({
      loginEmail: ['', [
      ]],
      loginPassword: ['', [
      ]],
      rememberMe: ['', [
      ]]
    });

    this.firstStepFormGroup.valueChanges.subscribe(val => {
      this.error = false;
    });

    this.secondStepFormGroup = this.fb.group({
      registerEmail: ['', [
        Validators.required,
        Validators.email
      ]],
      registerPassword: ['', [
        Validators.required,
        Validators.pattern(/^(?=.*\d)(?=.*[A-Za-z])(?!.*\s).{8,200}$/)
      ]],
      registerNewsletter: ['', [
      ]]
    });

    this.thirdStepFormGroup = this.fb.group({
      registerFirstName: ['', [
        Validators.required,
        Validators.pattern(/^.{2,64}$/)
      ]],
      registerLastName: ['', [
        Validators.required,
        Validators.pattern(/^.{2,64}$/)
      ]],
      registerCountry: ['', [
        Validators.pattern(/^[a-zA-Z ]{2}$/)
      ]],
      registerPhone: ['', [
        Validators.pattern(/^[-0-9+/ ]*$/)
      ]]
    });

    this.sixthStepFormGroup = this.fb.group({
      actualPassword: ['', [
        Validators.required
      ]],
      newPassword: ['', [
        Validators.required,
        Validators.pattern(/^(?=.*\d)(?=.*[A-Za-z])(?!.*\s).{8,200}$/)
      ]],
      cnewPassword: ['', [
        Validators.required,
        Validators.pattern(/^(?=.*\d)(?=.*[A-Za-z])(?!.*\s).{8,200}$/)
      ]]
    }, { validators: this.checkPasswords });

    this.seventhStepFormGroup = this.fb.group({
      recoveryEmail: ['', [
        Validators.required,
        Validators.email
      ]]
    })

    this.http.get("../../assets/countries.json").subscribe((resp: any) => {
      this.countryList = resp;
    });

    this.activeStepIndex = 0;
    if (this.data.process == "profileEdit") {
      this.activeStepIndex = 5;
      var userProfile = this.accountService.getUserProfile();
      this.thirdStepFormGroup.addControl('registerNewsletter', new FormControl());
      this.thirdStepFormGroup.setValue({
        registerFirstName: userProfile.Profile.FirstName,
        registerLastName: userProfile.Profile.LastName,
        registerCountry: userProfile.Profile.Country,
        registerPhone: userProfile.Profile.Phone ? userProfile.Profile.Phone : "",
        registerNewsletter: userProfile.Profile.Newsletter ? userProfile.Profile.Newsletter : ""
      });
      this.avatarImg = userProfile.Profile.Avatar;
    }
    if (this.data.process == "verifySuccess"){
      this.activeStepIndex = 4;
    }
    if (this.data.process == "verifyFailed"){
      this.activeStepIndex = 4;
      this.verifyFailed = true;
    }

    this.fbLibrary();
    this.googleSDK();
  }

  checkPasswords(group: FormGroup) {
    let pass = group.get('newPassword').value;
    let confirmPass = group.get('cnewPassword').value;

    return pass === confirmPass ? null : { notSame: true }
  }

  get loginEmail() {
    return this.firstStepFormGroup.get("loginEmail");
  }

  get loginPassword() {
    return this.firstStepFormGroup.get("loginPassword");
  }

  get rememberMe() {
    return this.firstStepFormGroup.get("rememberMe");
  }

  get registerEmail() {
    return this.secondStepFormGroup.get("registerEmail");
  }

  get registerPassword() {
    return this.secondStepFormGroup.get("registerPassword");
  }

  get registerNewsletter() {
    return this.secondStepFormGroup.get("registerNewsletter");
  }

  get registerFirstName() {
    return this.thirdStepFormGroup.get("registerFirstName");
  }

  get registerLastName() {
    return this.thirdStepFormGroup.get("registerLastName");
  }

  get registerCountry() {
    return this.thirdStepFormGroup.get("registerCountry");
  }

  get registerPhone() {
    return this.thirdStepFormGroup.get("registerPhone");
  }

  get actualPassword() {
    return this.sixthStepFormGroup.get("actualPassword");
  }

  get newPassword() {
    return this.sixthStepFormGroup.get("newPassword");
  }

  get cnewPassword() {
    return this.sixthStepFormGroup.get("cnewPassword");
  }

  get recoveryEmail() {
    return this.seventhStepFormGroup.get("recoveryEmail");
  }

  get secondFormGroupValid() {
    return this.secondStepFormGroup.valid;
  }

  get thirdFormGroupValid() {
    return this.thirdStepFormGroup.valid;
  }

  get sixthFormGroupValid() {
    return this.sixthStepFormGroup.valid;
  }

  get seventhFormGroupValid() {
    return this.seventhStepFormGroup.valid;
  }

  /* avatar image upload */
  onAvatarChange(event) {
    var file = event.target.files[0];
    if (file) {
      // valid image
      if (file.size / 1024 / 1024 <= 5 && (file.type == "image/png" || file.type == "image/jpeg")) {
        this.invalidImg = false;
        const reader = new FileReader();

        reader.addEventListener("load", () => {
          // convert image file to base64 string
          this.avatarImg = file;
          this.cnewPassword
          document.getElementById("avatar-img").setAttribute("style", "display: none");
          document.getElementById("avatar-div").setAttribute("style", "background-image:url(" + reader.result +");");
        }, false);

        if (file) {
          reader.readAsDataURL(file);
        }
      }
      // invalid upload (>5mb, !jpeg !png)
      else {
        this.invalidImg = true;
      }
    }
    // empty selection
    else {
      this.invalidImg = false;
    }
  }

  /* remove avatar img */
  deleteAvatarImg() {
    this.avatarImg = null;
    document.getElementById("avatar-img").setAttribute("style", "display:none");
    document.getElementById("avatar-div").setAttribute("style", "display:none");
  }

  ngAfterViewInit() {
    this.setDialogSize();
    if (this.avatarImg){
      document.getElementById("avatar-img").setAttribute("style", "");
      document.getElementById("avatar-img").setAttribute("src", "https://staging-api.evnavigation.com/storage" + this.avatarImg);
    }
  }

  /* signing in a user */
  signIn() {
    var login = {
      email: this.loginEmail.value,
      password: this.loginPassword.value,
      rememberMe: this.rememberMe.value
    };
    this.accountService.login(login).subscribe((resp) => {
      if (resp == "error") {
        this.error = true;
      }
      else if (resp.failed && resp.failed == "Email is not verified") {
        this.resendEmailAddress = this.loginEmail.value;
        this.activeStepIndex = 3;
      }
      else {
        this.dialogRef.close();
      }
    });
  }

  /**
   * take to the next step of the form
   * validate user inputs
   */
  nextStep() {
    if (!this.registerLoader) {
      if ((this.activeStepIndex == 1 && !this.secondFormGroupValid)) {
        this.secondStepFormGroup.markAllAsTouched();
      }
      else if (this.activeStepIndex == 2 && !this.thirdFormGroupValid) {
        this.thirdStepFormGroup.markAllAsTouched();
      }
      else {
        if (this.activeStepIndex == 2) {
          this.registerUser();
        }
        else {
          if (this.activeStepIndex == 1) {
            this.accountService.isExist(this.registerEmail.value).subscribe((resp) => {
              if (resp.success && resp.success.message == "email exist") {
                this.existingEmailMsg = this.registerEmail.value + " has already taken"
              }
              if (resp.failed && resp.failed.error == "email not exist") {
                this.existingEmailMsg = null;
                this.activeStepIndex++;
                this.showPassword = false;
              }
            });
          }
          else {
            this.activeStepIndex++;
            this.showPassword = false;
          }
        }
        this.accountScrollbar.scrollToTop();
      }
    }
  }

  /* go back to the form previous step */
  goBack() {
    if (!this.registerLoader) {
      this.activeStepIndex--;
      this.showPassword = false;
      this.backendErrorMsg = null;
    }
  }

  /* register a user */
  registerUser() {
    var profile = new UserProfile;
    profile = {
      Email: this.registerEmail.value,
      FirstName: this.registerFirstName.value,
      LastName: this.registerLastName.value,
      Country: this.registerCountry.value,
      Phone: this.registerPhone.value,
      Avatar: this.avatarImg,
      Newsletter: this.registerNewsletter.value
    };

    this.registerLoader = true;
    this.accountService.registerUser(profile, this.registerPassword.value).subscribe((resp) => {
      this.registerLoader = false;
      if (resp && resp.success) {
        this.resendEmailAddress = this.registerEmail.value;
        this.activeStepIndex++;
      }
      else if (resp && resp.failed) {
        this.backendErrorMsg = resp.failed.error;
      }
    });
  }

  /* sending a new email with the verification code */
  resendEmail() {
    this.accountService.resendActivationEmail(this.resendEmailAddress).subscribe((resp) => {
      if (resp.success) {
        this.emailSent = true;
      }
    });
  }

  /* save a modified profile */
  saveProfile() {
    var profile = new UserProfile;
    profile = {
      Email: this.registerEmail.value,
      FirstName: this.registerFirstName.value,
      LastName: this.registerLastName.value,
      Country: this.registerCountry.value,
      Phone: this.registerPhone.value,
      Avatar: this.avatarImg,
      Newsletter: this.thirdStepFormGroup.get("registerNewsletter").value
    };

    this.accountService.setUser(profile).subscribe((resp) => {
      if (resp && resp.success) {
        this.dialogRef.close();
      }
    });
  }

  deleteProfile() {
    let dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Would you really like to delete your account?",
        warning: "Warning: this will erase all your data and subscription. This action is not recoverable!"
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.accountService.deleteUser().subscribe((result) => {       
          let dialogRefOk = this.matDialog.open(ConfirmationDialogComponent, {
            data: {
              message: "Check your email to confirm deletion of your account.",
              buttons: ['ok']
            }
          });

          dialogRefOk.afterClosed().subscribe((result) => {
            this.accountService.logout();
            this.dialogRef.close();
          });
        });
      }
    });
  }

  changePassword() {
    if ((!this.sixthFormGroupValid)) {
      this.sixthStepFormGroup.markAllAsTouched();
    }
    else {
      var email = this.accountService.getUserProfile().Profile.Email;
      this.accountService.changePassword(email, this.actualPassword.value, this.newPassword.value, this.cnewPassword.value).subscribe((resp) => {
        console.log(resp);
        if (resp.failed && resp.failed.error == "wrong password") {
          this.invalidActualPassword = true;
        }
        if (resp.success) {
          this.invalidActualPassword = false;
          this.dialogRef.close();
        }
      });
    }
  }

  goBackToLogin() {
    this.activeStepIndex = 0;
  }

  recoverPassword() {
    if ((!this.seventhFormGroupValid)) {
      this.seventhStepFormGroup.markAllAsTouched();
    }
    else {
      this.accountService.getForgottenPassword(this.recoveryEmail.value).subscribe((resp) => {
        if (resp && resp.status == true) {
          this.emailSent = true;
        }
      });
    }
  }

  openForgotPassword() {
    this.showPassword = false;
    this.activeStepIndex = 7;
    this.emailSent = false;
    this.accountScrollbar.scrollToTop();
  }

  /* open privacy policy in a modal window */
  openPrivacyPolicy() {
    let dialogRef = this.matDialog.open(TabbedInfoDialogComponent, {
      panelClass: 'dialog-snap-to-fullscreen',
      data: { openMenu: "privacy" }
    });
  }

  /* change password visibility */
  togglePassword() {
    var elType = document.getElementsByClassName("password-input")[0].getAttribute("type");
    if (elType == "password") {
      var elNewType = "text";
      this.showPassword = true;
    }
    else {
      var elNewType = "password";
      this.showPassword = false;
    }
    document.getElementsByClassName("password-input")[0].setAttribute("type", elNewType);
  }

  /* snap the mobil view to fullscreen */
  setDialogSize() {
    if (window.innerWidth <= 500 || window.innerHeight <= 500) {
      document.getElementsByClassName("dialog-snap-to-fullscreen")[0].setAttribute("style", "width: " + window.innerWidth + "px; height: " + window.innerHeight + "px;");
      document.querySelectorAll(".dialog-snap-to-fullscreen .mat-dialog-content")[0].setAttribute("style", "height: " + (window.innerHeight - 46 - 44) + "px");
    }
    else if (window.innerWidth <= 1050 || window.innerHeight <= 700) {
      document.getElementsByClassName("dialog-snap-to-fullscreen")[0].setAttribute("style", "width: " + window.innerWidth + "px; height: " + window.innerHeight + "px;");
      document.querySelectorAll(".dialog-snap-to-fullscreen .mat-dialog-content")[0].setAttribute("style", "height: " + (window.innerHeight - 60 - 52) + "px");
    }
    else {
      document.getElementsByClassName("dialog-snap-to-fullscreen")[0].setAttribute("style", "");
      document.querySelectorAll(".dialog-snap-to-fullscreen .mat-dialog-content")[0].setAttribute("style", "");
    }
  }

  /* social sign in */

  /* init facebook library */
  fbLibrary() {
    (window as any).fbAsyncInit = function () {
      window['FB'].init({
        appId: '3862266610514995',
        cookie: true,
        xfbml: true,
        version: 'v3.1'
      });
      window['FB'].AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  /* sign in wih facebook */
  signInWithFacebook() {
    window['FB'].login((response) => {
      console.log('login response', response);
      if (response.authResponse) {
        //response.authResponse.accessToken
        this.accountService.authenticateSocial("facebook", response.authResponse.userID, response.authResponse.accessToken).subscribe((resp) => {
          if (resp.success) {
            this.dialogRef.close();
          }
        });

      } else {
        console.log('User login failed');
      }
    }, { scope: 'email' });
  }

  /* init google library */
  googleSDK() {
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-jssdk'));
  }

  signInWithGoogle() {
    window['gapi'].load('auth2', () => {
      this.auth2 = window['gapi'].auth2.init({
        client_id: '115047577895-h3p00mu3lf15qclgm3mor36j6egd78og.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });
      this.auth2.signIn().then((resp) => {
        this.accountService.authenticateSocial("google", resp.wc.id_token, resp.wc.access_token).subscribe((resp) => {
          if (resp.success) {
            this.dialogRef.close();
          }
        });
      })
    });

    /*this.auth2.attachClickHandler(this.loginElement.nativeElement, {},
      (googleUser) => {
 
        let profile = googleUser.getBasicProfile();
        console.log('Token || ' + googleUser.getAuthResponse().id_token);
        console.log('ID: ' + profile.getId());
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail());
        //YOUR CODE HERE
 
 
      }, (error) => {
        alert(JSON.stringify(error, undefined, 2));
      });*/

  }

  /* close dialog */
  close() {
    this.dialogRef.close();
  }
}