import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css']
})
export class ErrorDialogComponent implements OnInit {

  svgIcon: any;

  /* error modal window */
  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.errorTitle == "nomap") {
      this.svgIcon = "/assets/popup_icon_nomap.svg";
    }
    else if (data.errorTitle == "planfail") {
      this.svgIcon = "/assets/popup_icon_routeplanningfailed.svg";
    }
    else if (data.errorTitle == "toofar") {
      this.svgIcon = "/assets/popup_icon_toofar.svg";
    }
    else if (data.errorTitle == "zoom") {
      this.svgIcon = "/assets/popup_icon_zoom.svg";
    }
    else if (data.errorTitle == "nochargingstation"){
      this.svgIcon = "/assets/popup_icon_select_charging_station.svg";
    }
  }

  ngOnInit() {
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

}
