import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { fromEvent } from 'rxjs';
import { tap, concatMap, takeUntil } from 'rxjs/operators';
import { HostListener } from '@angular/core';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MatDialog } from '@angular/material/dialog';
import { MobileResolutionService } from 'src/app/services/mobile-resolution.service';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchComponent implements OnInit {

  // input output data
  @Input() onImage: any;
  @Input() offImage: any;
  @Input() value: number;
  @Output() valueChange = new EventEmitter();
  @Output() valueChangeEvent = new EventEmitter();

  @ViewChild('switchOuter', { static: true }) switchControl: ElementRef;

  // recalculating sliders when size changed
  @HostListener('window:resize') onResize() {
    this.setSliderToCurrValue();
  }

  sliderFill: any;
  sliderImg: any;

  grabImage: any = null;

  dragged: boolean = false;

  subscriptions: any = [];

  constructor(private inputParamsService: InputParamsService, public matDialog: MatDialog, private mobileResolutionService:MobileResolutionService) {
  }

  ngOnChanges(): void {
    this.setSliderToCurrValue();
  }

  // subscribe slider move/touch events, setting slider to the default values
  ngOnInit() {
    const sDown = fromEvent(this.switchControl.nativeElement, 'mousedown');
    const sMove = fromEvent(document, 'mousemove').pipe(tap((mm: MouseEvent) => mm.preventDefault()));
    const sUp = fromEvent(document, 'mouseup').pipe(tap((mu: MouseEvent) => mu.preventDefault()));
    const sDrag = sDown.pipe(concatMap(DragEvent => sMove.pipe(takeUntil(sUp))));

    var dragged = false;
    var mddownelement = null;
    sDown.subscribe((md: MouseEvent) => {mddownelement = this;});
    sUp.subscribe((md: MouseEvent) => {if (dragged == false && mddownelement == this) {this.toggle();} dragged = false; mddownelement = null;});

    sDrag.subscribe((md: MouseEvent) => {
      md.preventDefault();
      dragged = true;
      if (!isNaN(md.pageX)) {
        this.moveSliderCondition(md.pageX);
      }
    });

    const sTouchStart = fromEvent(this.switchControl.nativeElement, 'touchstart');
    const sTouchMove = fromEvent(this.switchControl.nativeElement, 'touchmove').pipe(tap((mm: TouchEvent) => mm.preventDefault()));
    const sTouchEnd = fromEvent(this.switchControl.nativeElement, 'touchend').pipe(tap((mm: TouchEvent) => mm.preventDefault()));
    const sTouchDrag = sTouchStart.pipe(concatMap(DragEvent => sTouchMove.pipe(takeUntil(sTouchEnd))));

    sTouchStart.subscribe((td: TouchEvent) => {mddownelement = this;});
    sTouchEnd.subscribe((td: TouchEvent) => {if (dragged == false && mddownelement == this) {this.toggle();} dragged = false; mddownelement = null;});

    sTouchDrag.subscribe((td: TouchEvent) => {
      td.preventDefault();
      dragged = true;
      if (!isNaN(td.changedTouches[0].pageX)) {
        this.moveSliderCondition(td.changedTouches[0].pageX);
      }
    });

    this.sliderFill = this.switchControl.nativeElement.querySelector('.switch-fill');
    this.sliderImg = this.switchControl.nativeElement.querySelector('.switch-image');
    this.setSliderToCurrValue();

    this.subscriptions.push(this.mobileResolutionService.ObservableMobileVisiblePanel.subscribe((resp) => {
      if (resp == "slider") {
        if (navigator.userAgent.includes("FBIOS")){
          this.setSliderToCurrValue();
        }
      }
    }));
  }

  // setting slider to the current value
  setSliderToCurrValue() {
    var sliderWidth = 62;
    var sliderMinPos: number = 16;
    var sliderMaxPos: number = sliderWidth - sliderMinPos;
    var valueInPercent = this.value;
    var newPos: number = (valueInPercent * (sliderMaxPos - sliderMinPos)) + sliderMinPos;
    if (this.sliderFill) {
      this.sliderFill.style.width = (newPos + 16) + "px";
    }
    if (this.sliderImg) {
      this.sliderImg.style.left = (newPos - 16) + "px";
    }
    this.grabImage = this.value == 0 ? this.offImage : this.onImage;
  }

  dialogRef;

  toggle() {
    this.changeValue((this.value == 0 ? 1 : 0));
  }

  // moving slider to the new value from mouse/touch event
  moveSliderCondition(pageX: number) {
      this.moveSlider(pageX);
  }

  moveSlider(pageX: number) {
    const buttonWidth = 16;
    // coords
    var newPos: number = pageX - this.switchControl.nativeElement.getBoundingClientRect().left;
    var sliderMinPos: number = buttonWidth;
    var sliderMaxPos: number = this.switchControl.nativeElement.clientWidth - buttonWidth;

    // overpulled
    if (newPos < sliderMinPos) {
      newPos = sliderMinPos;
    }
    if (newPos > sliderMaxPos) {
      newPos = sliderMaxPos;
    }

    var sliderStepWidth: number = sliderMaxPos - sliderMinPos;
    var currStep: number = ((newPos - sliderMinPos) / sliderStepWidth);
    newPos = sliderMinPos + currStep * sliderStepWidth;

    var currValue: number = (newPos - sliderMinPos) / (sliderMaxPos - sliderMinPos);
    this.changeValue(+this.toFixed(currValue, 0));
  }

  // create number with fixed decimals
  toFixed(num, precision) {
    return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
  }

  // passing slider new value to parent
  changeValue(value: number) {
    this.value = value;
    this.valueChange.emit(parseFloat(this.toFixed(value, 0)));
    this.valueChangeEvent.emit();
  }
}