import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrivacyPolicyDialogComponent } from '../privacy-policy-dialog/privacy-policy-dialog.component';
import device from 'current-device';
import { browser } from 'protractor';
import { TransitionCheckState } from '@angular/material/checkbox';

@Component({
  selector: 'app-gps-error-dialog',
  templateUrl: './gps-error-dialog.component.html',
  styleUrls: ['./gps-error-dialog.component.css']
})
export class GpsErrorDialogComponent implements OnInit {

  /**
   * for showing error dialog, when the user not allowed gps location
   */
  constructor(
    public dialogRef: MatDialogRef<GpsErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  fbAndroid: boolean = navigator.userAgent.includes('FB_IAB');
  fbIos: boolean = navigator.userAgent.includes('FBIOS');

  ngOnInit() {
    // browser detection
    this.detectBrowser();
    if (device.desktop()) {
      if (device.macos()) {
        if (this.browser == "Chrome") {
          this.informationText = this.desktopMacChromeText;
        }
        else {
          this.informationText = this.desktopSafariText;
        }
      }
      else {
        if (this.browser == "Firefox") {
          this.informationText = this.desktopFirefoxText;
        }
        else if (this.browser == "Edge") {
          this.informationText = this.desktopEdgeText;
        }
        else {
          this.informationText = this.dekstopChromeText;
        }
      }
    }
    else {
      if (device.android()) {
        if (this.browser == "Firefox") {
          this.informationText = this.androidFirefoxText;
        }
        else {
          this.informationText = this.androidChromeText;
        }
      }
      else if (device.ios()) {
        //chrome
        if (navigator.userAgent.match('CriOS')) {
          this.informationText = this.iphoneChromeText;
        }
        //safari
        else {
          if (navigator.userAgent.includes('iPhone OS 12') || navigator.userAgent.includes('iPhone OS 11') || navigator.userAgent.includes('iPhone OS 10') || navigator.userAgent.includes('iPhone OS 9') ||
            navigator.userAgent.includes('iPhone OS 8') || navigator.userAgent.includes('iPhone OS 7')) {
            this.informationText = this.iphoneSafari12Text;
          }
          else {
            this.informationText = this.iphoneSafariText;
          }

        }

      }
      else {
        this.informationText = this.androidChromeText;
      }
    }
  }

  // close window
  accept() {
    this.dialogRef.close();
  }

  informationText;
  browser;

  // error info definitions for different browsers
  androidFBText;
  iphoneFBText;

  androidChromeText = [
    'Press the <b>"Lock"</b> icon next to the domain name.',
    'Tap <b>"Edit Site Settings"</b>.',
    'At the permissions,  click allow the location access.',
    'Refresh the website.'
  ];

  androidFirefoxText = [
    'Press the <b>"Lock"</b> icon next to the domain name.',
    'Tap <b>"Edit Site Settings"</b>.',
    'Select the <b>"Location"</b> checkbox, and tap <b>"Clear"</b>.',
    'Share your location whenever you start a location-based procedure.',
    'Refresh the website.'
  ];

  iphoneSafariText = [
    'Open the <b>iPhone Settings</b>.',
    'Select <b>"Privacy"</b>.',
    'Open <b>"Location Services"</b>.',
    'Select <b>"Safari"</b> and tap <b>"While Using the App"</b>.',
    'Press <b>"<small>A</small>A"</b> next to the domain name.',
    'Open <b>"Website Settings"</b>.',
    'Tap <b>"Location"</b> and select <b>"Allow"</b>.',
    'Refresh the website.'
  ];

  iphoneSafari12Text = [
    'Open the <b>iPhone Settings</b>.',
    'Select <b>"Privacy"</b>.',
    'Open <b>"Location Services"</b>.',
    'Select <b>"Safari"</b> and tap <b>"While Using the App"</b>.',
    'Open the <b>iPhone Settings</b>.',
    'Select <b>"General"</b>.',
    'Scroll to the bottom and press <b>"Reset"</b>.',
    'Tap <b>"Reset Location & Privacy"</b>.',
    'Refresh the website.'
  ];

  iphoneChromeText = [
    'Open the <b>iPhone Settings</b>.',
    'Select <b>"Privacy"</b>.',
    'Open <b>"Location Services"</b>.',
    'Select <b>"Chrome"</b> and tap <b>"While Using the App"</b>.',
    'Refresh the website.'
  ];

  dekstopChromeText = [
    'Click the <b>"Lock"</b> icon next to the domain name.',
    'Click <b>"Website Settings"</b>.',
    'Allow location access.',
    'Refresh the website.'
  ];

  desktopFirefoxText = [
    'Press the <b>"Lock"</b> icon next to the domain name.',
    'At the <b>"Access your location"</b> part, click <b>"X"</b> by the <b>"Blocked Temporarily"</b> text.',
    'Share your location whenever you start a location-based procedure.',
    'Refresh the website.'
  ];

  desktopEdgeText = [
    'Press the <b>"Lock"</b> icon next to the domain name.',
    'Turn on the <b>"Location"</b> toggle.',
    'Refresh the website.'
  ];

  desktopSafariText = [
    'Click the <b>"Safari"</b> menu in the top left corner.',
    'Open the settings of this website.',
    'Click <b>"Location"</b>, and allow the location access.',
    'Refresh the website.'
  ]

  desktopMacChromeText = [
    'Click the <b>"Lock"</b> icon next to the domain name.',
    'Click the <b>"Location"</b> dropdown element and select <b>"Allow"</b>.',
    'Refresh the website.'
  ]

  // browser detection data
  header: any = [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor];
  dataos = [
    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'iPhone', value: 'iPhone', version: 'OS' },
    { name: 'iPad', value: 'iPad', version: 'OS' },
    { name: 'Kindle', value: 'Silk', version: 'Silk' },
    { name: 'Android', value: 'Android', version: 'Android' },
    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'Linux', value: 'Linux', version: 'rv' },
    { name: 'Palm', value: 'Palm', version: 'PalmOS' }
  ];
  databrowser = [
    { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
    { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
    { name: 'Safari', value: 'Safari', version: 'Version' },
    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
    { name: 'Edge', value: 'Edge', version: 'Edge' },
    { name: 'Opera', value: 'Opera', version: 'Opera' },
    { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
    { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
  ];

  // regex compare navigator data
  matchItem(string, data) {
    var i = 0,
      j = 0,
      html = '',
      regex,
      regexv,
      match,
      matches,
      version;

    for (i = 0; i < data.length; i += 1) {
      regex = new RegExp(data[i].value, 'i');
      match = regex.test(string);
      if (match) {
        regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
        matches = string.match(regexv);
        version = '';
        if (matches) { if (matches[1]) { matches = matches[1]; } }
        if (matches) {
          matches = matches.split(/[._]+/);
          for (j = 0; j < matches.length; j += 1) {
            if (j === 0) {
              version += matches[j] + '.';
            } else {
              version += matches[j];
            }
          }
        } else {
          version = '0';
        }
        return {
          name: data[i].name,
          version: parseFloat(version)
        };
      }
    }
    return { name: 'unknown', version: 0 };
  }

  detectBrowser() {
    var agent = this.header.join(' '),
      os = this.matchItem(agent, this.dataos),
      browser = this.matchItem(agent, this.databrowser);
    var edge = this.detectEdge();
    this.browser = edge ? "Edge" : browser.name;
  }

  // edge detection
  detectEdge() {
    var ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }

  openUrl(url) {
    location.assign(url);
  }
}
