import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { MapService } from 'src/app/services/map.service';
import { UtilsService } from 'src/app/services/utils.service';
import { skip } from 'rxjs/operators';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../modals/confirmation-dialog/confirmation-dialog.component';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-navigation-hud',
  templateUrl: './navigation-hud.component.html',
  styleUrls: ['./navigation-hud.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationHudComponent implements OnInit {
  @Input() Desktop: boolean;
  turnDistance: number;
  turnDistanceUnit: string;
  turnInstruction: string;
  turnIcon: string;
  secondTurnIcon: string = null;
  navigation: boolean = true;
  subscriptions: any = [];
  speedLimit: number = 0;
  unit: any = { "speed": "metric", "distance": "metric", "temperature": "metric", "pressure": "metric" };
  computersArray = [];
  roadLanesVisible: boolean = false;
  roadLanes: any = [];
  estimatedBatteryLevel: number = 100;
  actualBatteryLevel: number = 100;
  hudBottomOpened: boolean = false;
  documentClick = fromEvent(document, 'click').pipe(skip(1));
  bottomOpenedListener;
  NavigationHudDialogRef;
  NextWaypoint: boolean = false;
  NextWaypointDisplayName = "";

  constructor(private navigationService: NavigationService, private cdr: ChangeDetectorRef, private mapService: MapService, private utilsService: UtilsService,
    private inputParamsService: InputParamsService, private MatDialog: MatDialog) { }

  ngOnInit() {
    /*this.subscriptions.push(this.navigationService.ObservableChangeNavigation.subscribe((resp) => {
      if (resp != undefined && resp == "start") {
        this.navigation = true;
      }
      if (resp != undefined && resp == "exit") {
        this.navigation = false;
      }
      if (resp != undefined && resp == "arrive") {
        this.navigation = false;
      }
    }));*/

    this.subscriptions.push(this.mapService.ObservableUnit.subscribe((resp) => {
      if (resp) {
        this.unit = resp;
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.navigationService.ObservableShowRoadLane.subscribe((resp) => {
      if (resp != null) {
        this.roadLanesVisible = resp;
      }
    }
    ));

    this.subscriptions.push(this.navigationService.ObservableNextTurn.pipe(skip(1)).subscribe((resp) => {
      if (resp && this.navigation) {
        var dist = Math.round(resp.NextTurnDistance);
        this.turnDistance = dist;
        this.turnInstruction = resp.NextTurnInstruction.Maneuver.name.toLowerCase();
        this.turnInstruction = this.turnInstruction.charAt(0).toUpperCase() + this.turnInstruction.slice(1);
        this.estimatedBatteryLevel = resp.EstimatedBatteryLevel;

        if (resp.NextTurnInstruction.Maneuver.id.includes("_AND_")) {
          var icon = resp.NextTurnInstruction.Maneuver.id.toLowerCase();
          icon = icon.split("_and_");
          this.turnIcon = "assets/turn/big_icon/" + icon[0] + ".png";
          this.secondTurnIcon = "assets/turn/big_icon/" + icon[1] + ".png";
        }
        else {
          this.turnIcon = "assets/turn/big_icon/" + resp.NextTurnInstruction.Maneuver.icon;
          this.secondTurnIcon = null;
        }
        this.computersArray = [];
        if (resp.DistToNextChargingStation != false && resp.DistToNextWaypoint != false) {
          if (resp.DistToNextWaypoint < resp.DistToNextChargingStation) {
            this.computersArray.push({ icon: "assets/computer_icon_waypoint.svg", distToDest: resp.DistToNextWaypoint, timeToDest: this.utilsService.timeConverter(resp.TimeToNextWaypoint) });
          }
          else {
            this.computersArray.push({ icon: "assets/chargingplan_icon.svg", distToDest: resp.DistToNextChargingStation, timeToDest: this.utilsService.timeConverter(resp.TimeToNextChargingStation) });
          }
        }
        else if (resp.DistToNextWaypoint != false) {
          this.computersArray.push({ icon: "assets/computer_icon_waypoint.svg", distToDest: resp.DistToNextWaypoint, timeToDest: this.utilsService.timeConverter(resp.TimeToNextWaypoint) });
        }
        else if (resp.DistToNextChargingStation != false) {
          this.computersArray.push({ icon: "assets/chargingplan_icon.svg", distToDest: resp.DistToNextChargingStation, timeToDest: this.utilsService.timeConverter(resp.TimeToNextChargingStation) });
        }

        this.computersArray.push({ icon: "assets/target_icon.svg", distToDest: resp.DistToDest, timeToDest: this.utilsService.timeConverter(resp.TimeToDest) });
        this.speedLimit = resp.SpeedLimit;
        this.cdr.detectChanges();
      }
      if (resp.DistToNextWaypoint != false) {
        this.NextWaypoint = true;
        this.NextWaypointDisplayName = resp.NextWaypointDisplayName;
      }
      else {
        this.NextWaypoint = false;
      }
      if (resp && resp.NextTurnInstruction && resp.NextTurnInstruction.Roadlanes.length > 0) {
        this.roadLanes = resp.NextTurnInstruction.Roadlanes;
        this.cdr.detectChanges();
      }
      else {
        this.roadLanes = [];
        this.cdr.detectChanges();
      }
    }));

    this.subscriptions.push(this.inputParamsService.ObservableActualBattery.subscribe((resp) => {
      if (resp) {
        this.actualBatteryLevel = resp;
        this.cdr.detectChanges();
      }
    }));
  }

  openBottomPanel() {
    this.hudBottomOpened = true;
    this.bottomOpenedListener = this.documentClick.subscribe((val: any) => {
      this.hudBottomOpened = false;
      this.cdr.detectChanges();
      this.bottomOpenedListener.unsubscribe();
    });
    this.cdr.detectChanges();
  }

  skipWaypoint() {
    var skipmessage = "";
    if(this.NextWaypointDisplayName!=null && this.NextWaypointDisplayName!=undefined){
      skipmessage = "Do you really want to skip <b>" + this.NextWaypointDisplayName + "</b>?";
    }
    else{
      skipmessage = "Do you really want to skip the next waypoint?";
    }

    
    this.NavigationHudDialogRef = this.MatDialog.open(ConfirmationDialogComponent, { data: { message: skipmessage } });

    this.NavigationHudDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.navigationService.SkipNextWaypoint();
      }
      this.NavigationHudDialogRef = null;
    });
  }

  stopNavigation() {
    this.NavigationHudDialogRef = this.MatDialog.open(ConfirmationDialogComponent, { data: { message: "Do you really want to stop the navigation?" } });

    this.NavigationHudDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.navigationService.ObservableChangeNavigation.next("exit");
      }
      this.NavigationHudDialogRef = null;
    });
  }

  showMetricMeter(m: number): boolean {
    if (m < 1000 && this.unit['distance'] == 'metric') {
      return true;
    }
    else {
      return false;
    }
  }
  showMetricKMeter(m: number): boolean {
    if (m >= 1000 && this.unit['distance'] == 'metric') {
      return true;
    }
    else {
      return false;
    }
  }
  showImperialYard(m: number): boolean {
    if (this.utilsService.mToYard(m) < 1760 && this.unit['distance'] == 'imperial') {
      return true;
    }
    else {
      return false;
    }
  }
  showImperialMile(m: number): boolean {
    if (this.utilsService.mToYard(m) >= 1760 && this.unit['distance'] == 'imperial') {
      return true;
    }
    else {
      return false;
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.NavigationHudDialogRef) {
      this.NavigationHudDialogRef.close();
    }
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];
  }
}
