import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { InputParamsService } from 'src/app/services/input-params.service';
import { MapService } from 'src/app/services/map.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-wind-tab',
  templateUrl: './wind-tab.component.html',
  styleUrls: ['./wind-tab.component.css']
})
export class WindTabComponent implements OnInit {

  temperatureImg: string;
  temperatureValue: number;
  temperatureValueText: number;
  temperatureUnit: string = "°C";
  windDeg: string;
  windForce: number;
  windForceText: number;
  windUnit: string = "km/h";
  subscriptions = [];
  unit = 'metric';

  weatherImages = [
    { id: 1, img: "weather_icon_clear_sky_day.svg"},
    { id: 2, img: "weather_icon_few_clouds_day.svg"},
    { id: 3, img: "weather_icon_scattered_clouds.svg"},
    { id: 4, img: "weather_icon_broken_clouds.svg"},
    { id: 9, img: "weather_icon_shower_rain.svg"},
    { id: 10, img: "weather_icon_rain.svg"},
    { id: 11, img: "weather_icon_thunderstorm.svg"},
    { id: 13, img: "weather_icon_snow.svg"},
    { id: 50, img: "weather_icon_mist.svg"},
    { id: 101, img: "weather_icon_clear_sky_night.svg"},
    { id: 102, img: "weather_icon_few_clouds_night.svg"},
    { id: 103, img: "weather_icon_scattered_clouds.svg"},
    { id: 104, img: "weather_icon_broken_clouds.svg"},
    { id: 109, img: "weather_icon_shower_rain.svg"},
    { id: 110, img: "weather_icon_rain.svg"},
    { id: 111, img: "weather_icon_thunderstorm.svg"},
    { id: 113, img: "weather_icon_snow.svg"},
    { id: 150, img: "weather_icon_mist.svg"}
  ];

  constructor(private inputParamsService: InputParamsService, private cdr: ChangeDetectorRef, private mapService: MapService,
    private utilsService: UtilsService) {
  }

  public setTemperature(temperature, weathercode, windDeg, windForce){
    this.subscriptions.push(
    this.mapService.ObservableUnit.subscribe((resp) => {
      if (resp) {
        this.unit = resp;
        this.setValues();
        this.cdr.detectChanges();
      }
    }));
    this.temperatureValue = temperature;
    for (let i = 0; i<this.weatherImages.length; i++){
      if (this.weatherImages[i].id == weathercode){
        this.temperatureImg = this.weatherImages[i].img;
        break;
      }
    }
    this.windDeg = windDeg;
    this.windForce = windForce;
    this.setValues();
  }

  ngOnInit() {
  }

  private setValues(): void {
    if (this.unit['temperature'] == "imperial") {
      this.temperatureUnit = "°F";
      this.temperatureValueText = Math.round(this.utilsService.ConvertCelsiusToFahrenheit(this.temperatureValue));
    }
    else {
      this.temperatureUnit = "°C";
      this.temperatureValueText = Math.round(this.temperatureValue);
    }

    if (this.unit['speed'] == "imperial") {
      this.windUnit = "mph";
      this.windForceText = Math.round(this.utilsService.kmphToMph(this.windForce));
    }
    else {
      this.windUnit = "km/h";
      this.windForceText = Math.round(this.windForce);
    }

    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    for (let i = 0; i < this.subscriptions.length; i++) {
      this.subscriptions[i].unsubscribe();
    }
    this.subscriptions = [];

  }
}
