import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AccountService } from 'src/app/services/account.service';
import { AccountDialogComponent } from '../account-dialog/account-dialog.component';

@Component({
  selector: 'app-profile-mobile-menu-dialog',
  templateUrl: './profile-mobile-menu-dialog.component.html',
  styleUrls: ['./profile-mobile-menu-dialog.component.css']
})
export class ProfileMobileMenuDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ProfileMobileMenuDialogComponent>,
    private accountService: AccountService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  editProfile(){
    var process = "profileEdit";
    this.matDialog.open(AccountDialogComponent, {
      data: { process: process },
      panelClass: 'dialog-snap-to-fullscreen'
    });
    this.dialogRef.close();
  }

  logout(){
    this.accountService.logout();
    this.dialogRef.close();
  }

  close(){
    this.dialogRef.close();
  }
}
